/**
 *  Note: 'as const' in Typescript is also known as 'const assertion' or 'type narrowing'.
 *
 *  Adding 'as const' at the end of the object does the following:
 *  - Makes all the values READONLY - includes nested objects - cannot accidentally override any route.
 *  - Makes all the values LITERAL TYPES (instead of general 'string' type).
 */

export const routes = {
  home: '/',
  contactUs: '/contactus',
  privacy: '/privacy',
  termsandconditions: '/termsandconditions',
  password: {
    set: '/passwordset',
    reset: '/passwordreset',
    sendReset: '/send-reset-password',
  },
  emailVerification: '/email-verification',
  actionEvent: '/action-event',
  profile: '/profile',
  signup: '/signup',
  login: '/login',
  dashboard: '/dashboard',
  calendar: '/calendar',
  experiences: '/experiences',
  createExperience: '/experiences/create',
  statements: {
    main: '/statements',
    details: (id: string | number) => `/statements/${id}`,
  },
  messages: '/messages',
  photoGuidelines: '/photoguidelines',
  formsubmit: '/formsubmit',
  memberships: '/memberships',
  createMembership: '/memberships/create',
} as const;
