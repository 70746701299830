import './leftColumnContainer.css';
import { MainItem } from './MainItem';
import {
  MenuProgressStatus,
  MembershipCategory,
} from '@/hooks/useMembershipCreationHooks';

interface LeftColumnContainerProps {
  changePage: (
    sectionId: number,
    subItem: number,
    finished: MenuProgressStatus
  ) => void;
  currentSection: number;
  currentSubItem: number;
  categories: MembershipCategory[];
}

export const LeftNavigation = ({
  changePage,
  categories,
  currentSection,
  currentSubItem,
}: LeftColumnContainerProps) => {
  return (
    <div className="bg-light">
      <div className="left-column-container bg-light d-md-block d-none">
        <div className="listing">Create a membership</div>

        <div className="left-menu">
          {categories.map((category, i) => (
            <MainItem
              key={category.sectionId + category.title}
              id={i}
              category={category}
              changePage={changePage}
              pageIsOpen={currentSection === category.sectionId}
              currentSection={currentSection}
              currentSubItem={currentSubItem}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
