import { Experience, ExperienceStatus } from '@/types';
import {
  Button,
  LockedIcon,
  Stack,
  SuccessIcon,
  TimeIcon,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import { Typography } from '@mui/material';
import { formatDate, formatTime } from '@/utils';
import { SVGProps } from 'react';
import useSubmitExperienceMutation from '@/hooks/mutations/useSubmitExperienceMutation';

export type TitleAndDescriptionSectionPropsType = {
  experience: Experience;
  isCompleted?: boolean;
  onFormSubmit?: (data: Experience) => void;
};

const ExperienceStatusSection = ({
  title,
  details,
  extraDetails,
  iconColor,
  titleColor,
  detailsColor,
  Icon,
}: {
  title: string;
  details: string;
  extraDetails?: string;
  iconColor?: string;
  titleColor?: string;
  detailsColor?: string;
  Icon: React.ForwardRefExoticComponent<
    Omit<SVGProps<SVGSVGElement>, 'ref'> & React.RefAttributes<SVGSVGElement>
  >;
}) => {
  return (
    <>
      <Icon color={iconColor} width={80} />
      <Typography color={titleColor} variant="h5">
        {title}
      </Typography>
      <Typography color={detailsColor} textAlign="center">
        {details}
      </Typography>
      {!!extraDetails && (
        <Typography color={detailsColor} textAlign="center">
          {extraDetails}
        </Typography>
      )}
    </>
  );
};

export const SubmitForApprovalSection = ({
  experience,
  onFormSubmit,
  isCompleted,
}: TitleAndDescriptionSectionPropsType) => {
  const onSubmit = async () => {
    const submittedExperience = await submitExperience({
      productId: experience.id,
    });
    onFormSubmit && onFormSubmit(submittedExperience);
  };

  const { mutateAsync: submitExperience } = useSubmitExperienceMutation();
  const theme = useTheme();
  const RenderStatus = () => {
    switch (experience.status) {
      case ExperienceStatus['In Progress']: {
        if (isCompleted) {
          return (
            <>
              <ExperienceStatusSection
                Icon={LockedIcon}
                title="Submit for Approval"
                details="Your experience is ready to be submission"
                extraDetails="Please allow 24-48 hours to process your request."
              />
              <Button
                data-testid="submitForApprovalBtn"
                variant="contained"
                size="large"
                sx={{ mt: 2 }}
                onClick={() => {
                  onSubmit();
                }}
              >
                Submit for Approval
              </Button>
            </>
          );
        }
        return (
          <>
            <ExperienceStatusSection
              Icon={LockedIcon}
              title="Submit for Approval"
              details="Finish building your experience and unlock the ability to submit for approval."
            />
          </>
        );
      }
      case ExperienceStatus['Approved']:
      case ExperienceStatus['Published']:
      case ExperienceStatus['Hidden']:
        return (
          <>
            <ExperienceStatusSection
              Icon={SuccessIcon}
              iconColor={theme.palette.success.main}
              title="Experience approved"
              titleColor={theme.palette.success.main}
              details={
                !!experience.approvedAt
                  ? `Your experience was approved on ${formatDate(
                      experience.approvedAt
                    )} at ${formatTime(experience.approvedAt)}.`
                  : 'Your experience was approved.'
              }
            />
          </>
        );
      case ExperienceStatus['Pending Approval']:
        return (
          <ExperienceStatusSection
            Icon={TimeIcon}
            title="Experience submitted for approval"
            details={
              !!experience.submittedAt
                ? `Your experience was submitted on ${formatDate(
                    experience.submittedAt
                  )} at ${formatTime(experience.submittedAt)}.`
                : 'Your experience was submitted.'
            }
            detailsColor={theme.palette.success.main}
            extraDetails="Please allow 24-48 hours to process your request."
          />
        );
      default:
        return null;
    }
  };

  return (
    <Stack alignItems="center" spacing={1}>
      <RenderStatus />
    </Stack>
  );
};
