import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks';

interface ProtectedRoutePropsType {
  children: JSX.Element;
}

export const ProtectedRoute = ({ children }: ProtectedRoutePropsType) => {
  const { data: user, hasFirebaseUser, isFirebaseLoading } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasFirebaseUser && !isFirebaseLoading) {
      navigate('/login', { replace: true, state: { from: location.pathname } });
    }
  }, [user, hasFirebaseUser, isFirebaseLoading]);

  return children;
};
