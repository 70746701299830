import { useMerchant } from '@/hooks';
import { MainCommunicationCard, MainCommunicationMessage } from '@/types';
import { createInitialsFromName, formatTime } from '@/utils';
import { Avatar, Stack } from '@mui/material';
import { Grid, Typography } from '@silverstein-properties/inspirelabs-ui';
import { SYSTEM_GENERATED_COMMUNICATION_TYPE } from '@/utils/communicationUtils';
import { NotificationCard } from '../NotificationCard';
import { MainMessageIframe } from '../MainMessageIframe/MainMessageIframe';

export type MainMessageCardProps = {
  mainCommunicationCard: MainCommunicationCard;
  message: MainCommunicationMessage;
  openBookingRequestViewQuoteDrawer: () => void;
  isQuoteAdded: boolean;
};

export const MainMessageCard = ({
  mainCommunicationCard,
  message,
  openBookingRequestViewQuoteDrawer,
  isQuoteAdded,
}: MainMessageCardProps) => {
  const { data: merchantProfile } = useMerchant();

  const senderName = message.senderIsMerchant
    ? merchantProfile?.name
    : mainCommunicationCard.consumerName;

  return (
    <Grid
      container
      item
      sx={{ alignItems: 'center', ':not(:last-child)': { pb: 4 } }}
      xs={12}
    >
      {SYSTEM_GENERATED_COMMUNICATION_TYPE.includes(
        message.communicationMessageType
      ) ? (
        <NotificationCard
          consumerName={`${message.participantFirstName} ${message.participantLastName}`}
          message={message}
          noOfGuests={mainCommunicationCard.totalNumberOfConsumers}
          openBookingRequestViewQuoteDrawer={openBookingRequestViewQuoteDrawer}
          isQuoteAdded={isQuoteAdded}
        />
      ) : (
        <>
          <Grid container item>
            <Stack alignItems="center" flexDirection="row" columnGap={1}>
              <Avatar
                alt="Sender"
                sx={{
                  color: ({ palette }) => palette.primary.main,
                  height: 36,
                  width: 36,
                }}
                src={
                  message.senderIsMerchant ? merchantProfile?.photo : undefined
                }
              >
                {createInitialsFromName(senderName)}
              </Avatar>
              <Typography variant="subtitle2">{senderName}</Typography>&middot;
              <Typography variant="body3" color="primary.medium">
                {formatTime(message.sentAt)}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs sx={{ ml: 5.5 }}>
            <MainMessageIframe htmlContent={message.htmlFormat} />
          </Grid>
        </>
      )}
    </Grid>
  );
};
