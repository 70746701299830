// Since picture speaks a thousand words, here's the diagram that explains the idea of a layout:
// an <Outlet /> is a container for dynamic content
// a <Footer /> is a shared component for this specific layout
//
// ┌────┐
// │Page│
// ├────┴───────────────────────────────┐
// │ ┌──────┐                           │
// │ │Outlet│                           │
// │ ├──────┴─────────────────────────┐ │
// │ │                                │ │
// │ │                                │ │
// │ │                                │ │
// │ │                                │ │
// │ │                                │ │
// │ │                                │ │
// │ │       ┌───────────────┐        │ │
// │ │       │Dynamic content│        │ │
// │ │       └───────────────┘        │ │
// │ │                                │ │
// │ │                                │ │
// │ │                                │ │
// │ │                                │ │
// │ │                                │ │
// │ │                                │ │
// │ │                                │ │
// │ │                                │ │
// │ │                                │ │
// │ └────────────────────────────────┘ │
// │ ┌────────────────────────────────┐ │
// │ │                                │ │
// │ │            ┌──────┐            │ │
// │ │            │Footer│            │ │
// │ │            └──────┘            │ │
// │ │                                │ │
// │ └────────────────────────────────┘ │
// └────────────────────────────────────┘

import { HubLogo } from '@/assets';
import { routes } from '@/routing/routes';
import { Footer, useTheme } from '@silverstein-properties/inspirelabs-ui';
import { Outlet } from 'react-router';

export const LayoutWithFooter = () => {
  const theme = useTheme();

  return (
    <>
      <Outlet />

      <Footer
        companyName="Inspire Labs"
        logo={
          <HubLogo
            width={149}
            height={64}
            paddingBottom={theme.spacers.xxl3}
            paddingTop={theme.spacers.m}
          />
        }
        links={[
          { title: 'Privacy Policy', link: routes.privacy },
          { title: 'Terms & Conditions', link: routes.termsandconditions },
          { title: 'Fair Housing Policy', link: routes.termsandconditions },
        ]}
      />
    </>
  );
};
