import React from 'react';
import { render, screen } from '@testing-library/react';
import VendorCard from './Component';

test('renders Vendor Card', () => {
  render(
    <VendorCard
      event={{
        image: 'class1.svg',
        title: 'hello',
        location: 'FiDi',
        category: 'Wellness',
        price: '85',
      }}
    />
  );
  const linkElement = screen.getByText('hello');
  expect(linkElement).toBeDefined();
});
