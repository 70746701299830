import { Todo } from 'types/';
import { useAuth } from './useAuth';
import { useQuery } from '@tanstack/react-query';
import { ApiClient } from '../api/apiClient';

export const useTodos = () => {
  const { data: user } = useAuth();
  const apiClient = ApiClient();
  const userId = user?.id.toString();

  const getUserTodos = async (accessToken?: string): Promise<Todo[]> => {
    if (accessToken && userId) {
      return await apiClient.todos.getUserTodos({ userId });
    } else {
      return [];
    }
  };

  return useQuery<Todo[]>(['todos'], () => getUserTodos(user?.accessToken), {
    enabled: !!user && !!user.id,
  });
};
