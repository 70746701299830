import { LabelField } from '@/components';
import { Membership } from '@/types';
import { Stack, Grid, Box } from '@silverstein-properties/inspirelabs-ui';
import { StyledExperiencePhoto } from './PhotoSection.styles';

export type PhotoSectionPropsType = {
  membership: Membership;
};

export const PhotoSection = ({ membership }: PhotoSectionPropsType) => {
  const renderOtherPhotos = (photos: string[]) => {
    if (photos.length) {
      return photos.map(photo => {
        return photo ? (
          <StyledExperiencePhoto
            key={photo}
            src={photo}
            alt="membership image"
            height={120}
            width={180}
          />
        ) : null;
      });
    } else {
      return 'Add additional photos to help show off your membership!';
    }
  };
  return (
    <Stack spacing={4}>
      <LabelField
        label="Cover photo"
        value={
          <Box sx={{ mt: 2 }}>
            {membership.coverPhoto ? (
              <StyledExperiencePhoto
                src={membership.coverPhoto}
                alt={membership.name}
                height={286}
                width={384}
              />
            ) : (
              'Add a cover photo to help show off your membership!'
            )}
          </Box>
        }
      />
      <LabelField
        label="Other photos"
        value={
          <Grid container sx={{ mt: 2, gap: 3 }}>
            {renderOtherPhotos(
              Object.values(membership.photos || {}).filter(
                photo => !!photo.length
              )
            )}
          </Grid>
        }
      />
    </Stack>
  );
};
