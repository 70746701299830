import { ApiClient } from '@/api/apiClient';
import {
  BookingRequestLoaded,
  BookingRequestStatus,
  Pagination,
  PaginationQuery,
} from '@/types';
import { useQuery } from '@tanstack/react-query';

export const useGetAllBookingRequestsLoaded = (
  {
    productId,
    status,
    search,
    pagination,
  }: {
    productId: string;
    search?: string;
    status?: BookingRequestStatus[];
    pagination: PaginationQuery;
  },
  shouldCallApi = true
) => {
  const apiClient = ApiClient();

  const getAllBookingRequestLoaded = async (): Promise<
    Pagination<BookingRequestLoaded>
  > => {
    return await apiClient.bookingRequests.getAllBookingRequests({
      productId,
      status,
      search,
      pagination,
    });
  };

  return useQuery<Pagination<BookingRequestLoaded>>(
    ['AllBookingRequestsLoaded', productId, status, search, pagination],
    () => getAllBookingRequestLoaded(),
    { enabled: shouldCallApi }
  );
};
