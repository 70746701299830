import { Skeleton, Stack } from '@silverstein-properties/inspirelabs-ui';

export const QuoteSkeleton = () => {
  return (
    <Stack spacing={2}>
      <Skeleton variant="rounded" height={32} />
      <br />
      <Skeleton variant="rounded" width="50%" />
      <Skeleton variant="rounded" />
      <br />
      <Skeleton variant="rounded" width="20%" />
      <Skeleton variant="rounded" height={40} />
      <br />
      <Skeleton variant="rounded" width="30%" />
      <Skeleton variant="rounded" height={40} />
      <br />
      <Skeleton variant="rounded" width="40%" />
      <Skeleton variant="rounded" height={40} />
    </Stack>
  );
};
