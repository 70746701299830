import { LabelField } from '@/components';
import { Experience } from '@/types';
import { Stack } from '@mui/material';

export type RequestCommunicationSectionProps = {
  experience: Experience;
};

export const RequestCommunicationSection = ({
  experience,
}: RequestCommunicationSectionProps) => {
  return (
    <Stack spacing={4}>
      <LabelField
        label="Initial Details"
        value={experience.bookingRequestInfo?.info || ''}
      />
    </Stack>
  );
};
