import './vendorCard.css';

export interface VendorCardEvent {
  image: string;
  title: string;
  location: string;
  category: string;
  price: string;
}

export interface VendorCardProps {
  event: VendorCardEvent;
}

export default function VendorCard({ event }: VendorCardProps): JSX.Element {
  const { image, title, location, category, price } = event;

  return (
    <section className="vendor-card bottom-90">
      <div className="frame-3 text-primary">
        <img className="card-image" src={require(`./${image}`)} alt={title} />
        <div className="category-label-container">
          <span className="category-label-text">{category}</span>
        </div>
        <div className="frame-2">
          <div className="frame-1">
            <div className="frame-4">
              <span className="location-label text-start">{location}</span>
              <span className="title-label text-start">{title}</span>
              <span className="price-label text-start">{price}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
