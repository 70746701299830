import './SignupFormContainer.css';
import ContactDetails from './ContactDetails';
import BusinessInfo from './BusinessInfo';
import CompanyInfo from './CompanyInfo';
import { Success } from './Success';
import { useHooks } from './useHooks';
import { useFormData } from '../../contexts/SignUpProvider';

export function SignupFormContainer(): JSX.Element {
  const {
    currentPage,
    handleNextPage,
    handlePrevPage,
    submitAndSuccess,
    warning,
  } = useHooks();

  const { signupFormData } = useFormData();

  return (
    <div className="form-container container">
      {currentPage <= 3 && (
        <div className="row text-start">
          <div className="col-xl-4 col-lg-2 col-md-2 d-md-4 d-sm-block d-none"></div>
          <div className="col-xl-4 col-lg-10 col-md-10 mb-4 px-0 top-64 ps-2">
            <h4>Become a Hub service provider</h4>
          </div>
          <div className="col-xl-4 col-lg-2 col-md-2 d-md-4 pb-3 d-sm-block d-none"></div>
        </div>
      )}
      {currentPage === 1 ? (
        <ContactDetails handleNext={handleNextPage} />
      ) : currentPage === 2 ? (
        <CompanyInfo handleNext={handleNextPage} handlePrev={handlePrevPage} />
      ) : currentPage === 3 ? (
        <BusinessInfo
          handlePrev={handlePrevPage}
          handleNext={handleNextPage}
          submit={submitAndSuccess}
          warning={warning}
        />
      ) : (
        <Success userName={signupFormData.firstName || ''} />
      )}
    </div>
  );
}
