import { AxiosInstance } from 'axios';

export const endpoints = {
  allTaxRates() {
    return '/payments/tax-rates';
  },
  getTaxRate(id: number) {
    return `/payments/tax-rates/${id}`;
  },
};

export const taxRates = ({
  axiosInstance,
}: {
  axiosInstance: AxiosInstance;
}) => {
  return {
    getAllTaxRates: async () => {
      const { data } = await axiosInstance.get(endpoints.allTaxRates());
      return data;
    },
    getTaxRate: async (id: number) => {
      const { data } = await axiosInstance.get(endpoints.getTaxRate(id));
      return data;
    },
  };
};
