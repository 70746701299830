import {
  IsAlpha,
  IsNotEmpty,
  IsNumberString,
  IsOptional,
  IsString,
  MaxLength,
  MinLength,
} from 'class-validator';
import { TEXT_AREA_LIMIT_LONG } from '@/constants';
import { PricingInput } from './PricingInput';
import { DaysOfTheWeek, RepeatDaysType } from '@/types/RecurringEvent';

export class RepeatDaysModalInput {
  selectedDays: DaysOfTheWeek[];
  repeatDaysType: RepeatDaysType;
  repeatDaysInterval: number;
  until: Date;
}

export class TimeIntervalInput {
  intervalSegment: number;
  timeBetweenInterval: number;
}

export interface RecurringEventInput {
  timeInterval: TimeIntervalInput;
  repeatDays: RepeatDaysModalInput;
}

export class AddListingInput extends PricingInput {
  @MinLength(1, { message: 'Please enter a date for this listing' })
  date: string;

  @IsNotEmpty({
    message: 'Please enter a start time for this listing',
  })
  startTime: Date;

  @IsNotEmpty({
    message: 'Please enter an end time for this listing',
  })
  endTime: Date;

  @IsOptional()
  @MinLength(1, { message: 'Please enter an address.' })
  @MaxLength(50, { message: 'Your address is too long.' })
  address?: string;

  @IsOptional()
  @IsString()
  apt?: string;

  @IsOptional()
  @MinLength(1, { message: 'City is blank.' })
  @MaxLength(50, { message: 'City name is too long.' })
  city?: string;

  @IsOptional()
  @IsAlpha(undefined, { message: 'Invalid' })
  @MinLength(2, { message: 'Invalid' })
  @MaxLength(3, { message: 'Invalid' })
  state?: string;

  @IsOptional()
  @IsNumberString(undefined, { message: 'Invalid ZIP' })
  @MinLength(5, { message: 'Invalid ZIP' })
  @MaxLength(10, { message: 'Invalid ZIP' })
  zip?: string;

  @IsOptional()
  @MinLength(1, { message: 'Please enter a description for this location' })
  @MaxLength(TEXT_AREA_LIMIT_LONG, { message: 'Description is too long' })
  description?: string;

  @IsOptional()
  repeatDays?: RepeatDaysModalInput;

  @IsOptional()
  timeInterval?: TimeIntervalInput;
}
