// Disabled rule because this needs to be refactored
// Not worth the effort to fix dozen's of lint errors in this file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { DragEvent, useState, Fragment, useEffect } from 'react';
import UploadIcon from './icon_upload.svg';
import ErrorIcon from './x_icon.svg';
import { UseFormRegister } from 'react-hook-form';
import { useFeatureFlags } from '@/hooks';

export interface FileInputProps {
  onClick?: () => void;
  multiple: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  reset: (e: React.ChangeEvent<HTMLInputElement>) => void;
  register?: UseFormRegister;
  name?: string;
  testId?: string;
  value?: File | string;
  // Disabled rule due to current implementation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  image?: any;
}

export default function FileInput(props: FileInputProps) {
  const [dragActive, setDragActive] = useState(false);
  const [fileSelected, setFileSelected] = useState(undefined);
  const [invalidInput, setInvalidInput] = useState(false);
  const { skipImageValidation } = useFeatureFlags();
  const [changedFromInvalidToValid, setChangedFromInvalidToValid] =
    useState(false);

  const validateImage = async (file: File): Promise<boolean> => {
    if (file instanceof File && !skipImageValidation) {
      const img = new Image();
      img.src = URL.createObjectURL(file); //use image API to access height & width
      await img.decode();
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      const validSize = file.size >= 30000 && file.size <= 15000000; // current validation - 30k min, 15MB max
      const validRectangleRatio =
        width / height >= 1.3 && width / height <= 1.7; // something close to 3:2 ratio
      const validSquareRatio = width / height >= 0.9 && width / height <= 1.1; // something close to square
      const validSevenToFiveRatio =
        width / height >= 1.4 && width / height <= 1.5; // something close to 7:5 ratio
      return (
        validSize &&
        (validRectangleRatio || validSquareRatio || validSevenToFiveRatio)
      );
    }
    return true;
  };

  const handleInputFile = async (
    e: React.ChangeEvent<HTMLInputElement> | File | string
  ) => {
    if (fileSelected) {
      setFileSelected(undefined);
    }
    if (e.target?.files || typeof e === File) {
      const [file] = e.target.files;
      const imageHasCorrectAttributes = await validateImage(file);
      if (!imageHasCorrectAttributes) {
        setFileSelected(false);
        if (changedFromInvalidToValid) {
          setChangedFromInvalidToValid(false);
        }
        setInvalidInput(true);
        props.reset(e);
      } else {
        if (invalidInput) {
          setChangedFromInvalidToValid(true);
          setInvalidInput(false);
        }
        setFileSelected(URL.createObjectURL(file));
        props.handleChange(e);
      }
    }
    if (e instanceof File) {
      //check if there is already an image for this input, set it
      setFileSelected(URL.createObjectURL(e));
      props.handleChange(e);
    }
    if (typeof e === 'string') {
      // if the input props are a string instead of a file(props come from DB instead of file input), set it
      setFileSelected(e);
    }
  };

  const handleDrag = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
    /*
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
    */
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    //actual drag handling to come, need Yael update
    e.preventDefault();
    e.stopPropagation();
    console.log('file dropped');
    setDragActive(false);
    /*
    setDragActive(false);
    handleInputFile(e);
    */
  };

  useEffect(() => {
    if (props.value) {
      handleInputFile(props.value);
    }
  }, []);

  const inputComponent = props.register ? (
    <input
      {...props.register(props.name, {
        onChange: handleInputFile,
      })}
      id="photo-upload"
      type="file"
      accept=".jpg, .jpeg, .png"
      multiple={props.multiple}
    />
  ) : (
    <input
      name={props.name}
      id="photo-upload"
      type="file"
      onChange={handleInputFile}
      accept=".jpg, .jpeg, .png"
      multiple={props.multiple}
    />
  );

  return (
    <div
      data-testid={props.testId}
      className={`file-uploader${dragActive ? ' dragged' : ''}`}
      onDrop={handleDrop}
      onDragOver={handleDrag}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
    >
      <label
        className={`input-label ${invalidInput ? ' invalid-photo' : ''}${
          changedFromInvalidToValid ? ' sucessful-photo-validation' : ''
        }`}
        htmlFor="photo-upload"
      >
        {inputComponent}
        <div className="photo-upload-inner-box">
          <img
            src={
              fileSelected
                ? fileSelected
                : invalidInput
                ? ErrorIcon
                : UploadIcon
            }
            className={
              fileSelected ? 'file-input-selected-image' : 'file-input-icon'
            }
            alt="upload a file"
          />
          {!fileSelected && (
            <span className="photo-subtitle">
              {invalidInput ? (
                'Invalid Image, See Guidelines'
              ) : (
                <Fragment>
                  <u>Upload image</u>
                </Fragment>
              )}
            </span>
          )}
        </div>
      </label>
    </div>
  );
}
