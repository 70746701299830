import { Transform, Type } from 'class-transformer';
import { Allow, ValidateNested } from 'class-validator';
import { FileWithoutStream } from './FileWithoutStream';

export class PhotosInput {
  @Allow()
  @Type(() => FileWithoutStream)
  @Transform(({ obj, key }) => obj[key])
  photo1?: FileWithoutStream | string;
  @Allow()
  @Type(() => FileWithoutStream)
  @Transform(({ obj, key }) => obj[key])
  photo2?: FileWithoutStream | string;
  @Allow()
  @Type(() => FileWithoutStream)
  @Transform(({ obj, key }) => obj[key])
  photo3?: FileWithoutStream | string;
  @Allow()
  @Type(() => FileWithoutStream)
  @Transform(({ obj, key }) => obj[key])
  photo4?: FileWithoutStream | string;
}

export class PhotoSectionEditInput {
  @Allow()
  @Type(() => FileWithoutStream)
  @Transform(({ obj, key }) => obj[key])
  coverPhoto: FileWithoutStream | string;

  @ValidateNested()
  @Type(() => PhotosInput)
  photos?: PhotosInput;
}
