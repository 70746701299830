import { BusinessSignupComponentProps } from './Component';
import { useEffect, useState } from 'react';
import { ApiClient } from '@/api/apiClient';
import {
  styles,
  errorStyles,
  customValueContainer,
} from 'components/FormTextInput/HookFormSelect';
import { DropdownType } from '@/types';
import Select, { MultiValue } from 'react-select';

import { useForm, Controller, ControllerRenderProps } from 'react-hook-form';
import { useFormData } from '../../contexts/MerchCreationProvider';
import { MAIN_CATEGORY_LIMIT, SUB_CATEGORY_LIMIT } from '@/constants';
import { Box, Button, Svg } from '@silverstein-properties/inspirelabs-ui';
import { isTypeSelectionType } from '@/utils';

class ServiceThemeInput {
  category: DropdownType[];
  subCategory: DropdownType[];
  subCategory2?: DropdownType[];
}

export function ServiceTheme(props: BusinessSignupComponentProps) {
  const [categoryOptions, setCategoryOptions] = useState<DropdownType[]>([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState<DropdownType[]>(
    []
  );
  const [disableAddingMainCategories, setDisableAddingMainCategories] =
    useState(false);
  const [disableAddingSubCategories, setDisableAddingSubCategories] =
    useState(false);

  const apiClient = ApiClient();
  const { setFormValues, merchandiseData } = useFormData();
  const {
    control,
    formState: { dirtyFields, errors },
    getValues,
    setValue,
    watch,
    handleSubmit,
  } = useForm<ServiceThemeInput>({
    mode: 'onTouched',
    defaultValues: {
      category: [],
      subCategory: [],
      subCategory2: [],
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // TODO => refactor this into a hook, fix ts-ignore => https://spinspire.monday.com/boards/3396817916/pulses/4843424298
  // retrieve options from back and set default values
  const retrieveOptions = async () => {
    if (!categoryOptions.length && !subCategoryOptions.length) {
      try {
        const categories =
          await apiClient.productLookupItems.getCategoryChoices();
        const subCategories =
          await apiClient.productLookupItems.getSubCategoryChoices();
        if (categories) {
          setCategoryOptions(categories);
        }
        if (subCategories) {
          setSubCategoryOptions(subCategories);
        }
        // if values were pulled from BE and we've been to this page before, assign the categories to the form
        if (merchandiseData.category && categories) {
          setValue(
            'category',
            categories.filter(category =>
              (merchandiseData.category || [])
                .map(category =>
                  isTypeSelectionType(category) ? category?.value : category
                )
                ?.includes(category.value)
            )
          );
        }
        // if values were pulled from BE and we've been to this page before, assign the subcategories to the form
        if (merchandiseData.subCategory && subCategories) {
          setValue(
            'subCategory',
            subCategories.filter(
              subCatFilter =>
                (merchandiseData.subCategory || [])
                  .map(subCategory =>
                    isTypeSelectionType(subCategory)
                      ? subCategory?.value
                      : subCategory
                  )
                  .includes(subCatFilter.value) &&
                subCatFilter.categoryId === getValues('category')[0]?.id
            )
          );
        }
        // if this experience has 2 main categories, assign the subcategories that apply to the 2nd main category to the form
        if (subCategories && getValues('category').length > 1) {
          // subcategories will all be in merchandiseData.subCategory if we're completing an incomplete experience
          if (!merchandiseData.subCategory2) {
            setValue(
              'subCategory2',
              subCategories.filter(
                subcategory =>
                  merchandiseData.subCategory
                    ?.map(subCategory =>
                      isTypeSelectionType(subCategory)
                        ? subCategory?.value
                        : subCategory
                    )
                    .includes(subcategory.value) &&
                  subcategory.categoryId === getValues('category')[1]?.id
              )
            );
          } else {
            // if we're going back to this page from farther down in the form, the 2nd main category's subcategories will be in merchandiseData.subCategory2
            setValue(
              'subCategory2',
              subCategories.filter(
                subcategory =>
                  merchandiseData.subCategory2
                    ?.map(subCategory =>
                      isTypeSelectionType(subCategory)
                        ? subCategory?.value
                        : subCategory
                    )
                    .includes(subcategory.value) &&
                  subcategory.categoryId === getValues('category')[1]?.id
              )
            );
          }
        }
      } catch (err) {
        return;
      }
    }
  };

  // pass filtered subcategories that match main category
  const selectSubcategories = (subCategoryNumber: number) => {
    return subCategoryOptions.filter(
      subcategory =>
        subcategory.categoryId === getValues()?.category[subCategoryNumber]?.id
    );
  };

  // fetch values from backend
  useEffect(() => {
    retrieveOptions();
  }, []);

  // reset subcategory values when user de-selects main category
  // if the user has 2 main categories, and de-selects the first, move the subcategory options in subCategory2 to subCategory
  useEffect(() => {
    const subCategory2 = getValues('subCategory2');
    if (
      getValues('category').length === 1 &&
      getValues('subCategory').length &&
      subCategory2?.length
    ) {
      const subCategoryIdToKeep = getValues('category')[0].id;
      const subCategoryToRemove =
        getValues('subCategory')[0]?.categoryId !== subCategoryIdToKeep
          ? 'subCategory'
          : 'subCategory2';
      if (subCategoryToRemove === 'subCategory') {
        setValue('subCategory', subCategory2);
        setValue('subCategory2', []);
      } else if (subCategoryToRemove === 'subCategory2') {
        setValue('subCategory2', []);
      }
    }
    if (getValues('category').length < 1) {
      setValue('subCategory', []);
    }
  });

  const onSubmit = (data: ServiceThemeInput) => {
    setFormValues(data);
    props.updateExperience(data);
    props.nextPage();
  };

  // only add a selected category/subcategory if the user has selected less than the limit
  // TODO - add more graceful user feedback when maximum category limit is reached => https://spinspire.monday.com/boards/3397008425/pulses/4932736530
  const handleCategoryChange = (
    selectionLimit: number,
    field:
      | ControllerRenderProps<ServiceThemeInput, 'category'>
      | ControllerRenderProps<ServiceThemeInput, 'subCategory'>
      | ControllerRenderProps<ServiceThemeInput, 'subCategory2'>,
    selectedOptions?: MultiValue<unknown>,
    totalOptions?: MultiValue<unknown>
  ) => {
    if (totalOptions && totalOptions.length <= selectionLimit) {
      field.name === 'category'
        ? setDisableAddingMainCategories(false)
        : setDisableAddingSubCategories(false);
      field.onChange(selectedOptions);
    } else {
      field.name === 'category'
        ? setDisableAddingMainCategories(true)
        : setDisableAddingSubCategories(true);
    }
  };

  return (
    <div className="row ms-xl-5 ms-lg-5 me-md-5 ms-sm-1 ms-xs-1 top-64 d-flex justify-content-end">
      <div className="col-8">
        <h4 className="business-form-headline mb-5">Select a service theme</h4>
        <form onSubmit={handleSubmit(onSubmit)} className="theme-form">
          <div className="business-form-subtitle">
            What category best describes your service?
          </div>

          <Controller
            name="category"
            control={control}
            rules={{
              required: { value: true, message: 'Please enter a value' },
            }}
            render={({ field }) => (
              <div data-testid="serviceTheme.categorySelect">
                <Select
                  {...field}
                  inputId="category-dropdown"
                  data-testid="serviceTheme.categorySelect"
                  isSearchable={false}
                  isMulti
                  isOptionDisabled={() => disableAddingMainCategories}
                  onChange={selectedOptions =>
                    handleCategoryChange(
                      MAIN_CATEGORY_LIMIT,
                      field,
                      selectedOptions,
                      selectedOptions
                    )
                  }
                  options={categoryOptions}
                  styles={errors.category ? errorStyles : styles}
                  components={{ ValueContainer: customValueContainer }}
                  placeholder="Category"
                />
              </div>
            )}
          />
          <span className="privacy-policy">{`Select up to ${MAIN_CATEGORY_LIMIT} categories`}</span>
          {!!getValues('category').length ? (
            <>
              <div className="multi-select-container">
                <div className="business-form-subtitle">
                  Please select a sub-category for{' '}
                  {getValues('category')[0]?.label}
                </div>
                <Controller
                  name="subCategory"
                  control={control}
                  rules={{
                    required: { value: true, message: 'Please enter a value' },
                  }}
                  render={({ field }) => (
                    <div data-testid="serviceTheme.categorySelectSub">
                      <Select
                        {...field}
                        inputId="subCategory-dropdown"
                        data-testid="serviceTheme.categorySelectSub"
                        isSearchable={false}
                        isMulti
                        isOptionDisabled={() => disableAddingSubCategories}
                        onChange={selectedOptions =>
                          handleCategoryChange(
                            SUB_CATEGORY_LIMIT,
                            field,
                            selectedOptions,
                            selectedOptions.concat(getValues('subCategory2'))
                          )
                        }
                        options={selectSubcategories(0)}
                        styles={errors.subCategory ? errorStyles : styles}
                        components={{ ValueContainer: customValueContainer }}
                        placeholder="Select"
                      />
                    </div>
                  )}
                />
              </div>
              <span className="privacy-policy">
                {`Select up to ${SUB_CATEGORY_LIMIT} sub-categories in total`}
              </span>
            </>
          ) : null}
          {watch('category')?.length === 2 ? (
            <>
              <div className="multi-select-container">
                <div className="business-form-subtitle">
                  Please select a sub-category for{' '}
                  {getValues('category')[1]?.label}
                </div>
                <Controller
                  name="subCategory2"
                  control={control}
                  rules={{
                    required: {
                      value: getValues('category')?.length === 2,
                      message: 'Please enter a value',
                    },
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      inputId="subCategory2-dropdown"
                      isSearchable={false}
                      isMulti
                      isOptionDisabled={() => disableAddingSubCategories}
                      onChange={selectedOptions =>
                        handleCategoryChange(
                          SUB_CATEGORY_LIMIT,
                          field,
                          selectedOptions,
                          selectedOptions.concat(getValues('subCategory'))
                        )
                      }
                      options={selectSubcategories(1)}
                      styles={errors.subCategory2 ? errorStyles : styles}
                      components={{ ValueContainer: customValueContainer }}
                      placeholder="Select"
                    />
                  )}
                />
              </div>
              <span className="privacy-policy">
                {`Select up to ${SUB_CATEGORY_LIMIT} sub-categories in total`}
              </span>
            </>
          ) : null}
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Button
              variant="text"
              onClick={props.prevPage}
              startIcon={
                <Svg src="/images/chevron-left.svg" height={24} width={24} />
              }
            >
              Back
            </Button>
            {Object.keys(dirtyFields).includes('subCategory') ||
            !!merchandiseData.subCategory ? (
              <Button type="submit">Continue</Button>
            ) : null}
          </Box>
        </form>
      </div>
    </div>
  );
}
