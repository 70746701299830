import {
  SystemNotificationText,
  CommunicationMessageType,
  CommunicationPurchaseType,
  MainCommunicationMessage,
} from '@/types';
import { formatDate, formatTime, pluralize } from '@/utils';
import {
  Grid,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import { createNotificationCardMessage } from '@/utils/communicationUtils';
import { StyledButton } from './NotificationCard.styles';

export type NotificationCardProps = {
  consumerName: string;
  message: MainCommunicationMessage;
  noOfGuests: number;
  openBookingRequestViewQuoteDrawer: () => void;
  isQuoteAdded: boolean;
};

export type NotificationCardConfirmationsProps = {
  consumerName: string;
  message: MainCommunicationMessage;
  noOfGuests: number;
};

export const NotificationCard = ({
  consumerName,
  message,
  noOfGuests,
  openBookingRequestViewQuoteDrawer,
  isQuoteAdded,
}: NotificationCardProps) => {
  if (
    message.communicationPurchaseType ===
      CommunicationPurchaseType.BOOKING_REQUEST &&
    Object.values(SystemNotificationText).some(x => x === message.textBody)
  ) {
    return (
      <BookingRequestNotificationCard
        consumerName={consumerName}
        message={message}
        noOfGuests={noOfGuests}
        openBookingRequestViewQuoteDrawer={openBookingRequestViewQuoteDrawer}
        isQuoteAdded={isQuoteAdded}
      />
    );
  }

  if (
    message.communicationMessageType === CommunicationMessageType.SIGN_UP ||
    message.communicationMessageType === CommunicationMessageType.CANCELLATION
  ) {
    return (
      <NotificationCardConfirmations
        consumerName={consumerName}
        message={message}
        noOfGuests={noOfGuests}
      />
    );
  }

  return <NotificationCardGeneral message={message} />;
};

export const NotificationCardGeneral = ({
  message,
}: {
  message: MainCommunicationMessage;
}) => {
  return (
    <Paper
      elevation={0}
      sx={{
        backgroundColor: ({ palette }) => palette.grey['200'],
        width: '100%',
        borderRadius: ({ shape }) => shape.borderRadius,
        padding: ({ spacing }) => spacing(2),
      }}
    >
      <Grid container item xs>
        <Stack alignItems="center" flexDirection="row" columnGap={1}>
          <Typography variant="subtitle2">{message.textBody}</Typography>
          &middot;
          <Typography variant="body3" color="primary.medium">
            {formatTime(message.sentAt)}
          </Typography>
        </Stack>
      </Grid>
    </Paper>
  );
};

export const NotificationCardConfirmations = ({
  consumerName,
  message,
  noOfGuests,
}: NotificationCardConfirmationsProps) => {
  return (
    <Paper
      elevation={0}
      sx={{
        backgroundColor: ({ palette }) => palette.grey['200'],
        width: '100%',
        borderRadius: ({ shape }) => shape.borderRadius,
        padding: ({ spacing }) => spacing(2),
      }}
    >
      <Grid container item xs>
        <Stack alignItems="center" flexDirection="row" columnGap={1}>
          <Typography variant="subtitle2">{consumerName}</Typography>
          &middot;
          <Typography variant="body3" color="primary.medium">
            {formatTime(message.sentAt)}
          </Typography>
        </Stack>
        <Grid container item>
          <Typography variant="body2">
            {createNotificationCardMessage(
              message.communicationMessageType,
              noOfGuests
            )}
            {formatDate(message.sentAt)} at {formatTime(message.sentAt)}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export const BookingRequestNotificationCard = ({
  consumerName,
  message,
  noOfGuests,
  openBookingRequestViewQuoteDrawer,
  isQuoteAdded,
}: NotificationCardProps) => {
  const theme = useTheme();

  switch (message.textBody) {
    case SystemNotificationText.BOOKING_REQUEST__QUOTE_SENT:
      return (
        <Paper
          elevation={0}
          sx={{
            backgroundColor: ({ palette }) => palette.grey['200'],
            width: '100%',
            borderRadius: ({ shape }) => shape.borderRadius,
            padding: ({ spacing }) => spacing(2),
          }}
        >
          <Grid container item xs>
            <Stack alignItems="center" flexDirection="row" columnGap={1}>
              <Typography variant="subtitle2">{message.textBody}</Typography>
              &middot;
              <Typography variant="body3" color="primary.medium">
                {formatTime(message.sentAt)}
              </Typography>
            </Stack>
            <Grid container item>
              <Typography variant="body2">
                <StyledButton
                  disabled={!isQuoteAdded}
                  onClick={openBookingRequestViewQuoteDrawer}
                  variant="text"
                  sx={{ marginRight: ({ spacing }) => spacing(1) }}
                >
                  view quote
                </StyledButton>
                &#x7c;
                <StyledButton
                  disabled={!isQuoteAdded}
                  onClick={openBookingRequestViewQuoteDrawer}
                  variant="text"
                  sx={{ marginLeft: ({ spacing }) => spacing(1) }}
                >
                  retract quote
                </StyledButton>
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      );

    case SystemNotificationText.BOOKING_REQUEST__BOOKING_CONFIRMATION:
      return (
        <Paper
          elevation={0}
          sx={{
            backgroundColor: theme.palette.success.faint,
            borderRadius: ({ shape }) => shape.borderRadius,
            width: '100%',
            padding: ({ spacing }) => spacing(2),
          }}
        >
          <Grid container item xs>
            <Stack alignItems="center" flexDirection="row" columnGap={1}>
              <Typography variant="subtitle2">{message.textBody}</Typography>
              &middot;
              <Typography variant="body3" color="primary.medium">
                {formatTime(message.sentAt)}
              </Typography>
            </Stack>
            <Grid container item>
              <Typography variant="body2">
                Date: {formatTime(message.sentAt)} /{' '}
                {pluralize(noOfGuests, 'guest')}
              </Typography>
            </Grid>
            <Grid container item>
              <Typography variant="body2">
                <StyledButton
                  onClick={openBookingRequestViewQuoteDrawer}
                  variant="text"
                >
                  View details
                </StyledButton>
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      );
    case SystemNotificationText.BOOKING_REQUEST__QUOTE_RETRACTED:
      return (
        <Paper
          elevation={0}
          sx={{
            backgroundColor: theme.palette.error.light,
            borderRadius: ({ shape }) => shape.borderRadius,
            width: '100%',
            padding: ({ spacing }) => spacing(2),
          }}
        >
          <Grid container item xs>
            <Stack alignItems="center" flexDirection="row" columnGap={1}>
              <Typography variant="subtitle2">{message.textBody}</Typography>
              &middot;
              <Typography variant="body3" color="primary.main">
                {formatTime(message.sentAt)}
              </Typography>
            </Stack>
          </Grid>
        </Paper>
      );
    default:
      return (
        <NotificationCardConfirmations
          consumerName={consumerName}
          message={message}
          noOfGuests={noOfGuests}
        />
      );
  }
};
