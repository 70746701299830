import { Box, styled } from '@silverstein-properties/inspirelabs-ui';

export const StyledDescription = styled(Box)(({ theme }) => ({
  '& a': {
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.primary.light,
    },
  },
}));
