import React, { useState } from 'react';
import { FieldError, UseFormRegister } from 'react-hook-form';
import './FormTextInput.css';

export interface FormTextAreaProps {
  name: string;
  limit: number;
  handleChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  // Disabled rule due current implementation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  isFilled: boolean;
  error?: FieldError;
  label: string;
  size: string;
  // Disabled rule due current implementation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validation?: any;
  textSubtitle?: string;
  initialCharacterCount?: number;
  testid?: string;
}

export function HookFormTextArea({
  label,
  register,
  validation,
  name,
  error,
  isFilled,
  size,
  limit,
  textSubtitle,
  initialCharacterCount,
  testid,
}: FormTextAreaProps): JSX.Element {
  const [wasFocused, setWasFocused] = useState<boolean>(false);
  const [characterCount, setCharacterCount] = useState<number>(
    initialCharacterCount || 0
  );

  return (
    <div className={`text-area ${size}`}>
      <textarea
        data-testid={testid}
        {...register(name, {
          ...validation,
          onChange: e => setCharacterCount(e.target.value?.length),
        })}
        id={name}
        maxLength={limit}
        className={`${error ? 't-area-error' : 't-area'} ${size}`}
        onFocus={() => {
          if (!wasFocused) {
            setWasFocused(true);
          }
        }}
      />
      <label
        aria-labelledby={name}
        htmlFor={name}
        className={`${
          wasFocused || isFilled ? 'label-filled' : `label ${size}`
        } ${error ? 'error' : ''} `}
      >
        {label}
      </label>
      <span
        className={
          characterCount <= limit ? 'character-text' : 'character-text error'
        }
      >
        {textSubtitle ? textSubtitle : `${characterCount}/${limit}`}
      </span>
    </div>
  );
}
