import CheckMark from './checkmark.svg';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  IconBlock,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import { routes } from '@/routing/routes';

export interface SuccessProps {
  userName: string;
}

export function Success(props: SuccessProps): JSX.Element {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Container sx={{ paddingY: theme.spacers.xxl4 }}>
      <IconBlock
        icon={CheckMark}
        iconColor="success.main"
        title={`Thank you, ${props.userName ? props.userName : 'User'}!`}
        titleSize="h4"
        body="We'll be contacting you shortly"
        primaryButtonTitle="Back to home"
        onClickPrimaryButton={() => navigate(routes.home)}
      />
    </Container>
  );
}
