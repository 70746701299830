// Disabled due to current implementation. Needs to be refactored asap
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import LeftColumnContainer from 'components/LeftColumnContainer/Component';
import {
  AboutYou,
  AddCoverPhoto,
  BusinessSuccess,
  Cancellation,
  ExperienceLocation,
  ExperienceRequirements,
  ExperienceYears,
  GroupSize,
  OfferingDescription,
  Pricing,
  ServiceTheme,
  ServiceTitle,
  ServiceType,
} from './BusinessFormPages';

import { useHooks } from './useHooks';
import { useLocations } from 'hooks';
import './businessFormContainer.css';
import React, { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'hooks';
import { BusinessFormValuesType } from 'types/Business';
import { InputChangeEvent, PartialMerchCreationObject, User } from 'types';

export interface BusinessValuesProps {
  values: BusinessFormValuesType;
  reset: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  user?: User;
}

export interface HandleChangeProps {
  prevPage: () => void;
  nextPage: () => void;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleClickChange: (e: InputChangeEvent) => void;
  handleFilterChange: (e: InputChangeEvent) => void;
  handleMultiChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  partiallyCreateExperience: (data: PartialMerchCreationObject) => void;
  updateExperience: (
    data?: PartialMerchCreationObject,
    arePhotosDirty?: boolean
  ) => void;
  updateExperienceLocation: (data: PartialMerchCreationObject) => void;
}

export type BusinessSignupComponentProps = HandleChangeProps &
  BusinessValuesProps;

export default function BusinessFormContainer() {
  const { data: user } = useAuth();
  const {
    changePage,
    currentPage,
    handlePrevPage,
    handleNextPage,
    handleChange,
    handleClickChange,
    resetThisField,
    furthestPage,
    newBusiness,
    submitMerchandise,
    warning,
    formatExperienceForEditing,
    formatLocationForEditing,
    partiallyCreateExperience,
    updateExperience,
    updateExperienceLocation,
    handleFilterChange,
  } = useHooks();

  const defaultProps = {
    handleChange: handleChange,
    handleClickChange: handleClickChange,
    handleFilterChange: handleFilterChange,
    nextPage: handleNextPage,
    prevPage: handlePrevPage,
    values: newBusiness,
    partiallyCreateExperience,
    updateExperience,
    updateExperienceLocation,
  };

  const BusinessFormSteps: { [index: number]: ReactNode } = {
    1: <ServiceType {...defaultProps} />,
    2: <ServiceTitle {...defaultProps} />,
    3: <ServiceTheme {...defaultProps} />,
    4: <ExperienceYears {...defaultProps} />,
    5: <OfferingDescription {...defaultProps} />,
    6: <AboutYou {...defaultProps} user={user} reset={resetThisField} />,
    7: <ExperienceLocation {...defaultProps} />,
    8: <ExperienceRequirements {...defaultProps} />,
    9: <AddCoverPhoto {...defaultProps} reset={resetThisField} />,
    10: <GroupSize {...defaultProps} />,
    11: <Pricing {...defaultProps} />,
    12: (
      <Cancellation
        {...defaultProps}
        warning={warning}
        submit={submitMerchandise}
      />
    ),
    13: <BusinessSuccess />,
  };

  const location = useLocation(); // gets data passed as props thru react-router
  const locationId = location?.state?.data?.location?.id; // checks if we are editing an experience, if so, grab its location
  const { data: thisLocation } = useLocations({ locationId: locationId }); // fetch location data from BE to edit

  useEffect(() => {
    //check to see if you're arriving at this page as an edit, if so, fill in pre-entered data
    if (location.state) {
      formatExperienceForEditing(location.state.data);
    }
    if (thisLocation) {
      formatLocationForEditing(thisLocation);
    }
  }, [thisLocation]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-3 col-lg-3 col-md-3">
          {currentPage <= 12 && (
            <LeftColumnContainer
              changePage={changePage}
              currentPage={currentPage}
              furthestPage={furthestPage}
              partiallyCreateExperience={partiallyCreateExperience}
              updateExperience={updateExperience}
              updateExperienceLocation={updateExperienceLocation}
            />
          )}
        </div>
        <div className="col-xl-9 col-lg-9 col-md-9">
          {currentPage <= 12 && (
            <div className="right-form">
              {BusinessFormSteps[currentPage] || null}
            </div>
          )}
          {currentPage === 13 && <BusinessSuccess />}
        </div>
      </div>
    </div>
  );
}
