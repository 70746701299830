import { Controller, useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { Experience } from '@/types';
import { LabelField } from '@/components';
import { TEXT_AREA_LIMIT_MEDIUM_PLUS } from '@/constants';
import {
  Stack,
  Typography,
  InputField,
  Box,
} from '@silverstein-properties/inspirelabs-ui';
import { renderInputFieldHelperText } from '@/utils';
import { AdvertUrlSectionEditInput } from '@/classes/AdvertUrlSectionEditInput';

const resolver = classValidatorResolver(AdvertUrlSectionEditInput, {
  transformer: { enableImplicitConversion: true },
});

export type AdvertUrlSectionEditPropsType = {
  experience: Experience;
  onFormSubmit: (data: AdvertUrlSectionEditInput) => Promise<void>;
};

export const AdvertUrlSectionEdit = ({
  experience,
  onFormSubmit,
}: AdvertUrlSectionEditPropsType) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<AdvertUrlSectionEditInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      advertUrl: experience.advertUrl || '',
    },
  });
  return (
    <Stack spacing={4}>
      <Typography variant="h5">URL</Typography>
      <form id="advertUrlForm" onSubmit={handleSubmit(onFormSubmit)}>
        <Stack spacing={4} sx={{ width: '100%' }}>
          <LabelField
            label="Edit title"
            value={
              <Box sx={{ display: 'inline-flex', mt: 2 }}>
                <Controller
                  control={control}
                  name="advertUrl"
                  render={({ field }) => (
                    <InputField
                      {...field}
                      fullWidth
                      data-testid="advertUrlEdit"
                      label="URL"
                      error={!!errors.advertUrl}
                      helperText={renderInputFieldHelperText(
                        watch('advertUrl')?.length,
                        TEXT_AREA_LIMIT_MEDIUM_PLUS,
                        errors.advertUrl?.message
                      )}
                    />
                  )}
                />
              </Box>
            }
          />
        </Stack>
      </form>
    </Stack>
  );
};
