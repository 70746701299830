import LargeFlag from './large_flag.svg';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Container,
  MbBlock,
  useTheme,
  Button,
} from '@silverstein-properties/inspirelabs-ui';

interface EmptyStateProps {
  text: string;
  mainBtnLink?: string;
  mainBtnText: string;
  onMainBtnClick?: () => void;
}

export default function EmptyState(props: EmptyStateProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { text, mainBtnLink, mainBtnText } = props;

  return (
    <Container
      sx={{
        paddingY: '10vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          marginBottom: theme.spacers.xxl,
          paddingX: '40%',
        }}
      >
        <MbBlock
          align="center"
          icon={LargeFlag}
          iconColor={theme.palette.secondary.light}
          iconSize="large"
          title={text}
        />
      </Box>

      <Button
        onClick={() => {
          !!props.onMainBtnClick && props.onMainBtnClick();
          !!mainBtnLink && navigate(mainBtnLink);
        }}
      >
        {mainBtnText}
      </Button>
    </Container>
  );
}
