import {
  Box,
  Button,
  Container,
  InputField,
  MessageBanner,
  Snackbar,
  Stack,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import { DialogBox } from './AmountDialog.styles';
import { Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { AmountInput } from '@/classes';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { Error_sm } from '@/assets';
import { RefundReasons } from '@/types';
import { AxiosError } from 'axios';

const resolver = classValidatorResolver(AmountInput);

export interface AmountDialogPropsType {
  open: boolean;
  maxAmount: number;
  onClose: (value: boolean) => void;
  onConfirm?: (amount: AmountInput) => Promise<void>;
  title: string;
  agree: string;
  disagree: string;
  helperText?: string;
  disableInput?: boolean;
  type: 'refund' | 're-run';
}

export const AmountDialog = ({
  open,
  title,
  maxAmount,
  onClose,
  onConfirm = () => Promise.resolve(),
  agree,
  disagree,
  helperText,
  disableInput = false,
  type,
}: AmountDialogPropsType) => {
  const theme = useTheme();
  const [isLoadingButton, setLoadingButton] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [warning, setWarning] = useState('');
  const openSnackbar = !!snackbarMessage;
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AmountInput>({
    resolver,
    mode: 'onTouched',
    values: {
      amount: Math.round((maxAmount + Number.EPSILON) * 100) / 100,
      maxAmount: Math.round((maxAmount + Number.EPSILON) * 100) / 100,
      type: type,
    },
  });

  const closeModal = () => {
    setWarning('');
    onClose(false);
    setLoadingButton(false);
  };

  const onSubmit = async (data: AmountInput) => {
    try {
      setLoadingButton(true);
      await onConfirm(data);
      setSnackbarMessage(`a ${type} of $${data.amount} is being processed`);
      closeModal();
    } catch (e) {
      const error = e as AxiosError<{ message: string; statusCode: number }>;
      setWarning(
        error.response?.data?.message
          ? error.response?.data?.message
          : (error as Error).message
      );
    } finally {
      setLoadingButton(false);
    }
  };

  return (
    <>
      <Snackbar
        message={snackbarMessage}
        open={openSnackbar}
        onClose={() => setSnackbarMessage('')}
        autoHideDuration={2500}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      />

      <DialogBox
        open={open}
        onClose={() => closeModal()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            maxWidth: '400px',
            width: '100%',
            maxHeight: '400px',
            paddingBottom: '24px',
            borderRadius: '24px',
          },
        }}
      >
        <Container>
          <Stack display="flex" justifyContent="start" paddingTop={3}>
            <Typography variant="subtitle1" alignSelf="self-start">
              {title}
            </Typography>

            <Box sx={{ width: '100%' }}>
              <MessageBanner hidden={!warning} type="error">
                {warning}
              </MessageBanner>
            </Box>
          </Stack>
          <Stack
            spacing={theme.spacers.m}
            justifyContent="center"
            justifyItems="center"
            paddingY={!warning ? 5 : 3}
          >
            <Controller
              control={control}
              name="amount"
              render={({ field }) => (
                <InputField
                  {...field}
                  disabled={disableInput}
                  label="Refund"
                  isPriceInput
                  type="number"
                  helperIcon={errors.amount?.message ? Error_sm : undefined}
                  helperText={
                    errors.amount?.message ? errors.amount?.message : helperText
                  }
                  error={!!errors.amount}
                />
              )}
            />
            {type === 'refund' && (
              <Controller
                control={control}
                name="reason"
                render={({ field }) => (
                  <InputField
                    {...field}
                    label="Reason"
                    isPriceInput
                    select
                    dropdownItems={[
                      { label: 'Duplicate', value: RefundReasons.duplicate },
                      { label: 'Fraudulent', value: RefundReasons.fraudulent },
                      {
                        label: 'Requested by customer',
                        value: RefundReasons.requested_by_customer,
                      },
                    ]}
                    helperIcon={errors.reason?.message ? Error_sm : undefined}
                    helperText={errors.reason?.message}
                    error={!!errors.reason}
                  />
                )}
              />
            )}
          </Stack>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingX="12px"
          >
            <Button
              size="small"
              variant="text"
              onClick={() => {
                closeModal();
              }}
            >
              {disagree}
            </Button>
            <LoadingButton
              loading={isLoadingButton}
              size="small"
              variant="outlined"
              onClick={handleSubmit(onSubmit)}
            >
              {agree}
            </LoadingButton>
          </Box>
        </Container>
      </DialogBox>
    </>
  );
};
