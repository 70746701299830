import {
  Grid,
  Typography,
  Button,
  InputField,
  Select,
  MenuItem,
  Stack,
  MessageBanner,
} from '@silverstein-properties/inspirelabs-ui';
import {
  CountryIso2,
  defaultCountries,
  FlagEmoji,
  parseCountry,
  usePhoneInput,
} from 'react-international-phone';
import { Controller, useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { VerifyPhoneNumberInput } from '@/classes';
import { InputAdornment } from '@mui/material';
import { BackButton } from '@/components';

const resolver = classValidatorResolver(VerifyPhoneNumberInput);

export type MultiFactorProps = {
  handleMobileSubmit: (phoneNumber: string) => void;
  goBack: () => void;
  warning?: string;
  success?: string;
};

export const MultiFactor = ({
  handleMobileSubmit,
  goBack,
  warning,
  success,
}: MultiFactorProps) => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
  } = useForm<VerifyPhoneNumberInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      phoneNumber: '',
    },
  });
  const { phone, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      defaultCountry: 'us',
      value: getValues('phoneNumber'),
      countries: defaultCountries,
      onChange: data => {
        setValue('phoneNumber', data.phone);
      },
    });

  const onSubmit = (data: { phoneNumber: string }) => {
    handleMobileSubmit(data.phoneNumber);
  };
  return (
    <Stack flexDirection="column" justifyContent="center" mt={8} gap={4}>
      <Typography alignSelf="center" variant="h4">
        Add Two Factor Authentication
      </Typography>
      {!!success && <MessageBanner type="success">{success}</MessageBanner>}
      {warning ? <MessageBanner type="error">{warning}</MessageBanner> : null}
      <Stack flexGrow={1} flexDirection="column" gap={4}>
        <Typography variant="subtitle1">
          Please enter your mobile number
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="phoneNumber"
            render={({ field }) => (
              <InputField
                {...field}
                inputRef={inputRef}
                onChange={handlePhoneValueChange}
                fullWidth
                type="tel"
                value={phone}
                label="Verification Code"
                startAdornment={
                  <InputAdornment
                    position="start"
                    style={{ marginRight: '2px', marginLeft: '-8px' }}
                  >
                    <Select
                      MenuProps={{
                        style: {
                          height: '500px',
                          left: '-34px',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                      }}
                      sx={{
                        // Remove default outline (display only on focus)
                        fieldset: {
                          display: 'none',
                        },
                        '&.Mui-focused:has(div[aria-expanded="false"])': {
                          fieldset: {
                            display: 'block',
                          },
                        },
                        // Update default spacing
                        '.MuiSelect-select': {
                          padding: '8px',
                          paddingRight: '24px !important',
                        },
                        svg: {
                          right: 0,
                        },
                      }}
                      value={country}
                      onChange={e => setCountry(e.target.value as CountryIso2)}
                      renderValue={value => (
                        <FlagEmoji size="24px" iso2={value as CountryIso2} />
                      )}
                    >
                      {defaultCountries.map(c => {
                        const country = parseCountry(c);
                        return (
                          <MenuItem key={country.iso2} value={country.iso2}>
                            <FlagEmoji
                              size="24px"
                              iso2={country.iso2}
                              style={{ marginRight: '8px' }}
                            />
                            <Typography marginRight="8px">
                              {country.name}
                            </Typography>
                            <Typography color="gray">
                              +{country.dialCode}
                            </Typography>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </InputAdornment>
                }
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber?.message || ''}
              />
            )}
          />
          <Grid
            item
            container
            alignItems="center"
            justifyContent="space-between"
          >
            <BackButton onClick={goBack} />
            <Button type="submit">Next</Button>
          </Grid>
        </form>
      </Stack>
    </Stack>
  );
};
