import {
  Container,
  styled,
  Typography,
} from '@silverstein-properties/inspirelabs-ui';

export const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacers.xxl4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const StyledImg = styled('img')(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));
export const StyledBody2Typography = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(0.5),
}));
