import { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { auth } from '@/config/config';
import { useMutation } from '@tanstack/react-query';
import {
  confirmPasswordReset,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  applyActionCode,
} from 'firebase/auth';
import { ApiClient } from '@/api/apiClient';
import { CreatePasswordInput } from '@/classes';

export const usePassword = () => {
  const [password, setNewPassword] = useState('');
  const [oobCode, setOobCode] = useState('');
  const [isPasswordRequestCodeValidated, setPasswordRequestCodeValidated] =
    useState<boolean>(false);
  const [warning, setWarning] = useState('');
  const [success, setSuccess] = useState('');
  const [passwordResetWarning, setPasswordResetWarning] = useState('');
  const [passwordResetSuccess, setPasswordResetSuccess] = useState('');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const apiClient = ApiClient();

  const handleSetPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
  };

  const setPassword = useMutation<boolean, Error, CreatePasswordInput>({
    mutationFn: async (data: CreatePasswordInput) => {
      try {
        return await apiClient.users.resetPassword(data);
      } catch (error) {
        console.error(error);
        throw new Error('An error has occurred. Please try again later');
      }
    },
    onSuccess: () => {
      setPasswordResetWarning('');
      setPasswordResetSuccess('Success! Redirecting to login page');
      setTimeout(() => navigate('/login'), 2750);
    },
    onError: (error: Error) => {
      setPasswordResetSuccess('');
      setPasswordResetWarning(error.message);
    },
  });

  const verifyPasswordResetRequest = async (code: string) => {
    const isValid = !!(await verifyPasswordResetCode(auth, code));
    setPasswordRequestCodeValidated(isValid);

    if (isValid) {
      setOobCode(code);
    }

    return isValid;
  };

  const applyActionCodeRequest = async (code: string) => {
    await applyActionCode(auth, code);
  };

  const changePassword = async (password: string) => {
    setPasswordResetSuccess('');
    if (!isPasswordRequestCodeValidated) {
      setPasswordResetWarning('The password reset link is invalid');
      return;
    }
    if (password.length < 8) {
      setPasswordResetWarning('Passwords must be at least 8 characters');
      return;
    }

    // Save the new password.
    confirmPasswordReset(auth, oobCode, password)
      .then(() => {
        setPasswordResetWarning('');
        setPasswordResetSuccess('Success! Redirecting to login page');
      })
      .catch(() => {
        setPasswordResetSuccess('');
        setPasswordResetWarning(
          'An error has occurred. Please try again at a later time'
        );
      });
  };

  const fbMutation = useMutation({
    mutationFn: ({ email }: { email: string }) =>
      sendPasswordResetEmail(auth, email),
  });

  const resetSuccess = () => {
    setSuccess('');
  };

  const triggerResetEmail = (emailAddress: string) => {
    if (emailAddress) {
      try {
        setWarning('');
        setSuccess('Password reset email sent, please check your inbox');
        return fbMutation.mutate({ email: emailAddress });
      } catch (err) {
        return;
      }
    }
    setWarning('Please enter a valid email address');
  };

  return {
    password,
    success,
    warning,
    passwordResetSuccess,
    passwordResetWarning,
    searchParams,
    resetSuccess,
    setWarning,
    triggerResetEmail,
    handleSetPasswordChange,
    setPassword,
    verifyPasswordResetRequest,
    applyActionCodeRequest,
    changePassword,
  };
};
