import { LoadingButton } from '@mui/lab';
import { Grid, styled } from '@mui/material';

export const MainMessagesTitleGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2, 5),
  borderBottom: `1px solid ${theme.palette.grey[400]}`,
  height: '88px', // hardcoded value so that both sideMessage title and mainMessage title align
  flexDirection: 'column',
  justifyContent: 'center',
}));

export type StyledButtonsGridProps = {
  isBookingRequest: boolean;
};

export const StyledButtonsGrid = styled(Grid, {
  shouldForwardProp: prop => prop !== 'isBookingRequest',
})<StyledButtonsGridProps>(({ theme, isBookingRequest }) => ({
  flexDirection: 'row',
  justifyContent: isBookingRequest ? 'space-between' : 'flex-end',
  marginTop: theme.spacing(1),
}));

export const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  height: theme.spacing(4.5),
  padding: theme.spacing(0, 2),
}));
