import { SquareEnvironment } from '@/types';
import { Type } from 'class-transformer';
import { IsEnum, IsNotEmpty, IsString, ValidateNested } from 'class-validator';

export class SquareConfigurationDto {
  @IsString()
  @IsNotEmpty()
  @IsEnum(SquareEnvironment)
  clientEnvironment: SquareEnvironment;

  @IsString()
  @IsNotEmpty()
  locationId: string;

  @IsString()
  @IsNotEmpty()
  accessToken: string;
}

export class SquareConnectionSectionEditInput {
  @ValidateNested()
  @Type(() => SquareConfigurationDto)
  squareConfiguration: SquareConfigurationDto;
}
