import {
  IsAlpha,
  IsNumberString,
  MaxLength,
  MinLength,
  IsNotEmpty,
  IsOptional,
  ValidateIf,
} from 'class-validator';
import { TEXT_AREA_LIMIT_LONG } from '@/constants';
import { PricingInput } from './PricingInput';
import { RepeatDaysModalInput, TimeIntervalInput } from './AddListingInput';

export class EditListingInput extends PricingInput {
  @MinLength(1, { message: 'Please enter a date for this listing' })
  date: string;

  @IsNotEmpty({
    message: 'Please enter a start time for this listing',
  })
  startTime: Date;

  @IsNotEmpty({
    message: 'Please enter an end time for this listing',
  })
  endTime: Date;

  @ValidateIf(e => e.isEditLocationEnabled)
  @MinLength(1, { message: 'Please enter an address.' })
  @MaxLength(50, { message: 'Your address is too long.' })
  address: string;

  apt: string;

  @ValidateIf(e => e.isEditLocationEnabled)
  @MinLength(1, { message: 'City is blank.' })
  @MaxLength(50, { message: 'City name is too long.' })
  city: string;

  @ValidateIf(e => e.isEditLocationEnabled)
  @IsAlpha(undefined, { message: 'Invalid' })
  @MinLength(2, { message: 'Invalid' })
  @MaxLength(3, { message: 'Invalid' })
  state: string;

  @ValidateIf(e => e.isEditLocationEnabled)
  @IsNumberString(undefined, { message: 'Invalid ZIP' })
  @MinLength(5, { message: 'Invalid ZIP' })
  @MaxLength(10, { message: 'Invalid ZIP' })
  zip: string;

  @ValidateIf(e => e.isEditLocationEnabled)
  @MinLength(1, { message: 'Please enter a description for this location' })
  @MaxLength(TEXT_AREA_LIMIT_LONG, {
    message: 'Please shorten your description.',
  })
  description: string;

  @IsOptional()
  repeatDays?: RepeatDaysModalInput;

  @IsOptional()
  timeInterval?: TimeIntervalInput;

  @IsOptional()
  editRecurringSeries?: boolean;

  isEditLocationEnabled: boolean;
}
