export interface MenuItemProps {
  toggleMenu: () => void;
  buttonAction?: () => void;
  label: string;
}

export const MenuItem = ({
  label,
  toggleMenu,
  buttonAction,
}: MenuItemProps) => {
  const handleClick = () => {
    toggleMenu();
    buttonAction && buttonAction();
  };

  return (
    <div className="menu-item" onClick={handleClick}>
      <span className="menu-item-label">{label}</span>
    </div>
  );
};
