import { useAuth } from './useAuth';
import { useQuery } from '@tanstack/react-query';
import { ApiClient } from '../api/apiClient';
import { TaxRate } from '@/types';

export const useTaxRates = () => {
  const { data: user } = useAuth();
  const apiClient = ApiClient();

  const getTaxRates = async (): Promise<TaxRate[]> => {
    return await apiClient.taxRates.getAllTaxRates();
  };

  return useQuery<TaxRate[]>(
    ['taxRates', user?.primaryMerchantId],
    () => getTaxRates(),
    { enabled: !!user }
  );
};
