import {
  Box,
  Stack,
  Typography,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import {
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { DropdownType, Experience } from '@/types';
import { LabelField } from '@/components';
import { useCategoriesChoices, useSubCategoriesChoices } from '@/hooks';
import { MenuProps, getStyles } from './BasicInfoSectionEdit.styles';
import { BasicInfoSectionEditInput } from '@/classes/BasicInfoSectionEditInput';
import { useEffect, useState } from 'react';

const resolver = classValidatorResolver(BasicInfoSectionEditInput);

export type BasicInfoSectionEditPropsType = {
  experience: Experience;
  onFormSubmit: (data: BasicInfoSectionEditInput) => void;
};

export const BasicInfoSectionEdit = ({
  experience,
  onFormSubmit,
}: BasicInfoSectionEditPropsType) => {
  const [filteredSubcategories, setFilteredSubCategories] = useState<
    DropdownType[]
  >([]);
  const { data: categories } = useCategoriesChoices();
  const { data: subcategories } = useSubCategoriesChoices();
  const theme = useTheme();

  const {
    setValue,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<BasicInfoSectionEditInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      category: {
        info: experience.category?.info || [],
        type: experience.category?.type || [],
      },
    },
  });
  const selectedCategories = watch('category.type');
  const selectedSubCategories = watch('category.info');

  const removeUnselectedSubCategories = () => {
    const categoriesList = selectedCategories?.map(selectedCategory => {
      return categories?.find(category => category.value === selectedCategory);
    });
    const subcategoriesList = subcategories?.filter(subcategory => {
      const category = categoriesList?.find(
        category => category?.id === subcategory.categoryId
      );
      return !!category;
    });
    const newSubcategories = selectedSubCategories?.filter(subcategory => {
      return subcategoriesList?.find(
        category => category.value === subcategory
      );
    });
    setValue('category.info', newSubcategories);
  };

  useEffect(() => {
    if (selectedCategories && subcategories) {
      const categoriesList = selectedCategories?.map(selectedCategory => {
        return categories?.find(
          category => category.value === selectedCategory
        );
      });
      const filteredSubcategories = subcategories?.filter(subcategory => {
        const category = categoriesList?.find(
          category => category?.id === subcategory.categoryId
        );
        return !!category;
      });
      removeUnselectedSubCategories();
      setFilteredSubCategories(filteredSubcategories || []);
    }
  }, [selectedCategories, subcategories]);

  return (
    <Stack spacing={4}>
      <Typography variant="h5">Basic Info</Typography>
      <form id="basicInfoForm" onSubmit={handleSubmit(onFormSubmit)}>
        <Stack spacing={4} width="100%">
          <LabelField
            label="What is the category that best describes your experience?"
            value={
              <Box sx={{ display: 'inline-flex', mt: 2 }}>
                <Controller
                  name="category.type"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      error={
                        !!(errors.category?.type as { message: string })
                          ?.message
                      }
                      fullWidth
                    >
                      <InputLabel id={`${field.name}-label`}>
                        Categories
                      </InputLabel>
                      <Select
                        {...field}
                        data-testid="categoryFieldBasicInfo"
                        labelId={`${field.name}-label`}
                        multiple
                        input={
                          <OutlinedInput
                            id={`select-multiple-${field.name}`}
                            label="Building"
                          />
                        }
                        renderValue={selected => (
                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: 0.5,
                            }}
                          >
                            {selected.map(building => (
                              <Chip
                                key={building}
                                label={
                                  categories?.find(
                                    category => category.value === building
                                  )?.label || ''
                                }
                              />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {categories?.map(category => (
                          <MenuItem
                            key={category.id}
                            value={category.value}
                            style={getStyles(
                              category.value,
                              field.value as string[],
                              theme
                            )}
                          >
                            {category.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={!!errors.category?.type}>
                        {/** hack because type is also internal value for hookform */}
                        {(errors.category?.type as { message: string })
                          ?.message || ''}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Box>
            }
          />
          {selectedCategories.length > 0 && (
            <LabelField
              label="What is the subcategory that best describes your experience?"
              value={
                <Box sx={{ display: 'inline-flex', mt: 2 }}>
                  <Controller
                    name="category.info"
                    control={control}
                    render={({ field }) => (
                      <>
                        <FormControl
                          error={!!errors.category?.info?.message}
                          fullWidth
                        >
                          <InputLabel id={`${field.name}-label`}>
                            Subcategories
                          </InputLabel>
                          <Select
                            {...field}
                            data-testid="subcategoryFieldBasicInfo"
                            labelId={`${field.name}-label`}
                            multiple
                            input={
                              <OutlinedInput
                                id={`select-multiple-${field.name}`}
                                label="Building"
                              />
                            }
                            renderValue={selected => (
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  gap: 0.5,
                                }}
                              >
                                {selected.map(selectedSubCategory => (
                                  <Chip
                                    key={selectedSubCategory}
                                    label={
                                      filteredSubcategories?.find(
                                        subCategory =>
                                          subCategory.value ===
                                          selectedSubCategory
                                      )?.label || ''
                                    }
                                  />
                                ))}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                          >
                            {filteredSubcategories?.map(subcategory => (
                              <MenuItem
                                key={subcategory.id}
                                value={subcategory.value}
                                style={getStyles(
                                  subcategory.value,
                                  field.value as string[],
                                  theme
                                )}
                              >
                                {subcategory.label}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText error={!!errors.category?.info}>
                            {/** hack because type is also internal value for hookform */}
                            {!!errors.category?.info?.message
                              ? errors.category?.info?.message
                              : ''}
                          </FormHelperText>
                        </FormControl>
                      </>
                    )}
                  />
                </Box>
              }
            />
          )}
        </Stack>
      </form>
    </Stack>
  );
};
