import { LabelField } from '@/components';
import { Experience } from '@/types';
import {
  Stack,
  Grid,
  Box,
  Typography,
} from '@silverstein-properties/inspirelabs-ui';
import { Avatar } from '@mui/material';

export type PhotoSectionPropsType = {
  experience: Experience;
};

export const PhotoSection = ({ experience }: PhotoSectionPropsType) => {
  const renderOtherPhotos = (photos: string[]) => {
    const photosWithImage = photos.filter(photo => !!photo);
    if (photosWithImage.length) {
      return photos.map((photo, index) => {
        return (
          <Avatar
            key={photo || index}
            variant="square"
            src={photo}
            alt={`Experience photo ${index + 1}`}
            sx={{
              borderRadius: !photo ? 2 : 0,
              border: ({ palette }) =>
                !photo ? `1px solid ${palette.primary.main}` : 'none',
              bgcolor: ({ palette }) => palette.background.default,
              height: 120,
              width: 180,
            }}
          >
            <Typography color="primary.dark">No photo</Typography>
          </Avatar>
        );
      });
    } else {
      return 'Add additional photos to help show off your experience!';
    }
  };
  return (
    <Stack spacing={4}>
      <LabelField
        label="Cover photo"
        value={
          <Box sx={{ mt: 2 }}>
            <Avatar
              alt={experience.title}
              data-testid="coverPhoto"
              variant="square"
              src={experience.coverPhoto}
              sx={{
                borderRadius: !experience.coverPhoto ? 2 : 0,
                border: ({ palette }) =>
                  !experience.coverPhoto
                    ? `1px solid ${palette.primary.main}`
                    : 'none',
                bgcolor: ({ palette }) => palette.background.default,
                height: 286,
                width: 384,
              }}
            >
              <Typography color="primary.dark">No Cover photo</Typography>
            </Avatar>
          </Box>
        }
      />
      <LabelField
        label="Other photos"
        value={
          <Grid container sx={{ mt: 2, gap: 3 }}>
            {renderOtherPhotos(Object.values(experience.photos))}
          </Grid>
        }
      />
    </Stack>
  );
};
