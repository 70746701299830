import { Experience } from 'types';
import { useAuth } from './useAuth';
import { useQuery } from '@tanstack/react-query';
import { ApiClient } from '../api/apiClient';

export const useExperiences = () => {
  const { data: user } = useAuth();
  const apiClient = ApiClient();

  const getUserProducts = async (
    primaryMerchantId: string,
    accessToken?: string
  ): Promise<Experience[]> => {
    return accessToken
      ? await apiClient.products.getMerchantProducts({
          merchantId: primaryMerchantId,
        })
      : [];
  };

  return useQuery<Experience[]>(
    ['experiences'],
    () => getUserProducts(user?.primaryMerchantId || '', user?.accessToken),
    { enabled: !!user }
  );
};
