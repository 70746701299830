import { MenuItem } from './MenuItem';
import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './menu.css';
import { useFeatureFlags } from '@/hooks';

export interface MenuProps {
  toggleMenu: () => void;
  logOut?: () => void;
  type?: 'navbar' | 'minicard';
  // Disabled due to current implementation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}

export const Menu = ({ data, logOut, toggleMenu, type }: MenuProps) => {
  const { isMerchantTeamEnabled, isViewUserAndBusinessProfileEnabled } =
    useFeatureFlags();

  function useOutsideAlerter(ref: React.RefObject<HTMLInputElement>) {
    //closes on click outside
    useEffect(() => {
      const handleClickOutside = ({ target }: MouseEvent) => {
        if (
          ref.current !== null &&
          !ref.current.contains(target as Node) &&
          toggleMenu
        ) {
          toggleMenu();
        }
      };
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
    // }
  }
  const wrapperRef = useRef<HTMLInputElement>(null);

  useOutsideAlerter(wrapperRef);

  const minicardMenuItems = () => {
    return (
      <>
        <Link
          state={{ data: data || {} }}
          to={data.id ? '/experiences/create' : '/'}
        >
          <MenuItem label="Edit" toggleMenu={toggleMenu} />
        </Link>
        {process.env.NODE_ENV === 'development' ? (
          <>
            <MenuItem label="View" toggleMenu={toggleMenu} />
            <MenuItem label="Delete" toggleMenu={toggleMenu} />
          </>
        ) : null}
      </>
    );
  };

  return (
    <div
      ref={wrapperRef}
      className={
        type === 'minicard' ? 'minicard-menu-modal' : 'navbar-menu-modal'
      }
    >
      {type === 'minicard' ? (
        minicardMenuItems()
      ) : (
        <>
          <span style={{ width: 200 }}>
            {isViewUserAndBusinessProfileEnabled && (
              <Link to="/profile">
                <MenuItem label="My profile" toggleMenu={toggleMenu} />
              </Link>
            )}
            {isViewUserAndBusinessProfileEnabled && (
              <Link to="/businessProfile">
                <MenuItem label="My business profile" toggleMenu={toggleMenu} />
              </Link>
            )}
            {isMerchantTeamEnabled && (
              <Link to="/my-team">
                <MenuItem label="My team" toggleMenu={toggleMenu} />
              </Link>
            )}
            <Link to="/contactus">
              <MenuItem label="Contact Us" toggleMenu={toggleMenu} />
            </Link>
          </span>
          <MenuItem
            label="Log out"
            toggleMenu={toggleMenu}
            buttonAction={logOut}
          />
        </>
      )}
    </div>
  );
};
