import {
  Grid,
  Skeleton,
  Typography,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';

export const PricingWithTaxesSkeleton = () => {
  const theme = useTheme();
  return (
    <Grid container>
      <Grid container item xs={12}>
        <Grid item xs={6}>
          <Typography marginBottom={theme.spacers.s} variant="subtitle2">
            <Skeleton />
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={6}>
          <Skeleton variant="rounded" height={62} />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="rounded" height={62} />
        </Grid>
      </Grid>
    </Grid>
  );
};
