import { Type } from 'class-transformer';
import { IsNotEmpty, ValidateNested } from 'class-validator';

export class RefundPolicyInput {
  @IsNotEmpty({ message: 'Please select cancellation policy' })
  cancellationPolicy: string;
}

export class CancelationSectionEditInput {
  @ValidateNested()
  @Type(() => RefundPolicyInput)
  refundPolicy: RefundPolicyInput;
}
