import {
  Typography,
  TypographyProps,
} from '@silverstein-properties/inspirelabs-ui';
import { ReactNode } from 'react';

interface ExperiencesTableCellProps extends TypographyProps {
  isSubRow?: boolean;
  children: ReactNode;
}

export const ExperiencesTableCell = (props: ExperiencesTableCellProps) => {
  const { isSubRow = false, children, ...restOfProps } = props;
  return (
    <Typography variant={isSubRow ? 'body2' : 'subtitle2'} {...restOfProps}>
      {children}
    </Typography>
  );
};
