import { styled } from '@mui/material';

export const ReadIndicatorIcon = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
  width: 8,
  height: 8,
  padding: 1.25,
  borderRadius: '100%',
}));

export const IndicatorIcon = styled('div')(() => ({
  width: 8,
  height: 8,
  padding: 1.25,
  borderRadius: '100%',
}));
