import { useAuth } from '../useAuth';
import { useQuery } from '@tanstack/react-query';
import { ApiClient } from '../../api/apiClient';
import { SquareMenu } from '@/types';

export const useGetMenuProduct = ({
  productId,
  skipCache,
}: {
  productId: string;
  skipCache: boolean;
}) => {
  const { data: user } = useAuth();
  const apiClient = ApiClient();

  const getMenuProduct = async ({
    productId,
    skipCache,
  }: {
    productId: string;
    skipCache: boolean;
  }): Promise<SquareMenu> => {
    return await apiClient.products.getMenuProduct({ productId, skipCache });
  };

  return useQuery<
    SquareMenu | undefined,
    unknown,
    SquareMenu,
    [
      string,
      {
        productId: string;
        skipCache: boolean;
      }
    ]
  >({
    queryKey: [
      'menuProduct',
      {
        productId,
        skipCache,
      },
    ],
    queryFn: ({ queryKey }) => getMenuProduct(queryKey[1]),
    enabled: !!user && !!user.id,
  });
};
