import {
  Box,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@silverstein-properties/inspirelabs-ui';
import { Controller, useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { Experience } from '@/types';
import { LabelField } from '@/components';
import { CANCELLATION_OPTIONS } from '@/constants';
import { FormHelperText } from '@mui/material';
import { CancelationSectionEditInput } from '@/classes/CancelationSectionEditInput';

const resolver = classValidatorResolver(CancelationSectionEditInput);

export type CancelationSectionEditPropsType = {
  experience: Experience;
  onFormSubmit: (data: CancelationSectionEditInput) => Promise<void>;
};

export const CancelationSectionEdit = ({
  experience,
  onFormSubmit,
}: CancelationSectionEditPropsType) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<CancelationSectionEditInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      refundPolicy: {
        cancellationPolicy: experience.refundPolicy?.cancellationPolicy || '',
      },
    },
  });

  return (
    <Stack spacing={4}>
      <Typography variant="h5">Cancelation</Typography>
      <form id="cancelationForm" onSubmit={handleSubmit(onFormSubmit)}>
        <Stack spacing={4}>
          <LabelField
            label="Participants may cancel prior to service:"
            value={
              <Box mt={2}>
                <Controller
                  name="refundPolicy.cancellationPolicy"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        data-testid="cancellationPolicy"
                        displayEmpty={true}
                        error={!!errors.refundPolicy?.cancellationPolicy}
                        renderValue={selected => {
                          if (!selected) {
                            return 'Select policy';
                          }
                          const selectedCancellation =
                            CANCELLATION_OPTIONS.find(
                              cancellation => cancellation.value === selected
                            );
                          return selectedCancellation?.label || '';
                        }}
                      >
                        {CANCELLATION_OPTIONS.map(cancellation => (
                          <MenuItem
                            key={cancellation.id}
                            value={cancellation.value}
                          >
                            {cancellation.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText
                        error={!!errors.refundPolicy?.cancellationPolicy}
                      >
                        {!!errors.refundPolicy?.cancellationPolicy
                          ? errors.refundPolicy?.cancellationPolicy.message
                          : ''}
                      </FormHelperText>
                    </>
                  )}
                />
              </Box>
            }
          />
          <Typography variant="body2">
            Cancellation before the selected timeframe is fully refundable.
            Cancellation after the selected timeframe is non-refundable.
          </Typography>
        </Stack>
      </form>
    </Stack>
  );
};
