import { MerchantPaymentStatement } from 'types';
import { useAuth } from './useAuth';
import { useQuery } from '@tanstack/react-query';
import { ApiClient } from '../api/apiClient';

export const useMerchantPaymentStatements = () => {
  const { data: user } = useAuth();
  const apiClient = ApiClient();

  const getMerchantPaymentStatements = async (
    primaryMerchantId: string,
    accessToken?: string
  ): Promise<MerchantPaymentStatement | null> => {
    if (accessToken) {
      return await apiClient.paymentStatements.getMerchantPaymentStatements({
        merchantId: Number(primaryMerchantId),
      });
    } else {
      return null;
    }
  };

  return useQuery<MerchantPaymentStatement | null>(
    ['merchantPaymentStatements', user?.primaryMerchantId],
    () =>
      getMerchantPaymentStatements(
        user?.primaryMerchantId || '',
        user?.accessToken
      ),
    { enabled: !!user }
  );
};
