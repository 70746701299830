/* eslint-disable */
import { LabelField } from '@/components';
import { useMembershipPlansByProductId } from '@/hooks';
import {
  Experience,
  MembershipPlan,
  PriceType,
  PurchaseMethods,
} from '@/types';
import { evaluateIsExperienceBookingRequest, formatPricing } from '@/utils';
import {
  Stack,
  Typography,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';

export type PricingSectionPropsType = {
  experience: Experience;
};

const getMembershipPrices = (
  membershipPlans: MembershipPlan[],
  experience: Experience
) => {
  const membershipPrices: {
    id: string;
    name: string;
    price: number;
    taxRateId?: number;
  }[] = [];
  if (membershipPlans) {
    membershipPlans.forEach(plan => {
      const membershipPlanProduct = plan.membershipProducts.find(
        x => x.productId === experience.id
      );
      if (membershipPlanProduct) {
        // Membership plan pricing should be set to dirty when pricing is not set. This
        // ensures that once the merchant saves the form, we attach a price to membership plan
        if (membershipPlanProduct.price) {
          membershipPrices.push({
            id: membershipPlanProduct.price?.id || plan.id,
            name: `${plan?.subscriptionName} - ${plan?.name}`,
            price: membershipPlanProduct.price.totalAmount,
            taxRateId: membershipPlanProduct.price.taxRateId,
          });
        }
      }
    });
  }
  return membershipPrices;
};

export const PricingSection = ({ experience }: PricingSectionPropsType) => {
  const { data: membershipPlans } = useMembershipPlansByProductId({
    productId: experience.id,
  });
  const theme = useTheme();
  const membershipPrices = getMembershipPrices(
    membershipPlans || [],
    experience
  );

  const isExperienceBookingRequest =
    evaluateIsExperienceBookingRequest(experience);
  const isBasePriceNull = experience.basePrice.totalAmount === null;
  const basePriceComponent =
    experience.basePrice.priceType == PriceType.VIA_QUOTE &&
    isExperienceBookingRequest &&
    isBasePriceNull ? (
      <LabelField
        label="Amount paid by guest"
        value={'Price is agreed upon with customer via quote'}
      />
    ) : (
      <Typography variant="subtitle1">
        {formatPricing(experience.basePrice.totalAmount, 2)} / person
      </Typography>
    );

  return (
    <Stack spacing={4}>
      <LabelField
        label=""
        spacing={0.25}
        value={
          <Stack spacing={theme.spacers.l}>
            <Stack>
              {experience.acceptedPurchaseMethods?.length === 1 &&
              experience.acceptedPurchaseMethods?.includes(
                PurchaseMethods.SUBSCRIPTION
              ) ? (
                <Typography variant="subtitle1">Member exclusive</Typography>
              ) : (
                <Stack flexDirection="column">
                  {basePriceComponent}
                  {!!experience.basePrice.taxRateId && (
                    <Typography variant="caption">
                      This product is subject to taxes
                    </Typography>
                  )}
                </Stack>
              )}
            </Stack>
            {membershipPrices.map((price, i) => (
              <Stack key={price.id}>
                <Stack flexDirection="column" alignContent="center">
                  <Typography variant="subtitle1" marginRight={1}>
                    {formatPricing(price.price, 2)} / person
                  </Typography>
                  {!!price.taxRateId && (
                    <Typography variant="caption">
                      This product is subject to taxes
                    </Typography>
                  )}
                </Stack>
                <Typography key={i} variant="body1">
                  {price.name}
                </Typography>
              </Stack>
            ))}
          </Stack>
        }
      />
    </Stack>
  );
};
