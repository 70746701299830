import { Box, styled } from '@silverstein-properties/inspirelabs-ui';
import { Link } from 'react-router-dom';

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  paddingTop: theme.spacing(7.75),
  '>img': {
    height: theme.spacing(10),
    width: theme.spacing(10),
    marginBottom: theme.spacing(1),
  },
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  marginTop: theme.spacing(5),
}));
