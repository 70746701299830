import { ApiClient } from '@/api/apiClient';
import { useAuth } from './useAuth';
import { MembershipPlanPurchase } from '@/types';
import { useQuery } from '@tanstack/react-query';

export const useMembershipPlanPurchase = ({
  membershipPlanPurchaseId,
  execute = true,
}: {
  membershipPlanPurchaseId: string;
  execute?: boolean;
}) => {
  const { data: user } = useAuth();
  const apiClient = ApiClient();

  const getMembershipPlanPurchase =
    async (): Promise<MembershipPlanPurchase> => {
      return await apiClient.membershipPlanPurchases.getMembershipPlanPurchase({
        membershipPlanPurchaseId,
      });
    };

  return useQuery<MembershipPlanPurchase>(
    ['membershipPlanPurchase', membershipPlanPurchaseId],
    () => getMembershipPlanPurchase(),
    { enabled: !!user && execute }
  );
};

export const usePlanPurchasePastDueAmount = ({
  membershipPlanPurchaseId,
  execute = true,
}: {
  membershipPlanPurchaseId: string;
  execute?: boolean;
}) => {
  const { data: user } = useAuth();
  const apiClient = ApiClient();

  const getPlanPurchasePastDueAmount = async (
    membershipPlanPurchaseId: string
  ): Promise<{ balance: number }> => {
    return await apiClient.membershipPlanPurchases.getPlanPurchasePastDueBalance(
      {
        membershipPlanPurchaseId: membershipPlanPurchaseId,
      }
    );
  };

  return useQuery<{ balance: number }>(
    ['planPurchasePastDueAmount', membershipPlanPurchaseId],
    () => getPlanPurchasePastDueAmount(membershipPlanPurchaseId),
    { enabled: !!user && !!membershipPlanPurchaseId && execute }
  );
};
