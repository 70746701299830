import { LabelField } from '@/components';
import { Membership } from '@/types';
import { Stack } from '@silverstein-properties/inspirelabs-ui';

export type TitleAndDescriptionSectionPropsType = {
  membership: Membership;
};

export const TitleAndDescriptionSection = ({
  membership,
}: TitleAndDescriptionSectionPropsType) => {
  return (
    <Stack spacing={4}>
      <LabelField label="Title" value={membership.name || ''} />
      <LabelField
        label="Subscription Description"
        value={membership.description || ''}
      />
      {/* Publish changes is NOT part of MVP - commenting it out for now */}
      {/* <Box sx={{ marginLeft: 'auto' }}>
        <Button size="large" variant="contained">
          <Typography
            variant="button"
            sx={{ color: theme.palette.primary.light }}
          >
            Publish changes
          </Typography>
        </Button>
      </Box> */}
    </Stack>
  );
};
