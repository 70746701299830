import MainItem from './MainItem';
import './leftColumnContainer.css';
import { useHooks } from './useHooks';

export interface LeftMenuProps {
  currentPage: number;
  furthestPage: number;
  changePage: (name: number, finished: string) => void;
}

export default function LeftMenu(props: LeftMenuProps) {
  const { openFolder, categories, handleFolderOpen } = useHooks(props);

  return (
    <div className="left-menu">
      {categories.map(category => (
        <MainItem
          subItems={category['subItems']}
          changePage={props.changePage}
          name={category['title']}
          linkTo={category['pageOpen']}
          finished={
            props.furthestPage >= category.pageDone
              ? 'finished'
              : props.furthestPage >= category.pageOpen
              ? 'in progress'
              : 'locked'
          }
          pageIsOpen={openFolder === category['id']}
          handleOpen={handleFolderOpen}
          id={category['id']}
          furthestPage={props.furthestPage}
          currentPage={props.currentPage}
          key={category.id}
        />
      ))}
    </div>
  );
}
