import { BusinessSignupComponentProps } from './Component';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useFormData } from '../../contexts/MerchCreationProvider';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { useMerchantPlatformFee } from '@/hooks';
import { PricingInput } from '@/classes';
import { Box, Button } from '@silverstein-properties/inspirelabs-ui';
import { BackButton } from '../BackButton';
import { MerchCreationObject, PurchaseMethods } from '@/types';
import { PricingComponent } from '@/components';
import { ProductTypes } from '@/types/Product';

const resolver = classValidatorResolver(PricingInput);

// TODO: validation for pricing needs improving
// they are not checking that inputted number is positive value
// nor properly capping the values with min/max
// https://spinspire.monday.com/boards/3397008425/pulses/4387062958

export function Pricing(props: BusinessSignupComponentProps) {
  const { setFormValues, merchandiseData } = useFormData();
  const { data: platformFeeRaw } = useMerchantPlatformFee(
    ProductTypes.ONE_TIME_EVENT
  );

  const { control, formState, handleSubmit, watch, setValue } =
    useForm<PricingInput>({
      resolver,
      mode: 'onTouched',
      defaultValues: {
        price: merchandiseData.price || 0, // Net price
        totalAmount: merchandiseData.totalAmount || 0, // Guest price
        acceptedPurchaseMethods: merchandiseData.acceptedPurchaseMethods || [
          PurchaseMethods.ONE_TIME_MONEY_PURCHASE,
        ],
      },
    });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = (data: PricingInput) => {
    setFormValues(data as Partial<MerchCreationObject>);
    props.updateExperience(data as Partial<MerchCreationObject>);
    props.nextPage();
  };

  return (
    <div className="row ms-xl-5 ms-lg-5 me-md-5 ms-sm-1 ms-xs-1 top-64 d-flex justify-content-end">
      <div className="col-8">
        <div className="theme-form">
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ gap: 0 }} // NOTE: Just a quick fix b/c currently "form > gap: 24px" was there in CSS. It causes weird spacings in the form
          >
            <PricingComponent
              control={control}
              formState={formState}
              setValue={setValue}
              platformFeeRaw={platformFeeRaw}
              watch={watch}
            />
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <BackButton onClick={props.prevPage} />
              <Button type="submit">Continue</Button>
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
}
