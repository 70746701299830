import RoundButton from '../RoundButton/Component';
import HookFormSubmitButton from '../RoundButton/HookFormSubmitButton';
import './SignupFormContainer.css';
import { Link } from 'react-router-dom';

import { HookFormTextInput } from '../FormTextInput/HookFormTextInput';
import { Resolver, useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { MaxLength, MinLength, IsEmail, IsPhoneNumber } from 'class-validator';
import { useFormData } from '../../contexts/SignUpProvider';
import { fetchSignInMethodsForEmail } from 'firebase/auth';
import { auth } from '../../config/config';
import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { UserSignUpObject } from '../../types';
import { MessageBanner } from '@silverstein-properties/inspirelabs-ui';

export interface ContactDetailsProps {
  handleNext: () => void;
}

class ContactDetailsInput {
  @MinLength(1, { message: 'Please enter a first name' })
  @MaxLength(50, { message: 'Please enter a shorter version of your name' })
  firstName: string;

  @MinLength(1, { message: 'Please enter a last name' })
  @MaxLength(50, { message: 'Please enter a shorter version of your name' })
  lastName: string;

  @IsEmail(undefined, { message: 'Please enter a valid email.' })
  personalEmail: string;

  @IsPhoneNumber('US', { message: 'Please enter a valid phone number.' })
  personalPhone: string;
}

const resolver: Resolver<ContactDetailsInput> =
  classValidatorResolver(ContactDetailsInput);

export default function ContactDetails({ handleNext }: ContactDetailsProps) {
  const { setFormValues, signupFormData } = useFormData();
  const {
    register,
    formState: { dirtyFields, errors },
    handleSubmit,
  } = useForm<ContactDetailsInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      firstName: signupFormData.firstName || '',
      lastName: signupFormData.lastName || '',
      personalEmail: signupFormData.personalEmail || '',
      personalPhone: signupFormData.personalPhone || '',
    },
  });

  const mutation = useMutation((personalEmail: string) => {
    return fetchSignInMethodsForEmail(auth, personalEmail); //will return [] of sign in methods if user exists
  });

  const onSubmit = (newData: Partial<UserSignUpObject>) => {
    setFormValues(newData);
    checkEmailThenNext(newData.personalEmail || '');
  };
  const [warning, setWarning] = useState('');

  const checkEmailThenNext = async (emailAddress: string) => {
    //check if email is already in firebase
    if (emailAddress) {
      mutation.mutate(emailAddress);
    }
  };

  useEffect(() => {
    if (mutation.isError) {
      setWarning('Email address already in use'); // if user exists, do not proceed, and set banner feedback
    } else if (mutation.isSuccess) {
      setWarning('');
      handleNext();
    }
  }, [mutation]);

  return (
    <div className="row text-start ps-2">
      <div className="row text-start">
        <div className="col-xl-4 col-lg-2 col-md-1 d-md-block d-sm-none d-none"></div>
        <div className="col-xl-4 col-lg-8 col-md-10 mb-4">
          Please enter your contact details
        </div>
        <div className="col-xl-4 col-lg-2 col-md-1 d-md-block d-sm-none d-none"></div>
      </div>
      <div className="row text-start px-0">
        <div className="col-xl-4 col-lg-2 col-md-1 d-md-block d-sm-none d-none d-flex justify-content-center"></div>
        <div className="col-xl-4 col-lg-8 col-md-10 mb-5">
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            {warning && (
              <MessageBanner type="error" sx={{ width: '384px' }}>
                {warning}
              </MessageBanner>
            )}

            <HookFormTextInput
              error={errors.firstName}
              isFilled={
                Object.keys(dirtyFields).includes('firstName') ||
                !!signupFormData.firstName
              }
              name="firstName"
              label="First Name"
              testid="contactDetails.firstName"
              width="form-xl"
              register={register}
              rounded=""
            />
            <HookFormTextInput
              error={errors.lastName}
              isFilled={
                Object.keys(dirtyFields).includes('lastName') ||
                !!signupFormData.lastName
              }
              name="lastName"
              label="Last Name"
              testid="contactDetails.lastName"
              width="form-xl"
              register={register}
              rounded=""
            />
            <HookFormTextInput
              error={errors.personalEmail}
              isFilled={
                Object.keys(dirtyFields).includes('personalEmail') ||
                !!signupFormData.personalEmail
              }
              name="personalEmail"
              label="Email"
              testid="contactDetails.personalEmail"
              width="form-xl"
              register={register}
              rounded=""
            />
            <HookFormTextInput
              error={errors.personalPhone}
              isFilled={
                Object.keys(dirtyFields).includes('personalPhone') ||
                !!signupFormData.personalPhone
              }
              name="personalPhone"
              label="Phone"
              testid="contactDetails.personalPhone"
              width="form-xl"
              register={register}
              rounded=""
            />
            <div
              style={{
                marginTop: '-10px',
                marginLeft: '-20px',
                display: 'flex',
                flexDirection: 'row-reverse',
                gap: '180px',
              }}
            >
              <HookFormSubmitButton
                text="Next"
                default="blue-baby-blue"
                hover="white-navy"
              />
              <Link to="/">
                <RoundButton
                  text="< Back"
                  default="all-white"
                  hover="all-white"
                />{' '}
              </Link>
            </div>
          </form>
        </div>
        <div className="col-xl-4 col-lg-2 col-md-1 d-md-block d-sm-none d-none"></div>
      </div>
    </div>
  );
}
