import {
  MerchantUser,
  MerchantUserDisplayStatus,
  MerchantUserStatus,
  User,
  UserRole,
} from '@/types';
import { Theme } from '@silverstein-properties/inspirelabs-ui/dist/src/theme/types/Theme';

export const validateRole = (user: User | undefined, role: UserRole) => {
  if (!user) {
    return false;
  }
  return user.roles?.some(r => r.role === role);
};

export const validateRoles = (user: User | undefined, roles: UserRole[]) => {
  if (!user) {
    return false;
  }
  return user.roles?.some(r => roles.includes(r.role));
};

export const validateRoleByMerchantId = (
  user: User | undefined,
  role: UserRole,
  merchantId: string | undefined
) => {
  if (!user || !merchantId) {
    return false;
  }
  return user.roles?.some(r => r.role === role && r.merchantId === merchantId);
};

export const validateRolesByMerchantId = (
  user: User | undefined | null,
  roles: UserRole[],
  merchantId: string | undefined
) => {
  if (!user || !merchantId) {
    return false;
  }
  return user.roles?.some(
    r => roles.includes(r.role) && r.merchantId === merchantId
  );
};

export const getRolesNames = (user: User | undefined) => {
  if (!user) {
    return '';
  }
  return user.roles?.map(r => r.role).join(', ');
};

export const getStatusDisplayName = (
  status: MerchantUserStatus,
  passwordResetTokenExpiry: Date | undefined
) => {
  if (
    status !== MerchantUserStatus.ACTIVE &&
    passwordResetTokenExpiry &&
    passwordResetTokenExpiry < new Date()
  ) {
    return MerchantUserDisplayStatus[MerchantUserStatus.EXPIRED];
  }
  return MerchantUserDisplayStatus[status];
};

export const getColorUserStatus = (teamMember: MerchantUser, theme: Theme) => {
  const StatusColor = {
    [MerchantUserStatus.ACTIVE]: theme.palette.success.main,
    [MerchantUserStatus.DEACTIVATED]: theme.palette.error.main,
    [MerchantUserStatus.DELETED]: theme.palette.error.main,
    [MerchantUserStatus.EXPIRED]: theme.palette.error.medium,
    [MerchantUserStatus.PENDING_INVITATION]: theme.palette.primary.medium,
    [MerchantUserStatus.PENDING_REGISTRATION]: theme.palette.primary.medium,
  };
  if (
    teamMember.passwordResetTokenExpiry &&
    teamMember.status !== MerchantUserStatus.ACTIVE &&
    teamMember.passwordResetTokenExpiry < new Date()
  ) {
    return theme.palette.error.main;
  }
  return StatusColor[teamMember.status];
};
