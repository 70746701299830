import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Grid, Typography } from '@silverstein-properties/inspirelabs-ui';

export const ActionEventModule = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get('mode') && searchParams.get('oobCode')) {
      if (searchParams.get('mode') === 'verifyEmail') {
        navigate(`/email-verification?${searchParams.toString()}`);
      } else if (searchParams.get('mode') === 'resetPassword') {
        navigate(`/passwordreset?${searchParams.toString()}`);
      } else {
        navigate(`/${searchParams.get('mode')}?${searchParams.toString()}`);
      }
    }
  }, [searchParams]);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="h4">Loading...</Typography>
    </Grid>
  );
};
