import './App.css';
import NavBar from './components/NavBar/Component';
import { LandingPage } from './components/LandingPage/Component';
import { SignupFormContainer } from './components/SignupFormContainer';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { PageNotFound } from './components/PageNotFound/Component';
import { Privacy } from './components/Privacy/Component';
import { TermsAndConditions } from './components/TermsAndConditions/Component';
import BusinessFormContainer from './components/BusinessFormContainer/Component';
import {
  ExperienceDetailsModule,
  MerchantDashboardModule,
  MerchantExperiencesModule,
  MerchantPaymentStatementsModule,
  MessagesModule,
  PaymentStatementDetailsModule,
  SchedulingModule,
  MembershipCreationModule,
  MerchantMembershipsModule,
  UserProfileModule,
  LoginModule,
  ResetPasswordModule,
  CreatePasswordModule,
  SendResetPasswordModule,
  EmailVerificationModule,
  ActionEventModule,
  MerchantTeamsModule,
} from './modules';
import { PhotoGuidelines } from './components/PhotoGuidelines/Component';

import MerchFormProvider from './contexts/MerchCreationProvider';
import SubscriptionFormProvider from './contexts/MembershipCreationProvider';
import FormProvider from './contexts/SignUpProvider';
import { ContactUsForm } from 'components/ContactUs/Component';
import { FormSuccess } from 'components/ContactUs/FormSuccess';

import { signOut } from 'firebase/auth';
import { auth } from './config/config';
import { ProtectedRoute } from 'routing';
import { useEffect } from 'react';
import { useAuth, useFeatureFlags } from 'hooks';
import { MembershipDetailsModule } from './modules/MembershipDetailsModule';
import { MerchantBusinessProfileModule } from './modules/MerchantBusinessProfileModule';
import 'reflect-metadata';
import { routes } from './routing/routes';
import { LayoutWithFooter } from './layouts';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: user, refetch } = useAuth();
  const {
    isCommunicationInboxEnabled,
    isMerchantSchedulingEnabled,
    isMerchantStatementsEnabled,
    areMerchantMembershipsEnabled,
    isMerchantTeamEnabled,
  } = useFeatureFlags();

  const logOut = async () => {
    await signOut(auth);
    refetch();
    navigate('/');
  };

  useEffect(() => {
    // Redirect user to dashboard page if they're logged in
    // and have at least one experience
    if (user && location.pathname === '/') {
      navigate('/dashboard');
    }
  }, [user]);

  return (
    <div className="app">
      <NavBar logOut={logOut} />
      <Routes>
        {/* These routes use the same layout with <Footer /> */}
        <Route path={routes.home} element={<LayoutWithFooter />}>
          <Route path="" element={<LandingPage />} />
          <Route path="/photoguidelines" element={<PhotoGuidelines />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/termsandconditions" element={<TermsAndConditions />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>

        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <UserProfileModule />
            </ProtectedRoute>
          }
        />
        <Route
          path="/businessProfile"
          element={
            <ProtectedRoute>
              <MerchantBusinessProfileModule />
            </ProtectedRoute>
          }
        />
        {isMerchantTeamEnabled ? (
          <Route
            path="/my-team"
            element={
              <ProtectedRoute>
                <MerchantTeamsModule />
              </ProtectedRoute>
            }
          />
        ) : null}
        <Route
          path="/signup"
          element={
            <FormProvider>
              <SignupFormContainer />
            </FormProvider>
          }
        />
        <Route path="/login" element={<LoginModule />} />

        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <MerchantDashboardModule />
            </ProtectedRoute>
          }
        />
        {isMerchantSchedulingEnabled ? (
          <Route
            path="/calendar"
            element={
              <ProtectedRoute>
                <SchedulingModule />
              </ProtectedRoute>
            }
          />
        ) : null}
        <Route
          path="/experiences"
          element={
            <ProtectedRoute>
              <MerchantExperiencesModule />
            </ProtectedRoute>
          }
        />
        <Route
          path="/experiences/create"
          element={
            <ProtectedRoute>
              <MerchFormProvider>
                <BusinessFormContainer />
              </MerchFormProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="/experiences/:experienceId"
          element={
            <ProtectedRoute>
              <ExperienceDetailsModule />
            </ProtectedRoute>
          }
        />
        {isMerchantStatementsEnabled ? (
          <>
            <Route
              path="/statements"
              element={
                <ProtectedRoute>
                  <MerchantPaymentStatementsModule />
                </ProtectedRoute>
              }
            />
            <Route
              path="/statements/:statementId"
              element={
                <ProtectedRoute>
                  <PaymentStatementDetailsModule />
                </ProtectedRoute>
              }
            />
          </>
        ) : null}
        {isCommunicationInboxEnabled ? (
          <Route
            path="/messages"
            element={
              <ProtectedRoute>
                <MessagesModule />
              </ProtectedRoute>
            }
          />
        ) : null}
        <Route path="/passwordset" element={<CreatePasswordModule />} />
        <Route path="/passwordreset" element={<ResetPasswordModule />} />
        <Route
          path="/email-verification"
          element={<EmailVerificationModule />}
        />
        <Route path="/action-event" element={<ActionEventModule />} />
        <Route
          path="/contactus"
          element={
            <ProtectedRoute>
              <ContactUsForm />
            </ProtectedRoute>
          }
        />
        <Route path="/formsubmit" element={<FormSuccess />} />
        <Route
          path="/send-reset-password"
          element={<SendResetPasswordModule />}
        />
        {areMerchantMembershipsEnabled ? (
          <Route
            path="/memberships"
            element={
              <ProtectedRoute>
                <MerchantMembershipsModule />
              </ProtectedRoute>
            }
          />
        ) : null}
        {areMerchantMembershipsEnabled ? (
          <>
            <Route
              path="/memberships/create"
              element={
                <ProtectedRoute>
                  <SubscriptionFormProvider>
                    <MembershipCreationModule />
                  </SubscriptionFormProvider>
                </ProtectedRoute>
              }
            />
            <Route
              path="/memberships/:membershipId"
              element={
                <ProtectedRoute>
                  <MembershipDetailsModule />
                </ProtectedRoute>
              }
            />
          </>
        ) : null}
      </Routes>
    </div>
  );
}

export default App;
