import RoundButton from 'components/RoundButton/Component';
import HookFormSubmitButton from 'components/RoundButton/HookFormSubmitButton';
import './SignupFormContainer.css';
import { HookFormTextInput } from 'components/FormTextInput/HookFormTextInput';
import { Resolver, useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import {
  MaxLength,
  MinLength,
  IsEmail,
  IsUrl,
  IsAlpha,
  IsNumberString,
} from 'class-validator';
import { useFormData } from '../../contexts/SignUpProvider';

export interface CompanyInfoProps {
  handleNext: () => void;
  handlePrev: () => void;
}

class CompanyInfoInput {
  @MinLength(1, { message: 'Please enter a company name.' })
  @MaxLength(100, { message: 'Company name is too long.' })
  companyName: string;

  @MinLength(1, { message: 'Please enter an address.' })
  @MaxLength(50, { message: 'Your address is too long.' })
  address: string;

  @MinLength(1, { message: 'City is blank.' })
  city: string;

  apt: string;

  @IsAlpha(undefined, { message: 'Invalid' })
  @MinLength(2, { message: 'Invalid' })
  @MaxLength(3, { message: 'Invalid' })
  merchantState: string;

  @IsNumberString(undefined, { message: 'Invalid ZIP' })
  @MinLength(5, { message: 'Invalid ZIP' })
  @MaxLength(10, { message: 'Invalid ZIP' })
  zip: string;

  @IsUrl(undefined, { message: 'Please enter a valid URL.' })
  companyURL: string;

  @IsEmail(undefined, { message: 'Please enter a valid email.' })
  supportEmail: string;
}

const resolver: Resolver<CompanyInfoInput> =
  classValidatorResolver(CompanyInfoInput);

export default function CompanyInfo(props: CompanyInfoProps): JSX.Element {
  const { setFormValues, signupFormData } = useFormData();
  const {
    register,
    formState: { dirtyFields, errors },
    handleSubmit,
  } = useForm<CompanyInfoInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      companyName: signupFormData.companyName || '',
      address: signupFormData.address || '',
      apt: signupFormData.apt || '',
      city: signupFormData.city || '',
      merchantState: signupFormData.userState || '',
      zip: signupFormData.zip || '',
      companyURL: signupFormData.companyURL || '',
      supportEmail: signupFormData.supportEmail || '',
    },
  });

  const onSubmit = (newData: CompanyInfoInput) => {
    setFormValues(newData);
    props.handleNext();
  };

  return (
    <div className="row d-flex justify-content-center ps-2">
      <div className="row text-start">
        <div className="col-xl-4 col-lg-2 col-md-1 d-md-block d-sm-none d-none"></div>
        <div className="col-xl-4 col-lg-8 col-md-10 mb-4 px-0">
          Please enter your company info
        </div>
        <div className="col-xl-4 col-lg-2 col-md-1 d-md-block d-sm-none d-none"></div>
      </div>
      <div className="row text-start px-0">
        <div className="col-xl-4 col-lg-2 col-md-1 d-md-block d-sm-none d-none"></div>

        <div className="col-xl-7 col-lg-8 col-md-10 mb-5 px-0">
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <HookFormTextInput
              error={errors.companyName}
              isFilled={
                Object.keys(dirtyFields).includes('companyName') ||
                !!signupFormData.companyName
              }
              register={register}
              name="companyName"
              label="Company Name"
              testid="companyInfo.companyName"
              type="text"
              width="form-xl"
            />
            <span className="inline-group">
              <HookFormTextInput
                error={errors.address}
                isFilled={
                  Object.keys(dirtyFields).includes('address') ||
                  !!signupFormData.address
                }
                register={register}
                name="address"
                label="Address"
                testid="companyInfo.address"
                type="text"
                width="form-l"
                rounded="rounded-left"
              />
              <HookFormTextInput
                isFilled={
                  Object.keys(dirtyFields).includes('apt') ||
                  !!signupFormData.apt
                }
                register={register}
                name="apt"
                label="Apt./Fl."
                testid="companyInfo.apt"
                type="text"
                width="form-s"
                rounded="rounded-right"
              />
            </span>
            <span className="inline-group">
              <HookFormTextInput
                error={errors.city}
                isFilled={
                  Object.keys(dirtyFields).includes('city') ||
                  !!signupFormData.city
                }
                register={register}
                name="city"
                label="City"
                testid="companyInfo.city"
                type="text"
                width="form-m"
                rounded="rounded-left"
              />
              <HookFormTextInput
                error={errors.merchantState}
                isFilled={
                  Object.keys(dirtyFields).includes('merchantState') ||
                  !!signupFormData.userState
                }
                register={register}
                name="merchantState"
                label="State"
                testid="companyInfo.state"
                type="text"
                width="form-s"
                rounded="square-input"
              />
              <HookFormTextInput
                error={errors.zip}
                isFilled={
                  Object.keys(dirtyFields).includes('zip') ||
                  !!signupFormData.zip
                }
                register={register}
                name="zip"
                label="Zipcode"
                testid="companyInfo.zip"
                type="text"
                width="form-s"
                rounded="rounded-right"
              />
            </span>
            <HookFormTextInput
              error={errors.companyURL}
              isFilled={
                Object.keys(dirtyFields).includes('companyURL') ||
                !!signupFormData.companyURL
              }
              register={register}
              name="companyURL"
              label="Website URL"
              testid="companyInfo.companyURL"
              type="text"
              width="form-xl"
            />
            <HookFormTextInput
              error={errors.supportEmail}
              isFilled={
                Object.keys(dirtyFields).includes('supportEmail') ||
                !!signupFormData.supportEmail
              }
              register={register}
              name="supportEmail"
              label="Support Email Address"
              testid="companyInfo.supportEmail"
              type="email"
              width="form-xl"
            />
            <div
              style={{
                marginTop: '-10px',
                marginLeft: '-20px',
                display: 'flex',
                flexDirection: 'row-reverse',
                gap: '160px',
              }}
            >
              <HookFormSubmitButton
                text="Next"
                default="blue-baby-blue"
                hover="white-navy"
              />
              <RoundButton
                text="< Back"
                default="all-white"
                hover="all-white"
                handleClick={props.handlePrev}
              />
            </div>
          </form>
        </div>

        <div className="col-xl-1 col-lg-2 col-md-1 d-md-block d-sm-none d-none"></div>
      </div>
    </div>
  );
}
// }
