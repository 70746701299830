import { AxiosInstance } from 'axios';
import { DropdownType } from '@/types';

export const endpoints = {
  categoryChoices() {
    return '/product-lookup-items/service-categories';
  },
  subCategoryChoices() {
    return '/product-lookup-items/service-subcategories';
  },
};

export const productLookupItems = ({
  axiosInstance,
}: {
  axiosInstance: AxiosInstance;
}) => {
  return {
    getCategoryChoices: async (): Promise<DropdownType[]> => {
      const { data } = await axiosInstance.get(endpoints.categoryChoices());
      return data;
    },
    getSubCategoryChoices: async (): Promise<DropdownType[]> => {
      const { data } = await axiosInstance.get(endpoints.subCategoryChoices());
      return data;
    },
  };
};
