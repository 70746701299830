import { IsEnum, IsNotEmpty, ValidateIf } from 'class-validator';
import { PriceMaxValue, PriceMinValue } from './validations';
import { RefundReasons } from '@/types';

export class AmountInput {
  @IsNotEmpty({
    message: args => {
      return `Enter any refund amount you'd like to ${
        (args.object as AmountInput).type
      }, up to a maximum of the original transaction value`;
    },
  })
  @PriceMinValue(1, {
    message: args =>
      `Your ${
        (args.object as AmountInput).type
      } amount must be greater than $1`,
  })
  @PriceMaxValue('maxAmount', {
    message: args =>
      `Your ${
        (args.object as AmountInput).type
      } amount cannot be greater than $${
        (args.object as AmountInput).maxAmount
      }`,
  })
  amount: number | string;

  @ValidateIf(o => o.type === 'refund')
  @IsEnum(RefundReasons)
  @IsNotEmpty({
    message: 'Select a reason for your refund',
  })
  reason?: RefundReasons;

  maxAmount: number | string;

  type: 'refund' | 're-run';
}
