import { AddQuoteInput, PricingWithTaxesInput } from '@/classes';
import { PricingWithTaxes } from '@/components/PricingWithTaxes';
import { TEXT_AREA_LIMIT_MEDIUM } from '@/constants';
import { PriceType, PriceTypeLabel } from '@/types';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { FormHelperText } from '@mui/material';
import {
  Box,
  FormControl,
  FormControlLabel,
  InputField,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
} from '@silverstein-properties/inspirelabs-ui';
import { omit } from 'lodash';
import { useEffect } from 'react';
import {
  Control,
  Controller,
  FormState,
  UseFormResetField,
  UseFormSetValue,
  UseFormWatch,
  useForm,
} from 'react-hook-form';
import { formatPricing, pluralize, renderInputFieldHelperText } from '@/utils';
import { useFeatureFlags, useMembershipPlansByProductId } from '@/hooks';

export type QuotePricingProps = {
  control: Control<AddQuoteInput>;
  formState: FormState<AddQuoteInput>;
  watch: UseFormWatch<AddQuoteInput>;
  setValue: UseFormSetValue<AddQuoteInput>;
  resetField: UseFormResetField<AddQuoteInput>;
};

export const QuotePricing = ({
  control,
  formState: { errors },
  watch,
  setValue,
  resetField,
}: QuotePricingProps) => {
  const resolver = classValidatorResolver(PricingWithTaxesInput);
  const {
    control: pricingControl,
    formState: pricingFormState,
    watch: pricingWatch,
  } = useForm<PricingWithTaxesInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      priceId: watch('basePrice.priceId'),
      amount: watch('basePrice.amount') || '',
      taxRateId: watch('basePrice.taxRateId') || '',
      description: watch('basePrice.description') || '',
      membershipPlanId: watch('basePrice.membershipPlanId') || '',
    },
  });

  const { isBookingRequestWithMembershipEnabled } = useFeatureFlags();

  const amount = pricingWatch('amount');
  const taxRateId = pricingWatch('taxRateId');
  const description = pricingWatch('description');
  const priceType = watch('basePrice.priceType');

  useEffect(() => {
    setValue('basePrice.amount', amount);
  }, [amount]);

  useEffect(() => {
    setValue('basePrice.taxRateId', taxRateId);
  }, [taxRateId]);

  useEffect(() => {
    setValue('basePrice.description', description);
  }, [description]);

  useEffect(() => {
    if (priceType !== PriceType.MEMBERSHIP) {
      resetField('basePrice.membershipPlanId');
    }
  }, [priceType]);

  const { data, isLoading } = useMembershipPlansByProductId({
    productId: watch('productId'),
    isEnabled: priceType === PriceType.MEMBERSHIP,
  });

  const membershipPlansOptions = data?.map(plan => ({
    id: plan.id,
    value: plan.id,
    label: `${plan.name} ${formatPricing(plan.totalAmount || 0)}/${
      plan.billingCycleType
    }/${pluralize(plan.maxNbOfConsumers, 'member')}`,
  }));

  return (
    <Stack spacing={2}>
      <Controller
        control={control}
        name="basePrice.priceType"
        render={({ field }) => (
          <FormControl>
            <RadioGroup {...omit(field, 'ref')}>
              <FormControlLabel
                value={PriceType.PRICE_PER_PERSON}
                control={<Radio />}
                label={PriceTypeLabel.PRICE_PER_PERSON}
                sx={{
                  color: ({ palette }) =>
                    !!errors.basePrice?.priceType
                      ? palette.error.main
                      : palette.primary.main,
                }}
              />
              <FormControlLabel
                value={PriceType.FLAT_FEE}
                control={<Radio />}
                label={PriceTypeLabel.FLAT_FEE}
                sx={{
                  color: ({ palette }) =>
                    !!errors.basePrice?.priceType
                      ? palette.error.main
                      : palette.primary.main,
                }}
              />
              {isBookingRequestWithMembershipEnabled ? (
                <FormControlLabel
                  value={PriceType.MEMBERSHIP}
                  control={<Radio />}
                  label={PriceTypeLabel.MEMBERSHIP}
                  sx={{
                    color: ({ palette }) =>
                      !!errors.basePrice?.priceType
                        ? palette.error.main
                        : palette.primary.main,
                  }}
                />
              ) : null}
            </RadioGroup>
            <FormHelperText error={!!errors.basePrice?.priceType}>
              {errors.basePrice?.priceType?.message}
            </FormHelperText>
          </FormControl>
        )}
      />
      {priceType === PriceType.MEMBERSHIP ? (
        isLoading ? (
          <Skeleton variant="rounded" height={62} />
        ) : (
          <Controller
            name="basePrice.membershipPlanId"
            control={control}
            render={({ field }) => (
              <InputField
                label="Select membership plan"
                {...field}
                select
                dropdownItems={membershipPlansOptions}
                error={!!errors.basePrice?.membershipPlanId}
                helperText={errors.basePrice?.membershipPlanId?.message}
              />
            )}
          />
        )
      ) : (
        <PricingWithTaxes
          control={pricingControl}
          formState={pricingFormState}
          watch={pricingWatch}
          isFlatFee={priceType === PriceType.FLAT_FEE}
        />
      )}
      <Box pt={2}>
        <Controller
          control={control}
          name="basePrice.description"
          data-testid="addQuote_priceDescription"
          render={({ field }) => (
            <InputField
              label="Price description (optional)"
              {...field}
              multiline
              minRows={3}
              error={!!errors.basePrice?.description}
              helperText={renderInputFieldHelperText(
                watch('basePrice.description')?.length,
                TEXT_AREA_LIMIT_MEDIUM,
                errors.basePrice?.description?.message
              )}
            />
          )}
        />
      </Box>
    </Stack>
  );
};
