import {
  Button,
  Chip,
  Container,
  Grid,
  Link,
  Skeleton,
  Stack,
  Typography,
  useTheme,
  MessageBanner,
} from '@silverstein-properties/inspirelabs-ui';
import { To, useLocation, useNavigate } from 'react-router';
import {
  TitleAndDescriptionSectionEdit,
  DetailSection,
  FloatingMenu,
  MembersSectionOld,
  TitleAndDescriptionSection,
  PhotoSectionEdit,
} from './components';
import AlertDialog from '@/components/AlertDialog/AlertDialog';
import AlertIcon from '@/components/AlertDialog/AlertIcon.svg';
import { useEffect, useRef, useState } from 'react';
import { DetailsDrawer } from '@/components';
import { ScreensViews, useMembershipEdit } from '@/hooks/useMembershipEdit';
import { EditPlan } from './components/PlansSectionEdit';
import { PlansSection } from './components/PlansSection';
import { AddPlan } from './components/PlansSectionEdit';
import { PhotoSection } from './components';
import { useFeatureFlags } from '@/hooks';
import { MembersSection } from './components/MembersSection';

export const MembershipDetailsModule = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState<To>('/memberships');
  const [membersNumber, setMembersNumber] = useState(0);

  const { isNewMemberSectionEnabled } = useFeatureFlags();
  const menuRef = useRef<HTMLDivElement>(null);

  const {
    onFormSubmitTitleAndDescription,
    onFormSubmitEditPlan,
    onFormSubmitAddPlan,
    onFormSubmitPhotosMutation: { mutateAsync: onFormSubmitPhotos },
    setDrawerOpen,
    drawerScreen,
    setDrawerScreen,
    height,
    setHeight,
    onMenuClick,
    onEditClick,
    onWindowClose,
    menuSelected,
    drawerOpen,
    membership,
    planOnActionsClick,
    planId,
    showTerminateAlertDialog,
    showDeletePlanAlertDialog,
    banner,
    handleTerminatePlansAlertDialogClose,
    handleDeletePlansAlertDialogClose,
  } = useMembershipEdit();
  // TODO Navigation to certain areas
  // TODO add type information when the edit screen is implemented

  useEffect(() => {
    if (location.state?.from) {
      setPrevLocation(location.state.from);
    }
  }, []);

  if (!membership) {
    return null;
  }

  const navigateBack = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    navigate(prevLocation);
  };
  // TODO add type information when the edit screen is implemented

  const renderDetailContent = (id: string) => {
    switch (id) {
      case ScreensViews.TITLE_DESCRIPTION:
        return <TitleAndDescriptionSection membership={membership} />;
      case ScreensViews.PLANS:
        return (
          <PlansSection
            membership={membership}
            planOnActionsClick={planOnActionsClick}
            showDeletePlanAlertDialog={showDeletePlanAlertDialog}
            handleDeletePlansAlertDialogClose={
              handleDeletePlansAlertDialogClose
            }
          />
        );
      case ScreensViews.MEMBERS:
        return isNewMemberSectionEnabled ? (
          <MembersSection
            membership={membership}
            onMembersNumberChange={setMembersNumber}
          />
        ) : (
          <MembersSectionOld
            membership={membership}
            onMembersNumberChange={setMembersNumber}
          />
        );
      case ScreensViews.PHOTOS:
        return <PhotoSection membership={membership} />;
      default:
        return null;
    }
  };

  const renderDrawer = () => {
    switch (drawerScreen) {
      case ScreensViews.EDIT_TITLE_DESCRIPTION:
        return (
          <TitleAndDescriptionSectionEdit
            membership={membership}
            onFormSubmit={onFormSubmitTitleAndDescription}
          />
        );
      case ScreensViews.EDIT_PLANS: {
        const membershipPlan = membership.membershipPlans.find(
          x => x.id === planId
        );
        if (!membershipPlan) {
          console.error('Plan not found');
          setDrawerOpen(false);
          setDrawerScreen('');
          return null;
        } else {
          return (
            <EditPlan
              membershipPlan={membershipPlan}
              onFormSubmit={onFormSubmitEditPlan}
            />
          );
        }
      }
      case ScreensViews.ADD_PLANS:
        return (
          <AddPlan
            membershipId={membership.id}
            onFormSubmit={onFormSubmitAddPlan}
          />
        );
      case ScreensViews.EDIT_PHOTOS:
        return (
          <PhotoSectionEdit
            membership={membership}
            onFormSubmit={onFormSubmitPhotos}
          />
        );
      case ScreensViews.MEMBERS:
        return null;
      default:
        return null;
    }
  };

  const renderDetailTitle = (id: string, title: string) => {
    switch (id) {
      case ScreensViews.MEMBERS:
        return (
          <Stack direction="row" spacing={theme.spacers.s} alignItems="center">
            <Typography variant="h5">{title}</Typography>
            <Chip
              label={
                !!title ? (
                  <Typography variant="button">{membersNumber}</Typography>
                ) : (
                  <Skeleton />
                )
              }
              size="medium"
              color="default"
            />
          </Stack>
        );
      case ScreensViews.PLANS:
      case ScreensViews.TITLE_DESCRIPTION:
      default:
        return title;
    }
  };

  return (
    <Container sx={{ paddingY: theme.spacers.xxl4 }}>
      {showTerminateAlertDialog && (
        <AlertDialog
          open={showTerminateAlertDialog}
          onClose={handleTerminatePlansAlertDialogClose}
          image={AlertIcon}
          title="Are you sure you want to terminate this plan?"
          message="Current members will retain access to their plan entitlements until their billing cycle ends. This plan will be immediately unpublished, preventing new sign-ups."
          agree="Confirm"
          disagree="Cancel"
        />
      )}
      <DetailsDrawer
        actions={
          <>
            <Button onClick={onWindowClose} variant="text">
              Cancel
            </Button>

            <Button
              type="submit"
              form={`${drawerScreen}Form`}
              sx={{ color: theme.palette.primary.light }}
            >
              Save
            </Button>
          </>
        }
        closeWindow={onWindowClose}
        drawerOpen={drawerOpen}
        title={membership.name}
      >
        {renderDrawer()}
      </DetailsDrawer>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Link
            href="#"
            onClick={navigateBack}
            underline="hover"
            variant="subtitle2"
          >
            ‹ Back
          </Link>
          <Typography variant="h4" sx={{ pt: 3.75, wordWrap: 'break-word' }}>
            {membership.name}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <FloatingMenu
            ref={menuRef}
            onClick={onMenuClick}
            setHeight={setHeight}
          />
        </Grid>
        <Grid container item xs={9} rowSpacing={3}>
          <Grid item xs={12} sx={{ height }} mb={2}>
            {/* TODO: Add action buttons (preview and publish) here in the future */}
            {banner.message && (
              <MessageBanner type={banner.type}>{banner.message}</MessageBanner>
            )}
          </Grid>
          {!!menuSelected && (
            <DetailSection
              key={`${menuSelected.id}_section`}
              id={menuSelected.id}
              membership={membership}
              onEditClick={onEditClick}
              title={renderDetailTitle(menuSelected.id, menuSelected.title)}
              buttonLabel={menuSelected.button}
              showTimestamp={menuSelected.showTimestamp}
              onlyChildren={!menuSelected.withDetail}
            >
              {renderDetailContent(menuSelected.id)}
            </DetailSection>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
