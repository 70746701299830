import React from 'react';
import CheckBoxEmpty from './checkbox_empty.svg';
import RadioEmpty from './radio_empty.svg';
import CheckBoxFilled from './checkbox_filled.svg';
import RadioFilled from './radio_filled.svg';
import './selector.css';
import { InputChangeEvent } from '../../types';

export interface SelectorProps {
  type: 'checkbox' | 'radio';
  selected: boolean;
  value: string | boolean;
  text: string;
  name: string;
  onClick?: (e: InputChangeEvent) => void;
}

export default function Selector(props: SelectorProps): JSX.Element {
  let image;
  if (props.type === 'radio') {
    props.selected === true ? (image = RadioFilled) : (image = RadioEmpty);
  } else {
    props.selected === true
      ? (image = CheckBoxFilled)
      : (image = CheckBoxEmpty);
  }

  const { name, value } = props; // destructuring these props to be able to pass values to onClick fn without TS complaining

  return (
    <div className="selector">
      <div
        className="selector-check"
        title={name}
        onClick={() => props.onClick && props.onClick({ key: name, value })}
        id={value.toString()}
      >
        <img src={image} alt="selector" />
      </div>
      <span className="selector-text">{props.text} </span>
    </div>
  );
}
