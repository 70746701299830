import { useAuth } from '../useAuth';
import { useQuery } from '@tanstack/react-query';
import { ApiClient } from '../../api/apiClient';
import { TaxRate } from '@/types';

export const useTaxRate = (
  taxRateId: number | undefined,
  shouldCallApi = true
) => {
  const { data: user } = useAuth();
  const apiClient = ApiClient();

  const getTaxRate = async (): Promise<TaxRate | null> => {
    if (!taxRateId) {
      return null;
    }
    return await apiClient.taxRates.getTaxRate(taxRateId);
  };

  return useQuery<TaxRate | null>(['taxRate', taxRateId], () => getTaxRate(), {
    enabled: !!user && shouldCallApi,
  });
};
