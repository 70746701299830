import { Location } from 'types';
import { useAuth } from './useAuth';
import { useQuery } from '@tanstack/react-query';
import { ApiClient } from '../api/apiClient';

type UseLocationsPropType = {
  locationId: string;
};

export const useLocations = ({ locationId }: UseLocationsPropType) => {
  const { data: user } = useAuth();
  const apiClient = ApiClient();

  const getLocation = async (
    accessToken?: string
  ): Promise<Location | null> => {
    if (accessToken && locationId) {
      return await apiClient.locations.getLocation({ locationId });
    } else {
      return null;
    }
  };

  return useQuery<Location | null>(
    ['thisLocation', locationId],
    () => getLocation(user?.accessToken),
    { enabled: !!user && !!locationId }
  );
};
