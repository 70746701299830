import { formatDateShortForm } from '@/utils';

export class MembershipDisplayStatusObject {
  status: string;
  expiration: string | null;
  color: string | null;
}
export enum MembershipDisplayStatus {
  ACTIVE = 'Active',
  TERMINATED = 'Terminated',
  CANCELLED = 'Cancelled',
  ON_HOLD = 'On-hold',
  PAST_DUE = 'Past due',
  INACTIVE = 'Inactive',
  TRIAL = 'Trial',
  TBD = 'status TBD',
}

export const MEMBERSHIP_DISPLAY_STATUS = {
  ACTIVE: {
    status: MembershipDisplayStatus.ACTIVE,
    expiration: null,
    color: 'success.main',
  },
  ACTIVE_WITH_EXPIRATION: (expDate: Date) => ({
    status: MembershipDisplayStatus.ACTIVE,
    expiration: `Exp. ${formatDateShortForm(expDate, 'MM/dd/yy')}`,
    color: 'success.main',
  }),
  TERMINATED: {
    status: MembershipDisplayStatus.TERMINATED,
    expiration: null,
    color: 'error.main',
  },
  CANCELLED: {
    status: MembershipDisplayStatus.CANCELLED,
    expiration: null,
    color: 'error.main',
  },
  ON_HOLD: {
    status: MembershipDisplayStatus.ON_HOLD,
    expiration: null,
    color: 'error.main',
  },
  PAST_DUE: {
    status: MembershipDisplayStatus.PAST_DUE,
    expiration: null,
    color: 'error.main',
  },
  INACTIVE: {
    status: MembershipDisplayStatus.INACTIVE,
    expiration: null,
    color: null,
  },
  TRIALING: (expDate: Date) => ({
    status: MembershipDisplayStatus.TRIAL,
    expiration: `Exp. ${formatDateShortForm(expDate, 'MM/dd/yy')}`,
    color: 'primary.medium',
  }),
  DEFAULT: {
    status: MembershipDisplayStatus.TBD,
    expiration: null,
    color: null,
  },
};
