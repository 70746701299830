import {
  Membership,
  MembershipPlan,
  MembershipPlanDto,
} from '@/types/MerchantMembership';
import { AxiosInstance } from 'axios';

export const endpoints = {
  membershipPlans: '/membership-plans',
  geAllPlansByMerchantId({ merchantId }: { merchantId: number }) {
    return `/membership-plans/merchant/${merchantId}`;
  },
  geAllPlansByProductId({ productId }: { productId: string }) {
    return `/membership-plans/products/${productId}`;
  },
  getMembershipPlan({ membershipPlanId }: { membershipPlanId: string }) {
    return `/membership-plans/${membershipPlanId}`;
  },
  deleteMembershipPlan({ membershipPlanId }: { membershipPlanId: string }) {
    return `/membership-plans/${membershipPlanId}/delete`;
  },
  publishMembershipPlan({ membershipPlanId }: { membershipPlanId: string }) {
    return `/membership-plans/${membershipPlanId}/publish`;
  },
  publishMembershipPlans: '/membership-plans/publish',
  unpublishMembershipPlan({ membershipPlanId }: { membershipPlanId: string }) {
    return `/membership-plans/${membershipPlanId}/unpublish`;
  },
};

export const membershipPlans = ({
  axiosInstance,
}: {
  axiosInstance: AxiosInstance;
}) => {
  return {
    createPlan: async (
      membershipPlan: MembershipPlanDto
    ): Promise<MembershipPlan> => {
      const { data } = await axiosInstance.post(
        endpoints.membershipPlans,
        membershipPlan
      );
      return data;
    },
    getPlanById: async ({
      membershipPlanId,
    }: {
      membershipPlanId: string;
    }): Promise<MembershipPlan> => {
      const { data } = await axiosInstance.get(
        endpoints.getMembershipPlan({ membershipPlanId })
      );
      return data;
    },
    updatePlan: async (
      membershipPlan: MembershipPlanDto
    ): Promise<MembershipPlan> => {
      const { data } = await axiosInstance.put(
        endpoints.getMembershipPlan({
          membershipPlanId: membershipPlan?.membershipPlanId || '',
        }),
        membershipPlan
      );
      return data;
    },
    getPlansByMerchantId: async ({
      merchantId,
    }: {
      merchantId: number;
    }): Promise<MembershipPlan[]> => {
      const { data } = await axiosInstance.get(
        endpoints.geAllPlansByMerchantId({ merchantId })
      );
      return data;
    },
    getPlansByProductId: async ({
      productId,
    }: {
      productId: string;
    }): Promise<MembershipPlan[]> => {
      const { data } = await axiosInstance.get(
        endpoints.geAllPlansByProductId({ productId })
      );
      return data;
    },
    deletePlan: async ({
      membershipPlanId,
    }: {
      membershipPlanId: string;
    }): Promise<Membership[]> => {
      const { data } = await axiosInstance.put(
        endpoints.deleteMembershipPlan({ membershipPlanId })
      );
      return data;
    },
    publishPlan: async ({
      membershipPlanId,
    }: {
      membershipPlanId: string;
    }): Promise<Membership> => {
      const { data } = await axiosInstance.put(
        endpoints.publishMembershipPlan({ membershipPlanId })
      );
      return data;
    },
    unpublishPlan: async ({
      membershipPlanId,
    }: {
      membershipPlanId: string;
    }): Promise<Membership[]> => {
      const { data } = await axiosInstance.put(
        endpoints.unpublishMembershipPlan({ membershipPlanId })
      );
      return data;
    },
  };
};
