import {
  Grid,
  Typography,
  Button,
  MenuItem,
  InputField,
  FormControl,
  Box,
  Svg,
  useTheme,
  Link,
  Stack,
} from '@silverstein-properties/inspirelabs-ui';
import {
  BackButton,
  LabelField,
  MembershipProductsList,
  ProductListDialog,
} from '@/components';
import { useFormData } from '@/contexts/MembershipCreationProvider';
import { Controller, useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { FormHelperText, SxProps } from '@mui/material';
import { ExperienceStatus, SubscriptionType } from '@/types';
import { useEffect, useState } from 'react';
import { useExperiences } from '@/hooks';
import { MembershipPlanInput } from '@/classes/MembershipCreationInput';
import {
  MembershipCategorySubItem,
  MembershipCreationDirection,
} from '@/hooks/useMembershipCreationHooks';
import {
  billingCycleMap,
  contractLengthMap,
  contractTypeMap,
  convertToMonths,
  memberQuantityMap,
} from '@/utils/membershipUtils';
import {
  filterExperienceByStatus,
  formatPercentage,
  mapTaxRateOptions,
} from '@/utils';
import { useMerchantPlatformFee, useTaxRates } from '@/hooks';
import { TAX_EXEMPT_PRICING_NO_LABEL } from '@/constants';

type PlansProps = {
  changeSection: (sectionId: number) => void;
  changeSubItem: (direction: number) => void;
  savePlanToContext: (
    data: MembershipPlanInput,
    isSectionComplete: boolean
  ) => void;
  currentSubItem: number;
  subItems: MembershipCategorySubItem[];
  setMembershipPlanDb: (data: MembershipPlanInput) => Promise<boolean>;
};

// current assumption:
// Users can only add a plan once the current plan is complete
// once a plan is complete - it is saved when a new plan is made or when the section is complete

const resolver = classValidatorResolver(MembershipPlanInput);

export const Plans = ({
  changeSubItem,
  savePlanToContext,
  currentSubItem,
  subItems,
  setMembershipPlanDb,
}: PlansProps) => {
  const theme = useTheme();
  const { data: experiences, isLoading: isExperienceLoading } =
    useExperiences();
  const { subscriptionData } = useFormData();
  const { data: taxRates } = useTaxRates();
  const [membershipProductsError, setMembershipProductsError] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(
    subscriptionData.membershipPlans[currentSubItem]
  );
  const [isSelectionValid, setIsSelectionValid] = useState(true);

  const handleEditExperiencesClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault(); // added to prevent click from going to next plan
    setIsDialogOpen(true);
  };

  let isSectionComplete = false;

  // TODO - fix unexpected behavior when adding, removing, and re-adding experiences(new plans are created without wanting to) -> https://spinspire.monday.com/boards/3397008425/pulses/5323318954
  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
    setValue,
    clearErrors,
    reset,
  } = useForm<MembershipPlanInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      membershipPlanTitle: currentPlan.membershipPlanTitle || '',
      membershipPlanPrice: currentPlan.membershipPlanPrice || '',
      memberQuantity: currentPlan.memberQuantity || '',
      // default for MVP
      isAutoRenew: 'auto-renew',
      billingCycle: currentPlan.billingCycle || '',
      contractLength: currentPlan.contractLength || '',
      taxRateId: currentPlan.taxRateId || undefined,
      // membershipProducts: currentPlan.membershipProducts || [],
    },
  });

  const { data: platformFee } = useMerchantPlatformFee(
    SubscriptionType.MEMBERSHIP
  );

  useEffect(() => {
    setCurrentPlan({
      ...subscriptionData.membershipPlans[currentSubItem],
      membershipProducts: [], // Set this to an empty array or null
    });

    reset({
      membershipPlanTitle: '',
      membershipPlanPrice:
        subscriptionData.membershipPlans[currentSubItem].membershipPlanPrice ||
        '',
      memberQuantity:
        subscriptionData.membershipPlans[currentSubItem].memberQuantity || '',
      isAutoRenew: 'auto-renew',
      billingCycle:
        subscriptionData.membershipPlans[currentSubItem].billingCycle || '',
      membershipProducts: [],
    });
    clearErrors();
  }, [currentSubItem]);

  // Logic to determine if billingCycle <= contractLength
  useEffect(() => {
    const billingCycleKey =
      (watch('billingCycle') as keyof typeof billingCycleMap) ??
      'Every 1 month';
    const contractLengthKey =
      (watch('contractLength') as keyof typeof contractLengthMap) ?? '1 month';

    const billingCycleInMonths = convertToMonths(
      billingCycleMap[billingCycleKey].length,
      billingCycleMap[billingCycleKey].type
    );
    const contractLengthInMonths = convertToMonths(
      contractLengthMap[contractLengthKey].length,
      contractLengthMap[contractLengthKey].type
    );

    setIsSelectionValid(billingCycleInMonths <= contractLengthInMonths);
  }, [watch('contractLength'), watch('billingCycle')]);

  const onFormSubmit = async (data: MembershipPlanInput) => {
    // Check if billing cycle is valid
    if (!isSelectionValid) {
      return; // Prevent form submission
    }

    // Existing logic for membershipProductsError
    if (membershipProductsError === '' && data.membershipProducts.length > 0) {
      setMembershipProductsError('');
      await setMembershipPlanDb(data);
      savePlanToContext(data, isSectionComplete);
    } else {
      setMembershipProductsError('Please select some products');
    }
  };

  const onProductSelect = (ids: string[]) => {
    currentPlan.membershipProducts = experiences?.filter(x =>
      ids.includes(x.id)
    );

    setValue('membershipProducts', currentPlan?.membershipProducts || [], {
      shouldTouch: true,
    });

    if (
      currentPlan?.membershipProducts &&
      currentPlan?.membershipProducts?.length > 0
    ) {
      setMembershipProductsError('');
    } else {
      setMembershipProductsError('Please select some products');
    }
  };

  // Reusable styles for same-line inputs (e.g. price and billing cycle)
  const borderLeftStyle: SxProps = {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  };
  const borderRightStyle: SxProps = {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    '& .MuiOutlinedInput-notchedOutline': {
      borderRight: '1px solid transparent',
    },
  };

  if (isExperienceLoading) {
    return null;
  }
  // TODO: loading screen needed

  return (
    <Grid container xs={6}>
      <Typography variant="h4" marginBottom={theme.spacers.xxl2}>
        Plans
      </Typography>

      <Box
        component="form"
        onSubmit={handleSubmit(onFormSubmit)}
        sx={{ gap: 0 }} // NOTE: quick fix here b/c currently "form > gap: 24px" was there in the main CSS. It causes weird vertical spacings in the form
      >
        <Stack
          spacing={theme.spacers.m}
          marginBottom={theme.spacers.xxl}
          width="100%"
        >
          <Typography variant="subtitle1">Plan details</Typography>
          <Controller
            control={control}
            name="membershipPlanTitle"
            render={({ field }) => (
              <InputField
                {...field}
                label="Plan title"
                data-testid="planAddTitle"
                error={!!errors.membershipPlanTitle}
                helperText={
                  errors.membershipPlanTitle
                    ? errors.membershipPlanTitle.message
                    : 'For example: Household of 4'
                }
              />
            )}
          />
          <Controller
            control={control}
            name="memberQuantity"
            render={({ field }) => (
              <InputField
                {...field}
                select
                id="member-quantity"
                label="No. of members"
                data-testid="memberQuantity"
                dropdownItems={memberQuantityMap}
                error={!!errors.memberQuantity}
                helperText={errors.memberQuantity?.message}
              />
            )}
          />
          <Stack direction="row">
            <Controller
              control={control}
              name="membershipPlanPrice"
              render={({ field }) => (
                <InputField
                  {...field}
                  isPriceInput
                  label="Price"
                  data-testid="planAddPrice"
                  sx={borderRightStyle}
                  error={!!errors.membershipPlanPrice}
                  helperText={
                    errors.membershipPlanPrice
                      ? errors.membershipPlanPrice?.message
                      : `Earnings are subjected to a ${formatPercentage(
                          platformFee
                        )} platform fee`
                  }
                />
              )}
            />
            <Controller
              control={control}
              name="billingCycle"
              render={({ field }) => (
                <InputField
                  {...field}
                  select
                  id="billingCycle"
                  label="Billing cycle"
                  data-testid="billingCycle"
                  sx={borderLeftStyle}
                  error={!!errors.billingCycle || !isSelectionValid}
                  helperText={
                    errors.billingCycle
                      ? errors.billingCycle?.message
                      : isSelectionValid
                      ? 'Members will be charged on this frequency'
                      : "Billing cycle can't be longer than contract length"
                  }
                >
                  {Object.keys(billingCycleMap).map((value, i) => {
                    return (
                      <MenuItem key={i + value} value={value}>
                        {value}
                      </MenuItem>
                    );
                  })}
                </InputField>
              )}
            />
          </Stack>
          <Stack direction="row">
            <Controller
              control={control}
              name="taxRateId"
              render={({ field }) => (
                <InputField
                  {...field}
                  select
                  disabled={false}
                  dropdownItems={
                    taxRates
                      ? [
                          TAX_EXEMPT_PRICING_NO_LABEL,
                          ...mapTaxRateOptions(taxRates, false),
                        ]
                      : [{ id: 0, label: 'Loading...', value: '0' }]
                  }
                  label="Tax rate"
                  error={!!errors?.taxRateId}
                  helperText={
                    errors.taxRateId
                      ? errors.taxRateId.message
                      : 'The tax rate applied to this plan'
                  }
                />
              )}
            />
          </Stack>
          <Stack direction="row">
            <Controller
              control={control}
              name="contractLength"
              render={({ field }) => (
                <InputField
                  {...field}
                  select
                  label="Contract length"
                  data-testid="contractLength"
                  sx={borderRightStyle}
                  error={!!errors.contractLength}
                  helperText={
                    errors.contractLength
                      ? errors.contractLength.message
                      : "Member's duration of commitment"
                  }
                >
                  {Object.keys(contractLengthMap).map((value, i) => {
                    return (
                      <MenuItem key={i + value} value={value}>
                        {value}
                      </MenuItem>
                    );
                  })}
                </InputField>
              )}
            />
            <Controller
              control={control}
              name="isAutoRenew"
              render={({ field }) => (
                <InputField
                  {...field}
                  select
                  id="is-auto-renew"
                  label="Contract type"
                  dropdownItems={contractTypeMap}
                  sx={borderLeftStyle}
                  error={!!errors.isAutoRenew}
                  helperText={
                    errors.isAutoRenew
                      ? errors.isAutoRenew?.message
                      : "The full duration of a user's subscription"
                  }
                />
              )}
            />
          </Stack>
        </Stack>

        {/* ========================================================================================== */}

        <FormControl>
          <LabelField
            label="Select which experiences you would like to include in this plan"
            value={
              <>
                {!!currentPlan.membershipProducts?.length ? (
                  <>
                    <MembershipProductsList
                      isLoading={isExperienceLoading}
                      title="Included experiences"
                      productsInfo={(currentPlan.membershipProducts || []).map(
                        x => ({
                          id: x.id,
                          title: x?.title || '',
                        })
                      )}
                      defaultOpen={true}
                    />
                    <Link
                      component="button"
                      variant="body2"
                      underline="always"
                      onClick={handleEditExperiencesClick}
                      sx={{
                        textAlign: 'inherit',
                        margin: theme.spacers.s,
                      }}
                    >
                      Edit experiences list
                    </Link>
                  </>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      marginTop: theme.spacers.m,
                    }}
                  >
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => setIsDialogOpen(true)}
                    >
                      Add experiences
                    </Button>
                  </Box>
                )}
              </>
            }
          />
          {!!membershipProductsError && (
            <FormHelperText error={!!membershipProductsError}>
              {membershipProductsError}
            </FormHelperText>
          )}
        </FormControl>

        <ProductListDialog
          title="Connect experiences to this membership"
          agree="Connect to membership"
          disagree="Cancel"
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          onConfirm={onProductSelect}
          currentSelectedProducts={currentPlan.membershipProducts || []}
          products={filterExperienceByStatus(experiences || [], [
            ExperienceStatus.Approved,
            ExperienceStatus.Published,
          ])}
        />

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            height: '56px',
            marginTop: theme.spacers.xxl,
          }}
        >
          <BackButton
            onClick={() => changeSubItem(MembershipCreationDirection.Backwards)}
          />

          <Box>
            {subItems.length - 1 > currentSubItem && (
              <Button
                type="submit"
                variant="text"
                sx={{ marginRight: theme.spacers.l }}
                endIcon={
                  <Svg src="/images/chevron-right.svg" width={24} height={24} />
                }
                onClick={() => {
                  isSectionComplete = false;
                }}
              >
                Next Plan
              </Button>
            )}

            {subItems.length - 1 === currentSubItem &&
              !!currentPlan?.membershipProducts?.length && (
                <Button
                  type="submit"
                  data-testid="newPlanBtn"
                  variant="text"
                  sx={{ marginRight: theme.spacers.l }}
                  startIcon={
                    <Svg src="/images/plus.svg" width={24} height={24} />
                  }
                  onClick={() => {
                    isSectionComplete = false;
                  }}
                >
                  New Plan
                </Button>
              )}

            {!!currentPlan?.membershipProducts?.length && (
              <Button
                type="submit"
                onClick={() => {
                  isSectionComplete = true;
                }}
              >
                Finish
                {/* should only be clickable if ALL plans are finished and approved */}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};
