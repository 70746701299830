import { MonthlyStatement } from 'types';
import { useAuth } from './useAuth';
import { useQuery } from '@tanstack/react-query';
import { ApiClient } from '../api/apiClient';

type usePaymentStatementPropsType = {
  paymentStatementId?: number;
};

export const usePaymentStatement = ({
  paymentStatementId,
}: usePaymentStatementPropsType) => {
  const { data: user } = useAuth();
  const apiClient = ApiClient();

  const getPaymentStatement = async (
    accessToken?: string
  ): Promise<MonthlyStatement | null> => {
    if (accessToken && paymentStatementId) {
      return await apiClient.paymentStatements.getPaymentStatement({
        paymentStatementId: paymentStatementId,
      });
    } else {
      return null;
    }
  };

  return useQuery<MonthlyStatement | null>(
    ['paymentStatement', paymentStatementId],
    () => getPaymentStatement(user?.accessToken),
    { enabled: !!user && !!paymentStatementId }
  );
};
