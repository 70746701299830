import { Card } from '@mui/material';
import { styled, Typography } from '@silverstein-properties/inspirelabs-ui';

export const StyledTodo = styled(Card)(({ theme }) => ({
  display: 'inline-flex',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.grey[100],
  boxShadow: 'none',
  width: '100%',
  padding: theme.spacing(3, 2),
  borderRadius: 0,
  border: 0,
  borderBottom: `1px solid ${theme.palette.grey[400]}`,
  '&:first-of-type': {
    borderRadius: theme.spacing(1, 1, 0, 0),
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
  '&:last-child': {
    borderRadius: theme.spacing(0, 0, 1, 1),
    border: 0,
  },
  '&:only-child': {
    borderRadius: theme.spacing(1),
    borderBottom: 0,
  },
}));

export const StyledIcon = styled(Typography)(({ theme }) => ({
  marginRight: theme.spacing(2),
  alignSelf: 'center',
  paddingLeft: theme.spacing(3),
}));
