// TODO: This file is renamed to match the component in `src/components/BusinessFormContainer`
// ===> Once we move this "component" to the `modules` folder with additional refactors, we can rename this file to match.

import { TEXT_AREA_LIMIT_LONG, TEXT_AREA_LIMIT_SHORT } from '@/constants';
import {
  Allow,
  IsNotEmpty,
  IsOptional,
  IsPhoneNumber,
  MaxLength,
  MinLength,
} from 'class-validator';

export class AboutYouInput {
  @IsPhoneNumber('US', { message: 'Please enter a valid phone number' })
  hostPhone: string;

  @MaxLength(TEXT_AREA_LIMIT_LONG, { message: 'Description is too long' })
  @MinLength(1, { message: 'Please enter a brief description of yourself' })
  hostBio: string;

  // Disabled due to current implementation
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  @Allow
  hostPhoto: File;
}

export class TitleAndInternalNameInput {
  @MinLength(1, { message: 'Please enter a title' })
  @MaxLength(TEXT_AREA_LIMIT_SHORT, {
    message: 'Please enter a shorter title',
  })
  @IsNotEmpty({ message: 'Please enter a title' })
  title: string;

  @MaxLength(TEXT_AREA_LIMIT_SHORT, {
    message: 'Please enter a shorter title',
  })
  @IsOptional()
  internalName: string;
}

export class OfferingDescriptionInput {
  @IsNotEmpty({ message: 'Please enter a description' })
  @MaxLength(TEXT_AREA_LIMIT_LONG, {
    message: 'Please enter a shorter description',
  })
  description: string;

  @IsNotEmpty({ message: 'Please select a duration' })
  durationHours: string;

  @IsNotEmpty({ message: 'Please select a duration' })
  durationMinutes: string;
}
