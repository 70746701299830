import { useMutation, useQuery } from '@tanstack/react-query';
import { ApiClient } from '../api/apiClient';
import {
  SideCommunicationMessage,
  MainCommunicationConversation,
  CommunicationPurchaseType,
} from '@/types';
import { useAuth } from './useAuth';
import { useMerchant } from './useMerchant';

export const useSideCommunicationMessages = (
  isBookingRequestEnabled = true
) => {
  const apiClient = ApiClient();
  const { data: user } = useAuth();

  const getSideCommunicationMessages = async (): Promise<
    SideCommunicationMessage[]
  > => {
    const sideMessages =
      await apiClient.communications.getSideCommunicationMessages({
        merchantId: user?.primaryMerchantId || '',
      });

    return !isBookingRequestEnabled
      ? sideMessages.filter(
          x =>
            x.communicationPurchaseType !==
            CommunicationPurchaseType.BOOKING_REQUEST
        )
      : sideMessages;
  };

  return useQuery<SideCommunicationMessage[]>(
    ['side-messages', user?.primaryMerchantId],
    () => getSideCommunicationMessages(),
    { enabled: !!user }
  );
};

export const useMainCommunicationConversation = (
  communicationPurchaseId: string
) => {
  const apiClient = ApiClient();

  const getMainCommunicationMessages =
    async (): Promise<MainCommunicationConversation> => {
      return await apiClient.communications.getMainCommunicationMessages({
        communicationPurchaseId,
      });
    };

  return useQuery<MainCommunicationConversation>(
    ['main-messages', communicationPurchaseId],
    () => getMainCommunicationMessages(),
    { enabled: !!communicationPurchaseId }
  );
};

export type UseCommunicationReplyProps = {
  communicationPurchaseId: string;
  message: string;
  communicationPurchaseType: CommunicationPurchaseType;
};

export const useCommunicationReply = (successCallback: () => void) => {
  const { data: user } = useAuth();
  const { data: merchantProfile } = useMerchant();
  const apiClient = ApiClient();

  return useMutation(
    async ({
      communicationPurchaseId,
      message,
      communicationPurchaseType,
    }: UseCommunicationReplyProps): Promise<void> => {
      return await apiClient.communications.reply({
        communicationPurchaseId,
        message,
        communicationPurchaseType,
        userId: user?.id || 0,
        merchantId: merchantProfile?.id || 0,
      });
    },
    {
      onSuccess: () => {
        successCallback();
      },
    }
  );
};
