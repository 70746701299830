import {
  Grid,
  MbBlock,
  Typography,
  Box,
} from '@silverstein-properties/inspirelabs-ui';
import { useAuth } from '@/hooks';
import { CenteredBox, StyledGrid } from './MerchantDashboardEmptyState.styles';
import RoundButton from '@/components/RoundButton/Component';
import { useState } from 'react';
import { SelectBookingTypeDialog } from '@/modules/CommonModule/SelectBookingTypeDialog';

export const MerchantDashboardEmptyState = () => {
  const [openSelectBookingType, setOpenSelectBookingType] = useState(false);
  const { data: user } = useAuth();
  return (
    <>
      <SelectBookingTypeDialog
        open={openSelectBookingType}
        onCloseModal={() => {
          setOpenSelectBookingType(false);
        }}
      />
      <Box>
        <CenteredBox>
          <Typography variant="h2">Welcome, {user?.firstName}</Typography>
          <Typography variant="h6" sx={{ pt: 2 }}>
            Create your first experience in a few easy steps
          </Typography>
        </CenteredBox>
        <StyledGrid container columnGap={4}>
          <Grid item xs={4}>
            <MbBlock
              align="center"
              icon="/images/flag.svg"
              iconSize="large"
              title="Add details"
              body="Create your merchant account and get approved"
            />
          </Grid>
          <Grid item xs={4}>
            <MbBlock
              align="center"
              icon="/images/pencil.svg"
              iconSize="large"
              title="Fill up your schedule"
              body="Add details and put together an unforgettable experience"
            />
          </Grid>
          <Grid item xs={4}>
            <MbBlock
              align="center"
              icon="/images/inspire.svg"
              iconSize="large"
              title="Publish"
              body="Add dates and start hosting!"
            />
          </Grid>
        </StyledGrid>
        <Grid sx={{ py: 6, textAlign: 'center' }}>
          <RoundButton
            text="Create your first experience"
            default="blue-baby-blue"
            hover="white-navy"
            handleClick={() => {
              setOpenSelectBookingType(true);
            }}
          />
        </Grid>
      </Box>
    </>
  );
};
