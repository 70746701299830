import { LabelField } from '@/components';
import { Experience } from '@/types';
import { Stack } from '@silverstein-properties/inspirelabs-ui';

export type AdvertUrlSectionPropsType = {
  experience: Experience;
};

export const AdvertUrlSection = ({ experience }: AdvertUrlSectionPropsType) => {
  return (
    <Stack spacing={4} data-testid="advertUrl">
      <LabelField label="URL" value={experience.advertUrl || ''} />
    </Stack>
  );
};
