import { ThemeOptions } from '@silverstein-properties/inspirelabs-ui';

export const themeOptions: ThemeOptions = {
  palette: {
    text: {
      primary: '#15284C',
      buttonText: '#A8D9FF',
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          border: '1px solid #dedede',
        },
      },
    },
  },
};
