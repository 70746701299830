import { Box, Grid, Typography } from '@silverstein-properties/inspirelabs-ui';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ReactElement, ReactNode } from 'react';
import { StyledButtonsDiv, StyledDrawer } from './DetailsDrawer.styles';
import { isArray } from 'lodash';

export type DetailsDrawerPropsType = {
  actions?: ReactElement;
  actionTop?: ReactElement;
  children: ReactNode;
  closeWindow: () => void;
  drawerOpen: boolean;
  title?: string;
};

export const DetailsDrawer = ({
  actions,
  actionTop,
  children,
  closeWindow,
  drawerOpen,
  title,
}: DetailsDrawerPropsType) => {
  const titleElem = title ? (
    <Grid item>
      <Typography variant="subtitle2" sx={{ wordBreak: 'break-word' }}>
        {title}
      </Typography>
    </Grid>
  ) : null;
  const buttonsCount = isArray(actions?.props?.children)
    ? actions?.props?.children.length
    : 1;

  return (
    <StyledDrawer anchor="right" open={drawerOpen} onClose={closeWindow}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ m: theme => theme.spacing(1.75, 4), width: 'auto' }}
      >
        <Grid item flex={1}>
          <IconButton onClick={closeWindow}>
            <CloseIcon />
          </IconButton>
        </Grid>
        {titleElem}
        <Grid item flex={1} textAlign="right">
          {actionTop}
        </Grid>
      </Grid>
      <Box sx={{ paddingY: 3, paddingX: 5, overflowY: 'auto', flex: 1 }}>
        {children}
      </Box>
      {actions ? (
        <StyledButtonsDiv
          sx={{
            justifyContent: buttonsCount === 1 ? 'center' : 'space-between',
            alignItems: 'center',
          }}
        >
          {actions}
        </StyledButtonsDiv>
      ) : null}
    </StyledDrawer>
  );
};
