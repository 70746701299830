import { useQuery } from '@tanstack/react-query';
import { ApiClient } from '../api/apiClient';
import { useAuth } from './useAuth';
import { PurchasableType } from '@/types';

export const useMerchantPlatformFee = (purchasableType?: PurchasableType) => {
  const { data: user } = useAuth();
  const apiClient = ApiClient();

  const getMerchantPlatformFee = async (
    merchantId?: string,
    purchasableType?: PurchasableType
  ): Promise<number | undefined> => {
    if (merchantId) {
      return await apiClient.merchants.getMerchantPlatformFee({
        merchantId: parseInt(merchantId),
        purchasableType: purchasableType,
      });
    }

    return;
  };

  return useQuery<number | undefined>(
    ['merchantPlatformFee', user?.primaryMerchantId],
    () => getMerchantPlatformFee(user?.primaryMerchantId, purchasableType),
    { enabled: !!user }
  );
};
