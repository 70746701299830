import { BackButton } from '@/components';
import { MembershipCreationDirection } from '@/hooks/useMembershipCreationHooks';
import { useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import { useNavigate } from 'react-router';

interface PublishMembershipProps {
  changeSection: (sectionId: number) => void;
  publishAllPlansDb: () => Promise<boolean>;
}

export const PublishMembership = ({
  changeSection,
  publishAllPlansDb,
}: PublishMembershipProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handlePublishMembership = async () => {
    const updateSuccess = await publishAllPlansDb();
    //TODO: should add error message if failed to update
    // https://spinspire.monday.com/boards/3397008425/pulses/5095283831

    if (updateSuccess) {
      changeSection(MembershipCreationDirection.Forward);
    }
  };

  return (
    <Grid container direction="column" mt={2} xs={6}>
      {/* TODO: Margin bottoms need to be fixed to match the design exactly (acutally the whole parent layout)
        because some CSS is overriding the margin bottoms. Couldn't find out */}
      <Typography variant="h4" marginBottom="20px">
        Publish membership
      </Typography>
      <Typography variant="subtitle1" marginBottom="20px">
        Would you like to publish membership now? You can save it now and
        publish later.
      </Typography>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <BackButton
          onClick={() => changeSection(MembershipCreationDirection.Backwards)}
        />

        <Stack direction="row" spacing={theme.spacers.s}>
          <Button variant="outlined" onClick={() => navigate('/memberships')}>
            Save
          </Button>
          <Button onClick={handlePublishMembership}>Publish</Button>
        </Stack>
      </Box>
    </Grid>
  );
};
