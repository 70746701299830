import { LabelField } from '@/components';
import { Experience } from '@/types';
import { Stack } from '@silverstein-properties/inspirelabs-ui';
import { ACTIVITY_CHOICES, AUDIENCE_CHOICES } from '@/constants';

export type RequirementsSectionPropsType = {
  experience: Experience;
};

export const RequirementsSection = ({
  experience,
}: RequirementsSectionPropsType) => {
  return (
    <Stack spacing={4}>
      <LabelField
        label="Target audience"
        value={
          AUDIENCE_CHOICES.find(
            choice => choice.value === experience.targetAudience
          )?.label || 'No target audience specified'
        }
      />
      <LabelField
        label="Activity level"
        value={
          ACTIVITY_CHOICES.find(
            choice => choice.value === experience.activityLevel
          )?.label || 'No activity level specified'
        }
      />
      <LabelField
        label="I will provide the guests with"
        value={experience.requiredSupply?.byHost || 'Nothing'}
      />
      <LabelField
        label="Guests need to bring"
        value={experience.requiredSupply?.byGuest || 'Nothing'}
      />
    </Stack>
  );
};
