import { Link } from 'react-router-dom';
import { useHooks } from './useHooks';
import Flag from 'components/LandingPage/flag.svg';
import Pen from 'components/LandingPage/pen.svg';
import Wheel from 'components/LandingPage/inspire-wheel.svg';

import Inspirelabs from 'components/LandingPage/inspire-labs.svg';
import Rdl from 'components/LandingPage/RealDev.svg';
import Silver from 'components/LandingPage/Silverstein.svg';
import Swift from 'components/LandingPage/Swift-Connect.svg';

import { ContactUsForm } from 'components/ContactUs/Component';

import RoundButton from 'components/RoundButton/Component';
import VendorCard from 'components/VendorCard/Component';

export function LandingPage(): JSX.Element {
  const { classes, slideshow } = useHooks();

  return (
    <div className="landing-page">
      <div className="container-fluid">
        <div className="row flex-column-reverse flex-lg-row height-push bg-secondary">
          <div className="col-xl-6 col-lg-6 px-0">
            <div className="title-block">
              <h2 className="landing-title mb-0 text-center">
                Grow your business with The Hub
              </h2>
              <div className="my-4 sub-title">
                Tap into thousands of potential new clients from some of the
                world's most prestigious companies
              </div>
              <div className="text-center">
                <Link to="/signup">
                  {' '}
                  <RoundButton
                    text="Get started"
                    default="blue-baby-blue"
                    hover="white-navy"
                  />{' '}
                </Link>
              </div>
            </div>
          </div>
          <div className="rectangle-9 col-xl-6 col-lg-6">
            <div className="slideshow-container bg-secondary">
              <div className={`slideshow-${slideshow}`} />
            </div>
          </div>
        </div>
      </div>

      <div id="how-it-works"></div>
      <div className="container-xl">
        <div className="row top-80 d-flex justify-content-center">
          <div className="row bottom-55 gx-0 text-center text-primary">
            <div className="section-title text-center">
              Effortless onboarding
            </div>
          </div>

          <div className="col-xl-1 col-lg-1 d-lg-block d-sm-none d-block"></div>
          <div className="col-xl-10 col-lg-10 d-inline-block text-center">
            <div className="testimonial">
              <div className="">
                <img src={Flag} alt="flag icon" className="bottom-19" />
              </div>
              <div className="frame-11">
                <span className="testimonial-title text-primary">
                  Get started
                </span>
                <span className="testimonial-text text-primary">
                  Create your merchant account and get approved
                </span>
              </div>
            </div>

            <div className="testimonial">
              <div className="">
                <img src={Pen} alt="Pen icon" className="bottom-19" />
              </div>
              <div className="frame-11">
                <span className="testimonial-title text-primary">
                  Add your experience
                </span>
                <span className="testimonial-text text-primary">
                  Add details and put together an unforgettable experience
                </span>
              </div>
            </div>

            <div className="testimonial">
              <div className="">
                <img src={Wheel} alt="Publish icon" className="bottom-19" />
              </div>
              <div className="frame-11">
                <span className="testimonial-title text-primary">
                  Publish to the Hub!
                </span>
                <span className="testimonial-text text-primary">
                  Add dates and start hosting!
                </span>
              </div>
            </div>
          </div>
          <div className="col-xl-1 col-lg-1 d-lg-block d-sm-none d-block"></div>

          <div className="mt-5 d-flex justify-content-center">
            <Link to="/signup">
              {' '}
              <RoundButton
                text="Get started"
                default="blue-baby-blue"
                hover="white-navy"
              />{' '}
            </Link>
          </div>
        </div>
      </div>
      <div className="container-xxl grid-center">
        <div className="row removed">
          <h4 className="top-136 mb-3 pb-3 grid-center2 text-primary px-0">
            Recently added
          </h4>
          <div className="text-center px-0">
            {classes &&
              classes.map((newClass, i) =>
                i < 4 ? (
                  <span className="me-3" key={i}>
                    {' '}
                    <VendorCard event={newClass} />{' '}
                  </span>
                ) : null
              )}

            {classes &&
              classes.map((newClass, i) =>
                i >= 4 ? (
                  <span className="me-3 top-80" key={i}>
                    {' '}
                    <VendorCard event={newClass} />{' '}
                  </span>
                ) : null
              )}
          </div>
        </div>

        <div>
          <h4 className="mt-5 mb-3 pb-3 grid-center2 text-primary">
            Our partners
          </h4>
          <div className="conatiner-md">
            <div className="row text-center d-flex justify-content-center top-62">
              <div className="col-xl-2 col-lg-2 col-sm-4">
                <img src={Inspirelabs} alt="Inspire Labs" className="mb-5" />
              </div>
              <div className="col-xl-2 col-lg-2 col-sm-4">
                <img src={Rdl} alt="Real Dev Labs" className="mb-5" />
              </div>
              <div className="col-xl-2 col-lg-2 col-sm-4">
                <img
                  src={Silver}
                  alt="Silverstein Properties"
                  className="mb-5"
                />
              </div>
              <div className="col-xl-2 col-lg-2 col-sm-4">
                <img src={Swift} alt="Swift Connect" className="mb-5" />
              </div>
            </div>
          </div>
        </div>

        <div className="row align-sec top-136 removed">
          <div className="rectangle-9 col"></div>
          <div className="col mt-2 ps-4 ms-2">
            <div className="headline-3-desktop text-primary quote-block">
              “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor.”
            </div>
            <div className="body-2 mt-3 mb-2 text-primary">
              James Smith, CEO{' '}
            </div>
            <div className="body-2 mb-1 text-primary">
              Yogis & Yoginis, Brooklyn NY
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl top-203">
        <h3 className="row d-flex justify-content-center mt-5 mb-4 text-primary">
          Get started today
        </h3>
        <div className="row d-flex justify-content-center">
          <div className="d-flex justify-content-center">
            {' '}
            <Link to="/signup">
              {' '}
              <RoundButton
                default="blue-baby-blue"
                hover="white-navy"
                text="Get started"
              />{' '}
            </Link>{' '}
          </div>
        </div>
      </div>
      <div style={{ marginBottom: '5rem' }}></div>
      <div id="contact-us"></div>

      <ContactUsForm />
    </div>
  );
}
