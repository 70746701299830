import {
  Box,
  Typography,
  styled,
} from '@silverstein-properties/inspirelabs-ui';
import Circle from '@mui/icons-material/Circle';

export const StyledBox = styled(Box)(() => ({
  position: 'relative',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const StyledCircleIcon = styled(Circle)(({ theme }) => ({
  color: theme.palette.grey[200],
  fontSize: '2.5em',
}));

export const StyledTypography = styled(Typography)(() => ({
  position: 'absolute',
  lineHeight: 1,
  fontSize: '1em',
}));
