import {
  Button,
  Card,
  Grid,
  Stack,
  Svg,
  Typography,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import { StyledCardContent } from './DetailSection.styles';

export type DetailEmptySectionPropsType = {
  id: string;
  image?: string;
  title?: string;
  emptyMessage?: string;
  onEditClick?: (screenName: string) => void;
};

export const DetailEmptySection = ({
  id,
  image,
  title,
  emptyMessage,
  onEditClick,
}: DetailEmptySectionPropsType) => {
  const theme = useTheme();
  return (
    <Grid item id={id} xs={12}>
      <Card variant="outlined">
        <StyledCardContent>
          <Stack alignItems="center" spacing={1} sx={{ mt: 4 }}>
            {!!image && (
              <Svg
                src={image}
                width={80}
                height={80}
                color={theme.palette.primary.faint}
              />
            )}

            <Typography variant="h5">{title}</Typography>
            <Typography pb={2} textAlign="center">
              {emptyMessage}
            </Typography>
            <Button onClick={() => onEditClick && onEditClick(id)}>
              {`Add ${title}`}
            </Button>
          </Stack>
        </StyledCardContent>
      </Card>
    </Grid>
  );
};
