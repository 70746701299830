import { styled, Grid } from '@mui/material';

export const SideMessagesMainGrid = styled(Grid)(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.grey[400]}`,
}));

export const SideMessagesTitleGrid = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  padding: theme.spacing(3, 2),
  borderBottom: `1px solid ${theme.palette.grey[400]}`,
  height: 88, // hardcoded value so that both sideMessage title and mainMessage title align
}));
