import {
  IsAlpha,
  IsEnum,
  IsInt,
  IsNotEmpty,
  IsNumberString,
  IsObject,
  IsOptional,
  IsString,
  MaxLength,
  Min,
  MinLength,
  ValidateNested,
} from 'class-validator';
import { PricingWithTaxesInput } from './PricingInput';
import { TEXT_AREA_LIMIT_EXTRA_LONG } from '@/constants';
import { CancellationPolicy } from '@/types';
import { Transform, Type } from 'class-transformer';

export class AddQuoteInput {
  consumerId: string;

  productId: string;

  @MinLength(1, { message: 'Please enter a date for the quote' })
  date: string;

  @IsNotEmpty({
    message: 'Please enter a start time for the quote',
  })
  startTime: Date;

  @IsNotEmpty({
    message: 'Please enter an end time for the quote',
  })
  endTime: Date;

  @IsNotEmpty({
    message: 'Please enter total number of guests for the quote',
  })
  @Transform(({ value }) => parseInt(value))
  @IsInt({ message: 'Invalid number of guests' })
  @Min(1, { message: 'Number of guest cannot be less than 1' })
  numberOfGuests: string | number;

  @MinLength(1, { message: 'Please enter an address' })
  @MaxLength(50, { message: 'Your address is too long' })
  address: string;

  @IsOptional()
  @IsString()
  apt?: string;

  @MinLength(1, { message: 'Please enter a city' })
  @MaxLength(50, { message: 'City name is too long' })
  city: string;

  @IsAlpha(undefined, { message: 'Invalid' })
  @MinLength(2, { message: 'Invalid' })
  @MaxLength(3, { message: 'Invalid' })
  state: string;

  @IsNumberString(undefined, { message: 'Invalid ZIP' })
  @MinLength(5, { message: 'Invalid ZIP' })
  @MaxLength(10, { message: 'Invalid ZIP' })
  zipCode: string;

  @MinLength(1, { message: 'Please enter a description for this quote' })
  @MaxLength(TEXT_AREA_LIMIT_EXTRA_LONG, { message: 'Description is too long' })
  description: string;

  @IsEnum(CancellationPolicy, {
    message: 'Please select a valid cancellation policy',
  })
  cancellationPolicy: CancellationPolicy | '';

  @IsObject()
  @ValidateNested()
  @Type(() => PricingWithTaxesInput)
  basePrice: PricingWithTaxesInput;
}
