import {
  Grid,
  MessageBanner,
  Typography,
} from '@silverstein-properties/inspirelabs-ui';
import { HookFormTextInput } from 'components/FormTextInput/HookFormTextInput';
import { usePassword } from '@/hooks/usePassword';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { CenteredMessageBlock } from 'components/CenteredMessageBlock/CenteredMessageBlock';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { IsStrongPassword, IsNotEmpty } from 'class-validator';
import HookFormSubmitButton from 'components/RoundButton/HookFormSubmitButton';
import { useNavigate } from 'react-router';

class ResetPasswordInput {
  @IsStrongPassword(undefined, {
    message: 'Password must be stronger',
  })
  @IsNotEmpty({ message: 'Please enter a password' })
  password: string;
}

const resolver = classValidatorResolver(ResetPasswordInput);

export const ResetPasswordModule = () => {
  const {
    register,
    formState: { dirtyFields, errors },
    handleSubmit,
  } = useForm<ResetPasswordInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      password: '',
    },
  });
  const navigate = useNavigate();
  const {
    verifyPasswordResetRequest,
    changePassword,
    searchParams,
    passwordResetSuccess,
    passwordResetWarning,
  } = usePassword();

  useEffect(() => {
    const searchMode = searchParams.get('mode');
    if (searchMode === 'verifyEmail') {
      navigate(`/email-verification?${searchParams.toString()}`);
    } else if (searchMode === 'resetPassword') {
      verifyPasswordResetRequest(searchParams.get('oobCode') as string);
    } else {
      navigate(`/${searchMode}?${searchParams.toString()}`);
    }
  }, [searchParams]);

  const onSubmit = (data: { password: string }) => {
    changePassword(data.password);
  };

  if (!!passwordResetSuccess) {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        marginTop={8}
      >
        <Grid container xs={3}>
          <CenteredMessageBlock
            title="You're all set"
            image="/images/success.svg"
            cta={{
              message: 'Login',
              link: '/login',
              type: 'button',
            }}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={3}>
        <Grid item container flexDirection="column" mt={8}>
          <Typography variant="h4">Reset password</Typography>
          <Grid item container flexDirection="column" mt={5} gap={4}>
            <Typography variant="subtitle1">Enter a new password</Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              {passwordResetWarning && (
                <MessageBanner type="error">
                  {passwordResetWarning}
                </MessageBanner>
              )}
              {passwordResetSuccess && (
                <MessageBanner type="success">
                  {passwordResetSuccess}
                </MessageBanner>
              )}
              <HookFormTextInput
                type="password"
                width="form-xl"
                label="Password"
                name="password"
                assistiveText="8 characters or longer"
                isFilled={!!Object.keys(dirtyFields).includes('password')}
                register={register}
                error={errors.password}
              />
              <Grid item alignSelf="flex-end">
                <HookFormSubmitButton
                  text="Set password"
                  default="blue-baby-blue"
                  hover="white-navy"
                  disabled={!!passwordResetSuccess}
                />
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
