// Disabled rule because this needs to be refactored
// Not worth the effort to fix dozen's of lint errors in this file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { components, StylesConfig } from 'react-select';

export const styles: StylesConfig = {
  // react-select uses a styled components type approach to custom styles, this will be moved to separate module :)
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused
      ? '#D4E8F6'
      : state.onClick
      ? '#D4E8F6'
      : 'white',
    color: state.onClick ? '#15284C' : '#15284C',
    padding: '12px 18px',
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    transition: 'all .2s ease',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    alignContent: 'right',
    right: '0',
    top: 10,
    position: 'absolute',
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: 384,
    textAlign: 'left',
    fontStyle: 'normal',
    boxSizing: 'border-box',
  }),
  multiValueRemove: () => ({
    color: '#647087',
  }),
  menu: provided => ({
    ...provided,
    position: 'absolute',
    top: '60px',
    color: 'red',
    borderRadius: 0,
    boxSizing: 'border-box',
    padding: 0,
    margin: 0,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    position: 'absolute',
    color: '#15284C',
    opacity: 0.75,
    fontWeight: '300',
    top:
      (state.hasValue || state.selectProps.inputValue) && !state.isMulti
        ? '-25px'
        : (state.hasValue || state.selectProps.inputValue) && state.isMulti
        ? '-15px'
        : '0px',
    backgroundColor: 'rgb(255, 255, 255, 1)',
    padding: '4px',
    fontStyle: 'normal',
    transition: '400ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    fontSize: (state.hasValue || state.selectProps.inputValue) && '12px',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    borderRadius: '4px',
    overflow: 'visible',
    minHeight: 56,
    gap: '8px',
    padding: '12px 16px',
    border: state.selectProps.menuIsOpen
      ? '2px solid #15284C'
      : '1px solid #dedede',
    boxSizing: 'border-box',
  }),
  clearIndicator: () => ({
    display: 'none',
  }),
  multiValue: () => ({
    backgroundColor: '#A8D9FF',
    padding: '4px 8px 4px 16px',
    gap: '8px',
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '16px',
  }),
};

export const narrowStyles = {
  // react-select uses a styled components type approach to custom styles, this will be moved to separate module :)
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused
      ? '#D4E8F6'
      : state.onClick
      ? '#D4E8F6'
      : 'white',
    color: state.onClick ? '#15284C' : '#15284C',
    padding: '12px 18px',
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    transition: 'all .2s ease',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
    alignContent: 'right',
    right: '0',
    top: 10,
    position: 'absolute',
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: 192,
    height: 88,
    textAlign: 'left',
    fontStyle: 'normal',
    boxSizing: 'border-box',
  }),
  menu: (provided, state) => ({
    ...provided,
    position: 'absolute',
    top: state.menuPlacement === 'top' ? '' : '60px',
    bottom: state.menuPlacement === 'top' ? '94px' : '',
    color: 'red',
    borderRadius: 0,
    boxSizing: 'border-box',
    padding: 0,
    margin: 0,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    position: 'absolute',
    color: '#15284C',
    opacity: 0.75,
    fontWeight: '300',
    top: state.hasValue || state.selectProps.inputValue ? -15 : '8px',
    backgroundColor: 'rgb(255, 255, 255, 1)',
    padding: '4px',
    fontStyle: 'normal',
    transition: '400ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    fontSize: (state.hasValue || state.selectProps.inputValue) && '12px',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    borderRadius: '4px',
    overflow: 'visible',
    height: 56,
    border: state.selectProps.menuIsOpen
      ? '2px solid #15284C'
      : '1px solid #dedede',
    boxSizing: 'border-box',
  }),
} as StylesConfig;

export const narrowErrorStyles = {
  // react-select uses a styled components type approach to custom styles, this will be moved to separate module :)
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused
      ? '#D4E8F6'
      : state.onClick
      ? '#D4E8F6'
      : 'white',
    color: state.onClick ? '#15284C' : '#15284C',
    padding: '12px 18px',
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    transition: 'all .2s ease',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    alignContent: 'right',
    right: '0',
    top: 10,
    color: '#c51c1c',
    position: 'absolute',
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: 192,
    height: 88,
    textAlign: 'left',
    fontStyle: 'normal',
    boxSizing: 'border-box',
  }),
  menu: provided => ({
    ...provided,
    position: 'absolute',
    top: '60px',
    color: 'red',
    borderRadius: 0,
    boxSizing: 'border-box',
    padding: 0,
    margin: 0,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    position: 'absolute',
    color: '#c51c1c',
    opacity: 0.75,
    fontWeight: '400',
    top: state.hasValue || state.selectProps.inputValue ? -15 : '8px',
    backgroundColor: 'rgb(255, 255, 255, 1)',
    padding: '4px',
    fontStyle: 'normal',
    transition: '400ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    fontSize: (state.hasValue || state.selectProps.inputValue) && '12px',
  }),
  valueContainer: provided => ({
    ...provided,
    borderRadius: '4px',
    overflow: 'visible',
    height: 56,
    border: '2px solid #c51c1c',
    boxSizing: 'border-box',
  }),
} as StylesConfig;

export const errorStyles: StylesConfig = {
  // react-select uses a styled components type approach to custom styles, this will be moved to separate module :)
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused
      ? '#D4E8F6'
      : state.onClick
      ? '#D4E8F6'
      : 'white',
    color: state.onClick ? '#15284C' : '#15284C',
    padding: '12px 18px',
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    transition: 'all .2s ease',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    alignContent: 'right',
    right: '0',
    top: 10,
    position: 'absolute',
    color: '#c51c1c',
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: 384,
    height: 88,
    textAlign: 'left',
    fontStyle: 'normal',
    boxSizing: 'border-box',
  }),
  menu: provided => ({
    ...provided,
    position: 'absolute',
    top: '60px',
    color: 'red',
    borderRadius: 0,
    boxSizing: 'border-box',
    padding: 0,
    margin: 0,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    position: 'absolute',
    color: '#c51c1c',
    opacity: 0.75,
    fontWeight: '400',
    top: state.hasValue || state.selectProps.inputValue ? -15 : '8px',
    backgroundColor: 'rgb(255, 255, 255, 1)',
    padding: '4px',
    fontStyle: 'normal',
    transition: '400ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    fontSize: (state.hasValue || state.selectProps.inputValue) && '12px',
  }),
  valueContainer: provided => ({
    ...provided,
    borderRadius: '4px',
    overflow: 'visible',
    height: 56,
    border: '2px solid #c51c1c',
    boxSizing: 'border-box',
  }),
};

export const { ValueContainer, Placeholder } = components;

export const customValueContainer = ({ children, ...props }) => {
  // custom wrapper to allow floating label on react-select, will be refactored to separate module
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, child =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};
