import { Stack, Typography } from '@silverstein-properties/inspirelabs-ui';

export type LabelFieldPropsType = {
  label: string;
  value: string | JSX.Element;
  footer?: string | JSX.Element;
  spacing?: number;
  variant?: 'regular' | 'error' | 'success';
};

export const LabelField = ({
  label,
  value,
  footer,
  spacing = 0,
  variant = 'regular',
}: LabelFieldPropsType) => {
  const labelColor = variant === 'regular' ? '' : `${variant}.main`;

  return (
    <Stack spacing={spacing} sx={{ width: '100%' }}>
      <Typography variant="subtitle1" sx={{ color: labelColor }}>
        {label}
      </Typography>
      {typeof value === 'string' ? <Typography>{value}</Typography> : value}
      {typeof footer === 'string' ? <Typography>{footer}</Typography> : footer}
    </Stack>
  );
};
