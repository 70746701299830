export enum CommunicationMessageType {
  SYSTEM_GENERATED,
  SIGN_UP,
  CANCELLATION,
  MESSAGES,
  INQUIRIES,
  GENERAL,
  WARNING,
  BOOKING_REQUEST,
}

export const MessageTypeLabels = {
  SYSTEM_GENERATED: 'General',
  SIGN_UP: 'Signups',
  CANCELLATION: 'Cancelation',
  MESSAGES: 'General',
  INQUIRIES: 'Inquiry',
  GENERAL: 'General',
  WARNING: 'General',
  BOOKING_REQUEST: 'Booking request',
};

export enum CommunicationPurchaseType {
  BOOKING = 'booking',
  MEMBERSHIP_PLAN = 'membershipPlan',
  BOOKING_REQUEST = 'bookingRequest',
}

export enum SystemNotificationText {
  BOOKING_REQUEST__QUOTE_SENT = 'Quote sent',
  BOOKING_REQUEST__BOOKING_CONFIRMATION = 'Booking confirmation',
  BOOKING_REQUEST__QUOTE_RETRACTED = 'Quote retracted',
}

export class BaseCommunicationMessage {
  communicationPurchaseName: string;
  communicationPurchaseId: string; //bookingId/purchaseplanId => need a fixing
  communicationPurchaseType: CommunicationPurchaseType;

  senderIsMerchant: boolean;
  isRead: boolean;
  textBody: string;
  htmlFormat: string;
  sentAt: Date;
  communicationMessageType: CommunicationMessageType;
  participantFirstName: string;
  participantLastName: string;
  merchantId: number;
  listingId?: string;
  numberOfGuestsForBooking: number;
}

export class SideCommunicationMessage extends BaseCommunicationMessage {
  eventStartDate: Date;
}

export class MainCommunicationMessage extends BaseCommunicationMessage {}

export class MainCommunicationCard {
  communicationPurchaseName: string;
  communicationPurchaseType: CommunicationPurchaseType;
  consumerName: string;
  totalNumberOfConsumers: number;
  startsAt?: Date;
  merchantId: number;
}

export class MainCommunicationConversation {
  mainCommunicationCard: MainCommunicationCard;
  mainMessages: MainCommunicationMessage[];
}
