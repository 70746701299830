import { LabelField } from '@/components';
import { useGetMenuProduct } from '@/hooks/queries/useGetMenuProduct';
import { Experience } from '@/types';
import { obscuredString, roundUp } from '@/utils';
import {
  Box,
  Button,
  Stack,
  Typography,
} from '@silverstein-properties/inspirelabs-ui';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';

export type SquareConnectionSectionPropsType = {
  experience: Experience;
};

export const SquareConnectionSection = ({
  experience,
}: SquareConnectionSectionPropsType) => {
  const [skipCache, setSkipCache] = useState<boolean>(false);
  const { data, isLoading, error, isFetching } = useGetMenuProduct({
    productId: experience.id,
    skipCache: skipCache,
  });

  useEffect(() => {
    if (skipCache && !isFetching) {
      setSkipCache(false);
    }
  }, [skipCache, isFetching]);

  const handleRefresh = () => {
    setSkipCache(true);
  };

  return (
    <Stack spacing={4}>
      <LabelField
        label="Environment"
        value={
          experience?.squareConfiguration?.clientEnvironment?.toString() ||
          'Missing environment for this experience'
        }
      />
      <LabelField
        label="Access Token"
        value={
          experience.squareConfiguration?.accessToken
            ? obscuredString(
                experience.squareConfiguration?.accessToken,
                0,
                experience.squareConfiguration?.accessToken.length -
                  roundUp(
                    experience.squareConfiguration?.accessToken.length / 4
                  )
              )
            : 'Missing access token for this experience'
        }
      />

      <LabelField
        label="Location ID"
        value={
          experience.squareConfiguration?.locationId ||
          'Missing location ID for this experience'
        }
      />
      {(isLoading || !!error) && (
        <LabelField
          label="Menu Items"
          variant={error ? 'error' : undefined}
          value={
            <>
              {isLoading && <Typography>Loading...</Typography>}
              {!!error && (
                <>
                  <Typography color="error.main">
                    Error:{' '}
                    {(
                      (error as AxiosError).response?.data as Record<
                        string,
                        string
                      >
                    )?.message || (error as AxiosError)?.message}
                  </Typography>
                  <Box>
                    <Button
                      variant="contained"
                      size="medium"
                      onClick={() => {
                        handleRefresh();
                      }}
                    >
                      Refresh Menu
                    </Button>
                  </Box>
                </>
              )}
            </>
          }
        />
      )}
      {data && (
        <>
          <LabelField
            label="Menu Items"
            value={data.items?.length?.toString() || ''}
          />
          <LabelField
            label="Location Name"
            variant={error ? 'error' : undefined}
            value={data.location?.name || ''}
          />
          <Box>
            <Button
              variant="contained"
              size="medium"
              onClick={() => {
                handleRefresh();
              }}
            >
              Refresh Menu
            </Button>
          </Box>
        </>
      )}
    </Stack>
  );
};
