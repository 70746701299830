import './roundButton.css';
import { useHooks } from './useHooks';

export interface ButtonProps {
  text: string;
  default: string;
  hover: string;
  disabled?: boolean;
  handleClick?: React.MouseEventHandler<HTMLButtonElement>;
  type?: 'submit' | 'button' | 'reset';
  testId?: string;
}

export default function RoundButton(props: ButtonProps): JSX.Element {
  const { hover, toggleHover } = useHooks();
  return (
    <>
      <button
        onMouseEnter={toggleHover}
        onFocus={toggleHover}
        onMouseLeave={toggleHover}
        onBlur={toggleHover}
        onClick={props.handleClick}
        disabled={props.disabled || false}
        className={
          !hover
            ? `button ${props.default} ${
                props.disabled ? 'disabled-button' : ''
              }`
            : `button ${props.hover} ${props.disabled ? 'disabled-button' : ''}`
        }
        type={props.type}
        data-testid={props.testId}
      >
        <span className="label">{props.text}</span>
      </button>
    </>
  );
}
