import React, { useState } from 'react';
import {
  PartialMerchCreationObject,
  BusinessFormValuesType,
  InputChangeEvent,
  Location,
  Experience,
} from 'types';
import {
  deepObjDiff,
  deepOmitNil,
  formatMerchandise,
  getPhotosObject,
} from 'utils';
import { merge, set } from 'lodash';
import { useFormData } from '../../contexts/MerchCreationProvider';
import { ApiClient } from '../../api/apiClient';
import {
  ACTIVITY_CHOICES,
  AUDIENCE_CHOICES,
  EXPERIENCE_YEARS_CHOICES,
  HOURS_OPTIONS,
  MINUTES_OPTIONS,
  SERVICE_TYPE_CHOICES,
} from '@/constants';

export function useHooks() {
  const apiClient = ApiClient();

  const [currentPage, setCurrentPage] = useState(1);
  const [furthestPage, setFurthestPage] = useState(1);
  const [newBusiness, setNewBusiness] = useState<
    Partial<BusinessFormValuesType>
  >({
    inPerson: false,
    hostPhoto: '',
    hostProvide: true,
    guestProvide: true,
    coverPhoto: '',
    extraPhoto1: '',
    extraPhoto2: '',
    extraPhoto3: '',
    extraPhoto4: '',
    cancelPolicy: 'full-1w-prior',
    hostAgreement: '',
    subCategory: [],
    subCategory2: [],
  });
  const [warning, setWarning] = useState('');
  const [experienceId, setExperienceId] = useState<string>();
  const [experience, setExperience] = useState<Experience>();
  const [location, setLocation] = useState<Location>();

  const { merchandiseData, setFormValues } = useFormData();

  const formatLocationForEditing = (loc?: Location) => {
    // Set complete location from the Location instance
    setLocation(loc);
    setFormValues({
      locationZip: loc?.address?.zipCode || '',
      locationApt: loc?.address?.apartment || '',
      locationCity: loc?.address?.city || '',
      locationState: loc?.address?.state || '',
      locationAddress: loc?.address?.street || '',
      // Needs to be refactored. Mongo stores this as info
      // whereas pg location is stored under description
      locationDescription: loc?.info || loc?.description || '',
      locationType:
        SERVICE_TYPE_CHOICES.find(
          locationType => locationType.value === loc?.environmentType
        ) || '',
    });
  };

  const formatExperienceForEditing = (exp: Experience) => {
    setExperienceId(exp.id);
    setExperience(exp);
    // Set new location based on partial location data from the experience object
    setLocation({
      address: {
        city: '',
        country: '',
        state: '',
        street: '',
        apartment: '',
        zipCode: exp.location?.zipCode || '',
      },
      environmentType: exp.location?.environmentType,
    });

    setNewBusiness({
      inPerson: exp.inPerson || '',
      hostPhoto: exp.host?.hostPhoto || '',
      hostProvide: !!exp.requiredSupply?.byHost,
      guestProvide: !!exp.requiredSupply?.byGuest,
      coverPhoto: exp.coverPhoto || '',
      extraPhoto1: exp.photos?.photo1 || '',
      extraPhoto2: exp.photos?.photo2 || '',
      extraPhoto3: exp.photos?.photo3 || '',
      extraPhoto4: exp.photos?.photo4 || '',
      cancelPolicy: exp.refundPolicy?.cancellationPolicy || 'full-1w-prior',
      subCategory: [],
      subCategory2: [],
    });
    setFormValues({
      category: exp.category?.type,
      subCategory: exp.category?.info,
      description: exp.description,
      durationHours: HOURS_OPTIONS.find(
        experienceHours =>
          experienceHours.value === exp.duration?.hours?.toString()
      ),
      durationMinutes: MINUTES_OPTIONS.find(
        experienceMinutes =>
          experienceMinutes.value === exp.duration?.minutes?.toString()
      ),
      hostBio: exp.host?.bio,
      hostPhone: exp.host?.phoneNumber,
      hostOperatingYears: EXPERIENCE_YEARS_CHOICES.find(
        experienceYears => experienceYears.value === exp.host?.operatingYears
      ),
      capacityMin: exp.capacity?.min?.toString(),
      capacityMax: exp.capacity?.max?.toString(),
      locationZip: exp.location?.zipCode,
      locationType: SERVICE_TYPE_CHOICES.find(
        locationType => locationType.value === exp.location?.environmentType
      ),
      price: exp.price?.toString(),
      totalAmount: exp.totalAmount?.toString(),
      requiredByHost: exp.requiredSupply?.byHost,
      requiredByGuest: exp.requiredSupply?.byGuest,
      targetAudience: AUDIENCE_CHOICES.find(
        audience => audience.value === exp.targetAudience
      ),
      activityLevel: ACTIVITY_CHOICES.find(
        level => level.value === exp.activityLevel
      ),
      title: exp.title,
      subtitle: exp.subtitle,
      internalName: exp.internalName,
    });
  };

  const handleNextPage = (): void => {
    if (currentPage === furthestPage) {
      setFurthestPage(furthestPage + 1);
    }
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = (): void => {
    setCurrentPage(currentPage - 1);
  };

  const changePage = (page: number, finished: string) => {
    if (finished !== 'locked') {
      setCurrentPage(page);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.target instanceof Element) {
      const inputName = e.target.name || e.target.title;
      let inputValue: string | File = e.target.value || e.target.id;
      if (e.target instanceof HTMLInputElement && e.target.files) {
        inputValue = e.target.files[0];
      }
      setNewBusiness(oldValues => ({
        ...oldValues,
        [inputName]: inputValue,
      }));
    }
  };

  const resetThisField = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.target instanceof Element) {
      const inputName = e.target.name || e.target.title;
      setNewBusiness(oldValues => ({
        ...oldValues,
        [inputName]: '',
      }));
    }
  };

  const handleMultiChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.target instanceof Element) {
      const inputValue = e.target.name || e.target.title;
      const inputName = e.target.value || e.target.id;

      let currentDays = newBusiness.generalDays
        ? [...newBusiness.generalDays]
        : [];
      if (currentDays.includes(inputValue)) {
        currentDays = currentDays.filter(day => day !== inputValue);
      } else {
        currentDays.push(inputValue);
      }
      setNewBusiness(oldValues => ({
        ...oldValues,
        [inputName]: currentDays,
      }));
    }
  };

  const handleClickChange = (
    // used on selectionBox & selector components
    { key, value }: InputChangeEvent
  ) => {
    if (key && value) {
      if (value === 'true' || value === 'false') {
        value = value === 'true';
      } //coerce string to boolean if necessary
      setNewBusiness(oldValues => ({
        ...oldValues,
        [key]: value,
      }));
    }
  };

  const handleFilterChange = (
    // used on filter components
    { key, value }: InputChangeEvent
  ) => {
    if (key && value) {
      if (value === 'true' || value === 'false') {
        value = value === 'true';
      } //coerce string to boolean if necessary
      setNewBusiness(oldValues => ({
        ...oldValues,
        [key]: value,
      }));
    }
  };

  const partiallyCreateExperience = async (
    data: PartialMerchCreationObject
  ) => {
    // Partially create new experience if it isn't created yet
    // otherwise we simply update the existing experience
    if (!experienceId) {
      const format = formatMerchandise(merge({}, newBusiness, data));
      const newMerch = deepOmitNil(format.newMerch);
      try {
        const returnedMerch = await apiClient.products.createPartialProduct({
          partialProduct: newMerch,
        });
        // Disabled due to current implementation
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setExperience(newMerch as any);
        setExperienceId(returnedMerch.id);
        return returnedMerch;
      } catch (err) {
        setWarning("We couldn't create your experience");
        return err;
      }
    } else {
      updateExperience(data);
    }
  };

  const updateExperience = async (
    data?: PartialMerchCreationObject,
    arePhotosDirty?: boolean
  ) => {
    setWarning('');
    const { newMerch } = formatMerchandise(
      merge({}, newBusiness, merchandiseData, data)
    );

    const { newChanges, newCompleteChanges, objWithChanges } = deepObjDiff(
      experience,
      deepOmitNil(newMerch)
    );

    // Only update experiences if there are any changes
    if (Object.keys(newChanges).length !== 0) {
      // Upload new photos to BE
      const newPhotos = getPhotosObject(newChanges);
      if (Object.keys(newPhotos).length && arePhotosDirty) {
        for (const key in newPhotos) {
          const submittedPhoto = await submitPhoto(newPhotos[key].file, key);
          set(newCompleteChanges, newPhotos[key].objectKey, submittedPhoto);
          set(objWithChanges, newPhotos[key].objectKey, submittedPhoto);
          // Patching values for the local state and the context provider to
          // the submitted photo url instead of the file like above
          setNewBusiness({ [newPhotos[key].newBusinessKey]: submittedPhoto });
          if (key === 'hostPhoto') {
            setFormValues({ hostPhoto: submittedPhoto });
          }
        }
      }

      try {
        setExperience(objWithChanges);

        return await apiClient.products.updateProduct({
          productId: experienceId!,
          changes: newCompleteChanges,
        });
      } catch (err) {
        setWarning("We couldn't update your experience");
        return err;
      }
    }

    return;
  };

  const updateExperienceLocation = async (data: PartialMerchCreationObject) => {
    const { newLocation } = formatMerchandise({
      ...newBusiness,
      ...data,
    });
    const { objWithChanges } = deepObjDiff(location, deepOmitNil(newLocation));

    if (!location?.id) {
      const returnedLocation = await submitLocation(objWithChanges);
      objWithChanges.id = returnedLocation?.id;
    } else {
      await updateLocation(objWithChanges);
    }

    try {
      const returnedExp = await apiClient.products.updateProduct({
        productId: experienceId!,
        changes: { location: objWithChanges, locationId: objWithChanges.id },
      });
      setLocation(objWithChanges);
      setExperience(returnedExp);
      return returnedExp;
    } catch (err) {
      setWarning("We couldn't update your location");
      return err;
    }
  };

  const submitPhoto = async (photo: File, fileName: string) => {
    try {
      return await apiClient.images.uploadImage({
        fileName,
        photo,
        productId: experienceId!,
      });
    } catch (err) {
      setWarning("We couldn't upload your photos");
      return;
    }
  };

  const submitLocation = async (
    location: Location
  ): Promise<Location | undefined> => {
    //adds experience location to PG DB
    try {
      return await apiClient.locations.createLocation({
        newLocation: location,
      });
    } catch (err) {
      setWarning("We couldn't create a location");
      console.error(err);
      return;
    }
  };

  const updateLocation = async (location: Location) => {
    try {
      return await apiClient.locations.updateLocation({
        locationId: location.id!.toString(),
        changes: location,
      });
    } catch (err) {
      setWarning("We couldn't update the location");
      return err;
    }
  };

  const submitMerchandise = async () => {
    if (experienceId) {
      await apiClient.products.submitProduct({ productId: experienceId });
    }
    setCurrentPage(13);
  };

  return {
    currentPage,
    furthestPage,
    newBusiness,
    warning,
    formatExperienceForEditing,
    formatLocationForEditing,
    changePage,
    handlePrevPage,
    handleNextPage,
    handleClickChange,
    handleFilterChange,
    handleChange,
    submitMerchandise,
    handleMultiChange,
    partiallyCreateExperience,
    resetThisField,
    updateExperience,
    updateExperienceLocation,
  };
}
