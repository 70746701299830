import {
  Stack,
  Typography,
  InputField,
  Box,
  Checkbox,
  FormControlLabel,
} from '@silverstein-properties/inspirelabs-ui';
import { LabelField } from '@/components';
import { Controller, useForm } from 'react-hook-form';
import { CapacitySectionEditInput } from '@/classes';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { Experience } from '@/types';
import { useFeatureFlags } from '@/hooks';

const resolver = classValidatorResolver(CapacitySectionEditInput, {
  transformer: { enableImplicitConversion: true },
});

export type CapacitySectionEditPropsType = {
  experience: Experience;
  onFormSubmit: (data: CapacitySectionEditInput) => Promise<void>;
};

export const CapacitySectionEdit = ({
  experience,
  onFormSubmit,
}: CapacitySectionEditPropsType) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<CapacitySectionEditInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      capacity: {
        max: experience?.capacity?.max || '',
      },
      groupBookingsAllowed: experience?.groupBookingsAllowed || false,
    },
  });

  const { isEditProductCapacityEnabled } = useFeatureFlags();
  return (
    <Stack spacing={4}>
      <Typography variant="h5">Capacity</Typography>
      <form id="capacityForm" onSubmit={handleSubmit(onFormSubmit)}>
        <Stack spacing={4}>
          {!!isEditProductCapacityEnabled && (
            <LabelField
              label=""
              value={
                <Box mt={3}>
                  <Controller
                    control={control}
                    name="capacity.max"
                    render={({ field }) => (
                      <InputField
                        {...field}
                        data-testid="maxGuests"
                        label="Max no. of guests"
                        type="number"
                        helperText={
                          !errors.capacity?.max
                            ? ' '
                            : errors.capacity?.max.message
                        }
                      />
                    )}
                  />
                </Box>
              }
            />
          )}
          <LabelField
            label="Guests"
            value={
              <Box mt={3}>
                <FormControlLabel
                  label="Allow guests to sign up for more people than just themselves"
                  control={
                    <Controller
                      control={control}
                      name="groupBookingsAllowed"
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          data-testid="groupBookingsAllowed"
                          checked={field.value}
                          name="groupBookingsAllowed"
                        />
                      )}
                    />
                  }
                />
              </Box>
            }
          />
          <Typography variant="assistiveText">
            Guests do not have to be app users or members
          </Typography>
        </Stack>
      </form>
    </Stack>
  );
};
