import { Typography } from '@silverstein-properties/inspirelabs-ui';
import RoundButton from '../RoundButton/Component';
import { StyledBox, StyledLink } from './EmptyState.styles';

export type EmptyStateProps = {
  content: string;
  cta?: {
    label: string;
    link: string;
  };
  icon?: string;
};

export const EmptyState = ({ content, cta, icon }: EmptyStateProps) => {
  return (
    <StyledBox>
      {icon ? <img alt="empty state" src={icon} /> : null}
      <Typography variant="h5" sx={{ maxWidth: '380px' }}>
        {content}
      </Typography>
      {cta ? (
        <StyledLink to={cta.link}>
          <RoundButton
            text={cta.label}
            default="blue-baby-blue"
            hover="white-navy"
          />
        </StyledLink>
      ) : null}
    </StyledBox>
  );
};
