import { FormHelperText, Grid } from '@mui/material';
import { Box, styled } from '@silverstein-properties/inspirelabs-ui';

export const StyledBoxFormControlContainer = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  flexDirection: 'row',
  width: '100%',
  marginTop: theme.spacers.m,
  '& > *:first-of-type fieldset': {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRightColor: 'transparent',
  },
  '& > *:last-of-type fieldset': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
}));

export const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  marginTop: 0,
  marginLeft: theme.spacers.xs,
  width: '100%',
  'text-align': 'center',
}));

export type StyledGridParentProps = {
  hasTwoOrMoreChildren?: boolean;
};

export const StyledGridParent = styled(Grid, {
  shouldForwardProp: prop => prop !== 'hasTwoOrMoreChildren',
})<StyledGridParentProps>(({ hasTwoOrMoreChildren }) => ({
  '& > *:first-of-type fieldset': {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    ...(hasTwoOrMoreChildren && { borderRightColor: 'transparent' }),
  },
  '& > *:not(:first-of-type):not(:last-of-type) fieldset': {
    borderRadius: 0,
  },
  '& > *:last-of-type fieldset': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeftColor: 'transparent',
  },
}));
