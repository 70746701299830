import {
  DialogBox,
  StyledDialogActions,
} from './DeactivateMembersDialog.styles';
import {
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ErrorIcon,
  FormControl,
  FormControlLabel,
  MessageBanner,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { MembershipPlanPurchase, PlanPurchaseStatus } from '@/types';
import { formatDateShortForm } from '@/utils';

export interface DeactivateMembersDialogProps {
  open: boolean;
  membershipPlanPurchase: MembershipPlanPurchase;
  onConfirm: (isGracefulCancelation: boolean) => Promise<void> | void;
  onClose: () => Promise<void> | void;
  errorMessage?: string;
}

export default function DeactivateMembersDialog({
  open,
  membershipPlanPurchase,
  onConfirm,
  onClose,
  errorMessage,
}: DeactivateMembersDialogProps) {
  const [isLoadingButton, setLoadingButton] = useState(false);
  const [isGracefulCancelation, setIsGracefulCancelation] = useState(
    !(
      membershipPlanPurchase.status === PlanPurchaseStatus.CANCELLED_BY_MERCHANT
    )
  );
  const theme = useTheme();

  const handleUserConfirmation = async () => {
    setLoadingButton(true);
    try {
      await onConfirm(isGracefulCancelation);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingButton(false);
    }
  };
  return (
    <>
      <DialogBox
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            width: '450px',
            maxWidth: '100%',
            maxHeight: '90%',
            height: 'auto',
          },
        }}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          alignSelf="center"
          marginTop={theme.spacers.l}
        >
          <ErrorIcon width={80} color={theme.palette.error.main} />
        </Stack>
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h4">Deactivate members?</Typography>
          <Stack sx={{ width: '100%' }}>
            <MessageBanner flexWrap="wrap" hidden={!errorMessage} type="error">
              {errorMessage}
            </MessageBanner>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography color="primary" variant="body1">
              Clicking the "Confirm" button below will terminate the memberships
              on the scheduled deactivation date and will block members from
              using it.
            </Typography>
            <Stack
              sx={{
                marginTop: theme.spacers.l,
                marginBottom: theme.spacers.l,
                marginLeft: theme.spacers.l,
              }}
            >
              <Typography
                color="primary"
                variant="subtitle1"
                alignSelf="flex-start"
              >
                Members
              </Typography>
              <>
                {membershipPlanPurchase.planConsumers.map(consumer => (
                  <Typography
                    key={consumer.id}
                    variant="body1"
                    alignSelf="flex-start"
                  >
                    {consumer.consumer?.firstName} {consumer.consumer?.lastName}{' '}
                    {consumer.consumerId ===
                    membershipPlanPurchase.primaryConsumerId
                      ? '(Primary)'
                      : ''}
                  </Typography>
                ))}
              </>
            </Stack>
          </DialogContentText>
          <FormControl>
            <RadioGroup
              value={isGracefulCancelation ? 'graceful' : 'immediately'}
              onChange={e =>
                setIsGracefulCancelation(e.target.value === 'graceful')
              }
            >
              <FormControlLabel
                value="graceful"
                disabled={
                  membershipPlanPurchase.status ===
                  PlanPurchaseStatus.CANCELLED_BY_MERCHANT
                }
                control={<Radio />}
                label={`End of the billing cycle (${formatDateShortForm(
                  membershipPlanPurchase.currentPeriodEnd
                )})`}
              />
              <FormControlLabel
                value="immediately"
                control={<Radio />}
                label="Immediately"
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <StyledDialogActions>
          <LoadingButton
            loading={isLoadingButton}
            variant="contained"
            size="large"
            onClick={handleUserConfirmation}
          >
            Confirm
          </LoadingButton>

          <Button
            variant="text"
            size="large"
            sx={{ marginTop: theme.spacers.l }}
            onClick={onClose}
          >
            Cancel
          </Button>
        </StyledDialogActions>
      </DialogBox>
    </>
  );
}
