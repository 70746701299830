import { useQuery } from '@tanstack/react-query';
import { ApiClient } from '@/api/apiClient';
import { useAuth } from './useAuth';

export const useMembershipPlansByProductId = ({
  productId,
  isEnabled,
}: {
  productId: string;
  isEnabled?: boolean;
}) => {
  const { data: user } = useAuth();
  const apiClient = ApiClient();
  // Default isEnabled to true if value is undefined
  isEnabled ??= true;

  const getMembershipPlansByProductId = async () => {
    return await apiClient.membershipPlans.getPlansByProductId({ productId });
  };

  return useQuery(
    ['getMembershipPlansByProductId', productId],
    () => getMembershipPlansByProductId(),
    {
      enabled: !!user && isEnabled,
      retry: false, // Stop endpoint from retrying if no plans were found
    }
  );
};

export const useMembershipPlan = ({
  membershipPlanId,
}: {
  membershipPlanId?: string;
}) => {
  const { data: user } = useAuth();
  const apiClient = ApiClient();

  return useQuery(
    ['getMembershipPlanById', membershipPlanId],
    () =>
      apiClient.membershipPlans.getPlanById({
        membershipPlanId: membershipPlanId!,
      }),
    {
      enabled: !!user && !!membershipPlanId,
    }
  );
};
