import { useMutation } from '@tanstack/react-query';
import { ApiClient } from '@/api/apiClient';
import { deepObjDiff } from '@/utils';
import { UserProfileInput } from '@/classes/UserProfileInput';
import { User } from '@/types';

export const useMerchantProfile = (user?: User | null) => {
  // TODO rename to avoid confusing with Merchant Business Profile
  // this is the user profile - used in MerchantProfileModule
  const apiClient = ApiClient();

  const updateMerchantProfile = async (data: Partial<UserProfileInput>) => {
    const { id, ...rest } = data;
    const updatedUser = { ...rest };
    const userId = user?.id.toString() || id?.toString();
    if (!userId) {
      return;
    }
    const { newChanges } = deepObjDiff(user, updatedUser);

    return await apiClient.users.updateUser({
      updatedUser: newChanges,
      userId,
    });
  };

  return useMutation(updateMerchantProfile);
};
