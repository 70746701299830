import { Grid, Typography } from '@silverstein-properties/inspirelabs-ui';
import { Skeleton, Paper } from '@mui/material';
import RoundButton from '@/components/RoundButton/Component';

const StatementTableRowSkeleton = () => {
  return (
    <Grid item xs={12} sx={{ mt: 2 }}>
      <Paper variant="outlined" sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Typography variant="body2" sx={{ mt: 0.6 }}>
              <Skeleton />
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography sx={{ mt: 0.6 }}>
              <Skeleton />
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography sx={{ mt: 0.6 }}>
              <Skeleton />
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography sx={{ mt: 0.6 }}>
              <Skeleton />
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography sx={{ mt: 0.6 }}>
              <Skeleton />
            </Typography>
          </Grid>
          <Grid item xs>
            <Skeleton sx={{ float: 'right' }}>
              <RoundButton
                text="Skeleton"
                default="white-navy small"
                hover="blue small"
              />
            </Skeleton>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default StatementTableRowSkeleton;
