import { NumericCircle } from '@/components/NumericCircle';
import { SideCommunicationMessage } from '@/types';
import { Grid, Typography } from '@silverstein-properties/inspirelabs-ui';
import { SideMessageCard } from '../SideMessageCard';
import {
  SideMessagesMainGrid,
  SideMessagesTitleGrid,
} from './SideMessages.styles';

export type SideMessagesProps = {
  handleMessageSelect: (bookingId: string) => void;
  selectedPurchaseId: string;
  sideMessages: SideCommunicationMessage[];
};

export const SideMessages = ({
  handleMessageSelect,
  selectedPurchaseId,
  sideMessages,
}: SideMessagesProps) => {
  return (
    <SideMessagesMainGrid container item xs={4} alignContent="flex-start">
      <SideMessagesTitleGrid container item columnGap={1}>
        <Typography variant="h4">Messages</Typography>
        <NumericCircle value={sideMessages.length || 0} size="sm" />
      </SideMessagesTitleGrid>
      <Grid
        container
        item
        xs={12}
        sx={{
          // 100vh is 100% of the screen height, subtract 128px for the
          // container padding, 64px is for the header of the messages,
          // 88px is for the navbar and 2px is for borders
          height: 'calc(100vh - 128px - 64px - 88px - 2px)',
          overflow: 'auto',
          alignContent: 'flex-start',
        }}
      >
        {sideMessages.map((message, i) => (
          <SideMessageCard
            key={`sideMessage_${message.communicationPurchaseId}_${i}`}
            handleClick={handleMessageSelect}
            isSelected={message.communicationPurchaseId === selectedPurchaseId}
            message={message}
          />
        ))}
      </Grid>
    </SideMessagesMainGrid>
  );
};
