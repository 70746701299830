import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction'; // needed for dayClick
import { DatesSetArg, EventClickArg } from '@fullcalendar/core';
import { CalendarListing, ListingStatus, ProductTypes } from '@/types';
import listPlugin from '@fullcalendar/list'; // list view
import { useFeatureFlags } from 'hooks';
import MiniCard from '@/components/MiniCard/MiniCard';
import {
  CateringIcon,
  RepeatIcon,
  Tooltip,
} from '@silverstein-properties/inspirelabs-ui';

export type CalendarProps = {
  height: string;
  width: string;
  handleNewEventClick: (arg: DateClickArg) => void;
  handleEventClick: (id: string) => void;
  onDatesSet?: (dateInfo: DatesSetArg) => void;
  events?: CalendarListing[];
};

export const Calendar = ({
  handleNewEventClick,
  handleEventClick,
  onDatesSet = () => {},
  height,
  width,
  events,
}: CalendarProps) => {
  const onEventClick = (e: EventClickArg) => {
    if (e.view.type !== 'listMonth') {
      handleEventClick(e.event._def.publicId);
    }
  };

  const mapListingStatus = (status: ListingStatus): string => {
    return status === ListingStatus.MERCHANT_CANCELLED
      ? 'Cancelled'
      : 'Published';
  };

  const { isMerchantSchedulingListViewEnabled } = useFeatureFlags();

  const onEventButtonClick = (id: string) => {
    handleEventClick(id);
  };

  return (
    <div style={{ height, width }}>
      <FullCalendar
        datesSet={onDatesSet}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
        editable={true}
        initialView="dayGridMonth"
        views={{
          ...(isMerchantSchedulingListViewEnabled && {
            listMonth: {
              // add a custom list view for displaying events by month
              type: 'list',
              duration: { months: 1 },
              titleFormat: { year: 'numeric', month: 'long' },
              listDayFormat: {
                weekday: 'long',
                month: 'short',
                day: 'numeric',
              },
              displayEventTime: false,
            },
          }),
          month: {
            fixedWeekCount: false, // allow variable week count
            dayMaxEventRows: 3, // set max number of events showing a day to 3 before only 2 and the text '+2 more'
            eventLimitClick: 'popover', // display list of events when limit is reached
            showNonCurrentDates: false, // hide dates from previous and next months
          },
          week: {
            displayEventEnd: false,
            allDaySlot: false,
            slotEventOverlap: false,
          },
          day: {
            displayEventEnd: false,
            allDaySlot: false,
            slotEventOverlap: false,
          },
        }}
        dateClick={handleNewEventClick}
        eventClick={onEventClick}
        buttonText={{
          ...(isMerchantSchedulingListViewEnabled && { listMonth: 'List' }), // add list button for month view
          month: 'Month',
          week: 'Week',
          day: 'Day',
        }}
        headerToolbar={{
          left: 'prev,next',
          center: 'title',
          right: `${
            isMerchantSchedulingListViewEnabled ? 'listMonth,' : ''
          }dayGridMonth,timeGridWeek,timeGridDay`,
        }}
        events={events}
        eventContent={({ event, view }) => {
          const eventTime = event?.start?.toLocaleTimeString([], {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
          });
          const eventDate = event?.start?.toLocaleDateString();
          const guests = event?.extendedProps?.nbOfGuests; // number of guests
          const status = event?.extendedProps?.status;
          const image = event?.extendedProps?.productSnapshot?.coverPhoto;
          const title = event?.extendedProps?.productSnapshot?.title;
          const type = event?.extendedProps?.productSnapshot?.type;
          const mappedStatus = mapListingStatus(status); // Use the helper function
          const cancelledBookings = event?.extendedProps?.nbOfCancellations;
          const isRecurringEvent = event?.extendedProps?.isRecurringEvent;
          const capacity = event?.extendedProps?.capacity;

          if (view.type === 'listMonth') {
            return (
              <MiniCard
                title={title || 'unknown title'}
                image={image}
                status={mappedStatus}
                date={eventDate || 'no date'}
                time={eventTime || 'no time'}
                id={event?._def?.publicId}
                nbOfGuests={guests}
                nbOfCancellations={cancelledBookings || undefined}
                onCalendarView={onEventButtonClick}
                miniCardType="event"
                isRecurring={isRecurringEvent}
              />
            );
          } else {
            return (
              <div
                className={`cellTime ${
                  isRecurringEvent ? 'recurringEvent' : ''
                }`}
              >
                <span className="eventTimeFormat">{eventTime}</span>
                <div className="cellPrice">
                  {`${guests}/${capacity} `}
                  {isRecurringEvent && (
                    <Tooltip
                      arrow
                      title="This is a recurring event."
                      placement="top"
                    >
                      <RepeatIcon width={16} color="primary" />
                    </Tooltip>
                  )}
                  {type === ProductTypes.FOOD_SERVICE && (
                    <Tooltip
                      arrow
                      title="This is a time slot for catering orders."
                      placement="top"
                    >
                      <CateringIcon width={16} color="primary" />
                    </Tooltip>
                  )}
                </div>
              </div>
            );
          }
        }}
        eventTimeFormat={{
          hour: 'numeric',
          minute: '2-digit',
          hour12: true,
          meridiem: 'short',
        }}
        eventTextColor="#15284C"
        eventDisplay="block"
        scrollTime="09:00:00" //in week or day view, autoscroll to 9 AM(or whenever we'd like).
        eventStartEditable={false}
      />
    </div>
  );
};
