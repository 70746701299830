import { useState, useEffect } from 'react';

export interface VendorClassType {
  title: string;
  category: string;
  location: string;
  description: string;
  price: string;
  image: string;
}

export function useHooks() {
  const [slideshow, setSlideshow] = useState<number>(1);
  const [classes, setClasses] = useState<VendorClassType[] | []>([]);

  useEffect(() => {
    const interval = setInterval(() => {
      setSlideshow(slideshow => {
        return slideshow === 3 ? 1 : slideshow + 1;
      });
    }, 2300);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    let i = 1;
    const fetchedClasses: VendorClassType[] = [];
    while (i <= 0) {
      const newClass: VendorClassType = {
        title: 'Title of Event',
        category: 'Food & Drinks',
        location: 'FiDi',
        description: 'Event Description',
        price: 'From $35 / person',
        image: `class${i}.svg`,
      };
      fetchedClasses.push(newClass);
      i++;
    }
    setClasses(fetchedClasses);
  }, []);

  const partnerShadows = [0, 1, 2, 3, 4, 5];

  return { slideshow, classes, partnerShadows };
}
