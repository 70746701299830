import { SelectionBox } from 'components/SelectionBox';
import { BusinessSignupComponentProps } from './Component';
import { useEffect } from 'react';

export function ServiceType(props: BusinessSignupComponentProps) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="row ms-xl-5 ms-lg-5 me-md-5 ms-sm-1 ms-xs-1 top-64 d-flex justify-content-end">
      <div className="col-8">
        <h4 className="business-form-headline mb-5">
          What type of service do you provide?
        </h4>

        <div className="selection-boxes">
          <form style={{ gap: '16px', width: '100%' }}>
            <SelectionBox
              handleChange={props.handleClickChange}
              handleClick={props.nextPage}
              icon="/images/selection_in-person.svg"
              isSelected={props.values.inPerson === true}
              label="In-Person"
              name="inPerson"
              subtitle="guests will join in the real world"
              value={true}
            />
            <SelectionBox
              handleChange={props.handleClickChange}
              handleClick={props.nextPage}
              icon="/images/selection_online.svg"
              isDisabled
              isSelected={props.values.inPerson === false}
              label="Online"
              name="online"
              subtitle="this option coming soon!"
              value={false}
            />
          </form>
        </div>
      </div>
    </div>
  );
}
