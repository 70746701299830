import { Box, styled } from '@mui/material';

export const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  borderRadius: theme.spacing(1),
  border: `2px solid ${theme.palette.primary.main}`,
  width: '100%',
  display: 'inline-flex',
  alignItems: 'flex-end',
  columnGap: theme.spacing(5),
}));
