import { AxiosInstance } from 'axios';
import { User } from '@/types';
import { CreatePasswordInput } from '@/classes';

export const endpoints = {
  users: '/users',
  user({ userId }: { userId: string }) {
    return `/users/${userId}`;
  },
  resetPassword({ email }: { email: string }) {
    return `/users/${email}/password`;
  },
};
export const users = ({ axiosInstance }: { axiosInstance: AxiosInstance }) => {
  return {
    getUserByEmail: async ({
      accessToken,
      email,
    }: {
      accessToken: string;
      email: string;
    }): Promise<User> => {
      const { data } = await axiosInstance.get(endpoints.users, {
        params: {
          email,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return data;
    },
    updateUser: async ({
      updatedUser,
      userId,
    }: {
      updatedUser: Partial<User>;
      userId: string;
    }): Promise<User> => {
      const { data } = await axiosInstance.patch(
        endpoints.user({ userId }),
        updatedUser
      );
      return data;
    },
    resetPassword: async ({
      email,
      ...resetPasswordDto
    }: CreatePasswordInput): Promise<boolean> => {
      const { data } = await axiosInstance.put(
        endpoints.resetPassword({ email }),
        resetPasswordDto
      );
      return data;
    },
  };
};
