export enum PaymentStatus {
  REQUIRES_PAYMENT_METHOD,
  REQUIRES_CONFIRMATION,
  REQUIRES_ACTION,
  PROCESSING,
  REQUIRES_CAPTURE,
  SUCCEEDED,
  FAILED,
  CANCELED,
  REFUNDED,
  CANCELLATION_REQUESTED,
  REFUND_REQUESTED,
}
