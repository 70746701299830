import { TeamInviteInput } from '@/classes';
import { useNewTeamMember } from '@/hooks';
import { UserRole } from '@/types';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import {
  Box,
  Button,
  DetailsDrawer,
  IconBlock,
  InputField,
  MessageBanner,
  Stack,
  Typography,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

interface InviteDrawerProps {
  open: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  rolesDropdown: {
    label: string;
    value: UserRole;
  }[];
}

const resolver = classValidatorResolver(TeamInviteInput);

export const InviteDrawer = (props: InviteDrawerProps) => {
  const theme = useTheme();
  const { open, onClose, rolesDropdown, onSubmit } = props;
  const [isInviteSent, setIsInviteSent] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const renderFormActions = () => {
    if (isInviteSent) {
      return (
        <>
          <Button
            variant="text"
            onClick={() => {
              setIsInviteSent(false);
              reset();
            }}
          >
            Invite another member
          </Button>
        </>
      );
    }
    return (
      <>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit" form="memberInviteForm" disabled={isLoading}>
          Invite
        </Button>
      </>
    );
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<TeamInviteInput>({
    resolver: resolver,
    mode: 'onTouched',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      role: UserRole.ADMIN,
    },
  });

  const onSuccessCallback = () => {
    reset();
    setIsInviteSent(true);
  };
  const onErrorCallback = () => {
    setSubmitError(true);
  };

  const { mutateAsync: addTeamMember, isLoading } = useNewTeamMember(
    onSuccessCallback,
    onErrorCallback
  );

  const onFormSubmit = async (data: TeamInviteInput) => {
    await addTeamMember(data);
    onSubmit && onSubmit();
  };

  const onInputChange = (e: Event, onChange: (e: Event) => void) => {
    setSubmitError(false);
    onChange(e);
  };

  return (
    <DetailsDrawer
      title="Invite member"
      open={open}
      onClose={onClose}
      actionsBottom={renderFormActions()}
    >
      {!isInviteSent ? (
        <Stack
          component="form"
          id="memberInviteForm"
          onSubmit={handleSubmit(onFormSubmit)}
          direction="column"
          spacing={theme.spacers.xxl2}
        >
          <Stack direction="column" spacing={theme.spacers.m} width="100%">
            {submitError && (
              <MessageBanner type="error">
                Could not add team member
              </MessageBanner>
            )}
            <Typography variant="subtitle1">Team member details</Typography>
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <InputField
                  {...field}
                  label="First Name"
                  data-testid="firstNameInvite"
                  type="text"
                  error={!!errors.firstName}
                  helperText={errors.firstName?.message}
                  onChange={e => onInputChange(e, field.onChange)}
                />
              )}
            />
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <InputField
                  {...field}
                  label="Last Name"
                  data-testid="lastNameInvite"
                  type="text"
                  error={!!errors.lastName}
                  helperText={errors.lastName?.message}
                  onChange={e => onInputChange(e, field.onChange)}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <InputField
                  {...field}
                  label="Email"
                  data-testid="emailInvite"
                  type="email"
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  onChange={e => onInputChange(e, field.onChange)}
                />
              )}
            />
          </Stack>

          <Stack direction="column" spacing={theme.spacers.m} width="100%">
            <Typography variant="subtitle1">Assigned role</Typography>
            <Controller
              name="role"
              control={control}
              render={({ field }) => (
                <InputField
                  {...field}
                  label="Role"
                  select
                  dropdownItems={rolesDropdown}
                  error={!!errors.role}
                  helperText={errors.role?.message}
                  onChange={e => onInputChange(e, field.onChange)}
                />
              )}
            />
          </Stack>
        </Stack>
      ) : (
        <Box marginTop="30vh" paddingX="15%">
          <IconBlock
            icon="/images/checkmark.svg"
            iconColor="success.main"
            body="Thank you. Your invite was sent successfully."
          />
        </Box>
      )}
    </DetailsDrawer>
  );
};
