import { CommunicationMessageType } from '@/types';
import { pluralize } from './commonUtils';

export const SYSTEM_GENERATED_COMMUNICATION_TYPE = [
  CommunicationMessageType.CANCELLATION,
  CommunicationMessageType.SIGN_UP,
  CommunicationMessageType.SYSTEM_GENERATED,
];

export const createNotificationCardMessage = (
  communicationMessageType: CommunicationMessageType,
  noOfGuests: number
) => {
  if (communicationMessageType === CommunicationMessageType.SIGN_UP) {
    return `Signed up for ${pluralize(noOfGuests, 'guest')}, `;
  }
  if (communicationMessageType === CommunicationMessageType.CANCELLATION) {
    return `Cancellation for ${pluralize(noOfGuests, 'guest')}, `;
  }
  return 'Notification sent ';
};
