import { LabelField } from '@/components';
import { Experience } from '@/types';
import { Stack, Typography } from '@silverstein-properties/inspirelabs-ui';
import { useCategoriesChoices, useSubCategoriesChoices } from '@/hooks';

export type BasicInfoSectionPropsType = {
  experience: Experience;
};

export const BasicInfoSection = ({ experience }: BasicInfoSectionPropsType) => {
  const { data: categories, isLoading } = useCategoriesChoices();
  const { data: subcategories } = useSubCategoriesChoices();

  if (isLoading) {
    return null;
  }

  return (
    <Stack spacing={4}>
      <LabelField
        label="Categories that best describe your experience"
        value={
          <>
            {(experience.category?.type ?? []).map(selectedCategory => {
              const category = categories?.find(
                category => category.value === selectedCategory
              );

              const subcategoriesList =
                (experience.category?.info ?? [])
                  ?.map(selectedCategory => {
                    const subcategory = subcategories?.find(
                      subcategory =>
                        subcategory.value === selectedCategory &&
                        subcategory.categoryId === category?.id
                    );
                    return subcategory ? subcategory.label : null;
                  })
                  ?.filter(value => !!value)
                  .join(', ') || 'No subcategories';

              return (
                <Typography key={category?.id}>
                  {`${category?.label}: ${subcategoriesList}`}
                </Typography>
              );
            })}
          </>
        }
      />
    </Stack>
  );
};
