import { CommunicationMessageType, MessageTypeLabels } from '@/types';

export const getMessageType = (
  key?: CommunicationMessageType
): string | undefined => {
  if (key) {
    return MessageTypeLabels[
      CommunicationMessageType[key] as keyof typeof MessageTypeLabels
    ];
  }
  return MessageTypeLabels.GENERAL;
};
