import { Skeleton, Stack } from '@silverstein-properties/inspirelabs-ui';

export const DataTableLoading = ({
  show = false,
  numberOfSkeletons = 3,
}: {
  show: boolean;
  numberOfSkeletons?: number;
}) => {
  return show ? (
    <Stack padding={5}>
      {[...Array(numberOfSkeletons)].map((_, index) => (
        <Skeleton key={index} height={60} />
      ))}
    </Stack>
  ) : null;
};
