import { formatPropertyName } from '@/utils';
import {
  registerDecorator,
  ValidationOptions,
  ValidationArguments,
} from 'class-validator';

export const PriceMinValue = (
  property: number,
  validationOptions?: ValidationOptions
) => {
  return (object: Object, propertyName: string) => {
    registerDecorator({
      name: 'PriceMinValue',
      target: object.constructor,
      propertyName: propertyName,
      constraints: [property],
      options: validationOptions,
      validator: {
        validate(value: string, args: ValidationArguments) {
          const [minValue] = args.constraints;
          const parsedValue = parseFloat(value);
          // throw validation error if less than min amount
          return !(parsedValue < minValue);
        },
        defaultMessage(args: ValidationArguments) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return `Your ${formatPropertyName(
            args.property
          )} cannot be less than minimum value`;
        },
      },
    });
  };
};
