import { ApiClient } from '@/api/apiClient';
import { Experience } from '@/types';
import { useMutation } from '@tanstack/react-query';

const useSubmitExperienceMutation = (
  onSuccess?: (experience: Experience) => void,
  onError?: (error: Error) => void
) => {
  const apiClient = ApiClient();

  const mutation = useMutation<Experience, Error, { productId: string }>({
    mutationFn: async ({ productId }) => {
      try {
        return await apiClient.products.submitProduct({
          productId,
        });
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    onSuccess: data => {
      onSuccess && onSuccess(data);
    },
    onError: error => {
      console.error(error);
      onError && onError(error);
    },
  });
  return mutation;
};

export default useSubmitExperienceMutation;
