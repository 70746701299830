import {
  CommunityIcon,
  Container,
  FlagIcon,
  Grid,
  GrowthIcon,
  Typography,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import { Link as MuiLink } from '@mui/material';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { TodoContainer } from './components/TodoContainer';
import MiniCard from '@/components/MiniCard/MiniCard';
import { NumericCircle } from '@/components/NumericCircle';
import { useExperiences, useTodos } from '@/hooks';
import { ExperienceStatus } from '@/types';
import { formatDate, formatTime } from '@/utils';
import { MerchantDashboardEmptyState } from './components';
import { useDashboard } from '@/hooks/useDashboard';
import { DashboardCard } from '@/components';

export const MerchantDashboardModule = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { data: experiences } = useExperiences();
  const { data: todos } = useTodos();
  const { data: dashboardOverview } = useDashboard();
  const displayedExperiences = experiences
    ?.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )
    .slice(0, 2);

  if (!displayedExperiences?.length) {
    return (
      <Container sx={{ paddingY: theme.spacers.xxl4 }}>
        <MerchantDashboardEmptyState />
      </Container>
    );
  }

  const experiencesCards = displayedExperiences.map(experience => {
    const mDate = experience.updatedAt ?? experience.createdAt;
    const modifiedDate = mDate ? formatDate(mDate) : '';
    const modifiedTime = mDate ? formatTime(mDate) : '';
    const status =
      experience.status !== undefined
        ? ExperienceStatus[experience.status]
        : ExperienceStatus[1];
    return (
      <Grid key={experience.id} item xs={12}>
        <MiniCard
          title={experience.title || 'Untitled experience'}
          status={status}
          date={modifiedDate}
          time={modifiedTime}
          image={experience.coverPhoto || undefined}
          key={experience.id}
          onEdit={() =>
            navigate(`/experiences/${experience.id}`, {
              state: { from: document.location.pathname },
            })
          }
          onView={() =>
            navigate(`/experiences/${experience.id}`, {
              state: { from: document.location.pathname },
            })
          }
        />
      </Grid>
    );
  });

  const dashboardCards = (
    <Grid
      container
      spacing={4}
      sx={{
        mb: 4,
      }}
    >
      <Grid item xs={4}>
        <Link to="/experiences">
          <DashboardCard
            icon={<FlagIcon width={75} />}
            value={dashboardOverview?.experiences || 0}
            label="Experiences"
          />
        </Link>
      </Grid>
      <Grid item xs={4}>
        <DashboardCard
          icon={<GrowthIcon width={75} />}
          value={dashboardOverview?.upcomingEvents || 0}
          label="Upcoming"
        />
      </Grid>
      <Grid item xs={4}>
        <DashboardCard
          icon={<CommunityIcon width={75} />}
          value={dashboardOverview?.signups || 0}
          label="Signups"
        />
      </Grid>
    </Grid>
  );

  return (
    <Container sx={{ paddingY: theme.spacers.xxl4 }}>
      {!!dashboardOverview && dashboardCards}
      <Grid container columnSpacing={4}>
        <Grid item xs={8}>
          <Grid container item alignItems="center" xs={12} gap={1}>
            <Typography variant="h5">
              <Link to="/experiences">Experiences</Link>
            </Typography>
            <NumericCircle size="sm" value={experiences?.length} />
          </Grid>
          <Grid container item spacing={2} sx={{ pt: 2, pb: 2 }}>
            {experiencesCards}
          </Grid>
          <MuiLink component={Link} to="/experiences" variant="body2">
            View all
          </MuiLink>
        </Grid>
        <Grid item xs={4}>
          {todos ? <TodoContainer todos={todos} /> : null}
        </Grid>
      </Grid>
    </Container>
  );
};
