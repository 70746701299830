import {
  IsNotEmpty,
  IsPhoneNumber,
  MaxLength,
  IsEmail,
  IsOptional,
} from 'class-validator';
import { TEXT_AREA_LIMIT_LONG } from '@/constants';

export class ContactUsFormInput {
  @IsNotEmpty({ message: 'Please enter your first name' })
  firstName: string;

  @IsNotEmpty({ message: 'Please enter your last name' })
  lastName: string;

  @IsEmail({}, { message: 'Please enter a valid email address' })
  email: string;

  @IsPhoneNumber('US', { message: 'Please enter a valid phone number' })
  phone: string;

  @IsOptional()
  company: string;

  @IsNotEmpty({ message: 'Please enter your question' })
  @MaxLength(TEXT_AREA_LIMIT_LONG, {
    message: 'Please enter a shorter question',
  })
  question: string;
}
