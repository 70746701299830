import { useAuth } from '@/hooks';
import { AnalyticsAdapter } from '@/types';
import React, { createContext, useEffect } from 'react';

const AnalyticsContext = createContext({});

interface AnalyticsProviderProps {
  children: React.ReactNode;
  analyticsAdapter: AnalyticsAdapter;
}

export const AnalyticsProvider = ({
  children,
  analyticsAdapter,
}: AnalyticsProviderProps) => {
  const { data: user } = useAuth();

  useEffect(() => {
    if (!user?.accessToken) {
      analyticsAdapter.closeSession();
      return;
    }

    analyticsAdapter.initializeSession(user);
  }, [user]);

  return (
    <AnalyticsContext.Provider value={{}}>{children}</AnalyticsContext.Provider>
  );
};
