import React, { useState, useEffect } from 'react';

const linesOfText = [
  'Looking for something?',
  'Move along, nothing to see here.',
  'Nah, that URL does not look right.',
  'Welp, this is a whole bunch of nothing.',
  '¯\\_(ツ)_/¯',
  "You've done it! You've discovered the Lost City of Atlantis! Now leave and tell no one.",
  'If you have an account, please log in',
];

function getRandomLine() {
  return linesOfText[Math.floor(Math.random() * linesOfText.length)];
}

function RandomLine() {
  const [line, setLine] = useState('');

  useEffect(() => {
    setLine(getRandomLine());
  }, []);

  return <h5>{line}</h5>;
}

export default RandomLine;
