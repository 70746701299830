import {
  Box,
  InputField,
  Stack,
  Typography,
} from '@silverstein-properties/inspirelabs-ui';
import { Controller, useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { Experience } from '@/types';
import { LabelField } from '@/components';
import { TEXT_AREA_LIMIT_MEDIUM_PLUS } from '@/constants';
import { RequestCommunicationSectionEditInput } from '@/classes';
import { renderInputFieldHelperText } from '@/utils';

const resolver = classValidatorResolver(RequestCommunicationSectionEditInput);

export type RequestCommunicationSectionEditPropsType = {
  experience: Experience;
  onFormSubmit: (data: RequestCommunicationSectionEditInput) => Promise<void>;
};

export const RequestCommunicationSectionEdit = ({
  experience,
  onFormSubmit,
}: RequestCommunicationSectionEditPropsType) => {
  const {
    watch,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<RequestCommunicationSectionEditInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      bookingRequestInfo: {
        info: experience.bookingRequestInfo?.info || '',
      },
    },
  });

  return (
    <Stack spacing={4}>
      <Typography variant="h5">Communication</Typography>
      <form id="requestCommunicationForm" onSubmit={handleSubmit(onFormSubmit)}>
        <Stack spacing={4}>
          <LabelField
            label="Customers will contact you to request a quote. Please indicate what initial details you would like them to provide."
            value={
              <Box mt={2} sx={{ width: '100%' }}>
                <Controller
                  control={control}
                  name="bookingRequestInfo.info"
                  render={({ field }) => (
                    <InputField
                      {...field}
                      data-testid="bookingRequestInfo"
                      label="Info"
                      placeholder='Example: "Please tell me your high level plan for the event. State your name, phone number, requested date & time, length of service, and number of guests."'
                      showFloatingLabel={false}
                      multiline
                      minRows={6}
                      error={!!errors.bookingRequestInfo?.info}
                      helperText={renderInputFieldHelperText(
                        watch('bookingRequestInfo.info')?.length,
                        TEXT_AREA_LIMIT_MEDIUM_PLUS,
                        errors.bookingRequestInfo?.info?.message
                      )}
                    />
                  )}
                />
              </Box>
            }
          />
        </Stack>
      </form>
    </Stack>
  );
};
