import { ApiClient } from '@/api/apiClient';
import { Experience } from '@/types';
import { useMutation } from '@tanstack/react-query';

const usePartialCreateExperienceMutation = (
  onSuccess?: (experience: Partial<Experience>) => void,
  onError?: (error: Error) => void
) => {
  const apiClient = ApiClient();

  const mutation = useMutation<
    Partial<Experience>,
    Error,
    { data: Partial<Experience> }
  >({
    mutationFn: async ({ data }) => {
      try {
        return await apiClient.products.createPartialProduct({
          partialProduct: data,
        });
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    onSuccess: data => {
      onSuccess && onSuccess(data);
    },
    onError: error => {
      console.error(error);
      onError && onError(error);
    },
  });
  return mutation;
};

export default usePartialCreateExperienceMutation;
