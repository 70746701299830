import React, { useState } from 'react';
import './FormTextInput.css';
import ErrorIcon from './error_icon.png';

export interface FormTextInputProps {
  type: string;
  label: string;
  width: string;
  value: string | number;
  name: string;
  error?: string;
  maxLength?: string;
  rounded?: string;
  assistiveText?: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (componentName: string) => void;
  testId?: string;
}

export function FormTextInput(props: FormTextInputProps): JSX.Element {
  const [wasFocused, setWasFocused] = useState<boolean>(false);

  return (
    <div className={`input-container ${props.width}`}>
      <input
        name={props.name}
        type={props.type}
        value={props.value}
        maxLength={props.maxLength ? parseInt(props.maxLength, 10) : undefined}
        onChange={props.handleChange}
        data-testid={props.testId}
        className={
          props.error
            ? `error ${props.rounded && props.rounded}`
            : `no-error ${props.rounded && props.rounded}`
        }
        onFocus={() => {
          !wasFocused && setWasFocused(true);
        }}
        onBlur={() => {
          if (props.onBlur) {
            setWasFocused(false);
            props.onBlur(props.name);
          }
        }}
        min={props.type === 'number' ? 0 : undefined}
        id={props.name}
      />
      {props.label && (
        <label
          aria-labelledby={props.name}
          htmlFor={props.name}
          className={`${
            wasFocused ||
            (typeof props.value === 'string' && props.value?.trim().length)
              ? 'label-filled'
              : 'label'
          }${props.error ? ' error' : ''} `}
        >
          {props.label}
        </label>
      )}
      {props.error && (
        <img className="assistive-image" src={ErrorIcon} alt="error" />
      )}
      <span className="password-assistive-text">
        {props.assistiveText && props.assistiveText}
      </span>
    </div>
  );
}
