import { useState } from 'react';
import { MonthlyStatementSubscription } from '@/types';
import {
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  getExpandedRowModel,
  useReactTable,
  ExpandedState,
} from '@tanstack/react-table';
import { DataTable } from '@/components';
import { TableData, getTableColumns } from './SubscriptionsTable.columns';

interface SubscriptionsTableProps {
  subscriptions: MonthlyStatementSubscription[];
  onDetailsClick?: (id: string) => void;
}

export const SubscriptionsTable = (props: SubscriptionsTableProps) => {
  const { subscriptions, onDetailsClick } = props;

  // Transform the data into the expected format from the table.
  const transformedSubscriptions: TableData[] = subscriptions.map(
    subscription => {
      return {
        id: subscription.subscriptionId,
        name: subscription.subscriptionName,
        members: subscription.numberActiveMembers || 0,
        netEarnings: subscription.netEarnings,
        platformFee: subscription.inspireFee,
        refunds: subscription.refunds,
        plans: subscription.membershipPlans.map(plan => ({
          id: plan.membershipPlanId,
          name: `${plan.membershipPlanName}~${plan.numberActiveMembers}`, // Combine 2 values into a single string (b/c of the table data format)
          members: plan.numberActiveMembers,
          netEarnings: plan.netEarnings,
          platformFee: plan.inspireFee,
          refunds: plan.refunds,
        })),
      };
    }
  );

  const [data] = useState(() => [...transformedSubscriptions]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const rightAlignedColumns = [
    'members',
    'netEarnings',
    'platformFee',
    'refunds',
  ];

  const table = useReactTable({
    data,
    columns: getTableColumns(onDetailsClick),
    state: {
      sorting,
      expanded,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onExpandedChange: setExpanded,
    getSubRows: row => row.plans,
    getExpandedRowModel: getExpandedRowModel(),
  });

  return <DataTable table={table} rightAlignedColumns={rightAlignedColumns} />;
};
