import { Grid, Typography } from '@silverstein-properties/inspirelabs-ui';
import { SelectionBox } from '@/components/SelectionBox';
import { Experience, InputChangeEvent } from '@/types';
import { FullWidthBox } from './CalendarSidebar.styles';

export type CalendarSidebarPropsType = {
  experiences: Experience[];
  onClick: (e: InputChangeEvent) => void;
  selectedExperience?: Experience;
};

export const CalendarSidebar = ({
  experiences,
  onClick,
  selectedExperience,
}: CalendarSidebarPropsType) => {
  return (
    <FullWidthBox>
      <Typography variant="h4">Schedule</Typography>
      <Grid container item spacing={2} sx={{ mt: 7 }}>
        <Grid item key="allExperiences" xs={12}>
          <SelectionBox
            size="lg"
            label="All experiences"
            name="All experiences"
            value="allExperiences"
            isSelected={selectedExperience === undefined}
            handleChange={e => onClick(e)}
          />
        </Grid>
        {experiences &&
          experiences.map(experience => (
            <Grid item key={experience.id} xs={12}>
              <SelectionBox
                size="lg"
                label={experience.title}
                name={experience.title}
                internalName={experience.internalName}
                value={experience.id}
                isSelected={selectedExperience?.id === experience.id}
                handleChange={e => onClick(e)}
              />
            </Grid>
          ))}
      </Grid>
    </FullWidthBox>
  );
};
