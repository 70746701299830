import { formatPropertyName } from '@/utils';
import {
  registerDecorator,
  ValidationOptions,
  ValidationArguments,
} from 'class-validator';

export const IsPriceMinValueForPaidExperience = (
  property: number,
  validationOptions?: ValidationOptions
) => {
  return (object: Object, propertyName: string) => {
    registerDecorator({
      name: 'IsPriceMinValueForPaidExperience',
      target: object.constructor,
      propertyName: propertyName,
      constraints: [property],
      options: validationOptions,
      validator: {
        validate(value: string, args: ValidationArguments) {
          const [minAmount] = args.constraints;
          const parsedValue = parseFloat(value);
          // valid for free experiences/events otherwise
          // throw validation error if less than min amount
          if (parsedValue === 0) {
            return true;
          } else {
            return !(parsedValue < minAmount);
          }
        },
        defaultMessage(args: ValidationArguments) {
          return `${formatPropertyName(args.property)} cannot be lower than $${
            args.constraints[0]
          }`;
        },
      },
    });
  };
};
