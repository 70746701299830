import {
  ChevronDownIcon,
  Stack,
  SuccessIcon,
  Typography,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import { Grid } from '@mui/material';
import { EXPERIENCE_DETAIL_MENUS } from '@/constants';
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledCard,
  StyledCardContent,
} from './FloatingMenu.styles';
import {
  forwardRef,
  ForwardedRef,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { HashLink } from 'react-router-hash-link';
import { useNavigate, useLocation, To } from 'react-router-dom';
import { BackButton } from '@/components';
import { MerchantType } from '@/types/Merchant';
import {
  ExperienceDetailsMenuVisibility,
  getExperienceDetailSectionMenus,
} from '@/utils/experienceUtils';
import { BookingMethod, Experience, ExperienceStatus } from '@/types';
import {
  checkCustomValuesInObject,
  checkValuesInObject,
  formatDate,
} from '@/utils';
import { AccordionDetails } from '@mui/material';

export type FloatingMenuPropsType = {
  setHeight: (value: number) => void;
  onMenuItemClick?: (sectionId: string, menuItemId: string) => void;
  title: string;
  experience: Experience;
  merchantType?: MerchantType;
};

export type DetailMenuItemsPropsType = {
  setPrevLocation: (value: To) => void;
  onMenuItemClick?: (sectionId: string, menuItemId: string) => void;
  sectionId: string;
  menu: ExperienceDetailsMenuVisibility[];
  experience: Experience;
  includeSubmitApproval: boolean;
};

const DetailMenuItems = ({
  menu,
  sectionId,
  experience,
  includeSubmitApproval,
  setPrevLocation,
  onMenuItemClick,
}: DetailMenuItemsPropsType) => {
  const location = useLocation();

  const theme = useTheme();
  const currentHash = location.hash.replace('#', '');

  useEffect(() => {
    if (location.state?.from) {
      setPrevLocation(location.state.from);
    }
  }, []);

  const scrollWithOffset = (el: Element) => {
    // Scroll to the top if clicked on first menu otherwise scroll to the section
    if (el.id === EXPERIENCE_DETAIL_MENUS[0].id) {
      window.scrollTo({ top: 0 });
    } else {
      const yCoordinate = el.getBoundingClientRect().top + window.scrollY;
      // Offset of 6 MUI units to cancel the padding in the section
      const yOffset = -parseFloat(theme.spacing(6).replace('px', ''));
      window.scrollTo({ top: yCoordinate + yOffset });
    }
  };

  return (
    <>
      {menu.map(item => {
        const isComplete =
          item.showCompleteInfoCheck &&
          (experience?.bookingMethods || [BookingMethod.INSTANT]).every(
            bookingMethod =>
              checkValuesInObject(
                experience,
                item.completeInfoCheck[bookingMethod] as string[]
              )
          );
        return item.visible ? (
          <Grid item key={item.id} xs={12}>
            <HashLink
              to={`#${item.id}`}
              onClick={() => {
                onMenuItemClick?.(sectionId, item.id);
              }}
              scroll={scrollWithOffset}
            >
              <StyledCard
                isSelected={currentHash === item.id}
                variant="outlined"
              >
                <StyledCardContent>
                  <Stack justifyContent="space-between" flexDirection="row">
                    <Stack flexDirection="row" justifyContent="center">
                      <img alt={item.title} src={item.image} />
                      <Typography variant="subtitle1" marginLeft={1}>
                        {item.title}
                      </Typography>
                    </Stack>
                    {isComplete && (
                      <Stack alignSelf="center">
                        <SuccessIcon
                          color={theme.palette.success.main}
                          width={25}
                        />
                      </Stack>
                    )}
                  </Stack>
                </StyledCardContent>
              </StyledCard>
            </HashLink>
          </Grid>
        ) : null;
      })}
      {includeSubmitApproval && (
        <Grid item key="submitForApproval" xs={12}>
          <HashLink
            to="#submitForApproval"
            onClick={() => {
              onMenuItemClick?.(sectionId, 'submitForApproval');
            }}
            scroll={scrollWithOffset}
          >
            <StyledCard
              isSelected={currentHash === 'submitForApproval'}
              variant="outlined"
            >
              <StyledCardContent>
                <Stack justifyContent="space-between" flexDirection="row">
                  <Stack>
                    <Typography variant="subtitle1">
                      Submit for approval
                    </Typography>
                    {[
                      ExperienceStatus.Approved,
                      ExperienceStatus.Hidden,
                      ExperienceStatus.Published,
                    ].includes(experience.status) && (
                      <Typography
                        variant="subtitle2"
                        color={theme.palette.success.main}
                      >
                        Approved on{' '}
                        {formatDate(
                          experience.approvedAt || experience.createdAt
                        )}
                      </Typography>
                    )}
                    {experience.status ===
                      ExperienceStatus['Pending Approval'] && (
                      <Typography
                        variant="subtitle2"
                        color={theme.palette.success.main}
                        sx={{ display: 'inline-block' }}
                      >
                        Submitted on{' '}
                        {formatDate(
                          experience.submittedAt || experience.createdAt
                        )}
                      </Typography>
                    )}
                  </Stack>
                  {[
                    ExperienceStatus.Approved,
                    ExperienceStatus.Hidden,
                    ExperienceStatus.Published,
                  ].includes(experience.status) && (
                    <Stack alignSelf="center">
                      <SuccessIcon
                        fill={theme.palette.success.main}
                        color={theme.palette.background.paper}
                        width={30}
                      />
                    </Stack>
                  )}
                </Stack>
              </StyledCardContent>
            </StyledCard>
          </HashLink>
        </Grid>
      )}
    </>
  );
};

const FloatingMenuInternal = (
  props: FloatingMenuPropsType,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const { setHeight, title, merchantType = MerchantType.STANDARD } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  const [prevLocation, setPrevLocation] = useState<To>('/experiences');

  useEffect(() => {
    if (ref && typeof ref !== 'function' && ref.current) {
      setHeight(ref.current.offsetHeight);
    }
  }, [ref]);

  const sectionMenu = useCallback(
    () =>
      getExperienceDetailSectionMenus(
        merchantType,
        props.experience.bookingMethods
      ),
    [merchantType, props.experience.bookingMethods]
  )();

  const isOnlyOneSectionVisible =
    sectionMenu.filter(
      section =>
        section.visible &&
        checkCustomValuesInObject(props.experience, section.visibilityInfoCheck)
    ).length === 1;

  return (
    <Grid
      container
      rowSpacing={1}
      // Top of 64px or 8 MUI units for the header's height
      sx={{ position: 'sticky', top: theme.spacing(8) }}
    >
      <Grid ref={ref} item xs={12}>
        <BackButton onClick={() => navigate(prevLocation)} />
        <Typography variant="h4" sx={{ pt: 3.75, wordWrap: 'break-word' }}>
          {title || 'Untitled experience'}
        </Typography>
      </Grid>
      {sectionMenu.map(section => {
        const isVisible =
          section.visible &&
          checkCustomValuesInObject(
            props.experience,
            section.visibilityInfoCheck
          );

        return isVisible ? (
          <Grid item key={section.id} xs={12}>
            <StyledAccordion
              defaultExpanded
              square
              disableGutters
              disabled={isOnlyOneSectionVisible}
              sx={{ boxShadow: 'none' }}
            >
              <StyledAccordionSummary
                aria-controls="panel1-content"
                {...(!isOnlyOneSectionVisible && {
                  expandIcon: <ChevronDownIcon width={30} />,
                })}
                id="panel1-header"
              >
                <Typography variant="subtitle2">{section.title}</Typography>
              </StyledAccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <DetailMenuItems
                    sectionId={section.id}
                    menu={section.menu}
                    experience={props.experience}
                    onMenuItemClick={props.onMenuItemClick}
                    includeSubmitApproval={section.includeApprovalSection}
                    setPrevLocation={setPrevLocation}
                  />
                </Grid>
              </AccordionDetails>
            </StyledAccordion>
          </Grid>
        ) : null;
      })}
    </Grid>
  );
};

export const FloatingMenu = forwardRef(FloatingMenuInternal);
