import {
  BookingRequestStatus,
  CommunicationPurchaseType,
  ListingStatus,
  MainCommunicationConversation,
  MainCommunicationMessage,
} from '@/types';
import {
  MainMessagesTitleGrid,
  StyledButtonsGrid,
  StyledLoadingButton,
} from './MainMessages.styles';
import { formatDate, formatDateForExperienceView, pluralize } from '@/utils';
import { MainMessageCard } from '../MainMessageCard';
import {
  deleteQuoteMutation,
  useBookingRequestLoaded,
  useCommunicationReply,
  useFeatureFlags,
} from '@/hooks';
import { MessageTextField } from '../MessageTextField';
import { useEffect, useRef, useState } from 'react';
import {
  SuccessIcon,
  TrashIcon,
  useTheme,
  Typography,
  Button,
  Grid,
} from '@silverstein-properties/inspirelabs-ui';
import { QueryObserverResult } from '@tanstack/react-query';

export type MainMessagesProps = {
  selectedPurchaseId: string;
  openBookingRequestAddQuoteDrawer: () => void;
  openBookingRequestViewQuoteDrawer: () => void;
  unsentQuoteAdded: boolean;
  setIsUnsentQuoteAdded: (isAdded: boolean) => void;
  mainCommunicationThread: MainCommunicationConversation | undefined;
  refetchMainThread: () => Promise<
    QueryObserverResult<MainCommunicationConversation, unknown>
  >;
};

export const MainMessages = ({
  selectedPurchaseId,
  openBookingRequestAddQuoteDrawer,
  openBookingRequestViewQuoteDrawer,
  unsentQuoteAdded,
  setIsUnsentQuoteAdded,
  mainCommunicationThread,
  refetchMainThread,
}: MainMessagesProps) => {
  const theme = useTheme();
  const messageRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const isFirstRun = useRef(true);
  const [messageInputHeight, setMessageInputHeight] = useState(0);
  const [message, setMessage] = useState('');
  const { isBookingRequestEnabled } = useFeatureFlags();
  const isBookingRequest =
    mainCommunicationThread?.mainCommunicationCard.communicationPurchaseType ===
    CommunicationPurchaseType.BOOKING_REQUEST;

  const {
    data: bookingRequestLoaded,
    isLoading: isBookingRequestLoadedLoading,
    refetch: refetchBookingRequestLoaded,
  } = useBookingRequestLoaded(selectedPurchaseId, !!isBookingRequest);

  const isListingInDraftStatus =
    bookingRequestLoaded?.listing?.status === ListingStatus.DRAFT;

  const onSuccessCallback = () => {
    refetchMainThread();
    setMessage('');
    refetchBookingRequestLoaded();
  };
  const onMutationError = () => {};
  // TODO: add failure message to let user know if message couldn't be sent
  //  https://spinspire.monday.com/boards/3396817916/pulses/5358317101
  const { mutate: sendReply, isLoading: isLoadingCommunicationReply } =
    useCommunicationReply(onSuccessCallback);

  const { mutate: deleteQuote, isLoading: deleteQuoteLoading } =
    deleteQuoteMutation(
      {
        bookingRequestId: bookingRequestLoaded?.id || '',
      },
      onSuccessCallback,
      onMutationError
    );

  useEffect(() => {
    refetchBookingRequestLoaded();
  }, [unsentQuoteAdded]);

  useEffect(() => {
    if (!messageRef.current) {
      return;
    }
    const resizeObserver = new ResizeObserver(() => {
      if (
        messageRef.current &&
        messageRef.current.offsetHeight !== messageInputHeight
      ) {
        const style = window.getComputedStyle(messageRef.current);
        const margins =
          parseFloat(style.marginTop) + parseFloat(style.marginBottom);
        setMessageInputHeight(messageRef.current?.offsetHeight + margins || 0);
      }
    });

    resizeObserver.observe(messageRef.current);
    return () => resizeObserver.disconnect();
  }, [messageRef.current]);

  // Once mainCommunicationThread gets updated, the screen gets scrolled
  // to the last child of the messages list ref. It skips the first iteration
  useEffect(() => {
    if (isFirstRun.current && mainCommunicationThread) {
      isFirstRun.current = false;
      return;
    }

    const lastMessageInLastGroup =
      listRef.current?.lastElementChild?.lastElementChild;
    lastMessageInLastGroup?.scrollIntoView({ behavior: 'smooth' });
  }, [mainCommunicationThread]);

  if (
    !mainCommunicationThread ||
    !mainCommunicationThread.mainMessages.length
  ) {
    return null;
  }
  const communicationCard = mainCommunicationThread.mainCommunicationCard;

  const onMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const onSendReply = () => {
    sendReply({
      communicationPurchaseId: selectedPurchaseId,
      message,
      communicationPurchaseType: communicationCard.communicationPurchaseType,
    });
  };

  const onDeleteUnsentQuote = () => {
    // deleting unsent quote will delete the listing
    // consumer will not be advised
    deleteQuote();
    setIsUnsentQuoteAdded(false);
  };

  const messagesGroupedByDate = mainCommunicationThread.mainMessages.reduce(
    (acc, message) => {
      const date = formatDate(message.sentAt);
      return {
        ...acc,
        [date]: [...(acc[date] || []), message],
      };
    },
    // TODO: should be done in BE or is already done
    {} as { [date: string]: MainCommunicationMessage[] }
  );

  return (
    <Grid container item xs={8} alignContent="flex-start">
      <MainMessagesTitleGrid item xs={12}>
        <Typography variant="subtitle1">
          {communicationCard.consumerName}
        </Typography>
        <Typography variant="body2">
          {communicationCard.communicationPurchaseName} &middot;{' '}
          {communicationCard.communicationPurchaseType ===
          CommunicationPurchaseType.BOOKING
            ? pluralize(communicationCard.totalNumberOfConsumers, 'guest')
            : pluralize(
                communicationCard.totalNumberOfConsumers,
                'member'
              )}{' '}
          &middot;{' '}
          {communicationCard.communicationPurchaseType ===
            CommunicationPurchaseType.BOOKING &&
            formatDateForExperienceView(
              mainCommunicationThread.mainMessages[0].sentAt.toString() || ''
            )}
        </Typography>
      </MainMessagesTitleGrid>
      <Grid
        container
        item
        ref={listRef}
        xs={12}
        sx={{
          pt: 1,
          // 100vh is 100% of the screen height, subtract 128px for the
          // container padding, 64px is for the header of the messages,
          // 88px is for the navbar and 2px is for borders
          height: `calc(100vh - 128px - 88px - 64px - 2px - ${messageInputHeight}px)`,
          overflow: 'auto',
          alignContent: 'flex-start',
          borderBottom: `1px solid ${theme.palette.grey[400]}`,
        }}
      >
        {Object.keys(messagesGroupedByDate).map(date => {
          return (
            <Grid
              container
              item
              key={date}
              xs={12}
              sx={{
                alignItems: 'center',
                paddingX: 5,
                paddingY: 2,
                justifyContent: 'center',
              }}
            >
              <Typography
                color="primary.medium"
                variant="subtitle2"
                sx={{ pb: 4 }}
              >
                {date}
              </Typography>
              {messagesGroupedByDate[date].map((message, i) => (
                <MainMessageCard
                  key={`mainMessage_${message.communicationPurchaseId}_${i}`}
                  message={message}
                  mainCommunicationCard={communicationCard}
                  openBookingRequestViewQuoteDrawer={
                    openBookingRequestViewQuoteDrawer
                  }
                  isQuoteAdded={
                    bookingRequestLoaded?.listing?.id === message.listingId &&
                    bookingRequestLoaded?.status !==
                      BookingRequestStatus.ACCEPTED
                  }
                />
              ))}
            </Grid>
          );
        })}
      </Grid>
      <Grid
        container
        item
        sx={{ margin: theme.spacing(3, 5) }}
        ref={messageRef}
      >
        <Grid item xs={12}>
          <MessageTextField
            message={message}
            onMessageChange={onMessageChange}
          />
        </Grid>
        <StyledButtonsGrid
          container
          item
          xs={12}
          isBookingRequest={isBookingRequest && isBookingRequestEnabled}
        >
          {isBookingRequestEnabled &&
            isBookingRequest &&
            (isListingInDraftStatus ? (
              <Grid item>
                <Button
                  size="small"
                  sx={{
                    backgroundColor: theme.palette.success.faint,
                    color: ({ palette }) => palette.success.dark,
                    pointerEvents: 'none',
                  }}
                  endIcon={<SuccessIcon width={20} />}
                >
                  Quote attached
                </Button>
                <StyledLoadingButton
                  loading={isLoadingCommunicationReply || deleteQuoteLoading}
                  onClick={onDeleteUnsentQuote}
                  variant="text"
                  sx={{ minWidth: 'auto', ml: ({ spacing }) => spacing(1) }}
                >
                  <TrashIcon width={24} />
                </StyledLoadingButton>
              </Grid>
            ) : !bookingRequestLoaded?.listing ? (
              <Grid item>
                <StyledLoadingButton
                  loading={
                    isLoadingCommunicationReply ||
                    isBookingRequestLoadedLoading ||
                    deleteQuoteLoading
                  }
                  onClick={openBookingRequestAddQuoteDrawer}
                  variant="outlined"
                >
                  Attach quote
                </StyledLoadingButton>
              </Grid>
            ) : (
              <Grid item></Grid>
            ))}
          <Grid item>
            <StyledLoadingButton
              loading={isLoadingCommunicationReply || deleteQuoteLoading}
              onClick={() => setMessage('')}
              variant="text"
              disabled={!message}
            >
              Cancel
            </StyledLoadingButton>
            <StyledLoadingButton
              loading={isLoadingCommunicationReply || deleteQuoteLoading}
              onClick={onSendReply}
              variant="contained"
              disabled={!message}
            >
              Send
            </StyledLoadingButton>
          </Grid>
        </StyledButtonsGrid>
      </Grid>
    </Grid>
  );
};
