export enum PaymentStatementStatus {
  PAYMENT_PENDING,
  SUCCEEDED,
  FAILED,
}
// for FE: MerchantPaymentStatementType
// TODO: Deltas and expected information
export type MerchantPaymentStatement = {
  expectedIncome: number;
  expectedIncomeDelta: number;
  refunds: number;
  refundsDelta: number;
  bookingRate: number;
  bookingRateDelta: number;
  cancellationRate: number;
  cancellationRateDelta: number;
  statements: MerchantMonthlyPaymentStatement[];
};

export type MerchantMonthlyPaymentStatement = {
  id: number;
  billingCycle: string;
  startDate: Date;
  endDate: Date;
  totalExperiences: number;
  totalEvents: number;
  netEarnings: number;
  paymentDate: Date | null; //dueDate
  paymentStatementStatus: PaymentStatementStatus;
};
