import { PricingFormInput, PricingWithTaxesInput } from '@/classes';
import {
  Address,
  BookingMethod,
  BuildingSelect,
  Experience,
  ExperienceStatus,
  Listing,
  ListingStatus,
  PriceType,
  PurchaseMethods,
  SelectionType,
  TaxRate,
  UpdatePriceDto,
} from '@/types';
import { formatTo2DecimalPlaces, parseFloatIfString } from './commonUtils';

/**
 * Takes an experience object from the Merchandise Creation Form and formats it to be sent to the BE
 * @param merch An experience object being created in the Merchandise Creation Form
 * @returns experience that has the attributes/data types the BE expects
 */
// Disabling any due to current implementation of
// the BusinessFormContainer. To be refactored
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formatMerchandise = (merch: any) => {
  const newLocation = {
    name: `${merch.address?.city || merch.locationCity}, ${
      merch.address?.street || merch.locationState?.toUpperCase()
    }`,
    description: merch.description || merch.locationDescription,
    address: {
      city: merch.address?.city || merch.locationCity,
      street: merch.address?.street || merch.locationAddress,
      apartment: merch.address?.apartment || merch.locationApt,
      zipCode: merch.address?.zipCode || merch.locationZip,
      state: merch.address?.state || merch.locationState,
      country: 'USA',
    },
    url: 'www.testing.org',
    type: merch.inPerson ? 1 : 2,
    environmentType: merch.environmentType || merch.locationType?.value,
  };
  const newMerch = {
    title: merch.title,
    subtitle: merch.subtitle,
    internalName: merch.internalName,
    type: 'one-time-event',
    description: merch.description,
    category: {
      type: merch.category?.map((category: SelectionType) => category?.value),
      info: merch.subCategory
        ?.concat(merch.subCategory2)
        ?.map((subCategory: SelectionType) => subCategory?.value),
    },
    targetAudience: merch.targetAudience?.value || merch.targetAudience, // refactor!
    coverPhoto: merch.coverPhoto,
    photos: {
      photo1: merch.extraPhoto1,
      photo2: merch.extraPhoto2,
      photo3: merch.extraPhoto3,
      photo4: merch.extraPhoto4,
    },
    buildingIds: merch.buildingIds
      ? merch.buildingIds.map((building: SelectionType) =>
          building.id.toString()
        )
      : undefined,
    totalAmount: +merch.totalAmount,
    cancellationPolicy:
      merch.cancellationPolicy?.value ||
      merch.cancellationPolicy ||
      merch.cancelPolicy,
    duration: {
      hours: +(merch.durationHours?.value || merch.durationHours), // refactor!
      minutes: +(merch.durationMinutes?.value || merch.durationMinutes), // refactor!
    },
    inPerson: merch.inPerson,
    host: {
      operatingYears:
        merch.hostOperatingYears?.value || merch.hostOperatingYears, // refactor!
      bio: merch.hostBio,
      phoneNumber: merch.hostPhone,
      hostPhoto: merch.hostPhoto,
    },
    activityLevel: merch.activityLevel?.value || merch.activityLevel, // refactor!
    requiredSupply: {
      byHost: merch.requiredByHost,
      byGuest: merch.requiredByGuest,
    },
    capacity: {
      min: merch.capacityMin?.value || +merch.capacityMin, // refactor
      max: merch.capacityMax?.value || +merch.capacityMax, // refactor!
    },
    agreement: {
      type: merch.hostAgreement || 'default',
      status: 'signed',
    },
    generalAvailability: {
      // Disabling any due to current implementation of
      // the BusinessFormContainer. To be refactored
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      days: merch.generalDays?.map((day: any) => day.value), // refactor!
      time: merch.generalTime?.value, // refactor!
    },
    acceptedPurchaseMethods: merch.acceptedPurchaseMethods,
    groupBookingsAllowed: merch.groupBookingsAllowed,
    taxRateId: merch.taxRateId,
  };
  return { newLocation, newMerch };
};

export type GetPhotosObjectType = {
  file: File;
  objectKey: string;
  newBusinessKey: string;
};

/**
 * Goes through object to find any key that includes 'photo' and returns
 * a new object with the File value and its path to original object.
 *
 * @param data Object to find photos in
 * @returns Returns a new object with File and path to original object
 */
export const getPhotosObject = (
  // Disabling any due to current implementation of
  // the BusinessFormContainer. To be refactored
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
): Record<string, GetPhotosObjectType> => {
  const photos: Record<string, GetPhotosObjectType> = {};
  Object.keys(data).forEach(key => {
    if (key.toLowerCase().includes('photo') && data[key] instanceof File) {
      photos[key] = { file: data[key], objectKey: key, newBusinessKey: key };
    } else {
      Object.keys(data[key] || {}).forEach(subKey => {
        if (
          subKey.toLowerCase().includes('photo') &&
          data[key][subKey] instanceof File
        ) {
          photos[subKey] = {
            file: data[key][subKey],
            objectKey: `${key}.${subKey}`,
            newBusinessKey: /\d/.test(subKey)
              ? `extra${subKey.charAt(0).toUpperCase()}${subKey.substring(1)}`
              : subKey,
          };
        }
      });
    }
  });
  return photos;
};

/**
 * Formats pricing with currency
 * @param price Price value in number
 * @param decimalPlaces Min number of decimal places
 * @returns Formatted pricing
 */
export const formatPricing = (price: number, decimalPlaces = 0) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces || 2,
  }).format(price);
};

/**
 * Formats phone number to USA standard: (xxx)xxx-xxxx
 * We're making assumptions about the numbers entered in because they've had to pass thru `
 * @param phoneNumber unformatted phone number, could be 9 or 10 digits
 * @returns Formatted phone number
 */
export const formatPhoneNumber = (phoneNumber: string) => {
  let justDigitsNumber = phoneNumber.replace(/\D/g, ''); // strip all previous formatting, in case it's incorrect
  if (justDigitsNumber.length > 11 || justDigitsNumber.length < 10) {
    return 'incorrect number';
  } //reject numbers that are too short or long
  if (justDigitsNumber.length === 11 && justDigitsNumber[0] === '1') {
    justDigitsNumber = justDigitsNumber.slice(1);
  } // remove leading 1, if it exists
  const areaCode = justDigitsNumber.substring(0, 3);
  const officeCode = justDigitsNumber.substring(3, 6);
  const lineNumber = justDigitsNumber.substring(6, 10);
  return `(${areaCode})${officeCode}-${lineNumber}`;
};

/**
 * Formats experience duration
 * @param hours Experience duration hours,
 * @param minutes Experience duration minutes
 * @returns Length of experience in readable English
 */
export const formatExperienceDuration = ({
  hours,
  minutes,
}: {
  hours?: number | null;
  minutes?: number | null;
}) => {
  const formattedHours =
    hours && hours >= 1 ? `${hours} hour${hours > 1 ? 's' : ''}` : '';
  const formattedMinutes = minutes && minutes !== 0 ? `${minutes} minutes` : '';

  if (!!formattedHours && !!formattedMinutes) {
    return `${formattedHours}, ${formattedMinutes}`;
  } else {
    return formattedHours || formattedMinutes;
  }
};

/**
 * This method compares two Addresses and returns true if they're not identical
 *
 * @param firstAddress first address to compare
 * @param secondAddress second address to compare
 * @returns boolean value
 */
export const isAddressDifferent = (
  firstAddress?: Address,
  secondAddress?: Address
) => {
  if (!firstAddress || !secondAddress) {
    return false;
  }
  return Object.keys(firstAddress).some(
    key =>
      firstAddress[key as keyof Address] !== secondAddress[key as keyof Address]
  );
};

/**
 * Verify if an Experience Status is active ie has been approved and not rejected
 * @param status Experience Status | undefined
 * @returns Boolean
 */
export const isActiveExperienceStatus = (
  status: ExperienceStatus | undefined
): Boolean =>
  status === ExperienceStatus.Approved || status === ExperienceStatus.Published;

/**
 * Formats the value to a percent style
 *
 * @param value number (i.e. 12)
 * @param decimalPlaces number defaults to 0
 * @returns returns the formatted value (i.e. 12%)
 */
export const formatPercentage = (value?: number, decimalPlaces = 0): string => {
  if (!value) {
    return '';
  }
  return new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces || 2,
  }).format(value / 10000);
};

/**
 * Calculates the price using total amount or total amount using the price with platform fee
 * @param value total amount
 * @param platformFee fee percentage
 * @param isCalculatingTotalAmount when false, it calculates price from total amount
 * otherwise when true, it calculates the total amount using the price. Defaults to false
 * @returns formatted value
 */
export const calculatePriceWithPlatformFee = (
  value?: number | string,
  platformFee?: number,
  isCalculatingTotalAmount = false
) => {
  if (
    !value ||
    platformFee === undefined ||
    (typeof value === 'string' && isNaN(parseFloat(value)))
  ) {
    return '';
  }
  const numValue =
    typeof value === 'string' ? parseFloat(value.replace(',', '')) : value;

  let finalValue = 0;
  if (!isCalculatingTotalAmount) {
    finalValue = numValue / (1 + platformFee / 10000);
  } else {
    finalValue = (1 + platformFee / 10000) * numValue;
  }

  return finalValue.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
};

/**
 * Ensures a listing should be editable by making sure it's in the future and not canceled
 * @param listing the listing to check
 * @returns true or false
 */
export const isListingModifiable = (listing?: Listing): boolean => {
  if (!listing) {
    return false;
  }
  const today = new Date();
  const listingStartDate = new Date(listing.startsAt);
  return today < listingStartDate &&
    listing.status !== ListingStatus.MERCHANT_CANCELLED
    ? true
    : false;
};

/**
 * Transforms string array of building ids into building labels separated by comma
 * @param allBuildingIdOptions All valid building id options
 * @param buildingIds string array of building ids
 * @returns formatted string
 */
export const formatBuildingIdOptions = (
  allBuildingIdOptions: BuildingSelect[] | undefined,
  buildingIds: Experience['buildingIds']
) => {
  return allBuildingIdOptions
    ? allBuildingIdOptions
        .filter(building =>
          Object.values(buildingIds!).includes(building.id.toString())
        )
        .map(x => x.name)
        .join(', ') || 'No Permitted Audience Selected'
    : 'No Permitted Audience Selected';
};

/**
 * Transforms string array of building ids into SelectionType array
 * @param allBuildingIdOptions All valid building id options
 * @param buildingIds string array of building ids
 * @returns SelectionType array
 */
export const extractSelectedBuildingIdOptions = (
  allBuildingIdOptions: BuildingSelect[] | undefined,
  buildingIds: Experience['buildingIds']
) => {
  return buildingIds && allBuildingIdOptions
    ? allBuildingIdOptions.filter(building =>
        Object.values(buildingIds!).includes(building.id.toString())
      )
    : [];
};

/**
 * Filter array of experiences, return only those which can be scheduled/edited
 * @param experiences a list of experiences
 * @param statuses a list of statuses to filter by
 * @returns Experience array
 */
export const filterExperienceByStatus = (
  experiences: Experience[],
  statuses: ExperienceStatus[]
) => {
  return experiences?.filter(experience =>
    (statuses || []).includes(experience.status)
  );
};

/**
 * Filters all Experiences, removes Adverts, and sorts them
 * in preparation of the Calendar side view
 * @param experiences
 * @param statuses
 * @returns Experience[]
 */
export const filterAndSortCalendarViewExperience = (
  experiences: Experience[],
  statuses: ExperienceStatus[]
) => {
  return filterExperienceByStatus(experiences, statuses)
    .filter(x => !x.bookingMethods?.every(b => b === BookingMethod.ADVERT))
    .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
};

export const mapTaxRateOptions = (
  taxRates: TaxRate[],
  withLongDescription = true
) => {
  return taxRates.map(taxRate => {
    return {
      id: taxRate.id,
      value: taxRate.id,
      label: `${withLongDescription ? `${taxRate.displayName} - ` : ''}${
        taxRate.percentage
      }%`,
    };
  });
};

/**
 * Evaluates whether form data is of type PricingFormInput or not
 * @param data One of the validator classes for product updates. I.E. PricingFormInput
 * @returns boolean
 */
export const isFormSubmitOfTypePricingFormInput = (
  data: PricingFormInput
): boolean => {
  return !!data ? !!data.prices : false;
};

export const PricingWithTaxesInputToUpdateProductPricingDto = (
  prices: PricingWithTaxesInput[]
): UpdatePriceDto[] => {
  return prices.map(price => ({
    ...price,
    amount: parseFloatIfString(price.amount),
    taxRateId: parseFloatIfString(price.taxRateId),
    priceType: price.priceType || PriceType.PRICE_PER_PERSON,
  }));
};

/**
 * Calculates platform fee from total price using the platform fee percentage
 * @param feePercentage // fee percentage in format of 100 => 1%
 * @param totalPrice
 * @returns Platform fee in dollars
 */
export const calculatePlatformFeeInDollars = (
  feePercentage: number,
  totalPrice: number
) => {
  return (feePercentage / 10000) * totalPrice;
};

export type CalculatePriceBreakdownProps = {
  price: number;
  feePercentage?: number;
  taxRate?: TaxRate | null;
};

export type CalculatePriceBreakdown = {
  guestPrice: number; // total price that consumer see - includes tax & fee
  taxAmount: number;
  platformFee: number;
  netAmount: number;
};

/**
 * Calculates price breakdown
 * @param CalculatePriceBreakdownProps
 * @returns Breakdown object with guestPrice, taxAmount, platformFee and totalAmount
 */
export const calculatePriceBreakdown = ({
  feePercentage,
  price, // inputed amount / totalAmount /  subtotal => excluding tax & discount
  taxRate,
}: CalculatePriceBreakdownProps): CalculatePriceBreakdown => {
  const taxAmount = taxRate
    ? formatTo2DecimalPlaces((price * taxRate.percentage) / 100)
    : 0;
  const guestPrice = price + taxAmount;
  const platformFee = feePercentage
    ? calculatePlatformFeeInDollars(feePercentage, price)
    : 0;
  const netAmount = guestPrice - taxAmount - platformFee;

  return {
    guestPrice, // total price that consumer see - includes tax & fee
    platformFee,
    taxAmount,
    netAmount,
  };
};

/**
 * Evaluates whether an experience is of type BookingRequest
 * @param experience Experience
 * @returns boolean
 */
export const evaluateIsExperienceBookingRequest = (experience: Experience) =>
  experience.bookingMethods?.includes(BookingMethod.REQUEST) || false;

/**
 * Evaluates if a price in experience is members exclusive
 * @param acceptedMethods PurchaseMethods[]
 * @returns boolean
 */
export const evaluateIsPriceMembersExclusive = (
  acceptedMethods?: PurchaseMethods[]
) => acceptedMethods?.includes(PurchaseMethods.SUBSCRIPTION) || false;
