import {
  Box,
  Stack,
  Typography,
  Button,
  InputField,
  Grid,
  Container,
  useTheme,
  MessageBanner,
} from '@silverstein-properties/inspirelabs-ui';
import { ApiClient } from '@/api/apiClient';
import { Controller, useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { useEffect } from 'react';
import { useAuth, useMerchant, useUpdateMerchantBusinessProfile } from 'hooks';
import MiniFileInput from '@/components/FormTextInput/MiniFileInput';
import { omit } from 'lodash';
import { MerchantBusinessProfileInput } from '@/classes/MerchantBusinessProfileInput';
import { TEXT_AREA_LIMIT_LONG } from '@/constants';
import { deepObjDiff, deepOmitNil } from '@/utils';
import { UserRole } from '@/types';
import { validateRolesByMerchantId } from '@/utils/userUtils';

const resolver = classValidatorResolver(MerchantBusinessProfileInput);

export const MerchantBusinessProfileModule = () => {
  const theme = useTheme();
  const apiClient = ApiClient();

  const { data: user } = useAuth();
  const { data: merchantProfile } = useMerchant();
  const {
    mutate: updateBusinessProfile,
    isLoading,
    isError,
    isSuccess,
    reset: resetUpdateBusinessProfile,
  } = useUpdateMerchantBusinessProfile();

  const isUserWriteAccess = validateRolesByMerchantId(
    user,
    [UserRole.OWNER, UserRole.ADMIN, UserRole.SP_INTERNAL],
    user?.primaryMerchantId
  );

  const {
    control,
    getValues,
    setValue,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<MerchantBusinessProfileInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      photo: merchantProfile?.photo || '',
      name: merchantProfile?.name || '',
      websiteUrl: merchantProfile?.websiteUrl || '',
      email: merchantProfile?.email || '',
      mainAddress: {
        street: merchantProfile?.mainAddress?.street || '',
        apartment: merchantProfile?.mainAddress?.apartment || '',
        city: merchantProfile?.mainAddress?.city || '',
        state: merchantProfile?.mainAddress?.state || '',
        zipCode: merchantProfile?.mainAddress?.zipCode || '',
      },
      description: merchantProfile?.description || '',
      linkedinUrl: merchantProfile?.linkedinUrl || '',
      facebookUrl: merchantProfile?.facebookUrl || '',
    },
  });

  useEffect(() => {
    reset({
      photo: merchantProfile?.photo || '',
      name: merchantProfile?.name || '',
      websiteUrl: merchantProfile?.websiteUrl || '',
      email: merchantProfile?.email || '',
      mainAddress: {
        street: merchantProfile?.mainAddress?.street || '',
        apartment: merchantProfile?.mainAddress?.apartment || '',
        city: merchantProfile?.mainAddress?.city || '',
        state: merchantProfile?.mainAddress?.state || '',
        zipCode: merchantProfile?.mainAddress?.zipCode || '',
      },
      description: merchantProfile?.description || '',
      linkedinUrl: merchantProfile?.linkedinUrl || '',
      facebookUrl: merchantProfile?.facebookUrl || '',
    });
  }, [merchantProfile]);

  if (!merchantProfile) {
    // onloading
    return <Typography variant="h6">Loading...</Typography>;
  }

  const onSubmit = async (data: MerchantBusinessProfileInput) => {
    if (!isUserWriteAccess) {
      return;
    }
    const { newChanges } = deepObjDiff(merchantProfile, data);
    const mainAddOmit = deepOmitNil(newChanges.mainAddress);

    const updateMerchantProfile =
      Object.keys(mainAddOmit).length > 0
        ? {
            ...newChanges,
            mainAddress: deepOmitNil(newChanges.mainAddress),
          }
        : omit(newChanges, 'mainAddress');

    updateBusinessProfile({
      merchantId: merchantProfile.id,
      updateMerchantProfile: updateMerchantProfile,
    });
  };

  const onPhotoChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      // error in target file
      return;
    }

    const photoFile = e.target.files[0];
    const submittedPhoto = await apiClient.images.uploadImage({
      fileName: `merchant_profile_${+new Date()}`,
      photo: photoFile,
      productId: '',
    });

    setValue('photo', submittedPhoto);
  };

  const onInputChange = (
    e: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (
      e: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    ) => void
  ) => {
    resetUpdateBusinessProfile();
    onChange(e);
  };

  return (
    <Container
      sx={{
        paddingY: theme.spacers.xxl4,
      }}
      maxWidth="sm"
    >
      <Stack spacing={4} ml="auto">
        <Typography variant="h4">My business profile</Typography>
        <Typography variant="h5">Basic Information</Typography>
        {!!Object.keys(errors).length && (
          <MessageBanner type="error">
            Please fix the errors below.
          </MessageBanner>
        )}
        {isError && (
          <MessageBanner type="error">Could not add team member</MessageBanner>
        )}
        {isSuccess && (
          <MessageBanner type="success">
            Your business profile has been updated
          </MessageBanner>
        )}
        <form id="userBusinessProfileForm" onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={4}>
            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 2,
                mt: 1,
              }}
            >
              <Controller
                name="photo"
                control={control}
                render={({ field }) => (
                  <MiniFileInput
                    {...omit(field, 'ref')}
                    keyNumber="1"
                    handleChange={e => {
                      onPhotoChange(e);
                      resetUpdateBusinessProfile();
                    }}
                    disableUpload={!isUserWriteAccess}
                  />
                )}
              />
              {isUserWriteAccess && (
                <Typography color={theme.palette.primary.main} variant="body2">
                  Please provide a logo of your business (optional)
                </Typography>
              )}
            </Grid>
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <InputField
                  {...field}
                  readOnly={!isUserWriteAccess}
                  ref={null}
                  label="Company Name"
                  fullWidth
                  helperText={errors.name?.message}
                  error={!!errors.name}
                  onChange={e => onInputChange(e, field.onChange)}
                />
              )}
            />
            <Controller
              control={control}
              name="websiteUrl"
              render={({ field }) => (
                <InputField
                  {...field}
                  readOnly={!isUserWriteAccess}
                  ref={null}
                  label="Website URL"
                  fullWidth
                  helperText={errors.websiteUrl?.message}
                  error={!!errors.websiteUrl}
                  onChange={e => onInputChange(e, field.onChange)}
                />
              )}
            />
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <InputField
                  {...field}
                  readOnly={!isUserWriteAccess}
                  ref={null}
                  label="Support Email Address"
                  fullWidth
                  helperText={errors.email?.message}
                  error={!!errors.email}
                  onChange={e => onInputChange(e, field.onChange)}
                />
              )}
            />
            <Typography variant="h5">Location</Typography>
            <span
              className="inline-group"
              style={{ marginTop: '32px', flexGrow: '3' }}
            >
              <Controller
                control={control}
                name="mainAddress.street"
                render={({ field }) => (
                  <InputField
                    {...field}
                    readOnly={!isUserWriteAccess}
                    ref={null}
                    label="Address"
                    type="text"
                    fullWidth
                    helperText={errors.mainAddress?.street?.message}
                    error={!!errors.mainAddress?.street}
                    onChange={e => onInputChange(e, field.onChange)}
                  />
                )}
              />
              <Controller
                control={control}
                name="mainAddress.apartment"
                render={({ field }) => (
                  <InputField
                    {...field}
                    readOnly={!isUserWriteAccess}
                    ref={null}
                    label="Apt./Fl."
                    type="text"
                    fullWidth={false}
                    onChange={e => onInputChange(e, field.onChange)}
                  />
                )}
              />
            </span>

            <span
              className="inline-group"
              style={{ marginTop: '32px', flexGrow: '1' }}
            >
              <Controller
                control={control}
                name="mainAddress.city"
                render={({ field }) => (
                  <InputField
                    {...field}
                    readOnly={!isUserWriteAccess}
                    ref={null}
                    label="City"
                    type="text"
                    fullWidth
                    helperText={errors.mainAddress?.city?.message}
                    error={!!errors.mainAddress?.city}
                    onChange={e => onInputChange(e, field.onChange)}
                  />
                )}
              />

              <Controller
                control={control}
                name="mainAddress.state"
                render={({ field }) => (
                  <InputField
                    {...field}
                    readOnly={!isUserWriteAccess}
                    ref={null}
                    label="State"
                    type="text"
                    fullWidth={false}
                    helperText={errors.mainAddress?.state?.message}
                    error={!!errors.mainAddress?.state}
                    onChange={e => onInputChange(e, field.onChange)}
                  />
                )}
              />

              <Controller
                control={control}
                name="mainAddress.zipCode"
                render={({ field }) => (
                  <InputField
                    {...field}
                    readOnly={!isUserWriteAccess}
                    ref={null}
                    label="Zipcode"
                    type="text"
                    fullWidth={false}
                    helperText={errors.mainAddress?.zipCode?.message}
                    error={!!errors.mainAddress?.zipCode}
                    onChange={e => onInputChange(e, field.onChange)}
                  />
                )}
              />
            </span>

            <Typography variant="h5">About</Typography>

            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <InputField
                  {...field}
                  readOnly={!isUserWriteAccess}
                  ref={null}
                  multiline
                  minRows={6}
                  fullWidth
                  label="Description"
                  error={!!errors.description}
                  helperText={
                    errors.description
                      ? errors.description.message
                      : `${
                          getValues('description')
                            ? getValues('description').length
                            : 0
                        } / ${TEXT_AREA_LIMIT_LONG}`
                  }
                  onChange={e => onInputChange(e, field.onChange)}
                />
              )}
            />
            <Typography variant="h5">Social</Typography>
            <Controller
              control={control}
              name="linkedinUrl"
              render={({ field }) => (
                <InputField
                  readOnly={!isUserWriteAccess}
                  {...field}
                  ref={null}
                  label="Linkedin Url"
                  fullWidth
                  helperText={errors.linkedinUrl?.message}
                  error={!!errors.linkedinUrl}
                  onChange={e => onInputChange(e, field.onChange)}
                />
              )}
            />
            <Controller
              control={control}
              name="facebookUrl"
              render={({ field }) => (
                <InputField
                  readOnly={!isUserWriteAccess}
                  {...field}
                  ref={null}
                  label="Facebook Url"
                  fullWidth
                  helperText={errors.facebookUrl?.message}
                  error={!!errors.facebookUrl}
                  onChange={e => onInputChange(e, field.onChange)}
                />
              )}
            />
          </Stack>
          {isUserWriteAccess && (
            <Box sx={{ alignSelf: 'flex-end' }}>
              <Button type="submit" disabled={isLoading || isSuccess}>
                {isSuccess ? 'Changes Saved' : 'Save Changes'}
              </Button>
            </Box>
          )}
        </form>
      </Stack>
    </Container>
  );
};
