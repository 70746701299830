import { AxiosInstance } from 'axios';
import { useFeatureFlags } from '@/hooks';

export const endpoints = {
  images: '/images',
};

export const images = ({ axiosInstance }: { axiosInstance: AxiosInstance }) => {
  const { isMediaServiceEnabled } = useFeatureFlags();
  return {
    uploadImage: async ({
      fileName,
      photo,
      productId,
    }: {
      fileName: string;
      photo: File;
      productId?: string;
    }): Promise<string> => {
      const formData = new FormData();
      formData.append('file', photo);
      const { data } = await axiosInstance.post(endpoints.images, formData, {
        params: {
          fileName,
          productId,
        },
      });

      return !isMediaServiceEnabled
        ? `${data.Location}?etag=${data.Etag}`
        : data?.url;
    },
  };
};
