import { Box, styled } from '@silverstein-properties/inspirelabs-ui';

export const StyledTodoContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

export const StyledEmptyState = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  padding: theme.spacing(3),
  paddingLeft: '25%',
  paddingRight: '25%',
  borderRadius: theme.spacing(1),
  width: '100%',
}));
