import './roundButton.css';
import { useHooks } from './useHooks';

export interface ButtonProps {
  text: string;
  default: string;
  hover: string;
  handleClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  form?: string;
}

export default function HookFormSubmitButton(props: ButtonProps): JSX.Element {
  const { hover, toggleHover } = useHooks();
  return (
    <button
      onMouseEnter={toggleHover}
      onFocus={toggleHover}
      onMouseLeave={toggleHover}
      onBlur={toggleHover}
      type="submit"
      value={props.text}
      className={
        props.disabled
          ? 'button disabled-button'
          : !hover
          ? `button ${props.default}`
          : `button ${props.hover}`
      }
      disabled={props.disabled}
      form={props.form}
    >
      <span className="label">{props.text}</span>
    </button>
  );
}
