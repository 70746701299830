import { Dialog, DialogActions } from '@mui/material';
import {
  FormControlLabel,
  styled,
} from '@silverstein-properties/inspirelabs-ui';

export const DialogBox = styled(Dialog)({
  textAlign: 'center',
  alignItems: 'center',
});

export const StyledDialogBoxInternal = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(5),
}));

export const StyledImage = styled('img')({
  width: '100%',
});

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(2),
}));

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginTop: theme.spacing(1),
  textAlign: 'left',
}));
