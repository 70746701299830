import InProgress from './images/in_progress.svg';
import FullCheckmark from './images/checkmark.svg';
import GrayCheckMark from './images/gray_checkmark.svg';
import {
  MembershipCategory,
  MembershipCategorySubItem,
  MenuProgressStatus,
} from '@/hooks/useMembershipCreationHooks';

interface SubItemProps {
  subItemIndex: number;
  category: MembershipCategory;
  categorySubItem: MembershipCategorySubItem;
  changePage: (
    sectionId: number,
    subItem: number,
    finished: MenuProgressStatus
  ) => void;
  currentSection: number;
  currentSubItem: number;
}

const iconMapperToProgressStatus = {
  1: GrayCheckMark,
  2: InProgress,
  3: FullCheckmark,
};

export const SubItem = ({
  subItemIndex,
  category,
  categorySubItem,
  changePage,
  currentSection,
  currentSubItem,
}: SubItemProps) => {
  const finished =
    subItemIndex < currentSubItem
      ? MenuProgressStatus.Finished
      : subItemIndex > currentSubItem
      ? MenuProgressStatus.Locked
      : MenuProgressStatus.InProgress;

  let isCurrentPage = false;
  if (
    currentSection === category.sectionId &&
    currentSubItem === subItemIndex
  ) {
    isCurrentPage = true;
  }

  return (
    <div className={`sub-item ${isCurrentPage ? 'current' : ''}`}>
      <div className="sub-item-inner-frame">
        <span
          className="sub-item-label"
          onClick={() => changePage(category.sectionId, subItemIndex, finished)}
        >
          {categorySubItem.name}
        </span>
        <img
          src={iconMapperToProgressStatus[finished]}
          height="24px"
          width="24px"
          alt="icon"
          style={{ marginTop: '16px', marginLeft: '-10px', opacity: '60%' }}
        />
      </div>
    </div>
  );
};
