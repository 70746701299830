import { TEXT_AREA_LIMIT_SHORT } from '@/constants';
import { Experience } from '@/types';
import { billingCycleMap, contractLengthMap } from '@/utils/membershipUtils';
import { IsCurrency, IsNotEmpty, IsOptional, MaxLength } from 'class-validator';
import { PriceMinValue } from './validations/PriceMinValue';

export class TitleAndDescriptionInput {
  @MaxLength(TEXT_AREA_LIMIT_SHORT, { message: 'Please enter a shorter title' })
  @IsNotEmpty({ message: 'Please enter a title' })
  membershipTitle: string;

  @IsNotEmpty({ message: 'Please enter a description' })
  membershipDescription: string;
}

export class MembershipPlanInput {
  @MaxLength(TEXT_AREA_LIMIT_SHORT, { message: 'Please enter a shorter title' })
  @IsNotEmpty({ message: 'Please enter a title' })
  membershipPlanTitle: string;

  @IsCurrency(
    { allow_decimal: true, digits_after_decimal: [0, 1, 2] },
    { message: 'Please enter a valid price' }
  )
  @IsNotEmpty({ message: 'Please enter a price' })
  @PriceMinValue(0, { message: 'Please enter a valid price' })
  membershipPlanPrice: string;

  @IsNotEmpty({ message: 'Please enter quantity' })
  memberQuantity: string;

  @IsNotEmpty({ message: 'Please select a billing type' })
  isAutoRenew: string;

  @IsNotEmpty({ message: 'Please select a contract length' })
  contractLength: keyof typeof contractLengthMap;

  @IsNotEmpty({ message: 'Please select a billing cycle' })
  billingCycle: keyof typeof billingCycleMap;

  @IsOptional()
  taxRateId?: number;

  // @ArrayNotEmpty({ message: 'Please select some products' })
  membershipProducts: Experience[];
  // membershipProducts is required for the "next" button to be displayed
  // this isn't properly referenced in the plans.tsx
  // because it's not in a controller block
  // a useState is also set for error management of membershipProducts
}
