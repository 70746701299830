import { Price, UpdateProductPricesDto } from '@/types';
import { AxiosInstance } from 'axios';

export const endpoints = {
  updateProductPrices({ id }: { id: string }) {
    return `/purchasables/products/${id}/prices`;
  },
};

export const purchasables = ({
  axiosInstance,
}: {
  axiosInstance: AxiosInstance;
}) => {
  return {
    updateProductPrices: async ({
      id,
      prices,
    }: UpdateProductPricesDto): Promise<Price[]> => {
      const { data } = await axiosInstance.put(
        endpoints.updateProductPrices({ id }),
        { prices: prices }
      );
      return data;
    },
  };
};
