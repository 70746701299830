import { BusinessSignupComponentProps } from './Component';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useFormData } from '../../contexts/MerchCreationProvider';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import {
  Box,
  Button,
  InputField,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import { BackButton } from '../BackButton';
import { TitleAndInternalNameInput } from '@/classes/BusinessFormContainerInput';
import { TEXT_AREA_LIMIT_SHORT } from '@/constants';
import { renderInputFieldHelperText } from '@/utils';

const resolver = classValidatorResolver(TitleAndInternalNameInput);

export function ServiceTitle(props: BusinessSignupComponentProps) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const theme = useTheme();
  const { setFormValues, merchandiseData } = useFormData();
  const {
    formState: { errors },
    handleSubmit,
    control,
    watch,
  } = useForm<TitleAndInternalNameInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      title: merchandiseData.title || '',
      internalName: merchandiseData.internalName || '',
    },
  });

  const onSubmit = (data: TitleAndInternalNameInput) => {
    setFormValues(data);
    props.partiallyCreateExperience(data);
    props.nextPage();
  };

  return (
    <div className="row ms-xl-5 ms-lg-5 me-md-5 ms-sm-1 ms-xs-1 top-64 d-flex justify-content-end">
      <div className="col-8">
        <h4 className="business-form-headline mb-5">
          Add a title to your experience
        </h4>
        <div className="theme-form">
          <div className="business-form-subtitle">
            Make it descriptive and unique so participants will understand your
            offering
          </div>
          <Box mt={2.5} component="form" onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <InputField
                  {...field}
                  label="Add a Title"
                  inputProps={{
                    'data-testid': 'serviceTitle.title',
                  }}
                  error={!!errors.title}
                  helperText={renderInputFieldHelperText(
                    watch('title')?.length,
                    TEXT_AREA_LIMIT_SHORT,
                    errors.title?.message
                  )}
                />
              )}
            />

            <Controller
              name="internalName"
              control={control}
              render={({ field }) => (
                <InputField
                  {...field}
                  label="Internal Name"
                  inputProps={{
                    'data-testid': 'serviceTitle.internalName',
                  }}
                  error={!!errors.internalName}
                  helperText={
                    errors.internalName?.message ??
                    "Optional. This won't be visible to your guests."
                  }
                />
              )}
            />

            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: theme.spacers.xl,
              }}
            >
              <BackButton onClick={props.prevPage} />
              <Button type="submit">Continue</Button>
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
}
