import { SelectionType } from '@/types';
import { Type } from 'class-transformer';
import {
  IsNotEmpty,
  IsOptional,
  ValidateIf,
  ValidateNested,
} from 'class-validator';

export class RequiredSupplyInput {
  @IsOptional()
  byHost?: string;

  @IsOptional()
  byGuest?: string;
}

export class RequirementsSectionEditInput {
  @IsNotEmpty({ message: 'Please select a valid target audience' })
  targetAudience: string | SelectionType;

  @ValidateIf(o => o.isExperienceBookingRequest === false)
  @IsNotEmpty({ message: 'Please select a valid activity level' })
  activityLevel?: string | SelectionType;

  @IsOptional()
  @ValidateNested()
  @Type(() => RequiredSupplyInput)
  requiredSupply?: RequiredSupplyInput;

  isExperienceBookingRequest?: boolean;
}
