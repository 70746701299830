import { FieldValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { InputChangeEvent } from '../../types';
import './roundButton.css';
import { useHooks } from './useHooks';

export type ButtonProps = {
  text: string;
  default: string;
  hover: string;
  selected: boolean;
  value: string;
  name: string;
  handleClick?: (e: InputChangeEvent) => void;
  register?: UseFormRegister<FieldValues>;
  setValue?: UseFormSetValue<FieldValues>;
};

export default function FilterButton({
  default: defaultValue,
  hover: propsHover,
  name,
  selected,
  text,
  value,
  handleClick,
  register,
  setValue,
}: ButtonProps): JSX.Element {
  const { hover, toggleHover } = useHooks();
  if (register) {
    return (
      <button
        {...register(name, { onBlur: toggleHover })}
        id={value}
        onMouseEnter={toggleHover}
        onFocus={toggleHover}
        onMouseLeave={toggleHover}
        onClick={() => {
          setValue && setValue(name, value);
          handleClick && handleClick({ key: name, value });
        }}
        className={
          selected
            ? 'f-button filter-button-selected'
            : !hover
            ? `f-button ${defaultValue}`
            : `f-button ${propsHover}`
        }
        value={value}
        type="button"
      >
        <span className="label">{text}</span>
      </button>
    );
  }

  return (
    <button
      name={name}
      id={value}
      onMouseEnter={toggleHover}
      onFocus={toggleHover}
      onMouseLeave={toggleHover}
      onBlur={toggleHover}
      onClick={() => handleClick && handleClick({ key: name, value })}
      className={
        selected
          ? 'f-button filter-button-selected'
          : !hover
          ? `f-button ${defaultValue}`
          : `f-button ${propsHover}`
      }
      value={value}
      type="button"
    >
      <span className="label">{text}</span>
    </button>
  );
}
