import {
  Box,
  InputField,
  Select,
  Stack,
  Typography,
} from '@silverstein-properties/inspirelabs-ui';
import {
  RadioGroup,
  Radio,
  FormControlLabel,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { Experience } from '@/types';
import { LabelField } from '@/components';
import { TEXT_AREA_LIMIT_LONG } from '@/constants';
import { AUDIENCE_CHOICES, ACTIVITY_CHOICES } from '@/constants';
import { RequirementsSectionEditInput } from '@/classes/RequirementSectionEditInput';
import {
  evaluateIsExperienceBookingRequest,
  renderInputFieldHelperText,
} from '@/utils';

const resolver = classValidatorResolver(RequirementsSectionEditInput);

export type RequirementsSectionEditPropsType = {
  experience: Experience;
  onFormSubmit: (data: RequirementsSectionEditInput) => Promise<void>;
};

export const RequirementsSectionEdit = ({
  experience,
  onFormSubmit,
}: RequirementsSectionEditPropsType) => {
  const isExperienceBookingRequest =
    evaluateIsExperienceBookingRequest(experience);
  const {
    watch,
    control,
    resetField,
    formState: { errors },
    handleSubmit,
  } = useForm<RequirementsSectionEditInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      targetAudience: experience.targetAudience,
      activityLevel: experience.activityLevel,
      requiredSupply: {
        byHost: experience?.requiredSupply?.byHost || '',
        byGuest: experience?.requiredSupply?.byGuest || '',
      },
      isExperienceBookingRequest,
    },
  });

  const [hostSupply, setHostSupply] = useState<boolean>(
    !!experience?.requiredSupply?.byHost
  );
  const [guestSupply, setGuestSupply] = useState<boolean>(
    !!experience?.requiredSupply?.byGuest
  );

  //if user previously had a requirement, but then clicks 'no', clear the requirements field
  useEffect(() => {
    if (!hostSupply) {
      resetField('requiredSupply.byHost', { defaultValue: '' });
    }
    if (!guestSupply) {
      resetField('requiredSupply.byGuest', { defaultValue: '' });
    }
  }, [hostSupply, guestSupply]);

  return (
    <Stack spacing={4}>
      <Typography variant="h5">Requirements</Typography>
      <form id="requirementsForm" onSubmit={handleSubmit(onFormSubmit)}>
        <Stack spacing={4}>
          <LabelField
            label="Target Audience"
            value={
              <Box sx={{ mt: 2 }}>
                <Controller
                  name="targetAudience"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        data-testid="targetAudience"
                        displayEmpty={true}
                        error={!!errors.targetAudience}
                        renderValue={selected => {
                          if (!selected) {
                            return 'Select Target Audience';
                          }
                          const selectedTargetAudience = AUDIENCE_CHOICES.find(
                            targetAudience => targetAudience.value === selected
                          );
                          return selectedTargetAudience?.label || '';
                        }}
                      >
                        {AUDIENCE_CHOICES.map(targetAudience => (
                          <MenuItem
                            key={targetAudience.id}
                            value={targetAudience.value}
                          >
                            {targetAudience.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={!!errors.targetAudience}>
                        {!!errors.targetAudience
                          ? errors.targetAudience?.message
                          : ''}
                      </FormHelperText>
                    </>
                  )}
                />
              </Box>
            }
          />
          {isExperienceBookingRequest && (
            <Box>
              <Typography variant="h5" gutterBottom>
                Optional
              </Typography>
              <Typography>
                The fields below are optional. If you leave them blank they will
                not appear in your offering.
              </Typography>
            </Box>
          )}
          <LabelField
            label="Activity Level"
            value={
              <Box sx={{ mt: 2 }}>
                <Controller
                  name="activityLevel"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        data-testid="activityLevel"
                        displayEmpty={true}
                        error={!!errors.activityLevel}
                        renderValue={selected => {
                          if (!selected) {
                            return 'Select Activity';
                          }
                          const selectedActivityLevel = ACTIVITY_CHOICES.find(
                            activityLevel => activityLevel.value === selected
                          );
                          return selectedActivityLevel?.label || '';
                        }}
                      >
                        {ACTIVITY_CHOICES.map(activityLevel => (
                          <MenuItem
                            key={activityLevel.id}
                            value={activityLevel.value}
                          >
                            {activityLevel.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={!!errors.activityLevel}>
                        {!!errors.activityLevel
                          ? errors.activityLevel?.message
                          : ''}
                      </FormHelperText>
                    </>
                  )}
                />
              </Box>
            }
          />

          <LabelField
            label="Will you provide the guests with anything?"
            value={
              <Box>
                <RadioGroup
                  value={isExperienceBookingRequest ? undefined : hostSupply}
                  onChange={e => setHostSupply(e.target.value === 'true')}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No, I'm not providing anything"
                  />
                </RadioGroup>
                {!!hostSupply && (
                  <Box mt={2}>
                    <Controller
                      control={control}
                      name="requiredSupply.byHost"
                      render={({ field }) => (
                        <InputField
                          {...field}
                          label="I will provide the guests with"
                          multiline
                          minRows={6}
                          helperText={renderInputFieldHelperText(
                            watch('requiredSupply.byHost')?.length,
                            TEXT_AREA_LIMIT_LONG,
                            errors.requiredSupply?.byHost?.message
                          )}
                        />
                      )}
                    />
                  </Box>
                )}
              </Box>
            }
          />

          <LabelField
            label="Will guests need to bring anything to the experience?"
            value={
              <Box>
                <RadioGroup
                  value={isExperienceBookingRequest ? undefined : guestSupply}
                  onChange={e => setGuestSupply(e.target.value === 'true')}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No, guests just need to show up"
                  />
                </RadioGroup>
                {!!guestSupply && (
                  <Box mt={2}>
                    <Controller
                      control={control}
                      name="requiredSupply.byGuest"
                      render={({ field }) => (
                        <InputField
                          {...field}
                          label="Guests should supply"
                          multiline
                          minRows={6}
                          helperText={renderInputFieldHelperText(
                            watch('requiredSupply.byGuest')?.length,
                            TEXT_AREA_LIMIT_LONG,
                            errors.requiredSupply?.byGuest?.message
                          )}
                        />
                      )}
                    />
                  </Box>
                )}
              </Box>
            }
          />
        </Stack>
      </form>
    </Stack>
  );
};
