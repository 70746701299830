import {
  ArrayNotEmpty,
  IsArray,
  IsEnum,
  IsNotEmpty,
  IsOptional,
  IsString,
  MaxLength,
  ValidateIf,
  ValidateNested,
} from 'class-validator';
import { IsPriceMinValueForPaidExperience } from './validations';
import { PriceType, PurchaseMethods } from '@/types';
import { TEXT_AREA_LIMIT_MEDIUM } from '@/constants';
import { Type } from 'class-transformer';

// TODO: to be replaced by PricingWithTaxesInput once we use Price collection for all listings
export class PricingInput {
  @IsNotEmpty({ message: 'Please enter a total price' })
  @IsPriceMinValueForPaidExperience(0.5)
  totalAmount: number | string;

  // we don't need to validate this field, it is automatically calculated.
  price: number | string;

  @IsOptional()
  taxRateId?: number | string;

  @IsArray()
  @IsOptional()
  acceptedPurchaseMethods?: PurchaseMethods[];

  @IsString()
  @IsOptional()
  priceId?: string;
}

export class PricingWithTaxesInput {
  @IsString()
  @IsOptional()
  priceId?: string;

  @IsString()
  @IsOptional()
  membershipPlanProductId?: string;

  @ValidateIf(
    o =>
      o.priceType !== PriceType.VIA_QUOTE &&
      !o.isMemberExclusive &&
      o.priceType !== PriceType.MEMBERSHIP
  )
  @IsNotEmpty({ message: 'Please enter a price' })
  @IsPriceMinValueForPaidExperience(0.5)
  amount: number | string;

  taxRateId: number | string;

  @IsEnum(PriceType, { message: 'Please select a valid pricing type' })
  priceType: PriceType | '';

  @IsOptional()
  @MaxLength(TEXT_AREA_LIMIT_MEDIUM, { message: 'Description is too long' })
  description?: string;

  isMemberExclusive?: boolean;

  @ValidateIf(o => o.priceType === PriceType.MEMBERSHIP)
  @IsNotEmpty({ message: 'Please select a membership plan' })
  membershipPlanId?: string;
}

export class PricingFormInput {
  @IsArray()
  @ArrayNotEmpty()
  @ValidateNested({ each: true })
  @Type(() => PricingWithTaxesInput)
  prices: PricingWithTaxesInput[];

  @IsArray()
  @ArrayNotEmpty()
  @IsOptional()
  acceptedPurchaseMethods?: PurchaseMethods[];
}
