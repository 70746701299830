import React, { useState, createContext, useContext } from 'react';
import { UserSignUpObject } from '../types';

type PartialUserSignUpObject = Partial<UserSignUpObject>;

const defaultSignupUser: PartialUserSignUpObject = {
  firstName: '',
  lastName: '',
  personalEmail: '',
  personalPhone: '',
  companyName: '',
  address: '',
  apt: '',
  userState: '',
  city: '',
  zip: '',
  companyURL: '',
  supportEmail: '',
  category: '',
  years: '',
  description: '',
  linkedIn: '',
  facebook: '',
  otherPage: '',
  otherLinks: '',
};

export const FormContext = createContext({
  signupFormData: defaultSignupUser,
  // Disabling rule due to current implementation
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setFormValues: (_data: PartialUserSignUpObject) => {},
});

export default function FormProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [signupFormData, setSignupFormData] = useState(defaultSignupUser);

  const setFormValues = (newValues: PartialUserSignUpObject) => {
    setSignupFormData(previousValues => ({
      ...previousValues,
      ...newValues,
    }));
  };

  return (
    <FormContext.Provider value={{ signupFormData, setFormValues }}>
      {children}
    </FormContext.Provider>
  );
}

export const useFormData = () => useContext(FormContext);
