import { Address } from '@/types';
import { Type } from 'class-transformer';
import {
  IsNotEmpty,
  MaxLength,
  MinLength,
  IsOptional,
  IsNumberString,
  ValidateIf,
  IsUrl,
  IsString,
  IsEmail,
  ValidateNested,
} from 'class-validator';

export class AddressInput {
  @MinLength(1, { message: 'Please enter an address' })
  @IsNotEmpty({ message: 'Please select an address' })
  street: string;

  @IsOptional()
  apartment: string;

  @MinLength(1, { message: 'Please enter a city' })
  @IsNotEmpty({ message: 'Please select a city' })
  city: string;

  @MinLength(1, { message: 'Please enter a state' })
  @MaxLength(2, { message: 'Please enter a valid state abbreviation' })
  @IsNotEmpty({ message: 'Please enter a state' })
  state: string;

  @MinLength(5, { message: 'Please enter a zip code' })
  @IsNotEmpty({ message: 'Please enter a zip code' })
  @IsNumberString(undefined, { message: 'Invalid ZIP' })
  zipCode: string;
}

export class MerchantBusinessProfileInput {
  @MinLength(1, { message: 'Please enter a company name' })
  @MaxLength(100, {
    message: 'Please enter a shorter version of your company name',
  })
  @IsNotEmpty()
  @IsString()
  name: string;

  @MinLength(4, { message: 'Please enter your website URL' })
  @MaxLength(100, {
    message: 'Please enter just the main URL i.e. (www.site.com)',
  })
  @IsNotEmpty({ message: 'Please enter your website URL.' })
  @IsOptional()
  @IsUrl()
  websiteUrl: string;

  @MinLength(7, { message: 'Please enter an email address' })
  @IsNotEmpty({ message: 'Please enter an email address' })
  @IsOptional()
  @IsEmail()
  email: string;

  @MaxLength(900, {
    message: 'Please enter a description for your business',
  })
  @IsOptional()
  @IsString()
  description: string;

  @ValidateIf(e => e.linkedinUrl && e.linkedinUrl.trim() !== '')
  @IsOptional()
  @IsUrl()
  @MaxLength(100)
  linkedinUrl: string;

  @ValidateIf(e => e.facebookUrl && e.facebookUrl.trim() !== '')
  @IsOptional()
  @IsUrl()
  @MaxLength(100)
  facebookUrl: string;

  @Type(() => AddressInput)
  @ValidateNested()
  // @Type(() => AddressInput)
  mainAddress: AddressInput;

  @IsString()
  @IsOptional()
  photo: string | File;
}
// yearsOperating and otherUrl1 and otherUrl2 MIA

export class UpdatedUserType {
  id: number;
  name: string;
  mainAddressId: number;
  email: string;
  websiteUrl: string;
  linkedinUrl: string;
  facebookUrl: string;
  otherUrl1: string;
  otherUrl2: string;
  // businessCategory: BusinessCategory;
  yearsOperating: string;
  description: string;
  bankAccountId?: string; // stripe bank account id
  mainAddress: Address;
  photo: string;
  supportEmail: string;
  createdUserId: string;
  createdAt: Date;
  updatedUserId: string;
  updatedAt: Date;
  deletedUserId: string;
  deletedAt: Date;
}
