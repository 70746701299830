import { FC, useEffect, useState } from 'react';
import { MerchantUser, MerchantUserStatus } from '@/types';
import {
  Button,
  Typography,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import { capitalizeWords } from '@/utils';
import { WAIT_TIME_SEC_RESEND_INVITE } from '@/constants';
import { getColorUserStatus, getStatusDisplayName } from '@/utils/userUtils';
import { UseMutateFunction } from '@tanstack/react-query';
import { AxiosError } from 'axios';

interface StatusFieldProps {
  teamMember: MerchantUser;
  onResendInviteClick: UseMutateFunction<boolean, AxiosError, number>;
}

export const ResendInviteButton: FC<StatusFieldProps> = ({
  teamMember,
  onResendInviteClick,
}) => {
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    if (counter <= 0) {
      return;
    }
    const timer = setInterval(() => setCounter(counter - 1), 1000);
    return () => timer && clearInterval(timer);
  }, [counter]);

  if (teamMember?.status === MerchantUserStatus.ACTIVE) {
    return null;
  }

  return (
    <Button
      variant="text"
      disabled={counter > 0}
      onClick={() => {
        setCounter(WAIT_TIME_SEC_RESEND_INVITE);
        onResendInviteClick(teamMember.userId);
      }}
    >
      <Typography variant="body2">
        Resend Invite {counter ? `(${counter} sec)` : null}
      </Typography>
    </Button>
  );
};

export const StatusField: FC<StatusFieldProps> = ({
  teamMember,
  onResendInviteClick,
}) => {
  const theme = useTheme();
  return (
    <>
      <Typography
        variant="body2"
        color={getColorUserStatus(teamMember, theme)}
        align="right"
      >
        {capitalizeWords(
          getStatusDisplayName(
            teamMember.status,
            teamMember.passwordResetTokenExpiry
          ),
          'Each word'
        )}
      </Typography>
      <ResendInviteButton
        teamMember={teamMember}
        onResendInviteClick={onResendInviteClick}
      />
    </>
  );
};
