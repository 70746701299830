import { Typography } from '@silverstein-properties/inspirelabs-ui';
import { StyledIcon, StyledTodo } from './TodoItem.styles';
import { Todo } from 'types/Todo';

export const TodoItem = ({
  todoItem,
}: {
  todoItem: Todo;
  arrayLength: number;
}) => {
  const progressImage = {
    0: 'undone',
    1: 'partial',
    2: 'done',
  };

  return (
    <StyledTodo>
      <Typography
        variant="body1"
        title={todoItem.description}
        sx={{ wordBreak: 'break-word' }}
      >
        {todoItem.name}
      </Typography>
      <StyledIcon>
        <img
          src={`/images/${progressImage[todoItem.progressAmount]}.svg`}
          alt="status marker"
          height="24px"
          width="24px"
        />
      </StyledIcon>
    </StyledTodo>
  );
};
