import { PhotoSectionEditInput } from '@/classes';
import { LabelField } from '@/components';
import FileInput from '@/components/FormTextInput/FileInput';
import MiniFileInput from '@/components/FormTextInput/MiniFileInput';
import { Experience } from '@/types';
import {
  Box,
  Grid,
  MessageBanner,
  Stack,
  Typography,
} from '@silverstein-properties/inspirelabs-ui';
import { Link as MuiLink } from '@mui/material';
import { omit } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

export type PhotoSectionEditPropsType = {
  experience: Experience;
  onFormSubmit: (data: PhotoSectionEditInput) => void;
};

export const PhotoSectionEdit = ({
  experience,
  onFormSubmit,
}: PhotoSectionEditPropsType) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<PhotoSectionEditInput>({
    mode: 'onChange',
    defaultValues: {
      coverPhoto: experience.coverPhoto,
      photos: {
        photo1: experience.photos?.photo1,
        photo2: experience.photos?.photo2,
        photo3: experience.photos?.photo3,
        photo4: experience.photos?.photo4,
      },
    },
  });

  const onPhotoChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    onChangeCallback: (e: '' | File) => void
  ) => {
    if (e instanceof File) {
      onChangeCallback(e);
    } else {
      const newFile = e.target?.files ? e.target.files[0] : '';
      onChangeCallback(newFile);
    }
  };

  const onPhotoReset = () => {};

  return (
    <Stack spacing={4}>
      <Typography variant="h5">Photos</Typography>
      {Object.values(errors).length !== 0 ? (
        <Box sx={{ mb: 2 }}>
          <MessageBanner type="error">
            Please upload a different photo
          </MessageBanner>
        </Box>
      ) : null}
      <form id="photosForm" onSubmit={handleSubmit(onFormSubmit)}>
        <Stack spacing={4}>
          <LabelField
            label="Cover photo"
            value={
              <Controller
                name="coverPhoto"
                control={control}
                render={({ field }) => (
                  <FileInput
                    {...omit(field, 'ref')}
                    value={field.value as string | File}
                    handleChange={e => onPhotoChange(e, field.onChange)}
                    multiple={false}
                    reset={onPhotoReset}
                  />
                )}
              />
            }
            footer={
              <>
                <Typography variant="body2">
                  Photo should have good image quality—no heavy filters,
                  distortions, overlaid text, or watermarks.
                </Typography>
                <Typography variant="body2">
                  <MuiLink
                    component={Link}
                    to="/photoguidelines"
                    target="_blank"
                  >
                    Read our tips for best quality images
                  </MuiLink>
                </Typography>
              </>
            }
            spacing={2}
          />
          <LabelField
            label="Add four more photos that accurately illustrate the experience"
            value={
              <Grid container gap={2}>
                <Grid container item>
                  <Grid item xs={6}>
                    <Controller
                      name="photos.photo1"
                      control={control}
                      render={({ field }) => (
                        <MiniFileInput
                          {...omit(field, 'ref')}
                          value={field.value as string | File}
                          keyNumber="1"
                          handleChange={e => onPhotoChange(e, field.onChange)}
                          size="mini-input-rectangle"
                          reset={onPhotoReset}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="photos.photo2"
                      control={control}
                      render={({ field }) => (
                        <MiniFileInput
                          {...omit(field, 'ref')}
                          value={field.value as string | File}
                          keyNumber="2"
                          handleChange={e => onPhotoChange(e, field.onChange)}
                          size="mini-input-rectangle"
                          reset={onPhotoReset}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container item>
                  <Grid item xs={6}>
                    <Controller
                      name="photos.photo3"
                      control={control}
                      render={({ field }) => (
                        <MiniFileInput
                          {...omit(field, 'ref')}
                          value={field.value as string | File}
                          keyNumber="3"
                          handleChange={e => onPhotoChange(e, field.onChange)}
                          size="mini-input-rectangle"
                          reset={onPhotoReset}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="photos.photo4"
                      control={control}
                      render={({ field }) => (
                        <MiniFileInput
                          {...omit(field, 'ref')}
                          value={field.value as string | File}
                          keyNumber="4"
                          handleChange={e => onPhotoChange(e, field.onChange)}
                          size="mini-input-rectangle"
                          reset={onPhotoReset}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            }
            footer={
              <>
                <Typography variant="body2">
                  Choose a variety of details and angles, including photos of
                  people in action
                </Typography>
                <Typography variant="body2">
                  <MuiLink
                    component={Link}
                    to="/photoguidelines"
                    target="_blank"
                  >
                    Read our tips for best quality images
                  </MuiLink>
                </Typography>
              </>
            }
            spacing={2}
          />
        </Stack>
      </form>
    </Stack>
  );
};
