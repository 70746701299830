import { ExperiencesTableCell } from './components';
import { createColumnHelper } from '@tanstack/react-table';
import { formatDate, formatPricing, formatTime } from '@/utils';
import {
  Button,
  ChevronDownIcon,
  Typography,
} from '@silverstein-properties/inspirelabs-ui';

export interface TableData {
  id: string;
  name: string;
  guests: number;
  netEarnings: number;
  refunds: number;
  platformFee: number;
  events?: TableData[];
}

// ======================================================================================
//   TABLE COLUMN DEFINITIONS
// ======================================================================================

export const getTableColumns = (onDetailsClick: (id: string) => void) => {
  const columnHelper = createColumnHelper<TableData>();

  return [
    columnHelper.display({
      id: 'chevron',
      cell: ({ row }) => (
        <>
          {row.getCanExpand() ? (
            <ChevronDownIcon
              width={24}
              onClick={row.getToggleExpandedHandler()}
              style={{
                cursor: 'pointer',
                transform: row.getIsExpanded()
                  ? 'rotate(180deg)'
                  : 'rotate(0deg)',
                transition: 'transform 0.3s ease-in-out',
              }}
            />
          ) : null}
        </>
      ),
      size: 24,
    }),
    columnHelper.accessor('name', {
      header: () => (
        <Typography variant="labelMedium" color="primary.medium">
          Name
        </Typography>
      ),
      cell: info => (
        // Date & Time sub-row
        <ExperiencesTableCell isSubRow={info.row.depth > 0}>
          {info.row.depth > 0 ? (
            <>
              {formatDate(new Date(info.getValue()))} <br />
              <Typography variant="body3" color="primary.medium">
                {formatTime(new Date(info.getValue()))}
              </Typography>
            </>
          ) : (
            info.getValue()
          )}
        </ExperiencesTableCell>
      ),
      size: 192,
    }),
    columnHelper.accessor('guests', {
      header: () => (
        <Typography variant="labelMedium" color="primary.medium">
          Guests
        </Typography>
      ),
      cell: info => (
        <ExperiencesTableCell isSubRow={info.row.depth > 0} textAlign="right">
          {info.getValue()}
        </ExperiencesTableCell>
      ),
      size: 192,
    }),
    columnHelper.accessor('netEarnings', {
      header: () => (
        <Typography variant="labelMedium" color="primary.medium">
          Net earnings
        </Typography>
      ),
      cell: info => (
        <ExperiencesTableCell isSubRow={info.row.depth > 0} textAlign="right">
          {formatPricing(info.getValue() || 0, 2)}
        </ExperiencesTableCell>
      ),
      size: 192,
    }),
    columnHelper.accessor('platformFee', {
      header: () => (
        <Typography variant="labelMedium" color="primary.medium">
          Platform fee
        </Typography>
      ),
      cell: info => (
        <ExperiencesTableCell isSubRow={info.row.depth > 0} textAlign="right">
          {formatPricing(info.getValue() || 0, 2)}
        </ExperiencesTableCell>
      ),
      size: 192,
    }),
    columnHelper.accessor('refunds', {
      header: () => (
        <Typography variant="labelMedium" color="primary.medium">
          Refunds
        </Typography>
      ),
      cell: info => (
        <ExperiencesTableCell isSubRow={info.row.depth > 0} textAlign="right">
          {formatPricing(info.getValue() || 0, 2)}
        </ExperiencesTableCell>
      ),
      size: 192,
    }),
    columnHelper.accessor('id', {
      header: '',
      cell: info =>
        info.row.depth > 0 && onDetailsClick ? (
          <Button
            variant="outlined"
            size="small"
            onClick={() => onDetailsClick(info.getValue())}
            sx={{ float: 'right' }}
          >
            Details
          </Button>
        ) : null,
      size: 80,
      enableSorting: false,
    }),
  ];
};
