import { useState } from 'react';

const categories = [
  {
    id: 1,
    title: 'Basic info',
    subItems: [
      { name: 'Service Type', page: 1 },
      { name: 'Title', page: 2 },
      { name: 'Service Theme', page: 3 },
    ],
    pageOpen: 1,
    pageDone: 4,
  },
  {
    id: 2,
    title: 'Expertise',
    subItems: [{ name: 'Experience', page: 4 }],
    pageOpen: 4,
    pageDone: 4,
  },
  {
    id: 3,
    title: 'Experience Page',
    subItems: [
      { name: 'Description', page: 5 },
      { name: 'About the host', page: 6 },
      { name: 'Location', page: 7 },
      { name: 'Requirements', page: 8 },
      { name: 'Photos', page: 9 },
    ],
    pageOpen: 5,
    pageDone: 10,
  },
  {
    id: 4,
    title: 'Settings',
    subItems: [
      { name: 'Group Size', page: 10 },
      { name: 'Pricing', page: 11 },
      { name: 'Cancellation', page: 12 },
    ],
    pageOpen: 10,
    pageDone: 13,
  },
];

export interface BusinessSignupMenuCategories {
  id: number;
  title: string;
  subItems: { name: string; page: number }[];
  pageOpen: number;
  pageDone: number;
}

export function useHooks(props: { currentPage: number }) {
  const { currentPage } = props;

  const [openFolder, setOpenFolder] = useState<number | null>(1);

  const handleFolderOpen = (e: React.MouseEvent<HTMLElement>): void => {
    if (!e.currentTarget.id.includes('locked')) {
      const targetId = parseInt(e.currentTarget.id, 10);
      if (targetId !== openFolder) {
        setOpenFolder(targetId);
      } else {
        setOpenFolder(null);
      }
    }
  };

  const pageDictionary: { [index: number]: number } = {
    1: 1,
    2: 1,
    3: 1,
    4: 2,
    5: 3,
    6: 3,
    7: 3,
    8: 3,
    9: 3,
    10: 4,
    11: 4,
    12: 4,
  };

  if (openFolder !== pageDictionary[currentPage]) {
    setOpenFolder(pageDictionary[currentPage]);
  }

  return { openFolder, categories, handleFolderOpen };
}
