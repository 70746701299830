import { List } from '@mui/material';
import { styled } from '@silverstein-properties/inspirelabs-ui';

export const StyledList = styled(List)(({ theme }) => ({
  marginTop: theme.spacing(3),
  border: `1px solid ${theme.palette.grey[400]}`,
  borderRadius: theme.shape.borderRadius / 2,
  padding: 0,
  '& .MuiListItem-root': {
    padding: theme.spacing(2.5, 2),
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
  },
}));
