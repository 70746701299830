import { EventBookingSummary } from 'types';
import { useQuery } from '@tanstack/react-query';
import { ApiClient } from '../api/apiClient';

type useListingBookingSummaryType = {
  listingId?: string;
};

export const useListingBookingSummary = ({
  listingId,
}: useListingBookingSummaryType) => {
  const apiClient = ApiClient();

  const getListingBookingSummary =
    async (): Promise<EventBookingSummary | null> => {
      if (listingId) {
        return await apiClient.bookings.getListingBookingSummary({ listingId });
      } else {
        return null;
      }
    };

  return useQuery<EventBookingSummary | null>(
    ['listingBookingSummary', listingId],
    () => getListingBookingSummary(),
    {
      enabled: !!listingId,
    }
  );
};
