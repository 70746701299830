import { Dialog as DialogBox } from '@mui/material';

export interface DialogProps {
  open: boolean;
  onClose: (value: boolean) => void;
  children: React.ReactNode;
}

export function Dialog({ open, onClose, children }: DialogProps) {
  return (
    <DialogBox open={open} onClose={() => onClose(false)}>
      {children}
    </DialogBox>
  );
}
