import { ButtonProps } from '@mui/material';
import { Button, Svg } from '@silverstein-properties/inspirelabs-ui';

const BackButton = (props: ButtonProps) => {
  return (
    <Button
      {...props}
      variant="text"
      startIcon={<Svg src="/images/chevron-left.svg" height={24} width={24} />}
    >
      Back
    </Button>
  );
};

export default BackButton;
