import {
  Grid,
  Typography,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import { MEMBERSHIP_DETAIL_MENUS } from '@/constants';
import { StyledCard, StyledCardContent } from './FloatingMenu.styles';
import { forwardRef, ForwardedRef, useEffect, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { MenuItem } from '@/types';

export type FloatingMenuPropsType = {
  setHeight: (value: number) => void;
  onClick?: (child: MenuItem) => void;
};

const FloatingMenuInternal = (
  { setHeight, onClick = () => {} }: FloatingMenuPropsType,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const theme = useTheme();
  const location = useLocation();
  const currentHash = location.hash.replace('#', '');

  useEffect(() => {
    if (ref && typeof ref !== 'function' && ref.current) {
      setHeight(ref.current.offsetHeight);
    }
  }, [ref]);

  return (
    <Grid
      container
      rowSpacing={2}
      // Top of 64px or 8 MUI units for the header's height
      sx={{ position: 'sticky', top: theme.spacing(8) }}
    >
      {MEMBERSHIP_DETAIL_MENUS.map(item => (
        <Fragment key={item.id}>
          <Typography
            variant="subtitle1"
            sx={{ pt: 3.75, wordWrap: 'break-word' }}
          >
            {item.title}
          </Typography>
          {item.children.map(child => (
            <Grid item key={child.id} xs={12}>
              <StyledCard
                onClick={() => onClick(child)}
                isSelected={currentHash === child.id}
                variant="outlined"
              >
                <StyledCardContent>
                  <img alt={child.title} src={child.image} />
                  <Typography
                    variant="subtitle1"
                    sx={{ display: 'inline-block' }}
                  >
                    {child.title}
                  </Typography>
                </StyledCardContent>
              </StyledCard>
            </Grid>
          ))}
        </Fragment>
      ))}
    </Grid>
  );
};

export const FloatingMenu = forwardRef(FloatingMenuInternal);
