import { Container, Grid } from '@silverstein-properties/inspirelabs-ui';
import { ReactNode } from 'react';
import { PublishMembership } from './components/PublishMembership';

import {
  MembershipCreationSection,
  useHooks,
} from '../../hooks/useMembershipCreationHooks';
import {
  Plans,
  TitleAndDescription,
  LeftNavigation,
  MembershipSuccess,
} from './components';

export enum Steps {
  TitleAndDescription = 1,
  Plans,
  PublishMembership,
  PublishSuccess,
}

export const MembershipCreationModule = () => {
  const {
    currentSection,
    currentSubItem,
    categories,
    changePage,
    changeSection,
    changeSubItem,
    savePlanToContext,
    setMembershipDb,
    setMembershipPlanDb,
    publishAllPlansDb,
  } = useHooks();

  const MembershipFormSteps: { [index: string]: ReactNode } = {
    [MembershipCreationSection.Details]: (
      <TitleAndDescription
        changeSection={changeSection}
        setMembershipDb={setMembershipDb}
      />
    ),
    [MembershipCreationSection.Plans]: (
      <Plans
        changeSection={changeSection}
        changeSubItem={changeSubItem}
        savePlanToContext={savePlanToContext}
        currentSubItem={currentSubItem}
        subItems={categories[MembershipCreationSection.Plans].subItems}
        setMembershipPlanDb={setMembershipPlanDb}
      />
    ),
    [MembershipCreationSection.Publish]: (
      <PublishMembership
        changeSection={changeSection}
        publishAllPlansDb={publishAllPlansDb}
      />
    ),
  };

  return (
    <Container>
      {currentSection === MembershipCreationSection.Success ? (
        <MembershipSuccess />
      ) : (
        <>
          <Grid container item>
            <LeftNavigation
              changePage={changePage}
              currentSection={currentSection}
              currentSubItem={currentSubItem}
              categories={categories}
            />
          </Grid>
          <Grid item ml={75} mt={8}>
            {MembershipFormSteps[currentSection]}
          </Grid>
        </>
      )}
    </Container>
  );
};
