import { Membership } from '@/types';
import { PlanDetailSection } from '../PlanDetailSection';
import { MembershipProductsList } from '@/components';
import { ActionsType } from '@/hooks/useMembershipEdit';

export type PlansSectionPropsType = {
  membership: Membership;
  isLoading?: boolean;
  planOnActionsClick: (action: ActionsType, id: string) => void;
  showDeletePlanAlertDialog: boolean;
  handleDeletePlansAlertDialogClose: (value: boolean) => Promise<void>;
};

export const PlansSection = ({
  membership,
  isLoading = false,
  planOnActionsClick,
  showDeletePlanAlertDialog,
  handleDeletePlansAlertDialogClose,
}: PlansSectionPropsType) => {
  return (
    <>
      {membership.membershipPlans.map((membershipPlan, index) => (
        <PlanDetailSection
          key={membershipPlan.id}
          membershipPlan={membershipPlan}
          title={membershipPlan.name}
          id={membershipPlan.id}
          isLastPlan={index === membership.membershipPlans.length - 1}
          onActionsClick={planOnActionsClick}
          showDeletePlanAlertDialog={showDeletePlanAlertDialog}
          handleDeletePlansAlertDialogClose={handleDeletePlansAlertDialogClose}
        >
          <MembershipProductsList
            isLoading={isLoading}
            title="Experiences"
            productsInfo={(membershipPlan.membershipProducts || []).map(x => ({
              id: x.id,
              title: x.product?.title || '',
            }))}
          />
        </PlanDetailSection>
      ))}
    </>
  );
};
