import { ToggleButtonGroup } from '@mui/material';
import { styled } from '@silverstein-properties/inspirelabs-ui';

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
      color: theme.palette.primary.main,
      margin: theme.spacers.xxs,
      border: 0,
      borderRadius: '50% !important',
    },
    '& .MuiToggleButtonGroup-grouped.Mui-selected': {
      backgroundColor: 'transparent',
      border: `2px solid ${theme.palette.primary.main} !important`,
    },
  })
);
