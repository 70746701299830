import RoundButton from '../RoundButton/Component';
import MenuLabel from '../MenuLabel/Component';
import { Link } from 'react-router-dom';
import { useHooks } from './useHooks';
import { MobileHamburger } from './MobileHamburger';
import { User } from 'types';
import { useAuth } from 'hooks';
import { useState } from 'react';
import { Menu } from 'components/Menu/Component';
import { HubLogo } from '@/assets';
import { routes } from '@/routing/routes';

export interface NavBarProps {
  currentUser?: User | null;
  logOut: () => void;
}

export default function NavBar(props: NavBarProps) {
  const { data: user } = useAuth();
  const { location, buttons, windowSize } = useHooks({
    ...props,
    currentUser: user,
  });
  const [navMenuOpen, setNavMenuOpen] = useState(false);
  return (
    <nav className="nav-bar nav-space shadow-sm2 container-fluid sticky-top bg-white">
      <div className="row mx-3">
        <div className="col-lg-3 col-md-6 col-sm-7 col-7">
          <HubLogo link={!!user ? routes.dashboard : routes.home} />
        </div>
        <div className="col-lg-6 d-lg-block d-md-none d-sm-none col-none d-none">
          {buttons && windowSize > 1 ? (
            <ul className="nav-buttons d-flex justify-content-center list-unstyled">
              {buttons.map((button, i) => (
                <li key={i}>
                  <MenuLabel text={button.name} link={button.link} />
                </li>
              ))}
            </ul>
          ) : null}
        </div>
        <div className="col-lg-3 col-md-6 col-sm-5 col-5 d-md-block d-lg-block text-end px-0">
          <span className="login-buttons text-end">
            {!user?.firstName ? (
              windowSize > 1 ? (
                <>
                  {location.pathname !== '/signup' && (
                    <Link to="/signup">
                      <RoundButton
                        text="Get started"
                        default="blue small"
                        hover="white-navy small"
                      />
                    </Link>
                  )}
                  {location.pathname !== '/login' && (
                    <Link to="/login">
                      <RoundButton
                        text="Log in"
                        default="white-navy small"
                        hover="blue small"
                      />
                    </Link>
                  )}
                </>
              ) : (
                <MobileHamburger />
              )
            ) : (
              <RoundButton
                text={`Hi, ${user?.firstName}`}
                default="white-navy small"
                hover="blue small"
                handleClick={() => setNavMenuOpen(!navMenuOpen)}
              />
            )}
            {navMenuOpen && (
              <Menu
                logOut={props.logOut}
                toggleMenu={() => setNavMenuOpen(!navMenuOpen)}
              />
            )}
          </span>
        </div>
      </div>
    </nav>
  );
}
