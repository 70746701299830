import { MerchantUser, User, UserRole } from '@/types';
import {
  Button,
  Skeleton,
  Typography,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import {
  SelectRoleField,
  StyledTable,
  StyledTableRow,
} from './TeamTable.styles';
import {
  MenuItem,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { StatusField } from '../StatusField/StatusField';
import {
  getRolesNames,
  validateRoles,
  validateRolesByMerchantId,
} from '@/utils/userUtils';
import { UseMutateFunction } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export type SkeletonTableItemsPropsType = {
  show: boolean;
  title: string;
  numberOfSkeletons?: number;
};

export const SkeletonTableItems = ({
  show = false,
  title,
  numberOfSkeletons = 3,
}: SkeletonTableItemsPropsType) => {
  if (!show) {
    return null;
  }
  return (
    <>
      {[...Array(numberOfSkeletons)].map((_, index) => (
        <StyledTableRow key={`${title}-${index}`}>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
        </StyledTableRow>
      ))}
    </>
  );
};

export type TableItemsPropsType = {
  show: boolean;
  teamMembers: MerchantUser[] | undefined;
  user?: User | null;
  onRemoveClick: (merchantUser: MerchantUser) => void;
  onResendInviteClick: UseMutateFunction<boolean, AxiosError, number>;
};

export const TableItems = ({
  show = false,
  teamMembers,
  user,
  onRemoveClick,
  onResendInviteClick,
}: TableItemsPropsType) => {
  const userIsOwnerOrSpInternal = validateRolesByMerchantId(
    user,
    [UserRole.OWNER, UserRole.SP_INTERNAL],
    user?.primaryMerchantId
  );

  if (!show) {
    return null;
  }

  if (!teamMembers || (teamMembers && teamMembers.length === 0)) {
    return (
      <StyledTableRow>
        <TableCell colSpan={4}>
          <Typography variant="body2" align="center">
            No Team Members
          </Typography>
        </TableCell>
      </StyledTableRow>
    );
  }

  return (
    <>
      {teamMembers.map(teamMember => (
        <StyledTableRow key={teamMember.id}>
          <TableCell width="30%">
            <Typography variant="subtitle2">
              {teamMember.user?.firstName} {teamMember.user?.lastName}
            </Typography>
            <Typography variant="body3">{teamMember.user?.email}</Typography>
          </TableCell>
          <TableCell align="center" width="30%">
            {teamMember?.user?.roles && (
              <SelectRoleField
                label=""
                select
                disabled
                value={getRolesNames(teamMember?.user)}
              >
                <MenuItem value={UserRole.OWNER}>Owner</MenuItem>
                <MenuItem value={UserRole.ADMIN}>Admin</MenuItem>
                <MenuItem value={UserRole.HOST}>Member</MenuItem>
                <MenuItem value={UserRole.SP_INTERNAL}>Internal</MenuItem>
              </SelectRoleField>
            )}
          </TableCell>
          <TableCell align="right" width="30%">
            <StatusField
              teamMember={teamMember}
              onResendInviteClick={onResendInviteClick}
            />
          </TableCell>
          <TableCell align="right">
            {userIsOwnerOrSpInternal &&
              !validateRoles(teamMember.user, [
                UserRole.OWNER,
                UserRole.SP_INTERNAL,
              ]) && (
                <Button
                  sx={{ alignSelf: 'flex-end' }}
                  size="small"
                  variant="outlined"
                  onClick={() => onRemoveClick(teamMember)}
                >
                  Remove
                </Button>
              )}
          </TableCell>
        </StyledTableRow>
      ))}
    </>
  );
};

export type TeamTablePropsType = {
  isLoading?: boolean;
  teamMembers: MerchantUser[] | undefined;
  user?: User | null;
  onResendInviteClick: UseMutateFunction<boolean, AxiosError, number>;
  onRemoveClick: (memberUser: MerchantUser) => void;
};

export const TeamTable = ({
  teamMembers,
  isLoading = false,
  user,
  onResendInviteClick,
  onRemoveClick,
}: TeamTablePropsType) => {
  const theme = useTheme();
  return (
    <TableContainer sx={{ paddingTop: theme.spacers.xs_num }}>
      <StyledTable>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="body3">Team member</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body3">Role</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body3" color="primary.medium">
                Status
              </Typography>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          <SkeletonTableItems show={isLoading} title="123" />
          <TableItems
            show={!isLoading}
            user={user}
            teamMembers={teamMembers}
            onResendInviteClick={onResendInviteClick}
            onRemoveClick={onRemoveClick}
          />
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};
