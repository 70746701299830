import { BookingRequestLoaded, BookingRequestStatus, PriceType } from '@/types';
import { formatDate, formatPricing } from '@/utils';
import {
  Box,
  Button,
  Typography,
} from '@silverstein-properties/inspirelabs-ui';
import { createColumnHelper } from '@tanstack/react-table';

export const getStatusColor = (status: BookingRequestStatus) => {
  switch (status) {
    case BookingRequestStatus.ACCEPTED:
      return 'success.main';
    case BookingRequestStatus.REJECTED:
      return 'error.main';
    default:
      return 'primary.medium';
  }
};

export const getStatusName = (status: BookingRequestStatus) => {
  switch (status) {
    case BookingRequestStatus.ACCEPTED:
      return 'Accepted';
    case BookingRequestStatus.REJECTED:
      return 'Rejected';
    case BookingRequestStatus.PENDING_MERCHANT:
      return 'Pending Quote';
    case BookingRequestStatus.PENDING_CONSUMER:
      return 'Pending Consumer';
    default:
      return 'Pending';
  }
};

export const getTableColumns = (
  onButtonClick?: (action: 'view' | 'viewQuote', id: string) => void
) => {
  const columnHelper = createColumnHelper<BookingRequestLoaded>();

  return [
    columnHelper.accessor('fullName', {
      id: 'fullName',
      header: () => (
        <Typography variant="labelMedium" color="primary.medium">
          Name
        </Typography>
      ),
      cell: cellContext => (
        <Typography variant="labelMedium">{cellContext.getValue()}</Typography>
      ),
    }),

    columnHelper.accessor(row => row.listing?.startsAt, {
      id: 'listing.startsAt',
      header: () => (
        <Typography variant="labelMedium" color="primary.medium">
          Date
        </Typography>
      ),
      cell: cellContext =>
        cellContext.row.original.status === BookingRequestStatus.ACCEPTED &&
        cellContext.getValue() ? (
          <Typography variant="body3">
            {formatDate(cellContext.getValue()!)}
          </Typography>
        ) : null,
    }),

    columnHelper.accessor(row => row.listing?.basePrice?.totalAmount, {
      id: 'basePrice.totalAmount',
      header: () => (
        <Typography variant="labelMedium" color="primary.medium">
          Price
        </Typography>
      ),
      cell: cellContext => {
        if (
          cellContext.row.original.status !== BookingRequestStatus.ACCEPTED ||
          !cellContext.getValue()
        ) {
          return null;
        }
        const listing = cellContext.row.original.listing;
        const guestPrice =
          listing?.basePrice?.priceType === PriceType.FLAT_FEE
            ? listing?.basePrice?.totalAmount ?? 0
            : (listing?.basePrice?.totalAmount ?? 0) *
              (listing?.capacity?.max || 1);
        return (
          <Typography variant="body3">
            {formatPricing(guestPrice, 2)}
          </Typography>
        );
      },
    }),

    columnHelper.accessor(row => row.listing?.id, {
      id: 'viewQuote',
      header: '',
      cell: cellContext =>
        cellContext.getValue() && onButtonClick ? (
          <Button
            variant="text"
            size="small"
            onClick={() =>
              onButtonClick('viewQuote', cellContext.row.original.id)
            }
          >
            View quote
          </Button>
        ) : null,
      enableSorting: false,
    }),

    columnHelper.accessor('createdAt', {
      header: () => (
        <Typography variant="labelMedium" color="primary.medium">
          Sent
        </Typography>
      ),
      cell: cellContext => (
        <Typography variant="body3">
          {formatDate(cellContext.getValue())}
        </Typography>
      ),
    }),

    columnHelper.accessor('status', {
      header: () => (
        <Typography variant="labelMedium" color="primary.medium">
          Status
        </Typography>
      ),
      cell: cellContext => (
        <Box textAlign="right">
          <Typography
            variant="body3"
            color={getStatusColor(cellContext.getValue())}
          >
            {getStatusName(cellContext.getValue())}
          </Typography>
        </Box>
      ),
    }),

    columnHelper.accessor('id', {
      id: 'view',
      header: '',
      cell: info =>
        info.row.depth === 0 && onButtonClick ? (
          <Button
            variant="outlined"
            size="small"
            onClick={() => onButtonClick('view', info.getValue())}
            sx={{ float: 'right' }}
          >
            view
          </Button>
        ) : null,
      enableSorting: false,
    }),
  ];
};
