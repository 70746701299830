import { ApiClient } from '@/api/apiClient';
import { useAuth } from './useAuth';
import { useQueries } from '@tanstack/react-query';
import { PlanConsumer } from '@/types';

export const useGymAccesses = ({
  membershipPlanPurchaseId,
  planConsumers,
}: {
  membershipPlanPurchaseId: string;
  planConsumers: PlanConsumer[];
}) => {
  const { data: user } = useAuth();
  const apiClient = ApiClient();

  const queryObjects = useQueries({
    queries: planConsumers.map(planConsumer => ({
      queryKey: [
        'memberPhysicalGymAccess',
        membershipPlanPurchaseId,
        planConsumer.consumerId,
      ],
      queryFn: async () => {
        const data =
          await apiClient.membershipPlanPurchases.getMemberPhysicalGymAccess({
            membershipPlanPurchaseId,
            consumerId: planConsumer.consumerId,
          });

        return { ...data, consumerId: planConsumer.consumerId };
      },
      enabled: !!user,
    })),
  });

  return queryObjects;
};
