import InProgress from './in_progress.svg';
import FullCheckmark from './checkmark.svg';
import GrayCheckMark from './gray_checkmark.svg';

export interface SubItemProps {
  values: { name: string; page: number };
  name?: string;
  finished?: 'locked' | 'in progress' | 'finished';
  linkTo?: number | string;
  changePage: (page: number, finished: string) => void;
  id?: number | string;
  furthestPage: number | string;
  currentPage: number | string;
}

export default function SubItem(props: SubItemProps) {
  let image;
  let finished = '';
  if (props.values.page > props.furthestPage) {
    image = GrayCheckMark;
    finished = 'locked';
  }
  if (props.values.page === props.furthestPage) {
    image = InProgress;
  }
  if (props.values.page < props.furthestPage) {
    image = FullCheckmark;
  }

  if (!finished) {
    finished = 'finished';
  }

  return (
    <div
      className={`sub-item ${
        props.currentPage === props.values.page ? 'current' : ''
      }`}
    >
      <div className="sub-item-inner-frame">
        <span
          className="sub-item-label"
          onClick={() => props.changePage(props.values.page, finished)}
        >
          {props.values.name}
        </span>
        <img
          src={image}
          height="24px"
          width="24px"
          alt="icon"
          style={{ marginTop: '16px', marginLeft: '-10px', opacity: '60%' }}
        />
      </div>
    </div>
  );
}
