import { LabelField } from '@/components';
import { Experience, ProductTypes } from '@/types';
import { formatExperienceDuration } from '@/utils';
import { Stack } from '@silverstein-properties/inspirelabs-ui';

import Linkify from 'linkify-react';
import { StyledDescription } from './TitleAndDescriptionSection.styles';

export type TitleAndDescriptionSectionPropsType = {
  experience: Experience;
};

export const TitleAndDescriptionSection = ({
  experience,
}: TitleAndDescriptionSectionPropsType) => {
  const isAdvert = experience.type === ProductTypes.ADVERT;
  return (
    <Stack spacing={4}>
      <LabelField label="Title" value={experience.title || ''} />
      {experience.internalName && (
        <LabelField
          label="Internal Name (not visible to guests)"
          value={experience.internalName}
        />
      )}
      {experience.subtitle && (
        <LabelField label="Subtitle" value={experience.subtitle} />
      )}
      <LabelField
        label="Description"
        value={
          <StyledDescription>
            <Linkify as="p" options={{ target: '_blank', truncate: 30 }}>
              {experience.description || 'This experience has no description'}
            </Linkify>
          </StyledDescription>
        }
      />

      {!isAdvert && (
        <LabelField
          label="Length of Service"
          value={
            formatExperienceDuration(
              experience.duration || { hours: 0, minutes: 0 }
            ) || 'This experience has no duration'
          }
        />
      )}
    </Stack>
  );
};
