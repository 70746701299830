import { AddQuoteInput } from '@/classes';
import { LabelField } from '@/components';
import {
  useBookingRequestLoaded,
  useExperience,
  useLocations,
  useMainCommunicationConversation,
  useMerchantPlatformFee,
  useQuoteMutation,
  useTaxRates,
} from '@/hooks';
import {
  calculatePriceBreakdown,
  convertToLocalDate,
  formatDateShortForm,
  getServiceTypeFromOptions,
  parseFloatIfString,
} from '@/utils';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import {
  Box,
  DatePicker,
  DesktopTimePicker,
  FormControl,
  Grid,
  InputField,
  MessageBanner,
  Stack,
  Typography,
} from '@silverstein-properties/inspirelabs-ui';
import { Controller, useForm } from 'react-hook-form';
import {
  StyledBoxFormControlContainer,
  StyledFormHelperText,
  StyledGridParent,
} from './AddQuote.styles';
import { FormHelperText } from '@mui/material';
import { useEffect, useState } from 'react';
import { HostList } from '@/modules/SchedulingModule/components';
import { CANCELLATION_OPTIONS, TEXT_AREA_LIMIT_EXTRA_LONG } from '@/constants';
import { QuotePricing } from '../QuotePricing';
import { determineTimeFrameMessage } from '@/utils/recurrenceUtils';
import { QuotePriceBreakdown, QuoteSkeleton } from '@/modules/CommonModule';
import { PriceType } from '@/types';

export type AddQuoteProps = {
  bookingRequestId: string;
  closeDrawer: () => void;
  handleSubmitLoading: (isLoading: boolean) => void;
  setIsUnsentQuoteAdded: (isAdded: boolean) => void;
};

export const AddQuote = ({
  bookingRequestId,
  closeDrawer,
  handleSubmitLoading,
  setIsUnsentQuoteAdded,
}: AddQuoteProps) => {
  const [timeFrameMessage, setTimeFrameMessage] = useState<{
    message: string;
    error: boolean;
  }>({ message: '', error: false });
  const [errorMessage, setErrorMessage] = useState('');

  const { data: bookingRequest, isLoading: isBookingRequestLoading } =
    useBookingRequestLoaded(bookingRequestId);
  const {
    data: mainCommunicationThread,
    isLoading: isMainCommunicationThreadLoading,
  } = useMainCommunicationConversation(bookingRequestId);
  const { data: experience, isLoading: isExperienceLoading } = useExperience({
    experienceId: bookingRequest?.product?.id || '',
  });
  const { data: platformFeeRaw, isLoading: isPlatformFeeLoading } =
    useMerchantPlatformFee();
  const { data: taxRates, isLoading: areTaxRateLoading } = useTaxRates();
  const { data: location } = useLocations({
    locationId: experience?.location?.id?.toString() || '',
  });

  const onMutationSuccess = () => {
    setIsUnsentQuoteAdded(true);
    closeDrawer();
  };

  const onMutationError = (errorMessage: string) => {
    console.error('error', errorMessage);
    setErrorMessage(errorMessage);
    setIsUnsentQuoteAdded(false);
  };

  const {
    addQuoteMutation: { mutate: addQuote, isLoading: isAddQuoteLoading },
  } = useQuoteMutation(
    {
      bookingRequestId,
      consumerId: bookingRequest?.consumerId || '',
      experience,
    },
    onMutationSuccess,
    onMutationError
  );

  const resolver = classValidatorResolver(AddQuoteInput, {
    transformer: { enableImplicitConversion: true },
  });
  const {
    control,
    formState,
    handleSubmit: useFormHandleSubmit,
    setValue,
    watch,
    setError,
    clearErrors,
    resetField,
  } = useForm<AddQuoteInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      consumerId: bookingRequest?.consumerId,
      productId: bookingRequest?.product?.id,
      numberOfGuests: '',
      description: '',
      address: '',
      apt: '',
      city: '',
      state: '',
      zipCode: '',
      cancellationPolicy: '',
      basePrice: {
        amount:
          bookingRequest?.product?.basePrice?.totalAmount ||
          bookingRequest?.product?.totalAmount ||
          '',
        priceType: '',
        description: '',
        membershipPlanId: '',
      },
    },
  });
  const { errors } = formState;

  const handleTimeFrameMessage = () => {
    const startTime = watch('startTime');
    const endTime = watch('endTime');

    setTimeFrameMessage(
      determineTimeFrameMessage(startTime, endTime, undefined)
    );
  };

  const setNewEndTime = (newlySelectedDateTime: Date) => {
    const newEndTime = new Date(newlySelectedDateTime.getTime());
    if (newEndTime.getDay() === newlySelectedDateTime.getDay()) {
      setValue('endTime', newEndTime);
    }
  };

  const onChangeStartTime = (newStartTime: Date) => {
    setNewEndTime(newStartTime);
    handleTimeFrameMessage();
  };
  const onChangeEndTime = () => {
    handleTimeFrameMessage();
  };

  const renderDescriptionHelperText = (): string => {
    if (errors.description?.message) {
      return errors.description?.message;
    }
    const descriptionLength = watch('description').length ?? 0;
    return `${descriptionLength}/${TEXT_AREA_LIMIT_EXTRA_LONG}`;
  };

  useEffect(() => {
    if (location?.address) {
      setValue('address', location.address.street);
      if (!!location.address.apartment) {
        setValue('apt', location.address.apartment);
      }
      setValue('city', location.address.city);
      setValue('state', location.address.state);
      setValue('zipCode', location.address.zipCode);
    }
  }, [location]);

  useEffect(() => {
    if (experience?.refundPolicy) {
      setValue(
        'cancellationPolicy',
        experience?.refundPolicy?.cancellationPolicy
      );
    }
  }, [experience]);

  useEffect(() => {
    handleSubmitLoading(isAddQuoteLoading);
  }, [isAddQuoteLoading]);

  if (
    isBookingRequestLoading ||
    isExperienceLoading ||
    isMainCommunicationThreadLoading ||
    isPlatformFeeLoading ||
    areTaxRateLoading
  ) {
    return <QuoteSkeleton />;
  }

  if (!bookingRequestId || !bookingRequest) {
    return null;
  }

  const locationType = getServiceTypeFromOptions(location?.environmentType);

  const price = parseFloatIfString(watch('basePrice.amount'));
  const numberOfGuests = parseFloatIfString(watch('numberOfGuests'));
  const priceType = watch('basePrice.priceType');
  const isPricePerPerson = priceType === PriceType.PRICE_PER_PERSON;
  const totalPrice = isPricePerPerson ? price * numberOfGuests : price;
  const taxRate = taxRates?.find(x => x.id === watch('basePrice.taxRateId'));

  const priceBreakdown = calculatePriceBreakdown({
    feePercentage: platformFeeRaw,
    price: totalPrice,
    taxRate,
  });

  const handleSubmit = (data: AddQuoteInput) => {
    setErrorMessage('');
    addQuote(data);
  };

  return (
    <Box
      component="form"
      onSubmit={useFormHandleSubmit(handleSubmit)}
      id="addQuoteForm"
      sx={{ gap: 0 }}
    >
      {!!errorMessage && (
        <Box sx={{ mb: 2 }}>
          <MessageBanner type="error">{errorMessage}</MessageBanner>
        </Box>
      )}
      <Stack spacing={5} sx={{ width: '100%' }}>
        <Typography variant="h5">
          {
            mainCommunicationThread?.mainCommunicationCard
              .communicationPurchaseName
          }
        </Typography>
        <LabelField
          label="Prepared for"
          value={
            <Typography data-testid="addQuote_consumer">
              {mainCommunicationThread?.mainCommunicationCard.consumerName}
            </Typography>
          }
        />
        <LabelField
          label="Date"
          value={
            <Box mt={2}>
              <FormControl fullWidth>
                <Controller
                  name="date"
                  data-testid="addQuote_date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      label="Date"
                      // Convert the date string to a local date object. DatePicker will display the date in the local time zone
                      value={
                        !!field.value ? convertToLocalDate(field.value) : null
                      }
                      onChange={newValue => {
                        // Convert the local date object back to a date string (e.g. 2024-03-30) and store it in the form state
                        field.onChange(formatDateShortForm(newValue as Date));
                      }}
                      onError={(error, value) => {
                        // Set the error message for the 'date' field
                        if (!!error) {
                          setError('date', {
                            message: `${error}: ${value}`,
                          });
                        } else {
                          clearErrors('date');
                        }
                      }}
                      slotProps={{
                        textField: {
                          error: !!errors.date,
                        },
                        openPickerButton: {
                          sx: {
                            '& > svg': {
                              color: ({ palette }) =>
                                !!errors.date ? palette.error.main : 'initial',
                            },
                          },
                        },
                      }}
                      disablePast
                    />
                  )}
                />
                <FormHelperText error={!!errors.date}>
                  {!!errors.date ? errors.date.message : ''}
                </FormHelperText>
              </FormControl>
            </Box>
          }
        />
        <LabelField
          label="Time"
          value={
            <>
              <StyledBoxFormControlContainer>
                <FormControl>
                  <Controller
                    name="startTime"
                    data-testid="addQuote_startTime"
                    control={control}
                    render={({ field: { onChange, ...field } }) => (
                      <DesktopTimePicker
                        label="From"
                        {...field}
                        value={field.value ? field.value : null}
                        onChange={val => {
                          onChange(val);
                          onChangeStartTime(val);
                        }}
                        slotProps={{
                          textField: {
                            error: !!errors.startTime,
                          },
                          openPickerButton: {
                            sx: {
                              '& > svg': {
                                color: ({ palette }) =>
                                  !!errors.startTime
                                    ? palette.error.main
                                    : palette.grey[600],
                              },
                            },
                          },
                        }}
                      />
                    )}
                  />
                  <FormHelperText error={!!errors.startTime}>
                    {!!errors.startTime ? errors.startTime.message : ''}
                  </FormHelperText>
                </FormControl>
                <FormControl>
                  <Controller
                    name="endTime"
                    data-testid="addQuote_endTime"
                    control={control}
                    render={({ field: { onChange, ...field } }) => (
                      <DesktopTimePicker
                        label="To"
                        {...field}
                        value={field.value ? field.value : null}
                        onChange={val => {
                          onChange(val);
                          onChangeEndTime();
                        }}
                        slotProps={{
                          textField: {
                            error: !!errors.endTime,
                          },
                          openPickerButton: {
                            sx: {
                              '& > svg': {
                                color: ({ palette }) =>
                                  !!errors.endTime
                                    ? palette.error.main
                                    : palette.grey[600],
                              },
                            },
                          },
                        }}
                      />
                    )}
                  />
                  <FormHelperText error={!!errors.endTime}>
                    {!!errors.endTime ? errors.endTime.message : ''}
                  </FormHelperText>
                </FormControl>
              </StyledBoxFormControlContainer>
              {timeFrameMessage.message && (
                <StyledFormHelperText error={timeFrameMessage.error}>
                  {timeFrameMessage.message}
                </StyledFormHelperText>
              )}
            </>
          }
        />
        <LabelField
          label="Number of guests"
          value={
            <Box mt={2}>
              <Controller
                name="numberOfGuests"
                data-testid="addQuote_numberOfGuests"
                control={control}
                render={({ field }) => (
                  <InputField
                    label="Number of guests"
                    type="number"
                    {...field}
                    error={!!errors.numberOfGuests}
                    helperText={errors.numberOfGuests?.message}
                  />
                )}
              />
            </Box>
          }
        />
        <LabelField
          label="Location"
          value={
            <Stack gap={2}>
              <Typography>{locationType?.label}</Typography>
              <StyledGridParent container mb={2}>
                <Grid item xs={9}>
                  <Controller
                    name="address"
                    data-testid="addQuote_address"
                    control={control}
                    render={({ field }) => (
                      <InputField
                        label="Address"
                        {...field}
                        error={!!errors.address}
                        helperText={errors.address?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Controller
                    name="apt"
                    data-testid="addQuote_apt"
                    control={control}
                    render={({ field }) => (
                      <InputField
                        label="Apt./Fl."
                        {...field}
                        error={!!errors.apt}
                        helperText={errors.apt?.message}
                      />
                    )}
                  />
                </Grid>
              </StyledGridParent>
              <StyledGridParent container hasTwoOrMoreChildren>
                <Grid item xs={6}>
                  <Controller
                    name="city"
                    data-testid="addQuote_city"
                    control={control}
                    render={({ field }) => (
                      <InputField
                        label="City"
                        {...field}
                        error={!!errors.city}
                        helperText={errors.city?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Controller
                    name="state"
                    data-testid="addQuote_state"
                    control={control}
                    render={({ field }) => (
                      <InputField
                        label="State"
                        {...field}
                        error={!!errors.state}
                        helperText={errors.state?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Controller
                    name="zipCode"
                    data-testid="addQuote_zipCode"
                    control={control}
                    render={({ field }) => (
                      <InputField
                        label="Zipcode"
                        {...field}
                        error={!!errors.zipCode}
                        helperText={errors.zipCode?.message}
                      />
                    )}
                  />
                </Grid>
              </StyledGridParent>
            </Stack>
          }
        />
        {/* TODO: display lists of host once we have one to many relationship for listing and hosts */}
        <LabelField
          label="Host"
          value={<HostList hosts={experience?.host ? [experience.host] : []} />}
        />
        <LabelField
          label="Please provide full scope of service"
          value={
            <Box mt={2}>
              <Controller
                name="description"
                data-testid="addQuote_description"
                control={control}
                render={({ field }) => (
                  <InputField
                    label="Service description"
                    {...field}
                    multiline
                    minRows={6}
                    error={!!errors.description}
                    helperText={renderDescriptionHelperText()}
                  />
                )}
              />
            </Box>
          }
        />
        <LabelField
          label="Cancellation"
          value={
            <Stack mt={2} spacing={2}>
              <Controller
                name="cancellationPolicy"
                control={control}
                render={({ field }) => (
                  <InputField
                    label="Select policy"
                    {...field}
                    select
                    dropdownItems={CANCELLATION_OPTIONS}
                    error={!!errors.cancellationPolicy}
                    helperText={errors.cancellationPolicy?.message}
                  />
                )}
              />
              <Typography variant="body2">
                Cancellation before the selected timeframe is fully refundable.
                Cancellation after the selected timeframe is non-refundable.
              </Typography>
            </Stack>
          }
        />
        <LabelField
          label="Pricing"
          value={
            <Box mt={2}>
              <QuotePricing
                control={control}
                formState={formState}
                watch={watch}
                setValue={setValue}
                resetField={resetField}
              />
            </Box>
          }
        />
        {!!priceType ? <QuotePriceBreakdown {...priceBreakdown} /> : null}
      </Stack>
    </Box>
  );
};
