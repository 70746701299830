import { BusinessSignupComponentProps } from './Component';
import { useEffect } from 'react';
import { HookFormTextInput } from 'components/FormTextInput/HookFormTextInput';
import { Controller, useForm } from 'react-hook-form';
import { SERVICE_TYPE_CHOICES, TEXT_AREA_LIMIT_LONG } from '@/constants';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { useFormData } from '../../contexts/MerchCreationProvider';
import { PartialMerchCreationObject } from '@/types';
import Select from 'react-select';
import {
  styles,
  errorStyles,
  customValueContainer,
} from 'components/FormTextInput/HookFormSelect';
import { ExperienceLocationInput } from '@/classes';
import { capitalizeWords } from '@/utils';
import {
  Box,
  Button,
  InputField,
  Svg,
} from '@silverstein-properties/inspirelabs-ui';

const resolver = classValidatorResolver(ExperienceLocationInput);

export function ExperienceLocation(props: BusinessSignupComponentProps) {
  const { setFormValues, merchandiseData } = useFormData();
  const {
    control,
    register,
    formState: { dirtyFields, errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm<ExperienceLocationInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      locationType: merchandiseData.locationType || '',
      locationAddress: merchandiseData.locationAddress || '',
      locationApt: merchandiseData.locationApt || '',
      locationCity: merchandiseData.locationCity || '',
      locationState: merchandiseData.locationState || '',
      locationZip: merchandiseData.locationZip || '',
      locationDescription: merchandiseData.locationDescription || '',
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = (data: PartialMerchCreationObject) => {
    setFormValues(data);
    props.updateExperienceLocation(data);
    props.nextPage();
  };

  // control._fields

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(
      event.target.name as
        | 'locationType'
        | 'locationAddress'
        | 'locationCity'
        | 'locationApt'
        | 'locationState'
        | 'locationZip'
        | 'locationDescription'
        | 'locationType.value'
        | 'locationType.label'
        | 'locationType.id',
      capitalizeWords(
        event.target.value,
        event.target.name === 'locationState' ? 'Uppercase' : 'Each word'
      )
    );
  };

  const renderDescriptionHelperText = (): string => {
    if (errors.locationDescription?.message) {
      return errors.locationDescription?.message;
    }
    const descriptionLength = watch('locationDescription')?.length ?? 0;
    return `${descriptionLength}/${TEXT_AREA_LIMIT_LONG}`;
  };

  return (
    <div className="row ms-xl-5 ms-lg-5 me-md-5 ms-sm-1 ms-xs-1 top-64 d-flex justify-content-end">
      <div className="col-8">
        <h4 className="business-form-headline mb-5">Experience Location</h4>
        <div className="theme-form">
          <div
            className="business-form-subtitle"
            style={{ marginBottom: '16px' }}
          >
            Where is the experience taking place?
          </div>
          <Controller
            name="locationType"
            control={control}
            rules={{
              required: { value: true, message: 'Please enter a value' },
            }}
            render={({ field }) => (
              <div data-testid="experienceLocation.type">
                <Select
                  {...field}
                  inputId="location-category-dropdown"
                  data-testid="experienceLocation.type"
                  isSearchable={false}
                  options={SERVICE_TYPE_CHOICES}
                  styles={!!errors.locationType ? errorStyles : styles}
                  components={{ ValueContainer: customValueContainer }}
                  placeholder="Select"
                />
              </div>
            )}
          />
          <div className="business-form-subtitle">
            Where should the guests meet you?
          </div>
          <div className="theme-form">
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
              <span className="inline-group" style={{ marginTop: '16px' }}>
                <HookFormTextInput
                  error={errors.locationAddress}
                  isFilled={
                    Object.keys(dirtyFields).includes('locationAddress') ||
                    !!merchandiseData.locationAddress
                  }
                  register={register}
                  name="locationAddress"
                  testid="experienceLocation.address"
                  label="Address"
                  type="text"
                  width="form-l"
                  rounded="rounded-left"
                />
                <HookFormTextInput
                  isFilled={
                    Object.keys(dirtyFields).includes('locationApt') ||
                    !!merchandiseData.locationApt
                  }
                  register={register}
                  name="locationApt"
                  testid="experienceLocation.apt"
                  label="Apt./Fl."
                  type="text"
                  width="form-s"
                  rounded="rounded-right"
                />
              </span>
              <span className="inline-group">
                <HookFormTextInput
                  error={errors.locationCity}
                  isFilled={
                    Object.keys(dirtyFields).includes('locationCity') ||
                    !!merchandiseData.locationCity
                  }
                  register={register}
                  name="locationCity"
                  testid="experienceLocation.city"
                  label="City"
                  type="text"
                  width="form-m"
                  rounded="rounded-left"
                  onChange={handleChange}
                />
                <HookFormTextInput
                  error={errors.locationState}
                  isFilled={
                    Object.keys(dirtyFields).includes('locationState') ||
                    !!merchandiseData.locationState
                  }
                  register={register}
                  name="locationState"
                  testid="experienceLocation.state"
                  label="State"
                  type="text"
                  width="form-s"
                  rounded="square-input"
                  onChange={handleChange}
                />
                <HookFormTextInput
                  error={errors.locationZip}
                  isFilled={
                    Object.keys(dirtyFields).includes('locationZip') ||
                    !!merchandiseData.locationZip
                  }
                  register={register}
                  name="locationZip"
                  testid="experienceLocation.zip"
                  label="Zipcode"
                  type="text"
                  width="form-s"
                  rounded="rounded-right"
                />
              </span>
              <div className="business-form-subtitle">
                Describe the location
              </div>
              <Controller
                name="locationDescription"
                control={control}
                render={({ field }) => (
                  <InputField
                    {...field}
                    inputProps={{
                      'data-testid': 'experienceLocation.description',
                    }}
                    multiline
                    minRows={5}
                    fullWidth
                    label="Location description"
                    error={!!errors.locationDescription}
                    helperText={renderDescriptionHelperText()}
                  />
                )}
              />
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Button
                  variant="text"
                  onClick={props.prevPage}
                  startIcon={
                    <Svg
                      src="/images/chevron-left.svg"
                      height={24}
                      width={24}
                    />
                  }
                >
                  Back
                </Button>
                <Button type="submit">Continue</Button>
              </Box>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}
