import { CreateListing, Listing } from 'types';

export class ResponseRecurringEventDto {
  recurringEvent: RecurringEvent;
  listings?: Listing[];
}

export class RecurringEvent {
  id: string;
  productId: string;
  startDateTime: Date;
  endDateTime: Date;
  timeZone: string;
  repeatDays?: RepeatDays;
  timeInterval?: TimeInterval;
  merchantId: number;

  createdAt?: Date;
  createdUserId?: string;

  deletedAt?: Date;
  deletedUserId?: string;

  updatedAt?: Date;
  updatedUserId?: string;
}

export interface RepeatDays {
  until: Date;
  daysOfWeek?: DaysOfTheWeek[];
  type: RepeatDaysType; // current default weekly
  interval?: number; // current default 1
}

export enum DaysOfTheWeek {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

export enum RepeatDaysType {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  // weekly, monthly, etc.
}

export interface TimeInterval {
  intervalSegment: number;
  timeBetweenInterval: number;
}

export interface CreateRecurringEvent {
  productId: string;
  startDateTime: Date;
  timeZone?: string;
  endDateTime?: Date;
  repeatDays?: RepeatDays;
  timeInterval?: TimeInterval;
  listingDto?: Partial<CreateListing>;
}

export interface UpdateRecurringEvent extends Partial<RecurringEvent> {
  listingId: string;
  listingDto?: Partial<CreateListing>;
}
