import { AxiosInstance } from 'axios';
import {
  Experience,
  Listing,
  CreateListing,
  UserDashboardMetrics,
  PurchaseMethods,
  EditListingType,
  SquareMenu,
} from '../../../types';

export const endpoints = {
  merchantProducts({ merchantId }: { merchantId: string }) {
    return `/merchants/${merchantId}/products`;
  },
  products({ productId }: { productId: string }) {
    return `/products/${productId}`;
  },
  productMenu({ productId }: { productId: string }) {
    return `/products/${productId}/menu`;
  },
  productListings({ productId }: { productId: string }) {
    return `/products/${productId}/listings`;
  },
  productPartial() {
    return '/products/partial';
  },
  productPurchaseMethods() {
    return '/products/purchaseMethods';
  },
  productSubmit({ productId }: { productId: string }) {
    return `/products/${productId}/submit`;
  },
  dashboardOverview({ merchantId }: { merchantId: string }) {
    return `/merchants/${merchantId}/dashboardOverview`;
  },
  listings({ listingId }: { listingId: string }) {
    return `/listings/${listingId}`;
  },
  allMerchantListings({ merchantId }: { merchantId: string }) {
    return `/merchants/${merchantId}/listings`;
  },
  cancelListing({ listingId }: { listingId: string }) {
    return `/listings/${listingId}/cancel`;
  },
  closeListing({ listingId }: { listingId: string }) {
    return `/listings/${listingId}/close-event`;
  },
  messageAllBookedGuests({ listingId }: { listingId: string }) {
    return `/listings/${listingId}/participants/messages`;
  },
  messageOneBookedGuest({
    listingId,
    consumerId,
  }: {
    listingId: string;
    consumerId: string;
  }) {
    return `/listings/${listingId}/participants/${consumerId}/messages`;
  },
};

export const products = ({
  axiosInstance,
}: {
  axiosInstance: AxiosInstance;
}) => {
  return {
    getMerchantProducts: async ({
      merchantId,
    }: {
      merchantId: string;
    }): Promise<Experience[]> => {
      const { data } = await axiosInstance.get(
        endpoints.merchantProducts({ merchantId })
      );
      return data;
    },
    getProductListing: async ({
      productId,
      startsAt,
      endsAt,
    }: {
      productId: string;
      startsAt?: Date;
      endsAt?: Date;
    }): Promise<Listing[]> => {
      const { data } = await axiosInstance.get(
        endpoints.productListings({ productId }),
        {
          params: {
            startsAt,
            endsAt,
          },
        }
      );
      return data;
    },
    getProduct: async ({
      productId,
    }: {
      productId: string;
    }): Promise<Experience> => {
      const { data } = await axiosInstance.get(
        endpoints.products({ productId })
      );
      return data;
    },
    getMenuProduct: async ({
      productId,
      skipCache,
    }: {
      productId: string;
      skipCache?: boolean;
    }): Promise<SquareMenu> => {
      const { data } = await axiosInstance.get(
        endpoints.productMenu({ productId }),
        {
          params: {
            skipCache,
          },
        }
      );
      return data;
    },
    createProductListing: async ({
      productId,
      newListing,
    }: {
      productId: string;
      newListing: Partial<CreateListing>;
    }): Promise<Listing> => {
      const { data } = await axiosInstance.post(
        endpoints.productListings({ productId }),
        newListing
      );
      return data;
    },
    getListing: async ({ listingId }: { listingId: string }) => {
      const { data } = await axiosInstance.get(
        endpoints.listings({ listingId })
      );
      return data;
    },
    getAllMerchantListings: async ({
      merchantId,
      startsAt,
      endsAt,
    }: {
      merchantId: string;
      startsAt?: Date;
      endsAt?: Date;
    }) => {
      const { data } = await axiosInstance.get(
        endpoints.allMerchantListings({ merchantId }),
        {
          params: {
            startsAt,
            endsAt,
          },
        }
      );
      return data;
    },
    editProductListing: async ({
      editedListing,
    }: {
      editedListing: EditListingType;
    }): Promise<Listing> => {
      const listingId = editedListing.id;
      const { data } = await axiosInstance.put(
        endpoints.listings({ listingId }),
        editedListing
      );
      return data;
    },
    cancelProductListing: async ({
      listingId,
    }: {
      listingId: string;
    }): Promise<Listing> => {
      const { data } = await axiosInstance.put(
        endpoints.cancelListing({ listingId })
      );
      return data;
    },
    closeProductListing: async ({
      listingId,
      updateIsEventClosed,
    }: {
      listingId: string;
      updateIsEventClosed: boolean;
    }): Promise<Listing> => {
      const { data } = await axiosInstance.put(
        endpoints.closeListing({ listingId }),
        { isEventClosed: updateIsEventClosed }
      );
      return data;
    },
    createPartialProduct: async ({
      partialProduct,
    }: {
      partialProduct: Partial<Experience>;
    }): Promise<Partial<Experience>> => {
      const { data } = await axiosInstance.post(
        endpoints.productPartial(),
        partialProduct
      );
      return data;
    },
    getPurchaseMethods: async (): Promise<
      { value: PurchaseMethods; name: string; default: boolean }[]
    > => {
      const { data } = await axiosInstance.get(
        endpoints.productPurchaseMethods()
      );
      return data;
    },
    submitProduct: async ({
      productId,
    }: {
      productId: string;
    }): Promise<Experience> => {
      const { data } = await axiosInstance.put(
        endpoints.productSubmit({ productId })
      );
      return data;
    },
    updateProduct: async ({
      productId,
      changes,
    }: {
      productId: string;
      changes: Partial<Experience>;
    }): Promise<Experience> => {
      const { data } = await axiosInstance.put(
        endpoints.products({ productId }),
        changes
      );
      return data;
    },
    getDashboardOverview: async ({
      merchantId,
    }: {
      merchantId: string;
    }): Promise<UserDashboardMetrics> => {
      const { data } = await axiosInstance.get(
        endpoints.dashboardOverview({ merchantId })
      );
      return data;
    },
    messageAllBookedGuests: async ({
      listingId,
      message,
    }: {
      listingId: string;
      message: string;
    }): Promise<string> => {
      const { data } = await axiosInstance.post(
        endpoints.messageAllBookedGuests({ listingId }),
        { emailMessage: message }
      );
      return data;
    },
    messageOneBookedGuest: async ({
      listingId,
      consumerId,
      message,
    }: {
      listingId: string;
      consumerId: string;
      message: string;
    }): Promise<string> => {
      const { data } = await axiosInstance.post(
        endpoints.messageOneBookedGuest({ listingId, consumerId }),
        { emailMessage: message }
      );
      return data;
    },
  };
};
