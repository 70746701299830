export enum ProgressType {
  NONE = 0,
  PROGRESSING,
  COMPLETE,
}

export type Todo = {
  id?: string | number; // todo's ID
  name: string; // displayed text of Todo item
  description: string; // extra context, displayed onhover
  progressAmount: ProgressType;
};
