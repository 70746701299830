import { PhysicalAccessErrorCodes } from '@/types/Gym';

export const getReadableErrorMessage = (key?: string): string | undefined => {
  if (key && key in PhysicalAccessErrorCodes) {
    return PhysicalAccessErrorCodes[
      key as keyof typeof PhysicalAccessErrorCodes
    ];
  }
  return;
};
