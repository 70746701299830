import {
  CancellationPolicy,
  ExperienceCapacity,
  ExperienceHost,
  RefundPolicy,
} from './Experience';
import { Experience, UpdatePriceDto, Price, EventLocation } from 'types';

export interface Listing {
  id: string;
  title: string;
  description: string;
  price: number;
  totalAmount: number;
  inPerson: boolean;
  hasBookings: boolean;
  apt?: string;
  city?: string;
  state?: string;
  zip?: string;
  meetingPointId: number;
  meetingPoint?: Partial<EventLocation>;
  host?: ExperienceHost;
  productId: string;
  startsAt: Date;
  endsAt: Date;
  purchasedBy: Array<string>;
  status?: ListingStatus;
  productSnapshot?: Experience;
  recurringEventId?: string;
  isEventClosed?: boolean;
  refundPolicy?: RefundPolicy;
  capacity?: ExperienceCapacity;
  basePrice?: Price;
  basePriceId?: string;

  createdUserId: string;
  createdAt: Date;
  updatedUserId?: string;
  updatedAt?: Date;
  deletedUserId?: string;
  deletedAt?: Date;
  nbOfGuests?: number;
}

export enum ListingStatus {
  ACTIVE = 'ACTIVE',
  MERCHANT_CANCELLED = 'MERCHANT_CANCELLED',
  MERCHANT_PENDING = 'MERCHANT_PENDING',
  CONSUMER_PENDING = 'CONSUMER_PENDING', // For when a booking request is pending by consumer
  DRAFT = 'DRAFT', // For when a listing is in draft mode, aka quote created but not attached
  DELETED = 'DELETED', // Soft deleted
  //  could have "PAST EVENT" status
}

export interface EditListingType extends Omit<Listing, 'basePrice'> {
  locationId?: number;
  basePrice: UpdatePriceDto;
}

export interface CreateListing {
  id?: string;
  title: string;
  date?: string;
  startsAt?: Date;
  endsAt?: Date;
  price?: number;
  totalAmount?: number;
  productId?: string;
  meetingPointId?: number;
  meetingPoint?: Partial<EventLocation>;
  host?: Partial<ExperienceHost>;
  description: string;
  status?: ListingStatus;
  cancellationPolicy?: CancellationPolicy;
  capacity?: ExperienceCapacity;
  basePrice?: UpdatePriceDto;
}

export interface CalendarListing {
  id: string;
  title: string;
  start: Date | string;
  end: Date | string;
  backgroundColor?: string;
  borderColor?: string;
  classNames?: string;
  nbOfGuests: number;
  nbOfCancellations: number;
  capacity?: number;
  host?: ExperienceHost;
  status?: ListingStatus;
  productSnapshot?: Experience;
}
