import { MerchantMonthlyPaymentStatement } from '@/types';
import { DataTable } from '@/components';
import { useState } from 'react';
import {
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useNavigate } from 'react-router';
import { getTableColumns } from './StatementTable.columns';

export type StatementBlockProps = {
  statements: MerchantMonthlyPaymentStatement[];
};

export const StatementTable = ({ statements }: StatementBlockProps) => {
  const navigate = useNavigate();
  const [data] = useState(() => [...statements]);
  const [sorting, setSorting] = useState<SortingState>([]);

  const table = useReactTable({
    data,
    columns: getTableColumns(navigate),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return <DataTable table={table} rightAlignedColumns={['netEarnings']} />;
};
