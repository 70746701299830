import {
  Box,
  Button,
  Typography,
} from '@silverstein-properties/inspirelabs-ui';
import { NavigateFunction } from 'react-router';
import { routes } from '@/routing/routes';
import { formatPricing } from '@/utils';
import { createColumnHelper } from '@tanstack/react-table';
import { MerchantMonthlyPaymentStatement } from '@/types';

export const getTableColumns = (navigate: NavigateFunction) => {
  const columnHelper = createColumnHelper<MerchantMonthlyPaymentStatement>();
  return [
    columnHelper.accessor('billingCycle', {
      header: () => (
        <Typography variant="labelMedium" color="primary.medium">
          Billing cycle
        </Typography>
      ),
      cell: info => (
        <Typography variant="subtitle2">{info.getValue()}</Typography>
      ),
      size: 500,
    }),
    columnHelper.accessor('netEarnings', {
      header: () => (
        <Typography variant="labelMedium" color="primary.medium">
          Net earnings
        </Typography>
      ),
      cell: info => (
        <Typography variant="subtitle2" textAlign="right">
          {formatPricing(info.getValue() || 0, 2)}
        </Typography>
      ),
      size: 500,
    }),
    columnHelper.accessor('id', {
      header: '',
      cell: info => (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            size="small"
            variant="outlined"
            onClick={() =>
              navigate(routes.statements.details(info.getValue() || ''))
            }
          >
            Details
          </Button>
        </Box>
      ),
      size: 100,
      enableSorting: false,
    }),
  ];
};
