import { useAuth } from './useAuth';
import { useQuery } from '@tanstack/react-query';
import { ApiClient } from '../api/apiClient';
import { Membership } from '@/types/MerchantMembership';

export const useMemberships = () => {
  const { data: user } = useAuth();
  const apiClient = ApiClient();

  const getMemberships = async (
    accessToken?: string
  ): Promise<Membership[]> => {
    if (accessToken) {
      await apiClient.memberships.updateMembershipCount({
        merchantId: Number(user?.primaryMerchantId),
      });
      return await apiClient.memberships.getMerchantMemberships({
        merchantId: Number(user?.primaryMerchantId),
      });
    } else {
      return [];
    }
  };

  return useQuery<Membership[]>(
    ['memberships', user?.primaryMerchantId],
    () => getMemberships(user?.accessToken),
    { enabled: !!user }
  );
};
