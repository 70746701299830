import { Allow, IsNotEmpty, IsNumber, Min, Max } from 'class-validator';
import { Transform } from 'class-transformer';

export class GroupSizeInput {
  @Allow()
  capacityMin: string;

  @Transform(({ value }) => parseInt(value), { toClassOnly: true })
  @IsNotEmpty({ message: 'Please enter a maximum capacity.' })
  @Max(999, { message: 'Maximum capacity cannot be greater than 999' })
  @Min(1, { message: 'Maximum capacity cannot be less than 1' })
  @IsNumber(undefined, { message: 'Maximum capacity must be a number' })
  capacityMax: string;
}
