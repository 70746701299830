import { Experience } from 'types';
import { useAuth } from './useAuth';
import { useQuery } from '@tanstack/react-query';
import { ApiClient } from '../api/apiClient';

export type UseExperiencePropsType = {
  experienceId: string;
};

export const useExperience = ({ experienceId }: UseExperiencePropsType) => {
  const { data: user } = useAuth();
  const apiClient = ApiClient();

  const getProduct = async (): Promise<Experience> => {
    return await apiClient.products.getProduct({
      productId: experienceId,
    });
  };

  return useQuery<Experience>(
    ['experience', experienceId],
    () => getProduct(),
    { enabled: !!user }
  );
};
