import Vector from './vector.svg';
import Lock from './lock.svg';
import GreenCheck from './checkmark.svg';
import SubItem from './SubItem';
import React from 'react';

export interface MainItemProps {
  name: string;
  subItems: { name: string; page: number }[];
  finished: 'locked' | 'in progress' | 'finished';
  linkTo: number;
  changePage: (name: number, finished: string) => void;
  pageIsOpen: boolean;
  handleOpen: (event: React.MouseEvent<HTMLElement>) => void;
  id?: number;
  furthestPage: number;
  currentPage: number;
}

export default function MainItem(props: MainItemProps): JSX.Element {
  let image;
  if (props.finished === 'locked') {
    image = Lock;
  }
  if (props.finished === 'finished') {
    image = GreenCheck;
  }

  return (
    <>
      <div className={!props.pageIsOpen ? 'main-item' : 'open-main-item'}>
        <div className="main-item-inner-frame">
          <img
            src={Vector}
            onClick={props.handleOpen}
            id={`${props.id} ${props.finished}`}
            height="6px"
            width="12px"
            className={
              props.pageIsOpen ? 'main-category-open' : 'main-category-closed'
            }
            alt="vector"
          />

          <span
            className="main-item-label"
            onClick={() => props.changePage(props.linkTo, props.finished)}
          >
            {props.name}
          </span>
        </div>
        {image && (
          <img
            src={image}
            height="24px"
            width="24px"
            alt="icon"
            className="main-item-image"
          />
        )}
      </div>
      {props.pageIsOpen &&
        props.subItems &&
        props.subItems.map(subitem => (
          <SubItem
            currentPage={props.currentPage}
            furthestPage={props.furthestPage}
            changePage={props.changePage}
            values={subitem}
            key={subitem.name}
          />
        ))}
    </>
  );
}
