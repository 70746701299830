import { AxiosInstance } from 'axios';
import { Location } from 'types';

export const endpoints = {
  location: '/locations',
  getLocation({ locationId }: { locationId: string }) {
    return `/locations/${locationId}`;
  },
};

export const locations = ({
  axiosInstance,
}: {
  axiosInstance: AxiosInstance;
}) => {
  return {
    createLocation: async ({
      newLocation,
    }: {
      newLocation: Partial<Location>;
    }): Promise<Location> => {
      const { data } = await axiosInstance.post(
        endpoints.location,
        newLocation
      );
      return data;
    },
    getLocation: async ({
      locationId,
    }: {
      locationId: string;
    }): Promise<Location> => {
      const { data } = await axiosInstance.get(
        endpoints.getLocation({ locationId })
      );
      return data;
    },
    updateLocation: async ({
      locationId,
      changes,
    }: {
      locationId: string;
      changes: Partial<Location>;
    }): Promise<Location> => {
      const { data } = await axiosInstance.patch(
        endpoints.getLocation({ locationId }),
        changes
      );
      return data;
    },
  };
};
