import { useAuth } from '../useAuth';
import { useQuery } from '@tanstack/react-query';
import { ApiClient } from '../../api/apiClient';
import { DropdownType } from '@/types';

export const useSubCategoriesChoices = () => {
  const { data: user } = useAuth();
  const apiClient = ApiClient();
  const merchantId = user?.primaryMerchantId.toString();

  const getSubCategoriesChoices = async (
    accessToken?: string
  ): Promise<DropdownType[] | undefined> => {
    if (accessToken && merchantId) {
      return await apiClient.productLookupItems.getSubCategoryChoices();
    } else {
      return undefined;
    }
  };

  return useQuery<DropdownType[] | undefined>(
    ['subcategoriesChoices'],
    () => getSubCategoriesChoices(user?.accessToken),
    {
      enabled: !!user && !!user.id,
    }
  );
};
