import { Grid, styled } from '@silverstein-properties/inspirelabs-ui';

export const StyledGrid = styled(Grid)(({ theme }) => ({
  '& > *': {
    justifyContent: 'space-between',
    padding: theme.spacing(1, 1, 1, 0),
  },
  '& > *:last-of-type': {
    borderTop: `1px solid ${theme.palette.primary.main}`,
  },
}));
