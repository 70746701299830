import { StyledTodoContainer, StyledEmptyState } from './TodoContainer.styles';
import { NumericCircle } from 'components/NumericCircle';
import {
  Box,
  IconBlock,
  Stack,
  Typography,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import { TodoItem } from '../TodoItem';
import { Todo } from 'types/Todo';

export const EmptyTodos = ({ text }: { text: string }) => {
  return (
    <StyledEmptyState>
      <IconBlock
        icon="/images/success.svg"
        iconColor="success.main"
        title={text}
        titleSize="subtitle1"
      />
    </StyledEmptyState>
  );
};

export const TodoContainer = ({ todos }: { todos: Todo[] }) => {
  const theme = useTheme();

  return (
    <StyledTodoContainer>
      <Stack
        direction="row"
        alignItems="center"
        spacing={theme.spacers.s}
        marginBottom={theme.spacers.m}
      >
        <Typography variant="h5">To do</Typography>
        {!!todos?.length && (
          <NumericCircle value={todos?.length || 0} size="sm" />
        )}
      </Stack>
      <Box width="100%">
        {todos?.length ? (
          todos.map(todo => (
            <TodoItem
              key={todo.name}
              todoItem={todo}
              arrayLength={todos.length}
            />
          ))
        ) : (
          <EmptyTodos text="You're all caught up on your to-dos!" />
        )}
      </Box>
    </StyledTodoContainer>
  );
};
