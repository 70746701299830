import { Grid, Typography } from '@silverstein-properties/inspirelabs-ui';

export const MembershipHeaderRow = () => {
  return (
    <Grid container item alignItems="center" padding={2} xs={12}>
      <Grid item xs={2}>
        <Typography variant="body2">Membership</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body2">Billing</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body2">Status</Typography>
      </Grid>
      <Grid item xs={2} textAlign="right">
        <Typography variant="body2">Active members</Typography>
      </Grid>
      <Grid item xs={2} textAlign="right">
        <Typography variant="body2">Avg. monthly income</Typography>
      </Grid>
    </Grid>
  );
};
