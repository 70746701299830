import { DropdownItem } from '@silverstein-properties/inspirelabs-ui';

// TODO: move to BE
export const TIME_INTERVALS: DropdownItem[] = [
  {
    label: 'None',
    value: 0,
  },
  {
    label: 'Every 5 minutes',
    value: 5,
  },
  {
    label: 'Every 10 minutes',
    value: 10,
  },
  {
    label: 'Every 15 minutes',
    value: 15,
  },
  {
    label: 'Every 20 minutes',
    value: 20,
  },
  {
    label: 'Every 30 minutes',
    value: 30,
  },
  {
    label: 'Every 45 minutes',
    value: 45,
  },
  {
    label: 'Every hour',
    value: 60,
  },
  {
    label: 'Every 1.5 hours',
    value: 90,
  },
  {
    label: 'Every 2 hours',
    value: 120,
  },
];

export const TIME_INTERVALS_BETWEEN: DropdownItem[] = [
  {
    label: 'None',
    value: 0,
  },
  {
    label: '5 minutes',
    value: 5,
  },
  {
    label: '10 minutes',
    value: 10,
  },
  {
    label: '15 minutes',
    value: 15,
  },
  {
    label: '20 minutes',
    value: 20,
  },
  {
    label: '30 minutes',
    value: 30,
  },
  {
    label: '45 minutes',
    value: 45,
  },
  {
    label: 'hour',
    value: 60,
  },
  {
    label: '1.5 hours',
    value: 90,
  },
  {
    label: '2 hours',
    value: 120,
  },
];
