import { Membership } from 'types';
import { useAuth } from './useAuth';
import { useQuery } from '@tanstack/react-query';
import { ApiClient } from '../api/apiClient';

export type UseMembershipPropsType = {
  membershipId: string;
  withProducts?: boolean;
};

export const useMembership = ({
  membershipId,
  withProducts = false,
}: UseMembershipPropsType) => {
  const { data: user } = useAuth();
  const apiClient = ApiClient();

  const getMembership = async (): Promise<Membership> => {
    return await apiClient.memberships.getMembership({
      membershipId,
      withProducts,
    });
  };

  return useQuery<Membership>(
    ['membership', membershipId],
    () => getMembership(),
    { enabled: !!user }
  );
};
