import { useMembershipPlanByMembership } from '@/hooks/useMembershipPlanPurchaseByMembership';
import { Membership, MembershipPlanPurchase } from '@/types';
import {
  CloseIcon,
  IconButton,
  InputField,
  SearchIcon,
  Skeleton,
  Typography,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { StyledTableRow } from './Members.styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { MemberRow } from './MemberRow';

export type SkeletonTableItemsPropsTypeOld = {
  show: boolean;
  title: string;
  numberOfSkeletons?: number;
};

export const SkeletonTableItemsOld = ({
  show = false,
  title,
  numberOfSkeletons = 3,
}: SkeletonTableItemsPropsTypeOld) => {
  if (!show) {
    return null;
  }
  return (
    <>
      {[...Array(numberOfSkeletons)].map((_, index) => (
        <StyledTableRow key={`${title}-${index}`}>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
        </StyledTableRow>
      ))}
    </>
  );
};

export type TableItemsPropsTypeOld = {
  show: boolean;
  membershipPlanPurchases: MembershipPlanPurchase[] | undefined;
};

// TODO: I disabled one of the eslint rule (which prefers arrow function) because `memo()` doesn't take arrow functions
// eslint-disable-next-line prefer-arrow-callback
export const TableItemsOld = memo(function TableItems({
  show = false,
  membershipPlanPurchases,
}: TableItemsPropsTypeOld) {
  if (!show) {
    return null;
  }

  if (
    !membershipPlanPurchases ||
    (membershipPlanPurchases && membershipPlanPurchases.length === 0)
  ) {
    return (
      <StyledTableRow>
        <TableCell colSpan={7}>
          <Typography variant="body2" align="center">
            No members
          </Typography>
        </TableCell>
      </StyledTableRow>
    );
  }

  return (
    <>
      {membershipPlanPurchases.map(membershipPlanPurchase => (
        <MemberRow
          key={membershipPlanPurchase.id}
          membershipPlanPurchase={membershipPlanPurchase}
        />
      ))}
    </>
  );
});

export type MembersSectionPropsTypeOld = {
  membership: Membership;
  onMembersNumberChange?: (value: number) => void;
};

export const MembersSectionOld = ({
  membership,
  onMembersNumberChange,
}: MembersSectionPropsTypeOld) => {
  const { data: membershipPlanPurchases, isLoading } =
    useMembershipPlanByMembership({
      membershipId: membership.id,
    });

  // ========================= TEMPORARY SOLUTION: Search member logic =========================
  const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState('');

  const filteredPlanPurchases = useMemo(() => {
    if (!membershipPlanPurchases) {
      return [];
    }

    return membershipPlanPurchases.filter(purchase => {
      const primaryMemberfullName =
        `${purchase.firstName} ${purchase.lastName}`.toLowerCase();
      const isPrimaryMemberMatched = primaryMemberfullName.includes(
        searchTerm.toLowerCase()
      );

      const isPlanConsumerMatched = purchase.planConsumers
        .filter(
          planConsumer => planConsumer.consumerId !== purchase.primaryConsumerId
        )
        .some(planConsumer => {
          const consumerFullName =
            `${planConsumer.consumer?.firstName} ${planConsumer.consumer?.lastName}`.toLowerCase();
          return consumerFullName.includes(searchTerm.toLowerCase());
        });

      return isPrimaryMemberMatched || isPlanConsumerMatched;
    });
  }, [membershipPlanPurchases, searchTerm]);

  const handleMemberSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
    },
    []
  );
  // ============================================================================================

  useEffect(() => {
    if (
      !isLoading &&
      membershipPlanPurchases &&
      membershipPlanPurchases?.length > 0 &&
      onMembersNumberChange
    ) {
      const members = membership.membershipPlans.reduce(
        (acc, membershipPlan) =>
          acc + (membershipPlan?.membersCount?.totalActiveMembers || 0),
        0
      );
      onMembersNumberChange(members);
    }
  }, [isLoading, membershipPlanPurchases, onMembersNumberChange]);

  return (
    <TableContainer>
      <InputField
        label="Search members..."
        placeholder="Search members..."
        showFloatingLabel={false}
        notched={false}
        value={searchTerm}
        onChange={handleMemberSearch}
        startAdornment={
          <SearchIcon
            width={theme.constants.ICON_SIZE.small}
            style={{ marginRight: theme.spacers.s }}
          />
        }
        endAdornment={
          searchTerm ? (
            <IconButton onClick={() => setSearchTerm('')}>
              <CloseIcon width={theme.constants.ICON_SIZE.small} />
            </IconButton>
          ) : undefined
        }
        sx={{
          borderRadius: theme.constants.BORDER_RADIUS.large,
          width: '280px', // TODO: Need to validate these constants w/ Yael, doesn't match current design system
          height: '44px',
        }}
      />

      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>
              <Typography variant="body3">Name</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="body3">Plan</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="body3">Members</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body3">Payment</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body3">Status</Typography>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          <SkeletonTableItemsOld show={isLoading} title={membership.id} />
          <TableItemsOld
            show={!isLoading}
            membershipPlanPurchases={filteredPlanPurchases}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
};
