import { AmountInput } from '@/classes';
import AlertDialog from '@/components/AlertDialog/AlertDialog';
import { AmountDialog } from '@/components/AmountDialog/AmountDialog';
import {
  useCancelInvoiceMutation,
  useMembershipPlanPurchaseInvoices,
  usePayInvoiceMutation,
  useRefundInvoiceMutation,
} from '@/hooks/useMembershipPlanPurchaseInvoices';
import {
  InvoicePaymentStatus,
  MembershipPlanPurchase,
  MembershipPlanPurchaseInvoice,
  RefundReasons,
} from '@/types';
import {
  displayInvoicePaymentColor,
  displayInvoicePaymentMethod,
  displayInvoicePaymentStatus,
  displayInvoicePaymentValue,
  formatDate,
  invoicePaymentStatus,
} from '@/utils';
import {
  CreditsIcon,
  ListGroup,
  ListGroupItemProps,
  Menu,
  MenuItem,
} from '@silverstein-properties/inspirelabs-ui';
import { useState } from 'react';

export type PaymentHistoryListPropsType = {
  planPurchase: MembershipPlanPurchase | undefined;
  onGroupItemClick?: (
    payment: MembershipPlanPurchaseInvoice | undefined,
    action: 'cancel' | 'refund' | 're-run'
  ) => Promise<void> | void;
};

export const PaymentHistoryList = ({
  planPurchase,
  onGroupItemClick = () => Promise.resolve(),
}: PaymentHistoryListPropsType) => {
  const [anchorEl, setAnchorEl] = useState<null | Element>(null);
  const [openCancelInvoiceAlertDialog, setOpenCancelInvoiceAlertDialog] =
    useState(false);
  const [errorCancelInvoiceAlertDialog, setErrorCancelInvoiceAlertDialog] =
    useState('');
  const [openRefundModal, setOpenRefundModal] = useState(false);
  const [openRechargeModal, setOpenRechargeModal] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState<{
    payment: MembershipPlanPurchaseInvoice;
    status: InvoicePaymentStatus;
  } | null>(null);
  const openMenu = Boolean(selectedPayment);

  const {
    data: paymentHistory,
    isLoading: isPaymentHistoryLoading,
    refetch: refetchInvoices,
  } = useMembershipPlanPurchaseInvoices({
    membershipPlanPurchaseId: planPurchase?.id || '',
    limit: 20,
    execute: !!planPurchase?.id,
  });

  const { mutateAsync: refundInvoice } = useRefundInvoiceMutation({
    membershipPlanPurchaseId: planPurchase?.id || '',
  });

  const { mutateAsync: payInvoice } = usePayInvoiceMutation({
    membershipPlanPurchaseId: planPurchase?.id || '',
  });

  const { mutateAsync: cancelInvoice } = useCancelInvoiceMutation({
    membershipPlanPurchaseId: planPurchase?.id || '',
  });

  // Transform the payment history data to have the shape of "ListGroupItem"
  const transformedPaymentHistory: ListGroupItemProps[] =
    paymentHistory?.data.map((payment: MembershipPlanPurchaseInvoice) => {
      const invoiceStatus = invoicePaymentStatus(payment);
      return {
        label1: formatDate(payment.created),
        label2: planPurchase?.membershipPlanSnapshot.name,
        value1: displayInvoicePaymentValue(payment), // Convert cents -> dollars
        value2: displayInvoicePaymentMethod(payment), // To display "Visa... 4242"
        assistiveText: displayInvoicePaymentStatus(payment),
        assistiveTextColor: displayInvoicePaymentColor(invoiceStatus),
        payment,
        invoicePaymentStatus: invoiceStatus,
      };
    });

  const handleRefund = async (amountInput: AmountInput) => {
    await refundInvoice({
      invoiceId: selectedPayment?.payment.id || '',
      amount: (Math.round((+amountInput.amount + Number.EPSILON) * 100) /
        100) as number,
      reason: amountInput.reason as RefundReasons,
    });
    await onGroupItemClick(selectedPayment?.payment, 'refund');
    setSelectedPayment(null);
    refetchInvoices();
  };

  const handlePay = async (amountInput: AmountInput) => {
    await payInvoice({
      invoiceId: selectedPayment?.payment.id || '',
      amount: (Math.round((+amountInput.amount + Number.EPSILON) * 100) /
        100) as number,
    });
    onGroupItemClick(selectedPayment?.payment, 're-run');
    setSelectedPayment(null);
    refetchInvoices();
  };

  const handleCancel = async () => {
    try {
      await cancelInvoice({
        invoiceId: selectedPayment?.payment.id || '',
      });
      await onGroupItemClick(selectedPayment?.payment, 'cancel');
      setSelectedPayment(null);
      setOpenCancelInvoiceAlertDialog(false);
      refetchInvoices();
    } catch (e) {
      setErrorCancelInvoiceAlertDialog((e as Error).message);
      return;
    }
  };

  const onListItemClick = (
    payment: ListGroupItemProps,
    event: React.MouseEvent<Element, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedPayment({
      payment: payment.payment as MembershipPlanPurchaseInvoice,
      status: payment.invoicePaymentStatus as InvoicePaymentStatus,
    });
  };

  return (
    <>
      <ListGroup
        title="Payment history"
        listItems={transformedPaymentHistory}
        isLoading={isPaymentHistoryLoading}
        showRightIcon
        initialState="open"
        maxHeight="500px"
        onListItemClick={onListItemClick}
      />
      <Menu
        open={openMenu}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
          setSelectedPayment(null);
        }}
      >
        {selectedPayment &&
          [
            InvoicePaymentStatus.SETTLED,
            InvoicePaymentStatus.PARTIAL_REFUNDED,
          ].includes(selectedPayment.status) && (
            <MenuItem onClick={() => setOpenRefundModal(true)}>
              Refund member
            </MenuItem>
          )}
        {selectedPayment?.status === InvoicePaymentStatus.DECLINED && (
          <MenuItem onClick={() => setOpenRechargeModal(true)}>
            Re-run charge
          </MenuItem>
        )}
        {selectedPayment?.status === InvoicePaymentStatus.DECLINED && (
          <MenuItem onClick={() => setOpenCancelInvoiceAlertDialog(true)}>
            Cancel charge
          </MenuItem>
        )}
        {selectedPayment?.status === InvoicePaymentStatus.PROCESSING && (
          <MenuItem>
            Your refund/charge is being processed, please check back later
          </MenuItem>
        )}
        {selectedPayment &&
          [
            InvoicePaymentStatus.REFUNDED,
            InvoicePaymentStatus.CANCELED,
            InvoicePaymentStatus.UPCOMING,
            InvoicePaymentStatus.DISPUTED,
          ].includes(selectedPayment.status) && (
            <MenuItem>No action available</MenuItem>
          )}
      </Menu>
      <AmountDialog
        type="refund"
        open={openRefundModal}
        onConfirm={handleRefund}
        onClose={value => setOpenRefundModal(value)}
        title="Refund member"
        agree="Process Refund"
        disagree="Cancel"
        helperText="Enter any amount you'd like to refund, up to a maximum of the original transaction value"
        maxAmount={
          selectedPayment
            ? selectedPayment.payment.amountPaid -
              (selectedPayment.payment.paymentIntent?.latestCharge
                ?.amountRefunded || 0)
            : 0
        }
      />
      <AmountDialog
        type="re-run"
        open={openRechargeModal}
        onConfirm={handlePay}
        onClose={value => setOpenRechargeModal(value)}
        title="Re-run charge"
        agree="Re-run charge"
        disagree="Cancel"
        helperText="Enter any amount you'd like to re-run, up to a maximum of the original transaction value"
        maxAmount={selectedPayment ? selectedPayment.payment.amountDue : 0}
      />
      {openCancelInvoiceAlertDialog && (
        <AlertDialog
          open={openCancelInvoiceAlertDialog}
          onClose={async (didUserAccept: boolean) => {
            if (didUserAccept) {
              return await handleCancel();
            } else {
              setOpenCancelInvoiceAlertDialog(false);
              setErrorCancelInvoiceAlertDialog('');
              setSelectedPayment(null);
              return Promise.resolve();
            }
          }}
          image={<CreditsIcon />}
          title="Cancel charge?"
          message={`Clicking the "Confirm" button below will cancel the charge of ${
            selectedPayment ? selectedPayment?.payment.amountDue : 0
          }`}
          errorMessage={errorCancelInvoiceAlertDialog}
          agree="Confirm"
          disagree="Cancel"
        />
      )}
    </>
  );
};
