import FileInput from 'components/FormTextInput/FileInput';
import { BusinessSignupComponentProps } from './Component';
import MiniFileInput from 'components/FormTextInput/MiniFileInput';
import React, { FormEvent, useEffect, useState } from 'react';
import {
  Box,
  Button,
  MessageBanner,
  Svg,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';

type ResetProps = {
  reset: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

type CoverPhotoProps = BusinessSignupComponentProps & ResetProps;

export function AddCoverPhoto(props: CoverPhotoProps) {
  const theme = useTheme();
  const [warning, setWarning] = useState<string>('');
  const [arePhotosDirty, setPhotosDirty] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const checkThatCoverPhotoExists = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!props.values.coverPhoto) {
      setWarning('Please select a cover photo');
    } else {
      props.updateExperience(undefined, arePhotosDirty);
      props.nextPage();
    }
  };

  const onCoverPhotoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setWarning('');
    }
    setPhotosDirty(true);
    props.handleChange(e);
  };

  const onExtraPhotoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhotosDirty(true);
    props.handleChange(e);
  };

  return (
    <div className="row ms-xl-5 ms-lg-5 me-md-5 ms-sm-1 ms-xs-1 top-64 d-flex justify-content-end">
      <div className="col-8">
        <div className="business-form-headline mb-5">Add photos</div>
        <div className="business-form-subtitle mb-3">
          Choose a great cover photo that best represents your experience
        </div>
        <div className="theme-form">
          {warning && (
            <Box sx={{ width: '100%', marginBottom: theme.spacers.m }}>
              <MessageBanner type="error">{warning}</MessageBanner>
            </Box>
          )}
          <form onSubmit={e => checkThatCoverPhotoExists(e)}>
            <FileInput
              testId="Photos.coverPhoto"
              name="coverPhoto"
              handleChange={onCoverPhotoChange}
              multiple={false}
              value={props.values.coverPhoto}
              reset={props.reset}
            />

            <div className="photo-subtitle-label">
              Photo should have good image quality—no heavy filters,
              distortions, overlaid text, or watermarks.
              <p className="mt-1 text-decoration-underline">
                <a
                  title="Our Photo Guidelines"
                  href="/photoguidelines"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read our tips for best quality images
                </a>
              </p>
            </div>

            <div className="business-form-subtitle mb-1">
              Add four more photos that accurately illustrate the experience
            </div>
            <div
              style={{
                width: '600px',
                display: 'flex',
                flexDirection: 'row',
                gap: '24px',
              }}
            >
              <MiniFileInput
                keyNumber="1"
                handleChange={onExtraPhotoChange}
                size="mini-input-rectangle"
                name="extraPhoto1"
                testId="Photos.extraPhoto1"
                value={props.values.extraPhoto1}
                reset={props.reset}
              />
              <MiniFileInput
                keyNumber="2"
                handleChange={onExtraPhotoChange}
                size="mini-input-rectangle"
                name="extraPhoto2"
                testId="Photos.extraPhoto2"
                value={props.values.extraPhoto2}
                reset={props.reset}
              />
            </div>
            <div
              style={{
                width: '600px',
                display: 'flex',
                flexDirection: 'row',
                gap: '24px',
              }}
            >
              <MiniFileInput
                keyNumber="3"
                handleChange={onExtraPhotoChange}
                size="mini-input-rectangle"
                name="extraPhoto3"
                testId="Photos.extraPhoto3"
                value={props.values.extraPhoto3}
                reset={props.reset}
              />
              <MiniFileInput
                keyNumber="4"
                handleChange={onExtraPhotoChange}
                size="mini-input-rectangle"
                name="extraPhoto4"
                testId="Photos.extraPhoto4"
                value={props.values.extraPhoto4}
                reset={props.reset}
              />
            </div>
            <div className="photo-subtitle-label">
              Choose a variety of details and angles, including photos of people
              in action.
              <p className="mt-1 text-decoration-underline">
                <a
                  title="Our Photo Guidelines"
                  href="/photoguidelines"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read our tips for best quality images
                </a>
              </p>
            </div>

            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Button
                variant="text"
                onClick={props.prevPage}
                startIcon={
                  <Svg src="/images/chevron-left.svg" height={24} width={24} />
                }
              >
                Back
              </Button>
              <Button type="submit">Continue</Button>
            </Box>
          </form>
        </div>
      </div>
    </div>
  );
}
