import React, { useState } from 'react';
import { UserSignUpObject } from '../../types';
import axios from 'axios';

export function useHooks() {
  const [warning, setWarning] = useState(''); //sets the error to display to user
  const [currentPage, setCurrentPage] = useState(1);
  const [newUser, setNewUser] = useState<UserSignUpObject>({
    firstName: '',
    lastName: '',
    personalEmail: '',
    personalPhone: '',
    companyName: '',
    address: '',
    apt: '',
    userState: '',
    city: '',
    zip: '',
    companyURL: '',
    supportEmail: '',
    category: '',
    years: '',
    description: '',
    linkedIn: '',
    facebook: '',
    otherPage: '',
    otherLinks: '',
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    if (e.target) {
      const inputName = e.target.name || e.target.title;
      const inputValue = e.target.value || e.target.id;
      setNewUser(oldValues => ({
        ...oldValues,
        [inputName]: inputValue,
      }));
    }
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const formatPhoneNumber = (phoneNumber: string): string => {
    // refactor this to util folder OR
    let newNumber = phoneNumber.replace(/\D/g, ''); // deprecate after react hook form integration
    if (newNumber[0] === '1') {
      newNumber = newNumber.substring(1);
    } // remove country code
    if (newNumber.length >= 11) {
      newNumber = newNumber.slice(0, 10);
    } // remove extra digits(only for testing, not neccesary with FE validation)
    return newNumber;
  };

  // Disabled rule due to current implementation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const postNewUser = async (newUser: any) => {
    let result = 'OK'; //default assumption is POC + merch are created

    const newContact = {
      firstName: newUser.firstName,
      lastName: newUser.lastName,
      email: newUser.personalEmail,
      phone: formatPhoneNumber(newUser.personalPhone),
    };

    const newMerchant = {
      companyName: newUser.companyName,
      street: newUser.address,
      apartment: newUser.apt,
      state: newUser.merchantState,
      city: newUser.city,
      zipCode: newUser.zip,
      country: 'USA',
      websiteUrl: newUser.companyURL,
      email: newUser.supportEmail,
      yearsOperating: newUser.years.value,
      description: newUser.description,
      linkedinUrl: newUser.linkedIn,
      facebookUrl: newUser.facebook,
      otherUrl1: newUser.otherPage,
      otherUrl2: newUser.otherLinks,
      // businessCategoryId: 10,
    };

    const contactURL = `${process.env.REACT_APP_MERCHANT_VETTING_SERVICE_BASE_URL}/contacts`;
    const merchRequestURL = `${process.env.REACT_APP_MERCHANT_VETTING_SERVICE_BASE_URL}/merchant-registration-requests`;
    const postNewMerchant = async (api: string, merchant: {}) => {
      //posts new merchant request
      try {
        const response = await axios.post(api, merchant);
        const data = response.data;
        return data;
      } catch (error) {
        return error;
      }
    };

    const postNewContact = async (url: string, contact: {}) => {
      //add new POC to DB and return it
      try {
        const response = await axios.post(url, contact);
        const data = await response.data;
        return data;
      } catch (error) {
        return error;
      }
    };

    const createdUser = await postNewContact(contactURL, newContact);
    if (!createdUser.id) {
      result = 'Error creating user'; // return error if problem creating POC
      return result;
    }
    const pocId = createdUser.id.toString();
    const merchantToPost = {
      ...newMerchant,
      ...{ contactId: pocId },
    };
    const createdMerchant = await postNewMerchant(
      merchRequestURL,
      merchantToPost
    );
    if (createdMerchant.message) {
      // rewrite with more specificity
      result = 'Error creating merchant'; // return error if problem creating merch request
      return result;
    }
    return result; // should return 'ok', everything successful
  };

  // useEffect(() => {
  //   setNewUser(getStoredValues('SPI_HUB_SIGNUP', newUser));
  // }, []);

  // useEffect(() => {
  //   setTimeout(
  //     () =>
  //       window.localStorage.setItem('SPI_HUB_SIGNUP', JSON.stringify(newUser)),
  //     0
  //   );
  // }, [newUser]);

  // Disabled rule due to current implementation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submitAndSuccess = async (data: any) => {
    // submit form, display warning or display success
    const results = await postNewUser(data);
    if (results !== 'OK') {
      setWarning(results);
    } else {
      handleNextPage();
    }
  };

  return {
    currentPage,
    warning,
    newUser,
    postNewUser,
    handleNextPage,
    handlePrevPage,
    handleChange,
    submitAndSuccess,
  };
}
