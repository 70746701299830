import { Link } from 'react-router-dom';
import './terms.css';

export const TermsAndConditions = () => {
  return (
    <div className="container-fluid bg-secondary headings-line-height">
      <div className="row pt-5 px-5 d-flex justify-content-center">
        <div></div>
        <div className="col-8">
          <h2 className="my-5">Terms and Conditions</h2>
          <p className="policy-text">
            These Terms of Service (“<strong>Terms</strong>”) are a binding
            legal agreement between you and The HUB that govern your right to
            use the websites, applications, and other offerings from The HUB
            (collectively, the “<strong>The HUB Platform</strong>”). When used
            in these Terms, “<strong>The HUB</strong>,” “<strong>we</strong>,” “
            <strong>us</strong>,” or “<strong>our</strong>” refers to The HUB
            entity with whom you are contracting.
          </p>

          <p className="policy-text">
            The The HUB Platform offers an online venue that enables users (“
            <strong>Members</strong>”) to publish, offer, search for, and book
            services. Members who publish and offer services are “
            <strong>Hosts</strong>” and Members who search for, book, or use
            services are “<strong>Guests</strong>.” Hosts offer activities,
            excursions, and events (“<strong>Experiences</strong>”), and a
            variety of travel and other services (collectively, “
            <strong>Host Services</strong>,” and each Host Service offering, a “
            <strong>Listing</strong>”). You must register an account to access
            and use many features of The HUB Platform, and must keep your
            account information accurate. As the provider of The HUB Platform,
            The HUB does not own, control, offer or manage any Listings or Host
            Services.
          </p>
          <p className="policy-text">
            We maintain other{' '}
            <a href="/" target="_blank;">
              terms
            </a>{' '}
            and{' '}
            <a href="/" target="_blank;">
              policies
            </a>{' '}
            that supplement these Terms like our{' '}
            <a href="/" target="_blank;">
              Privacy Policy
            </a>
            , which describes our collection and use of personal data, and our{' '}
            <a href="/" target="_blank;">
              Payments Terms
            </a>
            , which govern any payment services provided to Members by The HUB
            payment entities (collectively "<strong>The HUB Payments</strong>").
          </p>
          <p className="policy-text">
            If you are a Host, you are responsible for understanding and
            complying with all laws, rules, regulations and contracts with third
            parties that apply to your Host Services.
          </p>
          <p className="policy-text">
            This HUB Terms of Use (this “<strong>Agreement</strong>” or “
            <strong>Terms</strong>”) sets forth the legally binding terms and
            conditions for your use of the HUB websites, including any
            subdomains thereof, and mobile sites (collectively, the “
            <strong>Site</strong>”) and the content, features, and services (the
            “<strong>Services</strong>”) made available by HUB. The Site and
            Services together are hereinafter collectively referred to as the “
            <strong>HUB Platform</strong>”. The terms “<strong>User</strong>”
            and “<strong>you</strong>” mean any user of the Services.
          </p>
          <p className="policy-text">
            Our Privacy Policy also governs your use of our Service and explains
            how we collect, safeguard and disclose information that results from
            your use of our web pages.
          </p>
          <p className="policy-text">
            By accessing, browsing or using the HUB Platform or by clicking
            “Accept” or “Agree” to this Agreement, (1) you acknowledge that you
            have read, understood, and agreed to be bound by this Agreement, and
            (2) you represent and warrant that you are of legal age and not
            prohibited by law from accessing or using the Services. IF YOU DO
            NOT AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT,
            YOU MAY NOT USE OR ACCESS THE HUB PLATFORM OR ANY OF THE SERVICES
            PROVIDED THROUGH THE HUB PLATFORM.
          </p>
          <h5 className="my-2">1. Scope of HUB Services</h5>
          <p className="policy-text">
            The HUB Platform is an online marketplace that enables certain third
            parties who offer experience services (“<strong>Vendors</strong>”)
            to publish such experience offerings on the HUB Platform (“
            <strong>Listings</strong>”), and allow registered users (“
            <strong>Users</strong>”) to book such Listings for the specific
            dates and times. It also allows Vendors to transact directly with
            Users that are seeking to book such experiences.
          </p>
          <p className="policy-text">
            As the online marketplace, HUB does not own, create, sell, resell,
            provide, control, manage, offer, deliver, or supply any experience
            Listings. Vendors alone are responsible for their Listings and the
            services promised or provided under each such Listing. Each time
            Users and Vendors make or accept a booking, they enter into a
            contract directly with each other. The HUB Platform may contain
            links to third-party websites or resources (“
            <strong>Third-Party Services</strong>”). Such Third-Party Services
            may be subject to different terms and conditions and privacy
            practices. HUB is not responsible or liable for the availability or
            accuracy of such Third-Party Services, or the content, products, or
            services available from such Third-Party Services. Links to such
            Third-Party Services are not endorsements by HUB of such Third-Party
            Services.
          </p>
          <h5 className="my-2">2. Modification to the Agreement</h5>
          <p className="policy-text">
            HUB reserves the right to modify the terms and conditions of this
            Agreement and such modifications (“<strong>Modifications</strong>”)
            shall be binding on you only upon your acceptance of the modified
            Agreement. If and when we make Modifications to this Agreement, we
            will post the revised Terms on the HUB Platform and update the “Last
            Updated” date at the top of these Terms to reflect the date on which
            such Terms were modified.
          </p>
          <p className="policy-text">
            HUB reserves the right to modify any information on pages referenced
            in the hyperlinks from this Agreement from time to time, and such
            modifications shall become effective upon posting. Continued use of
            the HUB Platform or Experience Services after any such changes shall
            constitute your consent to such changes. Unless material changes are
            made to the arbitration provisions herein, you agree that
            modification of this Agreement does not create a renewed opportunity
            to opt out of arbitration (if applicable).
          </p>
          <h5 className="my-2">3. Eligibility</h5>
          <p className="policy-text">
            In order to access and use the HUB Platform or create an account
            with HUB (“<strong>HUB Account</strong>”) you must be an individual
            at least <strong>18</strong> years old or a duly organized, validly
            existing business, organization or other legal entity in good
            standing under the laws of the country you are established and able
            to enter into legally binding contracts. HUB may make access to and
            use of the HUB Platform, or certain areas or features of the HUB
            Platform, subject to certain conditions or requirements, such as
            completing a verification process, meeting specific quality or
            eligibility criteria, meeting Ratings or Reviews thresholds, or a
            user's booking and cancellation history.
          </p>
          <p className="policy-text">
            User verification on the Internet is difficult and we do not assume
            any responsibility for the confirmation or assertion of any User or
            Vendor's identity.
          </p>
          <h5 className="my-2">4. Accounts</h5>
          <p className="policy-text">
            When you create an account with us, you guarantee that you are above
            the age of <strong>18</strong>, and that the information you provide
            us with is accurate, complete, and current at all times. Inaccurate,
            incomplete, or obsolete information may result in the immediate
            termination of your account on Service.
          </p>
          <p className="policy-text">
            You are responsible for maintaining the confidentiality of your
            account and password, including but not limited to the restriction
            of access to your computer and/or account. You agree to accept
            responsibility for any and all activities or actions that occur
            under your account and/or password, whether your password is with
            our Service or a third-party service. You must notify us immediately
            upon becoming aware of any breach of security or unauthorized use of
            your account.
          </p>
          <p className="policy-text">
            You may not use as a username the name of another person or entity
            or that is not lawfully available for use, a name or trademark that
            is subject to any rights of another person or entity other than you,
            without appropriate authorization. You may not use as a username any
            name that is offensive, vulgar or obscene.
          </p>
          <p className="policy-text">
            We reserve the right to refuse service, terminate accounts, remove
            or edit content, or cancel orders in our sole discretion.
          </p>

          <h5 className="my-2">5. Terms specific for Vendors</h5>
          <p className="policy-text">
            When creating a Listing through the HUB Platform you agree and
            acknowledge that (i) all information provided is complete and
            accurate information about your Listing (such as activity
            description, location, pictures and calendar availability), (ii) all
            critical information such as any restrictions and requirements that
            apply to the Listing (such as any minimum age, proficiency or
            fitness requirements for an activity), any risks inherent to the
            experience activity, anything else Users may need to know to safely
            participate (including dress codes, equipment, special
            certifications or licenses, etc) have been fully disclosed, (iii)
            any other pertinent information requested by HUB or otherwise has
            been provided, and (iv) that you have all licenses and will fully
            comply with all regulations that apply to your delivery and
            provision of services to HUB and its clients. You understand and
            agree that regulated products such as alcoholic beverages are sold
            directly by you to clients, and HUB is not a reseller or distributor
            of such substances. You shall be responsible for keeping the Listing
            information (including calendar availability) up-to-date at all
            times.
          </p>
          <p className="policy-text">
            As a Vendor, you shall be solely responsible for setting a price
            (including any Taxes if applicable, or charges such as cleaning
            fees) for your Listing (“Activity Fee”). Once a User requests a
            booking of your Listing, you may not request that the User pays a
            higher price than in the booking request.
          </p>
          <p className="policy-text">
            You are solely responsible for determining and complying with your
            obligations to report, collect, remit or include in your Activity
            Fee any applicable taxes (<strong>“Taxes”</strong>).
          </p>
          <p className="policy-text">
            Pictures, animations or videos (collectively,{' '}
            <strong>“Images”</strong>) used in your Listings must accurately
            reflect the quality and condition of your Services. HUB reserves the
            right to require that Listings have a minimum number of Images of a
            certain format, size and resolution. The placement and ranking of
            Listings on the HUB Platform may vary and depend on a variety of
            factors, such as User search parameters, location and preferences,
            price and calendar availability, number and quality of Images,
            booking and cancellation history, Reviews and Ratings, type of
            experience Service.
          </p>
          <p className="policy-text">
            When you accept or have pre-approved a booking request by a User,
            you are entering into a legally binding agreement with the User and
            are required to provide your experience Service(s) to the User as
            described in your Listing when the booking request is made. You also
            agree to pay the applicable Vendor Fee and any applicable Taxes.
          </p>
          <p className="policy-text">
            You may list more than one experience Listings. HUB reserves the
            right to decide, in its sole discretion, whether a submitted
            experience offering will be published on the HUB Platform.
          </p>
          <h5 className="my-2">5.1 Prices and Taxes on the Marketplace</h5>
          <p className="policy-text">
            <ul>
              <li className="policy-list">
                <strong>Price Setting</strong>: As a vendor on The HUB
                marketplace, you have the autonomy to set the prices for your
                products and services. The HUB marketplace will handle the
                collection of payments from consumers.
              </li>
              <li className="policy-list">
                <strong>Sales Tax</strong>: If your products and services are
                subject to sales tax, you must set the appropriate tax rate to
                be collected on your behalf. The HUB will collect this tax
                amount for you. However, it is your responsibility to set the
                correct tax rates for the relevant jurisdictions and to remit
                the collected tax amounts to the respective tax authorities.
              </li>
            </ul>
          </p>
          <h5 className="my-2">5.2 Platform Fees</h5>
          <p className="policy-text">
            <ul>
              <li className="policy-list">
                <strong>Service Fees</strong>: In return for the services and
                exposure provided by The HUB marketplace, a portion of the sales
                price is retained by Inspire Labs as a Platform fee.
              </li>
              <li className="policy-list">
                <strong>Fee Breakdown</strong>: A Platform fee of the sales
                price, which includes:
                <ul>
                  <li className="policy-list">
                    A transaction fee, covering payment processing fees.
                  </li>
                  <li className="policy-list">A marketplace fee.</li>
                </ul>
              </li>
            </ul>
          </p>
          <h5 className="my-2">5.3 Payouts</h5>
          <p className="policy-text">
            <ul>
              <li className="policy-list">
                <strong>Gross Amount Calculation</strong>: At the end of each
                calendar month (the Period), The HUB calculates the total amount
                from all successful payments made for your products and
                memberships. Only payments that have succeeded within the Period
                are accounted for.
              </li>
              <li className="policy-list">
                <strong>Deductions</strong>: The Gross amount is subject to the
                following deductions:
                <ul>
                  <li className="policy-list">
                    Any refunds issued within the Period.
                  </li>
                  <li className="policy-list">
                    The HUB Platform fees for the payments made in the Period.
                  </li>
                </ul>
              </li>
              <li className="policy-list">
                <strong>Net Amount</strong>: The Net amount is determined by
                subtracting the refunds and platform fees from the Gross amount.
              </li>
              <li className="policy-list">
                <strong>Collateral</strong>: A percentage of the Gross amount is
                set aside as collateral to cover potential disputes and/or
                future refunds related to the Period. This collateral is paid
                back in the next Period payout.
                <ul>
                  <li className="policy-list">
                    If your Merchant account is closed, the final Period
                    collateral will be paid out in the Period following the
                    account closure. Any outstanding fees, refunds, disputes,
                    and other charges will be deducted from this collateral.
                  </li>
                </ul>
              </li>
              <li className="policy-list">
                <strong>Period Payout Calculation</strong>: The Period Payout is
                calculated as Net amount - current period collateral + previous
                period collateral.
              </li>
              <li className="policy-list">
                <strong>Payment Statement</strong>: A payment statement is
                generated and made available to you in the days following the
                end of a month to allow for the settlement of payments approved
                by card issuers. Once the statement is generated, the payout is
                sent to your account.
              </li>
            </ul>
          </p>
          <h5 className="my-2">5.4 Connecting Your Bank Account</h5>
          <p className="policy-text">
            <ul>
              <li className="policy-list">
                <strong>Payout Method</strong>: The HUB uses Stripe Connect to
                send payouts to vendors. As part of your merchant onboarding
                process, you will receive a link to set up your Stripe Connect
                account and connect your bank account to receive the payouts.
              </li>
            </ul>
          </p>

          <h5 className="my-2">6. Terms specific for Users</h5>

          <p className="policy-text">
            Subject to meeting any requirements set by HUB and/or the Vendor,
            you can book a Listing available on the HUB Platform by following
            the respective booking process. All applicable fees, including the
            Activity Fee, Service Fee will be included in the Vendor Charges,
            and will be presented to you prior to booking a Listing. You agree
            to pay the Vendor Charges for any booking requested in connection
            with your HUB Account.
          </p>
          <p className="policy-text">
            Upon receipt of a booking confirmation from HUB, a legally binding
            agreement is formed between you and your Vendor, subject to any
            additional terms and conditions of the Vendor that apply, including
            in particular the applicable cancellation policy and any rules and
            restrictions specified in the Listing. HUB Platform will collect the
            Vendor Charges at the time of the booking. You are also required to
            ensure that every additional User attending the activity meets any
            requirements set by the Vendor, and is made aware of and agrees to
            these Terms and any terms and conditions, rules and restrictions set
            by the Vendor.
          </p>
          <p className="policy-text">
            You should carefully review the description of services and
            amenities provided by the Vendor (<strong>“Vendor Services”</strong>
            ) for the Listing you intend to book and ensure that you (and any
            additional guests you are booking for) meet any minimum age,
            proficiency, fitness or other requirements which the Vendor has
            specified in their Listing. At your sole discretion you may want to
            inform the Vendor of any medical or physical conditions, or other
            circumstances that may impact your and any additional guest's
            ability to participate in any experience activity. In addition,
            certain laws, like the minimum legal drinking age at the location of
            the Listing may also apply. You are responsible for identifying,
            understanding, and complying with all laws, rules and regulations
            that apply to your participation in a Vendor Service.
          </p>

          <h5 className="my-2">6.1. Charges</h5>
          <p className="policy-text">
            HUB may charge fees from Vendors a certain percentage of the fees (
            <strong>“Vendor Charges”</strong>) paid by Users for booking a
            Listing posted on the HUB Platform (“Vendor Fees”), and/or Users (
            <strong>“Service Fees”</strong>) in consideration for the use of the
            HUB Platform. Any applicable Vendor Fees will be provided to a
            Vendor prior to publishing its Listing.
          </p>
          <p className="policy-text">
            HUB has the authority and reserves the right to determine the
            Service Fees by quoting Users a price for a specific experience at
            the time of booking. HUB also reserves the right to change the
            Service Fees after the time of booking, provided however, HUB will
            not make any such changes without informing the Users and obtaining
            their express consent. The Users are responsible for reviewing the
            price quote on the relevant booking pages within the HUB Platform
            and shall be responsible for all Vendor Charges incurred under their
            User account regardless of their awareness of such Vendor Charges or
            the amounts thereof.
          </p>

          <h5 className="my-2">7. Facilitation of Charges</h5>

          <p className="policy-text">
            All payments on the HUB Platform are facilitated through a
            third-party payment processor (e.g., Stripe, Inc., or Braintree, a
            division of PayPal, Inc.). HUB may replace its third-party payment
            processor without notice to you. Charges shall only be made through
            the HUB Platform. With the exception of tips to Vendors, cash
            payments are strictly prohibited. HUB may group multiple charges
            into a single aggregate transaction on your payment method based on
            the date(s) they were incurred. If you don't recognize a
            transaction, then check your booking confirmation receipts.
          </p>

          <h5 className="my-2">8. Booking Cancellations and Refunds</h5>

          <p className="policy-text">
            Vendors may cancel a confirmed experience booking if weather poses a
            safety risk to Users, or if it prevents a Vendor from carrying out a
            experience Service that takes place primarily outdoors. Vendors may
            also cancel the booking if other conditions exist that would prevent
            the Vendor from offering the experience Service safely. If a Vendor
            cancels a confirmed booking, the User will receive a full refund of
            any payments made towards the Vendor Charges for such booking.
          </p>
          <p className="policy-text">
            Users may cancel a confirmed booking at any time pursuant to the
            Listing's cancellation policy set by the Vendor, and HUB will refund
            the amount due to the User in accordance with such cancellation
            policy, or as agreed to by the Vendor. Users may reach out to HUB
            Platform to help facilitate such cancellation.
          </p>

          <h5 className="my-2">9. Ratings and Reviews</h5>

          <p className="policy-text">
            Within a certain timeframe after completing the experience per the
            booking request, Users can leave a public review (
            <strong>“Review”</strong>) and submit a star rating (
            <strong>“Rating”</strong>) about the Vendor's Listing. Ratings or
            Reviews reflect the opinions of individual Users and do not reflect
            the opinion of HUB. Ratings and Reviews are not verified by HUB for
            accuracy and may be incorrect or misleading.
          </p>
          <p className="policy-text">
            Ratings and Reviews by Users must be accurate and must not contain
            any offensive or defamatory language.
          </p>
          <p className="policy-text">
            Ratings and Reviews are part of a Vendor's public profile and may be
            surfaced anywhere on the HUB Platform (such as the Vendor Listing
            page) together with other relevant information.
          </p>

          <h5 className="my-2">10. HUB Communications</h5>

          <p className="policy-text">
            By entering into this Agreement or using the HUB Platform, you agree
            to receive communications from us and your hosting Vendor, including
            via e-mail, text message and calls. Communications from HUB may
            include but are not limited to: operational communications
            concerning your HUB Account or use of the HUB Platform (such as
            upcoming bookings), updates concerning new and existing features on
            the HUB Platform, communications concerning promotions run by us or
            our third-party partners. Standard text messaging charges applied by
            your cell phone carrier will apply to text messages we send.
          </p>

          <h5 className="my-2">11. Feedback</h5>

          <p className="policy-text">
            We welcome and encourage you to provide feedback, comments,
            suggestions for improvements, ideas, problems, complaints and other
            matters related to the HUB Platform (<strong>“Feedback”</strong>).
            You may provide us either directly at{' '}
            <strong>
              <a href="mailto:support@inspirehub.io">support@inspirehub.io</a>
            </strong>{' '}
            or by other means of communication.
          </p>
          <p className="policy-text">
            You acknowledge and agree that: (i) you shall not retain, acquire or
            assert any intellectual property right or other right, title or
            interest in or to the Feedback; (ii) Company may have development
            ideas similar to the Feedback; (iii) Feedback does not contain
            confidential information or proprietary information from you or any
            third party; and (iv) Company is not under any obligation of
            confidentiality with respect to the Feedback. In the event the
            transfer of the ownership to the Feedback is not possible due to
            applicable mandatory laws, you grant Company and its affiliates an
            exclusive, transferable, irrevocable, free-of-charge,
            sub-licensable, unlimited and perpetual right to use (including
            copy, modify, create derivative works, publish, distribute and
            commercialize) Feedback in any manner and for any purpose.
          </p>

          <h5 className="my-2">12. Disclaimers</h5>

          <p className="policy-text">
            If you choose to use the HUB Platform, you do so voluntarily and at
            your sole risk. You agree that you have had whatever opportunity you
            deem necessary to investigate the HUB Services, laws, rules, or
            regulations that may be applicable to your Listings and/or Services
            you are receiving and that you are not relying upon any statement of
            law or fact made by HUB relating to a Listing.
          </p>
          <p className="policy-text">
            You agree that HUB is a marketplace and may not vet/verify each
            listing. You also agree and acknowledge that some Vendor Services
            may carry inherent risk, and by participating in such services, you
            choose to assume those risks voluntarily. For example, some Vendor
            Services may carry risk of illness, bodily injury, disability, or
            death, and you freely and willfully assume those risks by choosing
            to participate in those Vendor Services. You assume full
            responsibility for the choices you make before, during and after
            your participation in a Vendor Service.
          </p>
          <p className="policy-text">
            The foregoing disclaimers apply to the maximum extent permitted by
            law. You may have other statutory rights. However, the duration of
            statutorily required warranties, if any, shall be limited to the
            maximum extent permitted by law.
          </p>

          <h5 className="my-2">13. Liability</h5>

          <p className="policy-text">
            You acknowledge and agree that, to the maximum extent permitted by
            law, the entire risk arising out of your access to and use of the
            HUB Platform, your publishing or booking of any Listing via the HUB
            Platform, your participation in any Vendor Service. Neither HUB nor
            any other party involved in creating, producing, or delivering the
            HUB Platform will be liable for any incidental, special, exemplary
            or consequential damages, including lost profits, loss of data or
            loss of goodwill, service interruption, computer damage or system
            failure or the cost of substitute products or services, or for any
            damages for personal or bodily injury or emotional distress arising
            out of or in connection with (i) these Terms, (ii) from the use of
            or inability to use the HUB Platform, (iii) from any communications,
            interactions or meetings with other Users or other persons with whom
            you communicate, interact or meet with as a result of your use of
            the HUB Platform, or (iv) from your publishing or booking of a
            Listing, including the provision or use of a Listing's Services,
            whether based on warranty, contract, tort (including negligence),
            product liability or any other legal theory, and whether or not HUB
            has been informed of the possibility of such damage, even if a
            limited remedy set forth herein is found to have failed of its
            essential purpose.
          </p>

          <h5 className="my-2">14. Contact Us</h5>
          <p className="policy-text">
            If you have any questions or concerns about your privacy or anything
            in this policy, including if you need to access this policy in an
            alternative format, we encourage you to{' '}
            <Link to="/#contact-us">contact us</Link>.
          </p>
        </div>
      </div>
    </div>
  );
};
