import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { styled } from '@silverstein-properties/inspirelabs-ui';

export const DialogBox = styled(Dialog)({
  textAlign: 'center',
  alignItems: 'center',
});

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacers.l,
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  overflow: 'visible',
  width: '100%',
  maxWidth: '838px',
  padding: theme.spacing(1, 0, 1, 0),
  marginLeft: theme.spacers.l,
}));

export const HiddenScrollbarStyles = {
  '&.custom-scroll': {
    '&::-webkit-scrollbar': {
      display: 'none', // Hide scrollbar for Chrome, Safari and Opera
    },
    '-ms-overflow-style': 'none', // Hide scrollbar for IE and Edge
    scrollbarWidth: 'none', // Hide scrollbar for Firefox
  },
};
