import { Avatar } from '@mui/material';
import { Typography, styled } from '@silverstein-properties/inspirelabs-ui';

export type StyledAvatarProps = {
  isSelected: boolean;
};

export const StyledAvatar = styled(Avatar, {
  shouldForwardProp: prop => prop !== 'isSelected',
})<StyledAvatarProps>(({ theme, isSelected }) => ({
  marginTop: theme.spacing(3),
  border: `4px solid ${theme.palette.common.white}`,
  outline: `${isSelected ? 2 : 1}px solid ${
    isSelected ? theme.palette.primary.main : theme.palette.grey[400]
  }`,
  width: theme.spacing(7),
  height: theme.spacing(7),
}));

export const StyledTypography = styled(Typography)(() => ({
  // Hardcoding font size and weight here as that's the requirement of the design
  fontSize: 12,
  fontWeight: 500,
}));
