import {
  ChevronDownIcon,
  ChevronUpIcon,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import { flexRender, Table as ReactTable } from '@tanstack/react-table';
import {
  HeaderContent,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  TableWrapper,
} from './DataTable.styles';

interface DataTableProps<TData> {
  table: ReactTable<TData>;
  rightAlignedColumns?: string[];
}

export function DataTable<TData>(props: DataTableProps<TData>) {
  const { table, rightAlignedColumns } = props;
  const theme = useTheme();

  return (
    <TableWrapper>
      <Table width={table.getTotalSize()}>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <TableHeader
                  key={header.id}
                  colSpan={header.colSpan}
                  width={header.getSize()}
                  isRightAligned={rightAlignedColumns?.includes(
                    header.column.id
                  )}
                >
                  {header.isPlaceholder ? null : (
                    <HeaderContent
                      canSort={header.column.getCanSort()}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {/* ===== Header sort indicator ===== */}
                      {{
                        asc: (
                          <ChevronUpIcon
                            width={11}
                            style={{ marginRight: theme.spacers.xs }}
                          />
                        ),
                        desc: (
                          <ChevronDownIcon
                            width={11}
                            style={{ marginRight: theme.spacers.xs }}
                          />
                        ),
                      }[header.column.getIsSorted() as string] ?? null}

                      {/* ===== Header text ===== */}
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </HeaderContent>
                  )}
                </TableHeader>
              ))}
            </tr>
          ))}
        </thead>

        <tbody>
          {table.getRowModel().rows.map(row => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map(cell => (
                <TableCell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </tbody>
      </Table>
    </TableWrapper>
  );
}
