import CheckMark from '../SignupFormContainer/checkmark.svg';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Box, Button, useTheme } from '@silverstein-properties/inspirelabs-ui';
import { useMerchant } from '@/hooks';
import { isTrustedMerchant } from '@/utils/merchantUtils';

export function BusinessSuccess() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const theme = useTheme();
  const navigate = useNavigate();
  const { data: merchant } = useMerchant();
  const isMerchantTrusted = isTrustedMerchant(merchant);

  return (
    <div className="row bump-left mt-5">
      <div className="col-8 text-center mb-2">
        <img src={CheckMark} alt="green checkmark" height="80px" />
      </div>

      <h4 className="col-8 text-center">
        {isMerchantTrusted
          ? 'Your listing was published successfully'
          : 'Your listing was submitted for review'}
      </h4>
      <div className="success-subtitle col-8 text-center">
        {isMerchantTrusted
          ? 'You can now view the experience in the dashboard and start creating events!'
          : `Please allow 48-72 hours for the review process. If there is any issue,
        our team will contact you.`}
      </div>

      <Box
        className="col-8 text-center mb-2"
        sx={{ marginTop: theme.spacers.xxl }}
      >
        <Button onClick={() => navigate('/dashboard')}>
          View my dashboard
        </Button>
      </Box>
    </div>
  );
}
