import { LabelField } from '@/components';
import { CANCELLATION_OPTIONS } from '@/constants';
import { Experience } from '@/types';
import { Stack } from '@silverstein-properties/inspirelabs-ui';

export type CancelationSectionPropsType = {
  experience: Experience;
};

export const CancelationSection = ({
  experience,
}: CancelationSectionPropsType) => {
  return (
    <Stack spacing={4}>
      <LabelField
        label="Participants may cancel prior to service"
        value={
          CANCELLATION_OPTIONS.find(
            policy =>
              policy.value === experience.refundPolicy?.cancellationPolicy
          )?.label || 'No cancelation policy selected'
        }
      />
      <LabelField
        label="Terms"
        value="Cancelation before the selected timeframe is fully refundable. Cancelation after the selected timeframe is non-refundable."
      />
    </Stack>
  );
};
