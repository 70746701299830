import { ApiClient } from '@/api/apiClient';
import { Experience, ExperienceLocation } from '@/types';
import { deepOmitNil, getPhotosObject } from '@/utils';
import { formatLocationToExperienceLocation } from '@/utils/experienceUtils';
import { useMutation } from '@tanstack/react-query';
import { set } from 'lodash';

const useExperienceMutation = (
  onSuccess?: (experience: Experience) => void,
  onError?: (error: Error) => void
) => {
  const apiClient = ApiClient();

  const updateLocation = async (
    location: ExperienceLocation,
    locationId?: number
  ) => {
    if (!locationId) {
      return await apiClient.locations.createLocation({
        newLocation: deepOmitNil({
          ...location,
          info: null,
        }),
      });
    } else {
      return await apiClient.locations.updateLocation({
        locationId: locationId.toString(),
        changes: deepOmitNil({ ...location, info: null }),
      });
    }
  };

  const mutation = useMutation<
    Experience,
    Error,
    { changes: Partial<Experience>; productId: string; locationId?: number }
  >({
    mutationFn: async ({ productId, changes, locationId }) => {
      try {
        if (changes.location) {
          const returnedLocation = await updateLocation(
            changes.location,
            locationId
          );
          changes['locationId'] = returnedLocation?.id;
          changes['location'] =
            formatLocationToExperienceLocation(returnedLocation);
        }
        const newPhotos = getPhotosObject(changes);
        if (Object.keys(newPhotos).length) {
          for (const key in newPhotos) {
            const submittedPhoto = await apiClient.images.uploadImage({
              fileName: key,
              photo: newPhotos[key].file,
              productId,
            });

            set(changes, newPhotos[key].objectKey, submittedPhoto);
          }
        }

        return await apiClient.products.updateProduct({
          productId,
          changes,
        });
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    onSuccess: data => {
      onSuccess && onSuccess(data);
    },
    onError: error => {
      console.error(error);
      onError && onError(error);
    },
  });
  return mutation;
};

export default useExperienceMutation;
