import { Skeleton } from '@mui/material';
import {
  StyledBox,
  StyledCircleIcon,
  StyledTypography,
} from './NumericCircle.styles';

export type NumericCircleProps = {
  isLoading?: boolean;
  size?: 'sm' | 'md' | 'lg';
  value?: number | string;
};

export const NumericCircle = ({
  isLoading,
  size = 'md',
  value = 0,
}: NumericCircleProps) => {
  let fontSize: number;
  switch (size) {
    case 'sm':
      fontSize = 16;
      break;
    case 'md':
      fontSize = 18;
      break;
    case 'lg':
      fontSize = 21;
      break;
  }
  return (
    <StyledBox sx={{ fontSize }}>
      <StyledCircleIcon />
      <StyledTypography variant="subtitle2">
        {isLoading ? <Skeleton /> : value}
      </StyledTypography>
    </StyledBox>
  );
};
