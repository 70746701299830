import { Grid, Typography } from '@silverstein-properties/inspirelabs-ui';
import { InputChangeEvent } from '../../types';
import { StyledPaper } from './SelectionBox.styles';

export interface SelectionBoxProps {
  handleChange: (e: InputChangeEvent) => void;
  handleClick?: () => void;
  icon?: string;
  isDisabled?: boolean;
  isSelected: boolean;
  label: string;
  name: string;
  size?: 'sm' | 'md' | 'lg';
  subtitle?: string;
  internalName?: string;
  value: boolean | string;
}

export const SelectionBox = ({
  handleChange,
  handleClick,
  icon,
  isDisabled = false,
  isSelected,
  label,
  name,
  size,
  subtitle,
  internalName,
  value,
}: SelectionBoxProps) => {
  let spacing = 0;
  switch (size) {
    case 'sm': {
      spacing = 1;
      break;
    }
    case 'md': {
      spacing = 2;
      break;
    }
    case 'lg':
    default: {
      spacing = 3;
      break;
    }
  }

  const clickHandler = () => {
    if (!isDisabled) {
      handleChange({ key: name, value });
      if (handleClick) {
        handleClick();
      }
    }
  };

  return (
    <StyledPaper
      data-value={value}
      elevation={0}
      isDisabled={isDisabled}
      isSelected={isSelected}
      spacing={spacing}
      title={label}
      onClick={clickHandler}
    >
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="subtitle1" sx={{ wordBreak: 'break-word' }}>
            {label}
          </Typography>
          <Typography variant="body2">
            {subtitle ? subtitle : internalName || ''}
          </Typography>
        </Grid>
        {icon ? (
          <Grid item>
            <img alt={label} src={icon} />
          </Grid>
        ) : null}
      </Grid>
    </StyledPaper>
  );
};
