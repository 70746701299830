import { AxiosInstance } from 'axios';
import {
  SideCommunicationMessage,
  MainCommunicationConversation,
  CommunicationPurchaseType,
} from '@/types';

export const endpoints = {
  communications: '/communications',
  sideCommunications({ merchantId }: { merchantId: string }) {
    return `/communications/side-messages/${merchantId}`;
  },
  mainCommunications({
    communicationPurchaseId,
  }: {
    communicationPurchaseId: string;
  }) {
    return `/communications/main-messages/${communicationPurchaseId}`;
  },
  communicationsReply: '/communications/reply',
};

export const communications = ({
  axiosInstance,
}: {
  axiosInstance: AxiosInstance;
}) => {
  return {
    getSideCommunicationMessages: async ({
      merchantId,
    }: {
      merchantId: string;
    }): Promise<SideCommunicationMessage[]> => {
      const { data } = await axiosInstance.get(
        endpoints.sideCommunications({ merchantId })
      );
      return data;
    },
    getMainCommunicationMessages: async ({
      communicationPurchaseId,
    }: {
      communicationPurchaseId: string;
    }): Promise<MainCommunicationConversation> => {
      const { data } = await axiosInstance.get(
        endpoints.mainCommunications({ communicationPurchaseId })
      );
      return data;
    },
    reply: async ({
      communicationPurchaseId,
      message,
      communicationPurchaseType,
      userId,
      merchantId,
    }: {
      communicationPurchaseId: string;
      message: string;
      communicationPurchaseType: CommunicationPurchaseType;
      userId: number;
      merchantId: number;
    }): Promise<void> => {
      const { data } = await axiosInstance.post(endpoints.communicationsReply, {
        communicationPurchaseId,
        message,
        communicationPurchaseType,
        userId,
        merchantId,
      });
      return data;
    },
  };
};
