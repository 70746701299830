import { LabelField } from '@/components';
import { Experience } from '@/types';
import { Stack } from '@silverstein-properties/inspirelabs-ui';
import { Avatar } from '@mui/material';
import { useAuth } from '@/hooks';
import { formatPhoneNumber } from '@/utils';
import { EXPERIENCE_YEARS_CHOICES } from '@/constants';

export type AboutHostSectionPropsType = {
  experience: Experience;
};

export const AboutHostSection = ({ experience }: AboutHostSectionPropsType) => {
  const { data: user } = useAuth();

  // we're currently saving experienceYears as an enum value, this translates it back into readable English
  const matchedChoice = EXPERIENCE_YEARS_CHOICES.find(
    choice => choice.value === experience.host?.operatingYears
  );

  return (
    <Stack spacing={4}>
      <Avatar
        alt="host"
        src={experience.host?.hostPhoto}
        sx={{ height: 88, width: 88 }}
      />
      <LabelField
        label="Name"
        value={
          (experience.host?.firstName && experience.host?.lastName) ||
          (user?.firstName && user?.lastName)
            ? `${experience.host?.firstName || user?.firstName} ${
                experience.host?.lastName || user?.lastName
              }`
            : 'No host name provided'
        }
      />
      <LabelField
        label="Phone"
        value={
          experience.host?.phoneNumber
            ? formatPhoneNumber(experience.host?.phoneNumber)
            : 'No phone number'
        }
      />
      <LabelField
        label="Bio"
        value={experience.host?.bio || 'No bio entered'}
      />
      <LabelField
        label="Experience Start Year"
        value={matchedChoice?.label || 'No start year found'}
      />
    </Stack>
  );
};
