import { useAuth } from './useAuth';
import { useQuery } from '@tanstack/react-query';
import { ApiClient } from '../api/apiClient';
import { UserDashboardMetrics } from '@/types';

export const useDashboard = () => {
  const { data: user } = useAuth();
  const apiClient = ApiClient();
  const merchantId = user?.primaryMerchantId.toString();

  const getUserDashboardInfo = async (
    accessToken?: string
  ): Promise<UserDashboardMetrics | undefined> => {
    if (accessToken && merchantId) {
      return await apiClient.products.getDashboardOverview({ merchantId });
    } else {
      return undefined;
    }
  };

  return useQuery<UserDashboardMetrics | undefined>(
    ['dashboardOverview'],
    () => getUserDashboardInfo(user?.accessToken),
    {
      enabled: !!user && !!user.id,
    }
  );
};
