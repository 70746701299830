import React, { useState, createContext, useContext } from 'react';
import { MerchCreationObject, PartialMerchCreationObject } from '../types';

const defaultMerchCreationObject: Partial<MerchCreationObject> = {
  cancelPolicy: 'full-1w-prior',
  activityLevel: undefined,
  capacityMax: '0',
  capacityMin: '0',
  category: [],
  description: undefined,
  durationHours: undefined,
  durationMinutes: undefined,
  hostBio: undefined,
  hostOperatingYears: undefined,
  hostPhone: undefined,
  hostPhoto: undefined,
  locationAddress: '',
  locationApt: '',
  locationCity: '',
  locationDescription: '',
  locationState: '',
  locationType: '',
  locationZip: '',
  price: undefined,
  totalAmount: undefined,
  acceptedPurchaseMethods: [],
  requiredByGuest: '',
  requiredByHost: '',
  subCategory: [],
  targetAudience: undefined,
  title: undefined,
  subtitle: undefined,
  internalName: undefined,
};

export const FormContext = createContext({
  merchandiseData: defaultMerchCreationObject,
  // Disabling rule due to current implementation
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setFormValues: (_data: PartialMerchCreationObject) => {},
});

export default function MerchFormProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [merchandiseData, setMerchandiseData] = useState(
    defaultMerchCreationObject
  );

  const setFormValues = (newValues: PartialMerchCreationObject) => {
    setMerchandiseData(previousValues => ({
      ...previousValues,
      ...newValues,
    }));
  };

  return (
    <FormContext.Provider value={{ merchandiseData, setFormValues }}>
      {children}
    </FormContext.Provider>
  );
}

export const useFormData = () => useContext(FormContext);
