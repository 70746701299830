import { useState } from 'react';
import { UseFormRegister, FieldError } from 'react-hook-form';
import './FormTextInput.css';
import ErrorIcon from './error_icon.png';

export interface FormTextInputProps {
  label: string;
  name: string;
  width: string;
  isFilled: boolean;
  // Disabled rule due current implementation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  rounded?: string;
  testid?: string;
  type?: string;
  error?: FieldError;
  assistiveText?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function HookFormTextInput({
  label,
  name,
  width,
  isFilled,
  register,
  rounded,
  type,
  error,
  assistiveText,
  onChange,
  testid,
}: FormTextInputProps): JSX.Element {
  const [wasFocused, setWasFocused] = useState<boolean>(false);
  return (
    <div className={width ? `input-container  ${width}` : 'input-container'}>
      <input
        data-testid={testid}
        {...register(name, { onChange })}
        type={type}
        id={name}
        className={
          error
            ? `error ${rounded && rounded}`
            : `no-error ${rounded && rounded}`
        }
        onFocus={() => {
          !wasFocused && setWasFocused(true);
        }}
      />
      {label && (
        <label
          aria-labelledby={name}
          htmlFor={name}
          className={`${wasFocused || isFilled ? 'label-filled' : 'label'}${
            error ? ' error' : ''
          } `}
        >
          {label}
        </label>
      )}
      {error && <img className="assistive-image" src={ErrorIcon} alt="error" />}
      <span className="assistive-text">{error && error.message}</span>
      <span className="password-assistive-text">
        {assistiveText && !error ? assistiveText : undefined}
      </span>
    </div>
  );
}
