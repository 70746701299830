import { SERVICE_TYPE_CHOICES } from '@/constants';
import { BookingMethod, LocationEnvironmentTypes } from '@/types';

export const getServiceTypeFromOptions = (
  environmentType?: LocationEnvironmentTypes
) => {
  return SERVICE_TYPE_CHOICES.find(x => x.value === environmentType);
};

/**
 * Evaluates whether to validate the address in the form depending
 * on some criteria
 * @param bookingMethods BookingMethod[]
 * @param environmentType LocationEnvironmentTypes
 * @returns Boolean
 */
export const isAddressEditAvailable = (
  bookingMethods?: BookingMethod[],
  environmentType?: LocationEnvironmentTypes
) => {
  // adverts never needs address
  if (bookingMethods?.includes(BookingMethod.ADVERT)) {
    return false;
  }

  // for all other booking methods address need depends on location type
  return ![
    LocationEnvironmentTypes.INDOOR_CUSTOMER_LOCATION,
    LocationEnvironmentTypes.OUTDOOR_CUSTOMER_LOCATION,
  ].includes(environmentType || LocationEnvironmentTypes.NONE);
};
