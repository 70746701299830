import MiniCard from '@/components/MiniCard/MiniCard';
import { Experience } from '@/types';

import { DialogBox, HiddenScrollbarStyles } from './ProductListDialog.styles';
import {
  Button,
  Typography,
  useTheme,
  Box,
  Svg,
  Stack,
} from '@silverstein-properties/inspirelabs-ui';
import { useEffect, useState } from 'react';

export interface ProductListDialogProps {
  open: boolean;
  onClose: (value: boolean) => void;
  onConfirm?: (ids: string[]) => void;
  title: string;
  agree: string;
  disagree: string;
  products: Experience[];
  currentSelectedProducts: Experience[];
  selectedProductsAreReadOnly?: boolean;
}

export const ProductListDialog = ({
  open,
  onClose,
  onConfirm,
  title,
  agree,
  disagree,
  products,
  currentSelectedProducts,
  selectedProductsAreReadOnly = false,
}: ProductListDialogProps) => {
  const theme = useTheme();

  const [selectedProductIds, setSelectedProductIds] = useState<string[]>(
    currentSelectedProducts?.map(x => x.id) || []
  );

  useEffect(() => {
    setSelectedProductIds([...(currentSelectedProducts || []).map(x => x.id)]);
  }, [currentSelectedProducts]);

  const onProductSelect = (id: string, state: Boolean) => {
    if (state && !selectedProductIds.includes(id)) {
      selectedProductIds.push(id);
      setSelectedProductIds(selectedProductIds);
    } else if (!state) {
      const loc = selectedProductIds.findIndex(x => x === id);
      if (loc > -1) {
        selectedProductIds.splice(loc, 1);
        setSelectedProductIds(selectedProductIds);
      }
    }
  };

  return (
    <>
      <DialogBox
        open={open}
        onClose={() => onClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            maxWidth: '904px',
            width: '100%',
            maxHeight: '80%',
            height: '700px',
            borderRadius: '25px',
          },
        }}
      >
        <Box display="flex" justifyContent="center" paddingY="20px">
          <Svg
            src="/images/close.svg"
            height={24}
            width={24}
            sx={{
              position: 'absolute',
              left: theme.spacers.xl,
              cursor: 'pointer',
            }}
            onClick={() => onClose(false)}
          />
          <Typography variant="subtitle2">{title}</Typography>
        </Box>

        <Stack
          spacing={theme.spacers.m}
          paddingY={theme.spacers.m}
          alignItems="center"
          overflow="auto"
          className="custom-scroll"
          sx={HiddenScrollbarStyles}
        >
          {products?.map(product => (
            <MiniCard
              key={product.id}
              id={product.id}
              image={product.coverPhoto}
              title={product.title}
              supportingText={product.internalName}
              status="Some Status" // add a relevant status value
              date="Some Date" // add a relevant date value
              time="Some Time" // add a relevant time value
              miniCardType="simple"
              onSelect={onProductSelect}
              isSelected={selectedProductIds.includes(product.id)}
              isReadOnly={
                selectedProductsAreReadOnly
                  ? selectedProductIds.includes(product.id)
                  : false
              }
            />
          ))}
        </Stack>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding="12px 40px"
          borderTop={`1px solid ${theme.palette.grey[400]}`}
        >
          <Button variant="text" onClick={() => onClose(false)}>
            {disagree}
          </Button>
          <Button
            onClick={() => {
              onConfirm && onConfirm(selectedProductIds);
              onClose(true);
            }}
          >
            {agree}
          </Button>
        </Box>
      </DialogBox>
    </>
  );
};
