import { NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import './menuLabel.css';

export interface MenuLabelProps {
  text: string;
  link: string;
}

export default function MenuLabel({ link, text }: MenuLabelProps) {
  const linkSpan = <span className="label-text">{text}</span>;
  if (link.startsWith('/#')) {
    return <HashLink to={link}>{linkSpan}</HashLink>;
  }

  return (
    <NavLink
      className={({ isActive }) => (isActive ? 'selected' : '')}
      to={link}
    >
      {linkSpan}
    </NavLink>
  );
}
