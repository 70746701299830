import { Grid, styled } from '@silverstein-properties/inspirelabs-ui';

export const StyledTopHeaderGrid = styled(Grid)(() => ({
  justifyContent: 'space-between',
}));

export const StyledGridTitleCard = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const StatementTableGrid = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(6),
  border: `1px solid ${theme.palette.grey[400]}`,
  borderRadius: theme.shape.borderRadius / 2,
  '& > *': {
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    '&:last-child': {
      borderBottom: 0,
    },
  },
}));
