import { LabelField } from '@/components';
import { Experience, Location, ProductTypes } from '@/types';
import { useAuth, useMerchantServiceableBuildings } from '@/hooks';
import { formatBuildingIdOptions } from '@/utils';
import { Stack, Typography } from '@silverstein-properties/inspirelabs-ui';
import { StyledSpan } from './LocationSection.styles';
import { SERVICE_TYPE_CHOICES } from '@/constants';

export type LocationSectionPropsType = {
  location?: Location | null;
  experience: Experience;
};

export const LocationSection = ({
  experience,
  location,
}: LocationSectionPropsType) => {
  const { data: user } = useAuth();
  const { data: serviceableBuildings } = useMerchantServiceableBuildings(
    user?.primaryMerchantId || ''
  );
  const isNotAdvert = experience.type !== ProductTypes.ADVERT;

  const locationType = SERVICE_TYPE_CHOICES.find(
    serviceType => serviceType.value === location?.environmentType
  );

  const displayLocation = (
    <Typography variant="body1">
      <StyledSpan>{location?.address?.street}</StyledSpan>
      {location?.address?.apartment && (
        <StyledSpan>Apt./Fl. {location?.address?.apartment}</StyledSpan>
      )}
      <StyledSpan>
        {location?.address?.city}, {location?.address?.state}{' '}
        {location?.address?.zipCode}
      </StyledSpan>
    </Typography>
  );

  return (
    <Stack spacing={4}>
      {isNotAdvert && (
        <LabelField
          label="Address"
          value={
            location?.address
              ? displayLocation
              : 'There is no address for this experience'
          }
        />
      )}
      {isNotAdvert && (
        <LabelField
          label="Location Type"
          value={locationType?.label || 'This location type has not selected'}
        />
      )}
      {isNotAdvert && (
        <LabelField
          label="Location Description"
          value={
            location?.description || 'This location has not been described'
          }
        />
      )}
      {experience.buildingIds ? (
        <LabelField
          label="Permitted Audience"
          value={formatBuildingIdOptions(
            serviceableBuildings,
            experience.buildingIds
          )}
        />
      ) : null}
    </Stack>
  );
};
