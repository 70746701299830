import {
  Grid,
  Typography,
  Button,
  InputField,
  Stack,
  Box,
} from '@silverstein-properties/inspirelabs-ui';
import { TEXT_AREA_LIMIT_LONG } from '@/constants';
import { LabelField } from '@/components';
import { useFormData } from '@/contexts/MembershipCreationProvider';
import { Controller, useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { TitleAndDescriptionInput } from '@/classes/MembershipCreationInput';
import { MembershipCreationDirection } from '@/hooks/useMembershipCreationHooks';

type TitleAndDescriptionProps = {
  changeSection: (sectionId: number) => void;
  setMembershipDb: (data: TitleAndDescriptionInput) => Promise<boolean>;
};

const resolver = classValidatorResolver(TitleAndDescriptionInput);

export const TitleAndDescription = ({
  changeSection,
  setMembershipDb,
}: TitleAndDescriptionProps) => {
  const { setFormValues, subscriptionData } = useFormData();
  const {
    control,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm<TitleAndDescriptionInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      membershipTitle: subscriptionData.membershipTitle || '',
      membershipDescription: subscriptionData.membershipDescription || '',
    },
  });

  // Disabling rule due to current implementation of
  // the BusinessFormContainer. To be refactored
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFormSubmit = async (data: any) => {
    setFormValues(data);
    await setMembershipDb(data);
    //TODO: should add error message if failed to update
    // https://spinspire.monday.com/boards/3397008425/pulses/5095283831
    changeSection(MembershipCreationDirection.Forward);
  };

  return (
    <Grid container direction="column" mt={2} xs={6}>
      <Typography variant="h4">Title & description</Typography>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Stack spacing={4} mt={6}>
          <LabelField
            label="A membership is a group of one of more experiences. How’d you like to name it?"
            value={
              <Box mt={3}>
                <Controller
                  control={control}
                  name="membershipTitle"
                  render={({ field }) => (
                    <InputField
                      {...field}
                      label="Title"
                      fullWidth
                      helperText={
                        errors.membershipTitle
                          ? errors.membershipTitle.message
                          : 'Like "Yoga Pro Unlimited"'
                      }
                      error={!!errors.membershipTitle}
                    />
                  )}
                />
              </Box>
            }
          />
          <LabelField
            label="Provide details about this membership so that users understand its value."
            value={
              <Box mt={3}>
                <Controller
                  control={control}
                  name="membershipDescription"
                  render={({ field }) => (
                    <InputField
                      {...field}
                      multiline
                      minRows={6}
                      fullWidth
                      label="Add description"
                      helperText={
                        errors.membershipDescription
                          ? errors.membershipDescription.message
                          : `${
                              getValues('membershipDescription').length
                            } / ${TEXT_AREA_LIMIT_LONG}`
                      }
                      error={!!errors.membershipDescription}
                    />
                  )}
                />
              </Box>
            }
          />
          <Grid item alignSelf="flex-end">
            <Button type="submit">Continue</Button>
          </Grid>
        </Stack>
      </form>
    </Grid>
  );
};
