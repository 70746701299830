import { useMutation, useQuery } from '@tanstack/react-query';
import { ApiClient } from '@/api/apiClient';
import { useAuth } from './useAuth';
import { AxiosError } from 'axios';
import { RefundReasons } from '@/types';

export const useMembershipPlanPurchaseInvoices = ({
  membershipPlanPurchaseId,
  limit,
  page,
  execute = true,
}: {
  membershipPlanPurchaseId: string;
  limit?: number;
  page?: number;
  execute?: boolean;
}) => {
  const { data: user } = useAuth();
  const apiClient = ApiClient();

  const getMembershipPlanPurchaseInvoices = async () => {
    return await apiClient.membershipPlanPurchases.getMembershipPlanPurchaseInvoices(
      {
        membershipPlanPurchaseId,
        limit,
        page,
      }
    );
  };

  return useQuery(
    ['membershipPlanPurchaseInvoices', membershipPlanPurchaseId, limit, page],
    () => getMembershipPlanPurchaseInvoices(),
    {
      enabled: !!user && execute,
      keepPreviousData: true, // will be useful for pagination
    }
  );
};

export const useRefundInvoiceMutation = ({
  membershipPlanPurchaseId,
}: {
  membershipPlanPurchaseId: string;
}) => {
  const apiClient = ApiClient();

  const onRefundClick = async (
    invoiceId: string,
    amount: number,
    reason: RefundReasons
  ): Promise<boolean> => {
    return await apiClient.membershipPlanPurchases.refundMembershipPlanPurchaseInvoice(
      {
        membershipPlanPurchaseId,
        amount,
        invoiceId,
        reason,
      }
    );
  };

  return useMutation({
    mutationFn: ({
      invoiceId,
      amount,
      reason,
    }: {
      invoiceId: string;
      amount: number;
      reason: RefundReasons;
    }) => {
      return onRefundClick(invoiceId, amount, reason);
    },
    onError: (error: AxiosError) => {
      throw error;
    },
    onSuccess: data => {
      return data;
    },
  });
};

export const usePayInvoiceMutation = ({
  membershipPlanPurchaseId,
}: {
  membershipPlanPurchaseId: string;
}) => {
  const apiClient = ApiClient();

  const onPayClick = async (
    invoiceId: string,
    amount: number
  ): Promise<boolean> => {
    return await apiClient.membershipPlanPurchases.payMembershipPlanPurchaseInvoice(
      {
        membershipPlanPurchaseId,
        invoiceId,
        amount,
      }
    );
  };

  return useMutation({
    mutationFn: ({
      invoiceId,
      amount,
    }: {
      invoiceId: string;
      amount: number;
    }) => {
      return onPayClick(invoiceId, amount);
    },
    onError: (error: AxiosError) => {
      throw error;
    },
    onSuccess: data => {
      return data;
    },
  });
};

export const useCancelInvoiceMutation = ({
  membershipPlanPurchaseId,
}: {
  membershipPlanPurchaseId: string;
}) => {
  const apiClient = ApiClient();

  const onCancelClick = async (invoiceId: string): Promise<boolean> => {
    return await apiClient.membershipPlanPurchases.cancelMembershipPlanPurchaseInvoice(
      {
        membershipPlanPurchaseId,
        invoiceId,
      }
    );
  };

  return useMutation({
    mutationFn: ({ invoiceId }: { invoiceId: string }) => {
      return onCancelClick(invoiceId);
    },
    onError: (error: AxiosError) => {
      throw error;
    },
    onSuccess: data => {
      return data;
    },
  });
};
