import { Stack, Typography } from '@silverstein-properties/inspirelabs-ui';
import { useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { Membership } from '@/types';
import { LabelField } from '@/components';
import { HookFormTextArea } from '@/components/FormTextInput/HookFormTextArea';
import { TEXT_AREA_LIMIT_LONG, TEXT_AREA_LIMIT_SHORT } from '@/constants';
import { TitleAndDescriptionSectionEditInput } from '@/classes/MembershipEditInput';
import { ScreensViews } from '@/hooks/useMembershipEdit';

const resolver = classValidatorResolver(TitleAndDescriptionSectionEditInput);

export type TitleAndDescriptionSectionEditPropsType = {
  membership: Membership;
  onFormSubmit: (data: TitleAndDescriptionSectionEditInput) => Promise<void>;
};

export const TitleAndDescriptionSectionEdit = ({
  membership,
  onFormSubmit,
}: TitleAndDescriptionSectionEditPropsType) => {
  const {
    register,
    formState: { dirtyFields, errors },
    handleSubmit,
  } = useForm<TitleAndDescriptionSectionEditInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      name: membership.name || '',
      description: membership.description || '',
    },
  });

  return (
    <Stack spacing={4}>
      <Typography variant="h5">About</Typography>
      <form
        id={`${ScreensViews.EDIT_TITLE_DESCRIPTION}Form`}
        onSubmit={handleSubmit(onFormSubmit)}
      >
        <Stack spacing={4}>
          <LabelField
            label="Edit title"
            value={
              <HookFormTextArea
                limit={TEXT_AREA_LIMIT_SHORT}
                isFilled={
                  Object.keys(dirtyFields).includes('name') || !!membership.name
                }
                label="Title"
                name="name"
                size="form-xl"
                register={register}
                error={errors.name}
              />
            }
          />
          <LabelField
            label="Edit description"
            value={
              <HookFormTextArea
                limit={TEXT_AREA_LIMIT_LONG}
                isFilled={
                  Object.keys(dirtyFields).includes('description') ||
                  !!membership.description
                }
                label="Description"
                name="description"
                size="form-xl"
                register={register}
                error={errors.description}
              />
            }
          />
        </Stack>
      </form>
    </Stack>
  );
};
