import { styled, useTheme } from '@silverstein-properties/inspirelabs-ui';

export const TableWrapper = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[400]}`,
  borderRadius: `${theme.constants.BORDER_RADIUS.medium}px`,
}));

export const Table = styled('table')({
  width: '100%',
  borderCollapse: 'collapse',
  borderSpacing: '0',
});

export const TableHeader = styled('th')(
  ({
    width,
    isRightAligned = false,
  }: {
    width: string | number;
    isRightAligned?: boolean;
  }) => {
    const theme = useTheme();
    return {
      padding: theme.spacers.m,
      width: width || 'auto',
      textAlign: isRightAligned ? 'right' : 'left',
    };
  }
);

export const HeaderContent = styled('div')(
  ({ canSort }: { canSort: boolean }) => ({
    cursor: canSort ? 'pointer' : 'default',
  })
);

export const TableRow = styled('tr')({});

export const TableCell = styled('td')(({ theme }) => ({
  padding: theme.spacers.m,
  borderTop: `1px solid ${theme.palette.grey[400]}`,
}));
