import {
  Box,
  IconBlock,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import { useNavigate } from 'react-router';

export const MembershipSuccess = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box display="flex" justifyContent="center" marginTop={theme.spacers.xxl4}>
      <Box sx={{ width: { xs: '70vw', md: '35vw', lg: '25vw' } }}>
        <IconBlock
          icon="/images/success.svg"
          iconColor="success.main"
          iconSize={80}
          title="Your membership was published successfully"
          titleSize="h4"
          // body="Lorem ipsum dolores amet"
          titleMarginBottom={theme.spacers.xxl_num}
          primaryButtonTitle="View memberships"
          onClickPrimaryButton={() => navigate('/memberships')}
        />
      </Box>
    </Box>
  );
};
