import {
  Avatar,
  Box,
  Button,
  DetailsDrawer,
  DetailsDrawerProps,
  Label,
  ListGroup,
  ListGroupItemProps,
  Skeleton,
  Stack,
  Typography,
  useTheme,
  Link,
  Snackbar,
  Tooltip,
  InfoIcon,
  Dialog,
  MessageBanner,
  IconBlock,
  ApplicantsIcon,
  Container,
} from '@silverstein-properties/inspirelabs-ui';
import { capitalize } from 'lodash';
import { useMembershipPlanPurchase } from '@/hooks/useMembershipPlanPurchase';
import {
  CycleType,
  PlanConsumerStatus,
  PlanPurchaseSubscriptionStatus,
} from '@/types';
import {
  formatDate,
  getReadableErrorMessage,
  isDateInPast,
  pluralize,
} from '@/utils';
import { formatPlanConsumerStatus } from '@/utils/membershipUtils';
import {
  convertPriceAndBillingToString,
  determineMembersDisplayStatus,
} from '@/utils/membershipUtils';
import { useMembershipEdit } from '@/hooks/useMembershipEdit';
import { useGymAccesses } from '@/hooks/useGymAccesses';
import { GetGymPhysicalAccessType } from '@/types/Gym';
import { usePlanPurchasePastDueAmount } from '@/hooks/useMembershipPlanPurchase';
import { useFeatureFlags } from '@/hooks';
import { PaymentHistoryList } from './PaymentHistoryList';
import DeactivateMembersDialog from '../DeactivateMembersDialog/DeactivateMembersDialog';
import { useCallback, useMemo, useState } from 'react';
import { ApiClient } from '@/api/apiClient';
import {
  MessagingDialog,
  MessagingDialogProps,
} from '@/components/MessagingDialog/MessagingDialog';

interface MemberDetailsDrawerProps extends DetailsDrawerProps {
  membershipPlanPurchaseId: string;
}

const MemberDetailsDrawer = (props: MemberDetailsDrawerProps) => {
  const theme = useTheme();
  const { open, onClose, onClickAway, title, membershipPlanPurchaseId } = props;
  const [errorMessage, setErrorMessage] = useState('');
  const [showUnblockManageMembersDialog, setShowUnblockManageMembersDialog] =
    useState(false);
  const { data: planPurchasePastDueAmount, refetch: refetchBalanceDueAmount } =
    usePlanPurchasePastDueAmount({
      membershipPlanPurchaseId,
      execute: open,
    });
  const {
    isContactPrimaryMemberEnabled,
    isCancelPrimaryMemberEnabled,
    isMessageGuestsEnabled,
  } = useFeatureFlags();
  const apiClient = ApiClient();

  const {
    data: planPurchase,
    isLoading: isPlanPurchaseLoading,
    refetch: refetchMembershipPlanPurchase,
  } = useMembershipPlanPurchase({
    membershipPlanPurchaseId,
    execute: open,
  });

  const {
    showTerminateOneMemberAlertDialog,
    snackbarMessage,
    setSnackbarMessage,
    setShowTerminateOneMemberAlertDialog,
    terminateMembershipPlanPurchaseMutation,
  } = useMembershipEdit();

  const [messageBoxInfo, setMessageBoxInfo] = useState<
    MessagingDialogProps | undefined
  >();
  const [messageSuccess, setMessageSuccess] = useState(false);
  let membersDisplayStatus;
  if (planPurchase) {
    membersDisplayStatus = determineMembersDisplayStatus(planPurchase);
  }

  // Get gym accesses for all members
  const gymAccessQueries = useGymAccesses({
    membershipPlanPurchaseId,
    planConsumers: planPurchase?.planConsumers ?? [],
  });

  // Render gym access badge based on the 'consumerId'
  const renderGymAccessBadge = (consumerId: string) => {
    const queryResult = gymAccessQueries.find(
      query => query.data?.consumerId === consumerId
    );

    if (queryResult) {
      const { data: gymAccessForMember, isLoading } = queryResult;
      const rpError = getReadableErrorMessage(
        gymAccessForMember?.RIVER_PLACE?.error?.errorCode
      );
      const stError = getReadableErrorMessage(
        gymAccessForMember?.SILVER_TOWERS?.error?.errorCode
      );
      return isLoading ? (
        <Skeleton width="100px" />
      ) : rpError && stError ? (
        <Box>
          <Label color="error.main" backgroundColor="error.faint">
            No GYM access
            <Tooltip arrow placement="right" title={rpError || stError}>
              <InfoIcon
                width={theme.constants.ICON_SIZE.small}
                color={theme.palette.primary.medium}
                style={{ cursor: 'pointer' }}
              />
            </Tooltip>
          </Label>
        </Box>
      ) : gymAccessForMember?.hasPhysicalAccess ? (
        <Box>
          <Label color="success.main" backgroundColor="success.faint">
            {getBadgeText(gymAccessForMember)}
          </Label>
        </Box>
      ) : null;
    }

    return <Skeleton width="100px" />;
  };

  // Determine the text to display on the gym access badge
  const getBadgeText = (gymAccessData: GetGymPhysicalAccessType) => {
    const buildingList = [];
    if (gymAccessData.hasPhysicalAccess) {
      if (gymAccessData.SILVER_TOWERS?.hasAccess) {
        buildingList.push('ST');
      }
      if (gymAccessData.RIVER_PLACE?.hasAccess) {
        buildingList.push('RP');
      }
    }
    return `Gym access (${buildingList.join(', ')})`;
  };

  // Transform the affiliated members data to have the shape of "ListGroupItem"
  const transformedAffiliatedConsumers: ListGroupItemProps[] | undefined =
    planPurchase?.planConsumers
      .filter(
        planConsumer =>
          planConsumer.consumerId !== planPurchase.primaryConsumerId &&
          planConsumer.status === PlanConsumerStatus.ACTIVE
      )
      .map(planConsumer => ({
        id: planConsumer.id,
        label1: `${planConsumer.consumer?.firstName} ${planConsumer.consumer?.lastName}`,
        value1: renderGymAccessBadge(planConsumer.consumerId),
      }));

  const primaryMemberStatus = planPurchase?.planConsumers.filter(
    planConsumer => planConsumer.consumerId === planPurchase.primaryConsumerId
  )[0].status;

  const isPlanPurchaseCancelled =
    planPurchase?.subscriptionStatus ===
    PlanPurchaseSubscriptionStatus.CANCELED;

  const handleTerminateOneMemberPlansAlertDialogClose = async (
    isGracefulCancellation: boolean
  ) => {
    setErrorMessage('');
    // if the user clicks Accept in the confirmation dialog
    const { mutateAsync } = terminateMembershipPlanPurchaseMutation;
    try {
      if (planPurchase) {
        await mutateAsync({
          membershipPlanPurchaseId: planPurchase.id,
          isGracefulCancellation,
        });
        await refetchMembershipPlanPurchase();
        setSnackbarMessage(
          "This user's subscription has been successfully terminated"
        );
        setShowTerminateOneMemberAlertDialog(false);
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(
        'The subscription could not be terminated, please try again'
      );
    }
  };

  const sendMessage = async (data: { message: string }) => {
    try {
      const sentMessage =
        await apiClient.membershipPlanPurchases.messageOnePurchasedMember({
          membershipPlanPurchaseId: membershipPlanPurchaseId,
          consumerId: messageBoxInfo?.consumerId as string,
          message: data.message,
        });
      if (sentMessage) {
        setMessageBoxInfo(undefined);
        setMessageSuccess(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const isManageMembersBlocked = useMemo(() => {
    return (
      planPurchase?.planConsumersUpdates?.nextAllowedModificationAt &&
      !isDateInPast(planPurchase.planConsumersUpdates.nextAllowedModificationAt)
    );
  }, [planPurchase]);

  const unblockManageMembers = useCallback(async () => {
    try {
      await apiClient.membershipPlanPurchases.unblockManageMembers({
        membershipPlanPurchaseId,
      });
      await refetchMembershipPlanPurchase();
    } catch (error) {
      console.error(error);
    } finally {
      setShowUnblockManageMembersDialog(false);
    }
  }, [apiClient, membershipPlanPurchaseId]);

  return (
    <DetailsDrawer
      open={open}
      onClose={onClose}
      onClickAway={onClickAway}
      title={title}
    >
      {!!messageBoxInfo && (
        <Dialog
          open={!!messageBoxInfo}
          onClose={() => setMessageBoxInfo(undefined)}
        >
          <MessagingDialog {...messageBoxInfo} sendMessage={sendMessage} />
        </Dialog>
      )}
      {!!messageSuccess && (
        <Snackbar
          message="Message sent successfully"
          open={true}
          autoHideDuration={2500}
          onClose={() => setMessageSuccess(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        />
      )}
      {!!snackbarMessage && (
        <Snackbar
          message={snackbarMessage}
          open={true}
          onClose={() => setSnackbarMessage('')}
          autoHideDuration={2500}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        />
      )}
      {isCancelPrimaryMemberEnabled &&
        showTerminateOneMemberAlertDialog &&
        planPurchase && (
          <DeactivateMembersDialog
            open={showTerminateOneMemberAlertDialog}
            membershipPlanPurchase={planPurchase}
            onConfirm={handleTerminateOneMemberPlansAlertDialogClose}
            onClose={() => setShowTerminateOneMemberAlertDialog(false)}
            errorMessage={errorMessage}
          />
        )}
      <Stack
        direction="row"
        alignItems="center"
        spacing={theme.spacers.m}
        marginBottom={theme.spacers.xxl}
      >
        <Avatar
          sx={{ width: '44px', height: '44px' }}
          src={planPurchase?.primaryConsumer?.imageUrl}
        >
          {/* Get the first letter of the first name in case there is no profile photo */}
          {planPurchase?.firstName?.charAt(0)}
        </Avatar>
        <Box>
          <Typography variant="h5">
            {isPlanPurchaseLoading ? (
              <Skeleton />
            ) : (
              `${planPurchase?.firstName} ${planPurchase?.lastName}`
            )}
          </Typography>
          <Typography variant="body2" color="primary.medium">
            Primary member
          </Typography>
        </Box>
      </Stack>

      <Stack spacing={theme.spacers.l}>
        {renderGymAccessBadge(planPurchase?.primaryConsumerId ?? '')}

        <Box>
          <Stack spacing={theme.spacers.xxs} marginBottom={theme.spacers.m}>
            <Typography variant="subtitle1">Member since</Typography>
            <Typography variant="body1">
              {isPlanPurchaseLoading ? (
                <Skeleton />
              ) : (
                formatDate(planPurchase?.createdAt as Date)
              )}
            </Typography>
            <Typography variant="body1" color="success.main">
              {isPlanPurchaseLoading ? (
                <Skeleton />
              ) : (
                formatPlanConsumerStatus(primaryMemberStatus)
              )}
            </Typography>
          </Stack>

          {!!isContactPrimaryMemberEnabled && isMessageGuestsEnabled && (
            <Button
              variant="outlined"
              size="small"
              onClick={() =>
                setMessageBoxInfo({
                  messageType: 'membershipOne',
                  membershipPlanPurchaseId: planPurchase?.id,
                  consumerId: planPurchase?.primaryConsumerId,
                  firstName: planPurchase?.firstName,
                  lastName: planPurchase?.lastName,
                })
              }
            >
              Contact member
            </Button>
          )}
        </Box>

        <Stack spacing={theme.spacers.xxs}>
          <Typography variant="subtitle1">
            {planPurchase?.membershipPlan?.subscriptionName}
          </Typography>
          <Typography>{planPurchase?.membershipPlanSnapshot.name}</Typography>
          <Typography>
            {convertPriceAndBillingToString(
              planPurchase?.membershipPlanSnapshot.totalAmount ?? 0,
              planPurchase?.membershipPlanSnapshot.billingCycleType ??
                CycleType.MONTH
            )}
          </Typography>
          <Typography>
            {`${pluralize(
              planPurchase?.membershipPlanSnapshot.maxNbOfConsumers ?? 0,
              'member'
            )}`}
          </Typography>
          <Typography>
            {planPurchase?.membershipPlanSnapshot.autoRenew
              ? 'Auto-renew'
              : 'One time'}
          </Typography>
        </Stack>

        <Stack spacing={theme.spacers.xxs}>
          <Typography variant="subtitle1">Outstanding balance</Typography>
          <Typography>{`$${
            planPurchasePastDueAmount?.balance || 0
          }`}</Typography>
          <Typography color={membersDisplayStatus?.color || 'primary.medium'}>
            {membersDisplayStatus?.status || ''}
          </Typography>
        </Stack>
        <Stack spacing={theme.spacers.xxs}>
          <Typography variant="subtitle1">Payment methods</Typography>
          {isPlanPurchaseLoading ? (
            <>
              <Skeleton width="60%" />
              <Skeleton width="60%" />
              <Skeleton width="60%" />
            </>
          ) : (
            planPurchase?.paymentMethods?.map(paymentMethod => (
              <Stack
                direction="row"
                spacing={theme.spacers.xs}
                key={paymentMethod.id}
              >
                <Typography>{`${capitalize(paymentMethod.brand)}... ${
                  paymentMethod.last4
                }`}</Typography>
                {paymentMethod.isDefault && (
                  <Typography color="primary.medium">(Default)</Typography>
                )}
              </Stack>
            ))
          )}
        </Stack>

        <Stack spacing={theme.spacers.xxs}>
          <Typography variant="subtitle1">Next billing date</Typography>
          <Typography variant="body1">
            {formatDate(planPurchase?.currentPeriodEnd as Date)}
          </Typography>
        </Stack>
      </Stack>
      <Box marginY={theme.spacers.xxl}>
        {open && (
          <PaymentHistoryList
            planPurchase={planPurchase}
            onGroupItemClick={(_, action) => {
              if (action === 'cancel' || action === 're-run') {
                refetchBalanceDueAmount();
              }
            }}
          />
        )}
      </Box>
      <Box marginY={theme.spacers.xxl}>
        <ListGroup
          title="Affiliated members"
          initialState="open"
          isLoading={isPlanPurchaseLoading}
          listItems={transformedAffiliatedConsumers}
          messageBanner={
            isManageMembersBlocked ? (
              <MessageBanner
                type="warn"
                actionText="Unblock"
                action={() => setShowUnblockManageMembersDialog(true)}
              >
                {`The primary member is blocked from editing members until ${formatDate(
                  planPurchase?.planConsumersUpdates
                    ?.nextAllowedModificationAt as Date
                )}`}
              </MessageBanner>
            ) : null
          }
        />
      </Box>

      <Dialog
        open={showUnblockManageMembersDialog}
        onClose={() => setShowUnblockManageMembersDialog(false)}
      >
        <Container
          sx={{
            backgroundColor: theme.palette.common.white,
            padding: theme.spacers.xxl,
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <IconBlock
            icon={
              <ApplicantsIcon width={50} color={theme.palette.primary.main} />
            }
            iconColor="success.main"
            title="Unblock?"
            titleSize="h4"
            body={`The primary member, ${planPurchase?.firstName} ${
              planPurchase?.lastName
            }, is blocked from editing members until ${formatDate(
              planPurchase?.planConsumersUpdates
                ?.nextAllowedModificationAt as Date
            )}`}
            primaryButtonTitle="Unblock"
            onClickPrimaryButton={unblockManageMembers}
            secondaryButtonTitle="Cancel"
            onClickSecondaryButton={() =>
              setShowUnblockManageMembersDialog(false)
            }
          />
        </Container>
      </Dialog>

      {/* TODO: May need to uncomment this later - "it seems unlikely they'll be part of MVP for membership" */}
      <Stack spacing={theme.spacers.m} marginBottom={theme.spacers.xxl6}>
        {/* <Link href="#" onClick={() => alert('Revoking access...')}>
          Revoke access
        </Link> */}

        {!isPlanPurchaseCancelled && (
          <Link
            href="#"
            onClick={() => {
              setErrorMessage('');
              setShowTerminateOneMemberAlertDialog(true);
            }}
          >
            Deactivate members
          </Link>
        )}
      </Stack>
    </DetailsDrawer>
  );
};

export default MemberDetailsDrawer;
