import {
  Typography,
  MenuItem,
  InputField,
  Stack,
  useTheme,
  Link,
} from '@silverstein-properties/inspirelabs-ui';
import { Controller, useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { Experience, SubscriptionType } from '@/types';
import { PlanEditInput } from '@/classes/MembershipEditInput';
import { FormHelperText, SxProps } from '@mui/material';
import { MembershipProductsList, ProductListDialog } from '@/components';
import { useEffect, useState } from 'react';
import { useExperiences } from '@/hooks';
import {
  billingCycleMap,
  contractLengthMap,
  contractTypeMap,
  convertToMonths,
  memberQuantityMap,
} from '@/utils/membershipUtils';
import { formatPercentage, mapTaxRateOptions } from '@/utils';
import { useMerchantPlatformFee, useTaxRates } from '@/hooks';
import { ScreensViews } from '@/hooks/useMembershipEdit';
import { TAX_EXEMPT_PRICING_NO_LABEL } from '@/constants';

const resolver = classValidatorResolver(PlanEditInput);

export type AddPlanPropsType = {
  membershipId: string;
  onFormSubmit: (data: PlanEditInput) => Promise<void>;
};

export const AddPlan = ({ onFormSubmit }: AddPlanPropsType) => {
  const theme = useTheme();
  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<PlanEditInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      membershipPlanTitle: '',
      membershipPlanPrice: '',
      memberQuantity: 1,
      autoRenew: 'auto-renew',
      billingCycle: 'Every 1 month',
      contractLength: '1 year',
      membershipProducts: [],
      taxRateId: undefined,
    },
  });
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const { data: experiences } = useExperiences();
  const { data: platformFee } = useMerchantPlatformFee(
    SubscriptionType.MEMBERSHIP
  );
  const { data: taxRates } = useTaxRates();
  const [billingCycleDisplay] = useState('');
  const [membershipProducts, setMembershipProducts] = useState<Experience[]>(
    []
  );
  const [isSelectionValid, setIsSelectionValid] = useState(true);

  // Logic to determine if billingCycle <= contractLength
  useEffect(() => {
    const billingCycleKey =
      (watch('billingCycle') as keyof typeof billingCycleMap) ??
      'Every 1 month';
    const contractLengthKey =
      (watch('contractLength') as keyof typeof contractLengthMap) ?? '1 month';

    const billingCycleInMonths = convertToMonths(
      billingCycleMap[billingCycleKey].length,
      billingCycleMap[billingCycleKey].type
    );
    const contractLengthInMonths = convertToMonths(
      contractLengthMap[contractLengthKey].length,
      contractLengthMap[contractLengthKey].type
    );

    setIsSelectionValid(billingCycleInMonths <= contractLengthInMonths);
  }, [watch('contractLength'), watch('billingCycle')]);

  const onProductSelect = (ids: string[]) => {
    const newMembershipProducts = experiences?.filter(x => ids.includes(x.id));

    setValue('membershipProducts', newMembershipProducts || [], {
      shouldTouch: true,
    });
    setMembershipProducts(newMembershipProducts || []);
  };

  // Reusable styles for same-line inputs (e.g. price and billing cycle)
  const borderLeftStyle: SxProps = {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  };
  const borderRightStyle: SxProps = {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    '& .MuiOutlinedInput-notchedOutline': {
      borderRight: '1px solid transparent',
    },
  };

  return (
    <Stack spacing={theme.spacers.xxl}>
      <Typography variant="h5">Untitled Plan</Typography>
      <form
        id={`${ScreensViews.ADD_PLANS}Form`}
        onSubmit={handleSubmit(onFormSubmit)}
      >
        <Stack spacing={theme.spacers.m} width="100%">
          <Controller
            control={control}
            name="membershipPlanTitle"
            render={({ field }) => (
              <InputField
                {...field}
                label="Plan title"
                data-testid="planAddTitle"
                error={!!errors.membershipPlanTitle}
                helperText={
                  errors.membershipPlanTitle
                    ? errors.membershipPlanTitle.message
                    : 'For example: Household of 4'
                }
              />
            )}
          />

          <Controller
            control={control}
            name="memberQuantity"
            render={({ field }) => (
              <InputField
                {...field}
                select
                id="member-quantity"
                label="No. of members"
                data-testid="memberQuantity"
                dropdownItems={memberQuantityMap}
                error={!!errors.memberQuantity}
                helperText={errors.memberQuantity?.message}
              />
            )}
          />

          <Stack direction="row">
            <Controller
              control={control}
              name="membershipPlanPrice"
              render={({ field }) => (
                <InputField
                  {...field}
                  isPriceInput
                  label="Price"
                  sx={borderRightStyle}
                  error={!!errors.membershipPlanPrice}
                  helperText={
                    errors.membershipPlanPrice
                      ? errors.membershipPlanPrice?.message
                      : `Earnings are subjected to a ${formatPercentage(
                          platformFee
                        )} platform fee`
                  }
                />
              )}
            />
            <Controller
              control={control}
              name="billingCycle"
              render={({ field }) => (
                <InputField
                  {...field}
                  select
                  id="billingCycle"
                  label="Billing cycle"
                  sx={borderLeftStyle}
                  error={!!errors.billingCycle || !isSelectionValid}
                  helperText={
                    errors.billingCycle
                      ? errors.billingCycle?.message
                      : isSelectionValid
                      ? 'Members will be charged on this frequency'
                      : "Billing cycle can't be longer than contract length"
                  }
                >
                  {Object.keys(billingCycleMap).map((value, i) => {
                    return (
                      <MenuItem key={i + value} value={value}>
                        {value}
                      </MenuItem>
                    );
                  })}
                </InputField>
              )}
            />
          </Stack>

          <Controller
            control={control}
            name="taxRateId"
            render={({ field }) => (
              <InputField
                {...field}
                select
                label="Tax rate"
                error={!!errors.taxRateId}
                disabled={false}
                dropdownItems={
                  taxRates
                    ? [
                        TAX_EXEMPT_PRICING_NO_LABEL,
                        ...mapTaxRateOptions(taxRates, false),
                      ]
                    : [{ id: 0, label: 'Loading...', value: '0' }]
                }
                helperText={
                  errors.taxRateId
                    ? errors.taxRateId.message
                    : 'The tax rate applied to this plan'
                }
              />
            )}
          />

          <Stack direction="row">
            <Controller
              control={control}
              name="contractLength"
              render={({ field }) => (
                <InputField
                  {...field}
                  select
                  label="Contract length"
                  sx={borderRightStyle}
                  error={!!errors.contractLength}
                  helperText={
                    errors.contractLength
                      ? errors.contractLength.message
                      : "Member's duration of commitment"
                  }
                >
                  {Object.keys(contractLengthMap).map((value, i) => {
                    return (
                      <MenuItem key={i + value} value={value}>
                        {value}
                      </MenuItem>
                    );
                  })}
                </InputField>
              )}
            />
            <Controller
              control={control}
              name="autoRenew"
              render={({ field }) => (
                <InputField
                  {...field}
                  select
                  id="is-auto-renew"
                  label="Contract type"
                  dropdownItems={contractTypeMap}
                  sx={borderLeftStyle}
                  error={!!errors.autoRenew}
                  helperText={
                    errors.autoRenew
                      ? errors.autoRenew?.message
                      : "The full duration of a user's subscription"
                  }
                />
              )}
            />
            <Typography variant="subtitle2">{billingCycleDisplay}</Typography>
          </Stack>

          <MembershipProductsList
            isLoading={false}
            title="Included experiences"
            productsInfo={(membershipProducts || []).map(x => ({
              id: x.id,
              title: x?.title || '',
            }))}
          />
          <Link href="#" onClick={() => setIsProductModalOpen(true)}>
            Edit experience list
          </Link>
          <FormHelperText error={membershipProducts.length === 0}>
            {membershipProducts.length === 0 && 'Select experiences to add'}
          </FormHelperText>
        </Stack>
        <ProductListDialog
          title="Connect experiences to this membership"
          agree="Connect to membership"
          disagree="Cancel"
          open={isProductModalOpen}
          onClose={() => setIsProductModalOpen(false)}
          onConfirm={onProductSelect}
          currentSelectedProducts={membershipProducts}
          products={experiences || []}
        />
      </form>
    </Stack>
  );
};
