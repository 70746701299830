import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { auth } from '@/config/config';
import { useMutation } from '@tanstack/react-query';
import { sendPasswordResetEmail } from 'firebase/auth';

export const useLogin = () => {
  const [login, setLogin] = useState({ email: '', password: '' });
  const [warning, setWarning] = useState('');
  const [success, setSuccess] = useState('');
  const [searchParams] = useSearchParams();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLogin(oldValues => ({ ...oldValues, [e.target.name]: e.target.value }));
  };

  const fbMutation = useMutation({
    mutationFn: ({ email }: { email: string }) =>
      sendPasswordResetEmail(auth, email),
  });

  const resetSuccess = () => {
    setSuccess('');
  };

  const triggerResetEmail = (emailAddress: string) => {
    if (emailAddress) {
      try {
        setWarning('');
        setSuccess('Password reset email sent, please check your inbox');
        return fbMutation.mutate({ email: emailAddress });
      } catch (err) {
        return;
      }
    }
    setWarning('Please enter a valid email address');
  };

  return {
    login,
    success,
    warning,
    handleChange,
    setWarning,
    resetSuccess,
    triggerResetEmail,
    searchParams,
  };
};
