import { Type } from 'class-transformer';
import {
  ArrayNotEmpty,
  IsNotEmpty,
  IsNumberString,
  MaxLength,
  MinLength,
  ValidateNested,
} from 'class-validator';

export class Code2FA {
  @IsNumberString()
  @IsNotEmpty()
  @MinLength(1, { message: 'Please enter a valid verification code' })
  @MaxLength(1, { message: 'Please enter a valid verification code' })
  code: string;
}

export class Verify2FACodeInput {
  @ArrayNotEmpty({ message: 'Please enter the verification code' })
  @ValidateNested({ each: true })
  @Type(() => Code2FA)
  data: Code2FA[];
}
