import { TEXT_AREA_LIMIT_MEDIUM_PLUS } from '@/constants';
import { IsNotEmpty, IsUrl, MaxLength } from 'class-validator';

export class AdvertUrlSectionEditInput {
  @MaxLength(TEXT_AREA_LIMIT_MEDIUM_PLUS, {
    message: 'Please enter a shorter url',
  })
  @IsNotEmpty({ message: 'Please enter a url' })
  @IsUrl()
  advertUrl: string;
}
