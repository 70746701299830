import { SideCommunicationMessage } from '@/types';
import { Grid, Typography } from '@silverstein-properties/inspirelabs-ui';
import { MessageReadIndicator } from '../MessageReadIndicator';
import {
  StyledPurchaseableTypeLabel,
  StyledSideMessageCardGrid,
} from './SideMessageCard.styles';
import { formatTime, formatDateWithoutYear } from '@/utils';
import { getMessageType } from '@/utils/messagesUtils';

export type MessageCardProps = {
  handleClick: (bookingId: string) => void;
  isSelected: boolean;
  message: SideCommunicationMessage;
};

export const SideMessageCard = ({
  handleClick,
  isSelected,
  message,
}: MessageCardProps) => {
  const messageTypeText = getMessageType(message.communicationMessageType);

  return (
    <StyledSideMessageCardGrid
      container
      item
      isSelected={isSelected}
      onClick={() => handleClick(message.communicationPurchaseId)}
    >
      <Grid container item xs="auto" sx={{ pr: 2 }}>
        <MessageReadIndicator isRead={message.isRead} />
      </Grid>
      <Grid item container xs={11} sx={{ paddingY: 2 }}>
        <Grid item>
          <StyledPurchaseableTypeLabel
            messageType={message.communicationMessageType}
          >
            {messageTypeText}
          </StyledPurchaseableTypeLabel>
        </Grid>
        <Grid container item xs={12} justifyContent="space-between">
          <Grid item>
            <Typography variant="subtitle2">
              {message.participantFirstName} {message.participantLastName}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body3" color="primary.medium">
              {formatTime(message.sentAt)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Typography
            sx={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {message.textBody}
          </Typography>
        </Grid>
        <Grid container item xs columnGap={1} alignItems="center">
          <Typography variant="body2">
            {formatDateWithoutYear(message.sentAt)}
          </Typography>
          &middot;
          <Typography variant="subtitle2">
            {message.communicationPurchaseName}
          </Typography>
        </Grid>
      </Grid>
    </StyledSideMessageCardGrid>
  );
};
