import {
  Box,
  IconBlock,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import { ReactNode } from 'react';

interface DashboardCardProps {
  value: number | string;
  valueColor?: string;
  label: string;
  icon: ReactNode;
}

export const DashboardCard = (props: DashboardCardProps) => {
  const { value, valueColor, label, icon } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.grey[400]}`,
        borderRadius: `${theme.constants.BORDER_RADIUS.medium}px`,
        padding: theme.spacers.l,
      }}
      data-testid="dashboardCards"
    >
      <IconBlock
        icon={icon}
        title={value.toString()}
        titleColor={valueColor || 'primary.main'}
        titleSize="h4"
        titleMarginBottom={2}
        body={label}
        bodySize="body2"
      />
    </Box>
  );
};
