import {
  CreateListing,
  EditListingType,
  Experience,
  Listing,
  Location,
  PriceType,
  ProductTypes,
} from '@/types';
import { useAuth } from './useAuth';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ApiClient } from '../api/apiClient';
import {
  isActiveExperienceStatus,
  isAddressDifferent,
  parseFloatIfString,
  doesDateMatchDateTime,
  replaceDateInDateTime,
} from '@/utils';
import { AddListingInput, EditListingInput } from '@/classes';
import { useLocations } from './useLocations';
import { RepeatDays, RepeatDaysType } from '@/types/RecurringEvent';
import { CancelListingInput } from '@/classes/CancelListingInput';
import { AxiosError } from 'axios';

type UseListingsPropsType = {
  productId?: string;
  startsAt?: Date;
  endsAt?: Date;
};
// get all listings for a given experience
export const useListings = ({
  productId,
  startsAt,
  endsAt,
}: UseListingsPropsType) => {
  const { data: user } = useAuth();
  const apiClient = ApiClient();

  const getUserListings = async (
    accessToken?: string
  ): Promise<Listing[] | null> => {
    if (accessToken && productId) {
      return await apiClient.products.getProductListing({
        productId,
        startsAt,
        endsAt,
      });
    } else {
      return null;
    }
  };

  return useQuery<Listing[] | null>(
    ['listings', productId, startsAt, endsAt],
    () => getUserListings(user?.accessToken),
    { enabled: !!user && !!productId }
  );
};

//get all listings for all experiences of a given merchant
export const useMerchantListings = ({
  startsAt,
  endsAt,
}: {
  startsAt?: Date;
  endsAt?: Date;
}) => {
  const { data: user } = useAuth();
  const apiClient = ApiClient();

  const getAllMerchantListings = async (
    merchantId: string,
    accessToken?: string
  ): Promise<Listing[] | null> => {
    if (accessToken && merchantId) {
      return await apiClient.products.getAllMerchantListings({
        merchantId,
        startsAt,
        endsAt,
      });
    } else {
      return null;
    }
  };

  return useQuery<Listing[] | null>(
    ['allMerchantListings', startsAt, endsAt],
    () =>
      getAllMerchantListings(user?.primaryMerchantId || '', user?.accessToken),
    { enabled: !!user && !!user.primaryMerchantId }
  );
};

export const validateListing = ({
  experience,
  listingChanges,
}: {
  experience?: Experience;
  listingChanges: { startTime: Date; endTime: Date };
}) => {
  if (!isActiveExperienceStatus(experience?.status)) {
    const errorMessage =
      'Experience has not been approved - cannot create event';
    throw new Error(errorMessage);
  }

  // don't let events end before they start!
  if (listingChanges.endTime <= listingChanges.startTime) {
    const errorMessage = 'Event must end after it begins - cannot create event';
    throw new Error(errorMessage);
  }
};

export const useMutationListing = (
  data: {
    selectedExperience?: Experience;
    selectedListing?: Listing;
  },
  onListingSuccess: () => void,
  onListingError: (message: string, error?: Error) => void
) => {
  const { selectedExperience, selectedListing } = data;
  const { data: venue } = useLocations({
    locationId: selectedExperience?.location?.id?.toString() || '',
  });
  const apiClient = ApiClient();
  const addListingMutation = useMutation({
    mutationFn: async (data: AddListingInput) => {
      const productId = selectedExperience?.id || '';
      validateListing({ experience: selectedExperience, listingChanges: data });

      try {
        let meetingPoint;
        if (
          selectedExperience &&
          selectedExperience?.type === ProductTypes.ONE_TIME_EVENT
        ) {
          const address = {
            city: data.city || '',
            country: venue?.address?.country || '',
            state: data.state || '',
            street: data.address || '',
            zipCode: data.zip || '',
            apartment: data.apt,
          };

          // create new meeting point location
          if (isAddressDifferent(address, venue?.address)) {
            const newLocation: Location = {
              address,
              info: data.description,
              description: data.description,
              environmentType: venue?.environmentType,
              name: venue?.name,
              type: venue?.type,
              url: venue?.url,
            };

            meetingPoint = await apiClient.locations.createLocation({
              newLocation,
            });
          }
        }

        if (!doesDateMatchDateTime(data.startTime, data.date)) {
          data.startTime = replaceDateInDateTime(data.startTime, data.date);
          data.endTime = replaceDateInDateTime(data.endTime, data.date);
        }

        const listingDto: Partial<CreateListing> = {
          endsAt: data.endTime,
          startsAt: data.startTime,
          title: selectedExperience?.title,
          basePrice: {
            priceId: data.priceId,
            amount: parseFloatIfString(data.totalAmount),
            priceType:
              selectedExperience?.basePrice.priceType ||
              PriceType.PRICE_PER_PERSON,
          },
          productId,
          meetingPointId: meetingPoint?.id,
          meetingPoint,
          host: selectedExperience?.host,
        };

        if (data?.repeatDays || data?.timeInterval?.intervalSegment) {
          let repeatDays: RepeatDays = {
            until: data.endTime,
            type: RepeatDaysType.WEEKLY,
          };
          // default until and type required in BE
          let timeInterval;

          if (data?.timeInterval?.intervalSegment) {
            timeInterval = {
              intervalSegment: Number(data.timeInterval.intervalSegment),
              timeBetweenInterval:
                Number(data.timeInterval.timeBetweenInterval) || 0,
            };
          }

          if (data?.repeatDays) {
            repeatDays = {
              until: data.repeatDays.until,
              daysOfWeek: data.repeatDays.selectedDays,
              type: data.repeatDays.repeatDaysType,
              interval: Number(data.repeatDays.repeatDaysInterval) || 1,
            };
          }

          return await apiClient.recurringEvents.createRecurringEvents({
            productId: productId,
            startDateTime: data.startTime,
            endDateTime: data.endTime,
            repeatDays: repeatDays,
            timeInterval: timeInterval,
            listingDto: listingDto,
          });
        } else {
          // if regular Listing
          return await apiClient.products.createProductListing({
            productId,
            newListing: listingDto,
          });
        }
      } catch (error) {
        console.error(error);
        throw new Error("we couldn't create your listing");
      }
    },
    onSuccess() {
      onListingSuccess();
    },
    onError(error: AxiosError<{ message: string; statusCode: number }>) {
      onListingError(
        error.response?.data.message || "we couldn't create your listing",
        error
      );
    },
  });

  const editListingMutation = useMutation({
    mutationFn: async (data: EditListingInput) => {
      if (!selectedListing || !selectedExperience) {
        throw new Error('No listing or experience selected');
      }
      const productId = selectedExperience.id;
      try {
        const address = {
          city: data.city,
          country: venue?.address?.country || '',
          state: data.state,
          street: data.address,
          zipCode: data.zip,
          apartment: data.apt,
        };

        let meetingPoint = venue || undefined;
        // create new meeting point location
        if (
          isAddressDifferent(address, venue?.address) &&
          data.isEditLocationEnabled
        ) {
          const newLocation: Partial<Location> = {
            address,
            info: data.description,
            description: data.description,
            environmentType: venue?.environmentType,
            name: venue?.name,
            type: venue?.type,
            url: venue?.url,
          };

          meetingPoint = await apiClient.locations.createLocation({
            newLocation,
          });
        }

        if (!doesDateMatchDateTime(selectedListing.startsAt, data.date)) {
          data.startTime = replaceDateInDateTime(data.startTime, data.date);
          data.endTime = replaceDateInDateTime(data.endTime, data.date);
        }

        // TO DO -> reduce amount of data sent to BE with deepOmitDiff
        const editedListing: EditListingType = {
          ...selectedListing,
          endsAt: data.endTime,
          startsAt: data.startTime,
          title: selectedListing.title,
          basePrice: {
            priceId: data.priceId,
            amount: parseFloatIfString(data.totalAmount),
            priceType:
              selectedListing.basePrice?.priceType ||
              selectedExperience.basePrice?.priceType ||
              PriceType.PRICE_PER_PERSON,
          },
          productId,
          locationId: meetingPoint?.id,
          meetingPoint,
          host: selectedExperience.host,
        };

        if (
          data.editRecurringSeries &&
          editedListing?.recurringEventId &&
          (data?.repeatDays || data?.timeInterval?.intervalSegment)
        ) {
          let repeatDays;
          let timeInterval;

          if (data?.repeatDays) {
            repeatDays = {
              until: data.repeatDays.until,
              daysOfWeek: data.repeatDays.selectedDays,
              type: data.repeatDays.repeatDaysType,
              interval: Number(data.repeatDays.repeatDaysInterval) || 1,
            };
          }
          if (data?.timeInterval?.intervalSegment) {
            timeInterval = {
              intervalSegment: Number(data.timeInterval.intervalSegment),
              timeBetweenInterval:
                Number(data.timeInterval.timeBetweenInterval) || 0,
            };
          }

          return await apiClient.recurringEvents.editRecurringEvents({
            recurringEventId: editedListing?.recurringEventId,
            recurringEventDto: {
              listingId: editedListing.id,
              productId: productId,
              startDateTime: data.startTime,
              endDateTime: data.endTime,
              repeatDays: repeatDays,
              timeInterval: timeInterval,
              listingDto: editedListing,
            },
          });
        } else {
          return await apiClient.products.editProductListing({
            editedListing,
          });
        }
      } catch (error) {
        console.error(error);
        throw new Error('we could not edit your listing');
      }
    },
    onSuccess() {
      onListingSuccess();
    },
    onError(error: AxiosError<{ message: string; statusCode: number }>) {
      onListingError(
        error.response?.data.message || 'we could not edit your listing',
        error
      );
    },
  });

  const cancelListingMutation = useMutation({
    mutationFn: async (data: CancelListingInput) => {
      try {
        if (data.editRecurringSeries && data?.recurringEventId) {
          return await apiClient.recurringEvents.cancelRecurringEvents({
            recurringEventId: data.recurringEventId,
            listingId: data.listingId,
          });
        } else {
          return await apiClient.products.cancelProductListing({
            listingId: data.listingId,
          });
        }
      } catch (error) {
        console.error(error);
        throw new Error('we could not cancel your listing');
      }
    },
    onSuccess() {
      onListingSuccess();
    },
    onError(error: AxiosError<{ message: string; statusCode: number }>) {
      onListingError(
        error.response?.data.message || 'we could not cancel your listing',
        error
      );
      // TODO: remove the try/catch in the mutations once the BE sends proper error messages
      // current error messages are too long to fit and contain the listingID
    },
  });

  return {
    addListingMutation,
    editListingMutation,
    cancelListingMutation,
  };
};

export const useMutationCloseListing = (
  data: {
    listing: Listing;
  },
  onCloseSuccess: () => void,
  onCloseError: (message?: string) => void
) => {
  const { listing } = data;

  const apiClient = ApiClient();

  const closeListingSignUpsMutation = useMutation({
    mutationFn: async (data: { updateIsEventClosed: boolean }) => {
      if (!listing) {
        throw new Error('No listing or experience selected');
      }
      return await apiClient.products.closeProductListing({
        listingId: listing.id,
        updateIsEventClosed: data.updateIsEventClosed,
      });
    },
    onSuccess() {
      onCloseSuccess();
    },
    onError(error: AxiosError<{ message: string; statusCode: number }>) {
      onCloseError(error?.response?.data?.message);
    },
  });

  return {
    closeListingSignUpsMutation,
  };
};
