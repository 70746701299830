import { formatPropertyName } from '@/utils';
import {
  registerDecorator,
  ValidationOptions,
  ValidationArguments,
} from 'class-validator';

export const PriceMaxValue = (
  property: string,
  validationOptions?: ValidationOptions
) => {
  return (object: Object, propertyName: string) => {
    registerDecorator({
      name: 'PriceMaxValue',
      target: object.constructor,
      propertyName: propertyName,
      constraints: [property],
      options: validationOptions,
      validator: {
        validate(value: string, args: ValidationArguments) {
          const [maxAmountProperty] = args.constraints;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const relatedValue = (args.object as any)[maxAmountProperty];
          const parsedValue = parseFloat(value);
          if (!relatedValue) {
            return true;
          }
          const maxAmount = parseFloat(relatedValue);
          // throw validation error if greater than max amount
          return !(parsedValue > maxAmount);
        },
        defaultMessage(args: ValidationArguments) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const relatedValue = (args.object as any)[args.constraints[0]];
          return `Your ${formatPropertyName(
            args.property
          )} cannot be greater than $${relatedValue}`;
        },
      },
    });
  };
};
