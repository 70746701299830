import {
  Grid,
  MessageBanner,
  Typography,
} from '@silverstein-properties/inspirelabs-ui';
import { Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';
import { FormTextInput } from '@/components/FormTextInput';
import RoundButton from '@/components/RoundButton/Component';
import { useLogin } from '@/hooks/useLogin';

export interface LoginProps {
  logIn: (name: string, password: string) => void;
  warning?: string;
}

export const Login = (props: LoginProps): JSX.Element => {
  const { login, success, warning, handleChange } = useLogin();

  return (
    <Grid item container flexDirection="column" mt={8}>
      <Typography variant="h4">Login</Typography>
      <Grid item container flexDirection="column" mt={5} gap={4}>
        <Typography variant="subtitle1">Welcome back</Typography>
        {success && <MessageBanner type="success">{success}</MessageBanner>}
        {props.warning || warning ? (
          <MessageBanner type="error">{props.warning || warning}</MessageBanner>
        ) : null}
        <form onSubmit={e => e.preventDefault()}>
          <FormTextInput
            handleChange={handleChange}
            type="text"
            label="Email"
            testId="emailAccount"
            width="form-xl"
            value={login.email}
            name="email"
          />
          <FormTextInput
            handleChange={handleChange}
            type="password"
            label="Password"
            testId="passwordAccount"
            width="form-xl"
            value={login.password}
            name="password"
          />
          <Grid
            container
            item
            alignItems="center"
            justifyContent="space-between"
          >
            <MuiLink
              component={Link}
              to="/send-reset-password"
              state={{ email: login.email }}
              variant="body2"
            >
              Forgot password
            </MuiLink>
            <RoundButton
              handleClick={() => props.logIn(login.email, login.password)}
              text="Login"
              testId="loginButton"
              default="blue-baby-blue"
              hover="white-navy"
            />
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};
