import { TEXT_AREA_LIMIT_LONG } from '@/constants';
import { SelectionType } from '@/types';
import {
  IsAlpha,
  IsNotEmpty,
  IsNumberString,
  IsOptional,
  IsString,
  MaxLength,
  MinLength,
} from 'class-validator';

export class ExperienceLocationInput {
  @IsNotEmpty({ message: 'Enter a value' })
  locationType: string | SelectionType;

  @MinLength(1, { message: 'Please enter an address.' })
  @MaxLength(50, { message: 'Your address is too long.' })
  locationAddress: string;

  @MinLength(1, { message: 'City is blank.' })
  @MaxLength(50, { message: 'City name is too long.' })
  locationCity: string;

  @IsString()
  @IsOptional()
  locationApt: string;

  @IsAlpha(undefined, { message: 'Invalid' })
  @MinLength(2, { message: 'Invalid' })
  @MaxLength(2, { message: 'Invalid' })
  locationState: string;

  @IsNumberString(undefined, { message: 'Invalid ZIP' })
  @MinLength(5, { message: 'Invalid ZIP' })
  @MaxLength(10, { message: 'Invalid ZIP' })
  locationZip: string;

  @IsNotEmpty({ message: 'Please enter a description' })
  @MaxLength(TEXT_AREA_LIMIT_LONG, { message: 'Description is too long' })
  locationDescription: string;
}
