export interface BusinessFormValuesType {
  inPerson: string | boolean;
  category: string[];
  subCategory: string[];
  subCategory2?: string[];
  locationType: string;
  locationLink: string;
  locationZip: string;
  locationApt: string;
  locationCity: string;
  locationState: string;
  locationAddress: string;
  locationDescription: string;
  hostOperatingYears: number;
  hostBio: string;
  hostPhoto: string | File;
  hostPhone?: string;
  title: string;
  subtitle: string;
  internalName: string;
  description: string;
  durationHours: number;
  durationMinutes: number;
  targetAudience: string;
  activityLevel: string;
  hostProvide: boolean;
  guestProvide: boolean;
  requiredByHost: string;
  requiredByGuest: string;
  coverPhoto: string | File;
  extraPhoto1: string | File;
  extraPhoto2: string | File;
  extraPhoto3: string | File;
  extraPhoto4: string | File;
  capacityMin: number;
  capacityMax: number;
  generalDays: string[];
  generalTime: number;
  totalAmount: number;
  cancelPolicy: string;
  hostAgreement: string;
}

export enum ExperienceEventType {
  FREE = 'free',
  PAID = 'paid',
}
