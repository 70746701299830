import RoundButton from '../RoundButton/Component';
import HookFormSubmitButton from '../RoundButton/HookFormSubmitButton';
import { HookFormTextArea } from '../FormTextInput/HookFormTextArea';
import { HookFormTextInput } from '../FormTextInput/HookFormTextInput';
import {
  styles,
  errorStyles,
  customValueContainer,
} from 'components/FormTextInput/HookFormSelect';
import { useForm, Controller, Resolver } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import {
  MaxLength,
  MinLength,
  IsUrl,
  IsNotEmpty,
  IsOptional,
  ValidateIf,
} from 'class-validator';

import { useFormData } from '../../contexts/SignUpProvider';
import { UserSignUpObject } from '../../types';
import Select from 'react-select';
import { TEXT_AREA_LIMIT_LONG } from '@/constants';
import { MessageBanner } from '@silverstein-properties/inspirelabs-ui';

export interface BusinessInfoProps {
  handlePrev: () => void;
  handleNext: () => void;
  submit: (data: Partial<UserSignUpObject>) => void;
  warning?: string;
}

class BusinessInfoInput {
  // @IsNotEmpty({ message: 'Please enter a category' })
  // category: string | object;

  @IsNotEmpty({ message: 'Please enter a value' })
  years: string;

  @MinLength(1, { message: 'Please enter a description' })
  @MaxLength(TEXT_AREA_LIMIT_LONG, {
    message: 'Please enter a shorter description',
  })
  description: string;

  @ValidateIf(e => e.linkedIn && e.linkedIn.trim() !== '')
  @IsOptional()
  @IsUrl(undefined, { message: 'Please enter a valid URL' })
  linkedIn: string;

  @ValidateIf(e => e.facebook && e.facebook.trim() !== '')
  @IsOptional()
  @IsUrl(undefined, { message: 'Please enter a valid URL' })
  facebook: string;

  @ValidateIf(e => e.otherPage && e.otherPage.trim() !== '')
  @IsOptional()
  @IsUrl(undefined, { message: 'Please enter a valid URL' })
  otherPage: string;

  @ValidateIf(e => e.otherLinks && e.otherLinks.trim() !== '')
  @IsOptional()
  @IsUrl(undefined, { message: 'Please enter a valid URL' })
  otherLinks: string;
}

const resolver: Resolver<BusinessInfoInput> =
  classValidatorResolver(BusinessInfoInput);

export default function BusinessInfo(props: BusinessInfoProps) {
  const { setFormValues, signupFormData } = useFormData();

  const {
    register,
    control,
    formState: { dirtyFields, errors },
    handleSubmit,
  } = useForm<BusinessInfoInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      // category: signupFormData.category || '',
      years: signupFormData.years || '',
      description: signupFormData.description || '',
      linkedIn: signupFormData.linkedIn || '',
      facebook: signupFormData.facebook || '',
      otherPage: signupFormData.otherPage || '',
      otherLinks: signupFormData.otherLinks || '',
    },
  });

  const onSubmit = (newData: BusinessInfoInput) => {
    setFormValues(newData);
    const finishedSignupObject = { ...signupFormData, ...newData };
    props.submit(finishedSignupObject);
  };

  // const category_options = [
  //   { id: 1, label: 'Wellness', value: 'wellness' },
  //   { id: 2, label: 'Food & Drink', value: 'foodDrink' },
  //   { id: 3, label: 'Education', value: 'education' },
  // ];
  const years_options = [
    { id: 1, label: '0-2', value: '0-2' },
    { id: 2, label: '3-5', value: '3-5' },
    { id: 3, label: 'More than 5', value: '5+' },
  ];

  return (
    <div className="row d-flex justify-content-center">
      <div className="row text-start px-0">
        <div className="col-xl-4 col-lg-2 col-md-1 d-md-block d-sm-none d-none"></div>
        <div className="col-xl-4 col-lg-8 col-md-10 mb-4">
          Please tell us about your business
        </div>
        <div className="col-xl-4 col-lg-2 col-md-1 d-md-block d-sm-none d-none"></div>
      </div>

      <div className="row text-start px-0">
        <div className="col-xl-4 col-lg-2 col-md-1 d-md-block d-sm-none d-none"></div>
        <div className="col-xl-7 col-lg-8 col-md-10 mb-5">
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            {props.warning && (
              <MessageBanner type="error" sx={{ width: '384px' }}>
                {props.warning}
              </MessageBanner>
            )}
            {/* <Controller
              name="category"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  inputId="category-dropdown"
                  isSearchable={false}
                  options={category_options}
                  styles={!!errors.category ? errorStyles : styles}
                  components={{ ValueContainer: customValueContainer }}
                  placeholder="Select Business Category"
                />
              )}
            /> */}
            <Controller
              name="years"
              control={control}
              render={({ field }) => (
                <div data-testid="businessInfo.yearsOperate">
                  <Select
                    {...field}
                    inputId="years-dropdown"
                    isSearchable={false}
                    options={years_options}
                    styles={!!errors.years ? errorStyles : styles}
                    components={{ ValueContainer: customValueContainer }}
                    placeholder="Years operating"
                  />
                </div>
              )}
            />
            <HookFormTextArea
              isFilled={
                Object.keys(dirtyFields).includes('description') ||
                !!signupFormData.description
              }
              name="description"
              label="Please provide a short description"
              testid="businessInfo.description"
              size="form-xl"
              register={register}
              error={errors.description}
              limit={TEXT_AREA_LIMIT_LONG}
            />
            <HookFormTextInput
              isFilled={
                Object.keys(dirtyFields).includes('linkedIn') ||
                !!signupFormData.linkedIn
              }
              register={register}
              name="linkedIn"
              label="LinkedIn URL (optional)"
              testid="businessInfo.linkedIn"
              type="text"
              width="form-xl"
            />
            <HookFormTextInput
              isFilled={
                Object.keys(dirtyFields).includes('facebook') ||
                !!signupFormData.facebook
              }
              register={register}
              name="facebook"
              label="Facebook URL (optional)"
              testid="businessInfo.facebook"
              type="text"
              width="form-xl"
            />
            <HookFormTextInput
              isFilled={
                Object.keys(dirtyFields).includes('otherPage') ||
                !!signupFormData.otherPage
              }
              register={register}
              name="otherPage"
              label="Other business page (optional)"
              testid="businessInfo.otherPage"
              type="text"
              width="form-xl"
            />
            <HookFormTextInput
              isFilled={
                Object.keys(dirtyFields).includes('otherLinks') ||
                !!signupFormData.otherLinks
              }
              register={register}
              name="otherLinks"
              label="Other links to business pages (optional)"
              testid="businessInfo.otherLinks"
              type="text"
              width="form-xl"
            />
            <div
              style={{
                marginTop: '20px',
                marginLeft: '-40px',
                display: 'flex',
                flexDirection: 'row-reverse',
                gap: '160px',
              }}
            >
              <HookFormSubmitButton
                text="Submit request"
                default="blue-baby-blue"
                hover="white-navy"
              />
              <RoundButton
                text="< Back"
                default="all-white"
                hover="all-white"
                handleClick={props.handlePrev}
              />
            </div>
          </form>

          <div className="col-xl-1 col-lg-2 col-md-1 d-md-block d-sm-none d-none"></div>
        </div>
      </div>
    </div>
  );
}
