import { useAuth } from './useAuth';
import { useQuery } from '@tanstack/react-query';
import { ApiClient } from '../api/apiClient';
import { ResponseRecurringEventDto } from '@/types/RecurringEvent';

type UseRecurringEventPropsType = {
  recurringEventId?: string;
};

// get recurring-event for a given listing - when applicable
export const useRecurringEvents = ({
  recurringEventId,
}: UseRecurringEventPropsType) => {
  const { data: user } = useAuth();
  const apiClient = ApiClient();

  const getRecurringEvent = async (
    accessToken?: string
  ): Promise<ResponseRecurringEventDto | null> => {
    if (accessToken && recurringEventId) {
      return await apiClient.recurringEvents.getRecurringEvents({
        recurringEventId,
        includeListings: false,
      });
    } else {
      return null;
    }
  };

  return useQuery<ResponseRecurringEventDto | null>(
    ['recurringEvent', recurringEventId],
    () => getRecurringEvent(user?.accessToken),
    { enabled: !!user && !!recurringEventId }
  );
};
