import { Grid, Typography, Box } from '@silverstein-properties/inspirelabs-ui';
import { Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';
import RoundButton from '@/components/RoundButton/Component';

export type CtaType = {
  message: string;
  link?: string;
  function?: () => void;
  type: 'button' | 'link';
};

export type CenteredMessageBlockProps = {
  image?: string;
  title: string;
  body?: string;
  cta?: CtaType;
  cta2?: CtaType;
};

// this component can have button and/or underlined text call to actions, clicking these could either
// trigger a function or link to a page, we need to handle all these cases
const renderCta = (cta: CtaType) => {
  if (cta.type === 'button') {
    return cta.link ? (
      <Link to={cta.link}>
        <RoundButton
          text={cta.message}
          default="blue-baby-blue"
          hover="white-navy"
          handleClick={cta.function}
        />
      </Link>
    ) : (
      <RoundButton
        text={cta.message}
        default="blue-baby-blue"
        hover="white-navy"
        handleClick={cta.function}
      />
    );
  } else {
    return cta.link ? (
      <MuiLink variant="body2" component={Link} to={cta.link}>
        {cta.message}
      </MuiLink>
    ) : (
      <Box sx={{ cursor: 'pointer' }} onClick={cta.function}>
        <Typography sx={{ textDecoration: 'underline' }} variant="body2">
          {cta.message}
        </Typography>
      </Box>
    );
  }
};

export const CenteredMessageBlock = ({
  image,
  title,
  body,
  cta,
  cta2,
}: CenteredMessageBlockProps) => {
  return (
    <Grid container flexDirection="column" alignItems="center" sx={{ gap: 5 }}>
      <Grid
        container
        item
        flexDirection="column"
        alignItems="center"
        textAlign="center"
        sx={{ gap: 1 }}
      >
        {image && <img src={image} alt="image" height="80px" width="80px" />}
        <Typography variant="h4">{title}</Typography>
        <Typography variant="body1">{body}</Typography>
      </Grid>
      <Grid
        container
        item
        flexDirection="column"
        alignItems="center"
        sx={{ gap: 3 }}
      >
        {cta && renderCta(cta)}
        {cta2 && renderCta(cta2)}
      </Grid>
    </Grid>
  );
};
