import { InputBase } from '@mui/material';
import { StyledBox } from './MessageTextField.styles';
import React from 'react';

export type MessageTextFieldProps = {
  message: string;
  onMessageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const MessageTextField = ({
  message,
  onMessageChange,
}: MessageTextFieldProps) => {
  return (
    <StyledBox>
      <InputBase
        id="message"
        multiline
        maxRows={4}
        fullWidth
        value={message}
        onChange={onMessageChange}
        placeholder="Type a message..."
      />
    </StyledBox>
  );
};
