import { CommunicationMessageType } from '@/types';
import { Grid, styled } from '@mui/material';
import { Typography } from '@silverstein-properties/inspirelabs-ui';

export type StyledSideMessageCardGridProps = {
  isSelected: boolean;
};
export type StyledPurchaseableTypeLabelProps = {
  messageType: CommunicationMessageType;
};

export const StyledSideMessageCardGrid = styled(Grid, {
  shouldForwardProp: prop => prop !== 'isSelected',
})<StyledSideMessageCardGridProps>(({ theme, isSelected }) => ({
  alignItems: 'center',
  backgroundColor: isSelected ? theme.palette.secondary.light : 'initial',
  padding: theme.spacing(0, 3),
  cursor: 'pointer',
  flexWrap: 'nowrap',
  '&:not(:last-child)': {
    '& > div:last-child': {
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
  },
}));

export const StyledPurchaseableTypeLabel = styled(Typography, {
  shouldForwardProp: prop => prop !== 'messageType',
})<StyledPurchaseableTypeLabelProps>(({ theme, messageType }) => {
  let backgroundColor = theme.palette.primary.main;
  let color = theme.palette.common.white;

  switch (messageType) {
    case CommunicationMessageType.BOOKING_REQUEST:
      backgroundColor = theme.palette.success.main;
      color = theme.palette.common.white;
      break;
    case CommunicationMessageType.SIGN_UP:
      backgroundColor = theme.palette.success.main;
      color = theme.palette.common.white;
      break;
    case CommunicationMessageType.INQUIRIES:
      backgroundColor = theme.palette.grey[300];
      color = theme.palette.primary.main;
      break;
    case CommunicationMessageType.CANCELLATION:
      backgroundColor = theme.palette.error.main;
      color = theme.palette.common.white;
      break;
    default:
      backgroundColor = theme.palette.primary.main;
      color = theme.palette.common.white;
      break;
  }

  return {
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
    paddingRight: theme.spacing(0.825),
    paddingLeft: theme.spacing(0.825),
    borderRadius: theme.spacing(0.625),
    alignItems: 'center',
    backgroundColor: backgroundColor,
    color: color,
    fontSize: 12,
    marginBottom: theme.spacing(0.625),
  };
});
