import { EmptyState } from '@/components';
import { Button, Container } from '@silverstein-properties/inspirelabs-ui';
import { DetailsDrawer } from '@/components';
import { MainContainerGrid } from './MessagesModule.styles';
import { AddQuote, MainMessages, SideMessages } from './components';
import { useEffect, useState } from 'react';
import {
  retractQuoteMutation,
  useFeatureFlags,
  useMainCommunicationConversation,
  useSideCommunicationMessages,
} from '@/hooks';
import { LoadingButton } from '@mui/lab';
import { ViewQuote } from '../CommonModule/ViewQuote';
import { useLocation } from 'react-router';

enum DetailsDrawerTypeEnum {
  ADD = 'add',
  EDIT = 'edit',
  VIEW = 'view',
  NONE = 'none',
}

export const MessagesModule = () => {
  const location = useLocation();
  const { isBookingRequestEnabled } = useFeatureFlags();
  const [selectedPurchaseId, setSelectedPurchaseId] = useState<string>();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isAddingQuoteLoading, setIsAddingQuoteLoading] = useState(false);
  const [unsentQuoteAdded, setIsUnsentQuoteAdded] = useState(false);
  const [deleteQuoteErrorMessage, setDeleteQuoteErrorMessage] = useState('');
  const [detailsDrawerType, setDetailsDrawerType] =
    useState<DetailsDrawerTypeEnum>(DetailsDrawerTypeEnum.NONE);

  const {
    data: sideMessages,
    isFetched,
    refetch: refetchSideMessages,
  } = useSideCommunicationMessages(isBookingRequestEnabled);
  const { data: mainCommunicationThread, refetch: refetchMainThread } =
    useMainCommunicationConversation(selectedPurchaseId || '');

  const handleSideMessageSelect = (bookingId: string) => {
    setSelectedPurchaseId(bookingId);
    refetchSideMessages();
    refetchMainThread();
  };

  // retract
  const onSuccessRetractQuote = () => {
    setIsUnsentQuoteAdded(false);
    refetchSideMessages();
    refetchMainThread();
    setDrawerOpen(false);
    setDeleteQuoteErrorMessage('');
  };
  const onErrorRetractQuote = () => {
    setDeleteQuoteErrorMessage('Failed to delete quote');
  };
  const { mutate: retractQuote, isLoading: retractQuoteLoading } =
    retractQuoteMutation(
      {
        bookingRequestId: selectedPurchaseId || '',
      },
      onSuccessRetractQuote,
      onErrorRetractQuote
    );

  const onRetractSentQuote = () => {
    // retracting a quote that has been sent
    // will set the listing to merchant cancelled
    retractQuote();
    setIsUnsentQuoteAdded(false);
  };

  useEffect(() => {
    if (isFetched && sideMessages?.length) {
      setSelectedPurchaseId(
        location.state?.purchaseId || sideMessages[0].communicationPurchaseId
      );
    }
  }, [isFetched]);

  // drawer details
  const closeBookingRequestQuoteDrawer = () => {
    setDetailsDrawerType(DetailsDrawerTypeEnum.NONE);
    setDrawerOpen(false);
  };

  const openBookingRequestAddQuoteDrawer = () => {
    setDetailsDrawerType(DetailsDrawerTypeEnum.ADD);
    setDrawerOpen(true);
  };

  const openBookingRequestViewQuoteDrawer = () => {
    setDetailsDrawerType(DetailsDrawerTypeEnum.VIEW);
    setDrawerOpen(true);
  };

  // detail drawer
  const detailDrawerTitle =
    detailsDrawerType === DetailsDrawerTypeEnum.ADD
      ? 'Add quote'
      : 'Quote details';

  const detailDrawerActions =
    detailsDrawerType === DetailsDrawerTypeEnum.ADD ? (
      <>
        <Button onClick={closeBookingRequestQuoteDrawer} variant="text">
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          data-testid="attachQuoteBtn"
          form="addQuoteForm"
          loading={isAddingQuoteLoading}
          variant="contained"
        >
          Attach to message
        </LoadingButton>
      </>
    ) : (
      <LoadingButton
        variant="text"
        loading={retractQuoteLoading}
        onClick={onRetractSentQuote}
        // It suppose to have "spacers.xl : 32px" but there are "spacers.m : 16px" of paddings
        // added in this file already: src /components/DetailsDrawer/DetailsDrawer.styles.ts (line 13)
        sx={{ color: 'error.main', marginY: theme => theme.spacing(2) }}
      >
        Retract Quote
      </LoadingButton>
    );

  return (
    <Container
      maxWidth={sideMessages?.length ? 'lg' : 'xs'}
      sx={{ paddingY: 8 }}
    >
      <DetailsDrawer
        actions={detailDrawerActions}
        closeWindow={closeBookingRequestQuoteDrawer}
        drawerOpen={drawerOpen}
        title={detailDrawerTitle}
      >
        {detailsDrawerType === DetailsDrawerTypeEnum.ADD ? (
          <AddQuote
            bookingRequestId={selectedPurchaseId || ''}
            closeDrawer={closeBookingRequestQuoteDrawer}
            handleSubmitLoading={setIsAddingQuoteLoading}
            setIsUnsentQuoteAdded={setIsUnsentQuoteAdded}
          />
        ) : (
          <ViewQuote
            bookingRequestId={selectedPurchaseId || ''}
            deleteQuoteErrorMessage={deleteQuoteErrorMessage}
          />
        )}
      </DetailsDrawer>

      {sideMessages?.length && selectedPurchaseId ? (
        <MainContainerGrid container>
          <SideMessages
            handleMessageSelect={handleSideMessageSelect}
            selectedPurchaseId={selectedPurchaseId}
            sideMessages={sideMessages}
          />
          {!!selectedPurchaseId && (
            <MainMessages
              mainCommunicationThread={mainCommunicationThread}
              refetchMainThread={refetchMainThread}
              selectedPurchaseId={selectedPurchaseId}
              openBookingRequestAddQuoteDrawer={
                openBookingRequestAddQuoteDrawer
              }
              openBookingRequestViewQuoteDrawer={
                openBookingRequestViewQuoteDrawer
              }
              unsentQuoteAdded={unsentQuoteAdded}
              setIsUnsentQuoteAdded={setIsUnsentQuoteAdded}
            />
          )}
        </MainContainerGrid>
      ) : (
        <EmptyState
          content="This is where you'll see all your communications with your guests and The Hub"
          icon="/images/messages.svg"
          cta={{ label: 'Go to Dashboard', link: '/dashboard' }}
        />
      )}
    </Container>
  );
};
