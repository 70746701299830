import {
  DialogBox,
  StyledDialogActions,
  StyledDialogBoxInternal,
  StyledFormControlLabel,
  StyledImage,
} from './SelectionDialog.styles';
import {
  Button,
  FormControl,
  MessageBanner,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
  Radio,
  DialogTitle,
} from '@silverstein-properties/inspirelabs-ui';
import { Box } from '@mui/material';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';

export interface SelectionProps {
  label: string;
  value: string;
}

export interface SelectionDialogProps {
  open: boolean;
  onClose: (value: string | undefined) => Promise<void> | void;
  title: string;
  image: string | JSX.Element;
  selections: SelectionProps[];
  errorMessage?: string;
  agree: string;
  disagree: string;
}

export default function SelectionDialog({
  open,
  onClose,
  title,
  image,
  selections,
  errorMessage,
  agree,
  disagree,
}: SelectionDialogProps) {
  const [isLoadingButton, setLoadingButton] = useState(false);
  const [selectedChoice, setSelectedChoice] = useState<string | undefined>(
    selections[0].value
  );
  const theme = useTheme();

  const handleUserConfirmation = async () => {
    setLoadingButton(true);
    try {
      await onClose(selectedChoice);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingButton(false);
    }
  };
  const handleCancel = async () => {
    setSelectedChoice(undefined);
    setLoadingButton(true);
    try {
      await onClose(undefined);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingButton(false);
    }
  };

  return (
    <>
      <DialogBox
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            width: '420px',
            maxWidth: '420px',
            maxHeight: '80%',
            height: 'auto',
          },
        }}
      >
        <StyledDialogBoxInternal>
          {typeof image === 'string' ? (
            <StyledImage
              height="80px"
              width="80px"
              src={image}
              alt="Alert Icon"
            />
          ) : (
            <Stack
              justifyContent="center"
              alignItems="center"
              alignSelf="center"
              sx={{
                width: '80px',
                height: '80px',
              }}
            >
              {image}
            </Stack>
          )}
          <DialogTitle id="alert-dialog-title">
            <Typography variant="h4">{title}</Typography>
            <Box sx={{ width: '100%' }}>
              <MessageBanner hidden={!errorMessage} type="error">
                {errorMessage}
              </MessageBanner>
            </Box>
          </DialogTitle>

          <Box>
            <FormControl>
              <RadioGroup
                defaultValue={selections[0].value}
                onChange={e => setSelectedChoice(e.target.value)}
              >
                {(selections || []).map(selection => (
                  <StyledFormControlLabel
                    key={selection.value}
                    value={selection.value}
                    control={<Radio />}
                    label={selection.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Box>

          <StyledDialogActions>
            <LoadingButton
              loading={isLoadingButton}
              variant="contained"
              size="large"
              onClick={handleUserConfirmation}
            >
              {agree}
            </LoadingButton>

            <Button
              variant="text"
              size="large"
              sx={{ marginTop: theme.spacers.l }}
              onClick={handleCancel}
            >
              {disagree}
            </Button>
          </StyledDialogActions>
        </StyledDialogBoxInternal>
      </DialogBox>
    </>
  );
}
