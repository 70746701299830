import {
  Grid,
  MessageBanner,
  Typography,
} from '@silverstein-properties/inspirelabs-ui';
import { usePassword } from '@/hooks/usePassword';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { IsEmail } from 'class-validator';
import { HookFormTextInput } from 'components/FormTextInput/HookFormTextInput';
import HookFormSubmitButton from 'components/RoundButton/HookFormSubmitButton';
import { CenteredMessageBlock } from 'components/CenteredMessageBlock/CenteredMessageBlock';
import { Link } from 'react-router-dom';

class SendPasswordLinkInput {
  @IsEmail(undefined, { message: 'Please enter a valid email address' })
  email: string;
}

const resolver = classValidatorResolver(SendPasswordLinkInput);

export const SendResetPasswordModule = () => {
  const { state } = useLocation();
  const {
    register,
    formState: { dirtyFields, errors },
    handleSubmit,
    getValues,
  } = useForm<SendPasswordLinkInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      email: state?.email || '',
    },
  });

  const { triggerResetEmail, success, warning, resetSuccess } = usePassword();

  const onSubmit = (data: { email: string }) => {
    triggerResetEmail(data.email);
  };

  if (success) {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        marginTop={8}
      >
        <Grid container xs={3}>
          <CenteredMessageBlock
            title="Thank you"
            body={`An email was sent to ${getValues(
              'email'
            )} with a link to reset your password`}
            image="/images/messages.svg"
            cta2={{
              message: 'Resend reset link',
              function: resetSuccess,
              type: 'link',
            }}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={3} ml={10}>
        <Grid item container flexDirection="column" mt={8} xs={8}>
          <Typography variant="h4">Forgot password</Typography>
          <Grid item container flexDirection="column" mt={5} gap={4}>
            <Typography variant="subtitle1">
              Enter your email address and we'll get you back on track
            </Typography>
            {success && <MessageBanner type="success">{success}</MessageBanner>}
            {warning && <MessageBanner type="error">{warning}</MessageBanner>}
            <form onSubmit={handleSubmit(onSubmit)}>
              <HookFormTextInput
                type="text"
                width="form-xl"
                label="Email"
                name="email"
                register={register}
                isFilled={
                  Object.keys(dirtyFields).includes('email') || !!state?.email
                }
                error={errors.email}
              />
              <Grid
                item
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Link to="/login">
                  <Typography variant="subtitle2">‹ Back</Typography>
                </Link>

                <HookFormSubmitButton
                  text="Request Reset Link"
                  default="blue-baby-blue"
                  hover="white-navy"
                />
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
