import {
  Box,
  InputField,
  Stack,
  Typography,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import {
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import {
  BookingMethod,
  BuildingSelect,
  Experience,
  Location,
  LocationEnvironmentTypes,
  ProductTypes,
} from '@/types';
import { LabelField } from '@/components';
import { useAuth, useMerchantServiceableBuildings } from '@/hooks';
import { MenuProps, getStyles } from './LocationSectionEdit.styles';
import {
  extractSelectedBuildingIdOptions,
  renderInputFieldHelperText,
  isAddressEditAvailable,
} from '@/utils';
import { SERVICE_TYPE_CHOICES, TEXT_AREA_LIMIT_LONG } from '@/constants';
import { LocationSectionEditInput } from '@/classes/LocationSectionEditInput';
import { useEffect } from 'react';

const resolver = classValidatorResolver(LocationSectionEditInput);

export type LocationSectionEditPropsType = {
  experience: Experience;
  location?: Location | null;
  onFormSubmit: (data: LocationSectionEditInput) => void;
};

export const LocationSectionEdit = ({
  experience,
  location,
  onFormSubmit,
}: LocationSectionEditPropsType) => {
  const { data: user } = useAuth();
  const { data: serviceableBuildings } = useMerchantServiceableBuildings(
    user?.primaryMerchantId || ''
  );
  const theme = useTheme();
  const isNotAdvert = experience.type !== ProductTypes.ADVERT;

  const {
    watch,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    resetField,
  } = useForm<LocationSectionEditInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      address: isNotAdvert
        ? {
            street: location?.address?.street || '',
            city: location?.address?.city || '',
            state: location?.address?.state || '',
            apartment: location?.address?.apartment || '',
            zipCode: location?.address?.zipCode || '',
            // default in BE that country is US
          }
        : undefined,
      environmentType:
        location?.environmentType ||
        (experience.bookingMethods?.includes(BookingMethod.DELIVERY)
          ? LocationEnvironmentTypes.INDOOR_CUSTOMER_LOCATION
          : LocationEnvironmentTypes.NONE),
      ...(!experience.bookingMethods?.includes(BookingMethod.DELIVERY) && {
        description: location?.info || location?.description || '',
      }),
      buildingIds: extractSelectedBuildingIdOptions(
        serviceableBuildings,
        experience.buildingIds
      ),
      hasServiceableBuildings:
        !!serviceableBuildings && serviceableBuildings.length > 0,
      isExperienceAdvert: !isNotAdvert,
    },
  });

  const environmentType = watch('environmentType');

  // For request with booking request, we want to only show the address
  // fields only if the location isn't at the customer's location

  const canAddressBeEdited = isAddressEditAvailable(
    experience.bookingMethods,
    environmentType
  );

  const onSubmit = (data: LocationSectionEditInput) => {
    onFormSubmit(data);
  };

  const handleBuildingIdsChange = (
    event: SelectChangeEvent<BuildingSelect[]>
  ) => {
    const currentValue = event.target.value;
    const selectedValue = currentValue[currentValue.length - 1];
    const selectedBuilding = serviceableBuildings?.find(
      building => building.id.toString() === selectedValue
    );
    if (!selectedBuilding) {
      return;
    }
    // If building is selected again, remove it from the list
    if (
      (currentValue as BuildingSelect[]).some(
        x => x.id?.toString() === selectedValue
      )
    ) {
      (currentValue as BuildingSelect[]).pop();
      (currentValue as BuildingSelect[]).splice(
        (currentValue as BuildingSelect[]).findIndex(
          (x: BuildingSelect) => x.id === selectedBuilding?.id
        ),
        1
      );
    } else {
      // otherwise replace the string with SelectionType object
      (currentValue as BuildingSelect[])[currentValue.length - 1] =
        selectedBuilding;
    }

    // Set the value buildingIds with the new value
    setValue('buildingIds', currentValue as BuildingSelect[]);
  };

  useEffect(() => {
    if (!canAddressBeEdited) {
      setValue('address', undefined, {
        shouldValidate: false,
        shouldDirty: false,
      });
    }
  }, [canAddressBeEdited]);

  useEffect(() => {
    resetField('address');
    setValue(
      'shouldValidateAddress',
      isAddressEditAvailable(experience.bookingMethods, environmentType)
    );
  }, [environmentType]);

  return (
    <Stack spacing={4}>
      <Typography variant="h5">Location</Typography>
      <form id="locationForm" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4} width="100%">
          <LabelField
            label="Permitted Audience"
            value={
              serviceableBuildings && serviceableBuildings?.length > 0 ? (
                <Box sx={{ display: 'inline-flex', mt: 2 }}>
                  <Controller
                    name="buildingIds"
                    control={control}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <InputLabel id={`${field.name}-label`}>
                          Building
                        </InputLabel>
                        <Select
                          {...field}
                          data-testid="buildingIds"
                          error={!!errors.buildingIds}
                          onChange={handleBuildingIdsChange}
                          labelId={`${field.name}-label`}
                          multiple
                          input={
                            <OutlinedInput
                              id={`select-multiple-${field.name}`}
                              label="Building"
                            />
                          }
                          renderValue={selected => (
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 0.5,
                              }}
                            >
                              {selected.map(building => (
                                <Chip key={building.id} label={building.name} />
                              ))}
                            </Box>
                          )}
                          MenuProps={MenuProps}
                        >
                          {serviceableBuildings?.map(building => (
                            <MenuItem
                              key={building.id}
                              value={building.id.toString()}
                              style={getStyles(
                                building.name,
                                field.value.map(building => building.name),
                                theme
                              )}
                            >
                              {building.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText error={!!errors.buildingIds}>
                          {errors.buildingIds?.message}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </Box>
              ) : (
                'No buildings available. Please contact support to add buildings to your account.'
              )
            }
          />

          {isNotAdvert && (
            <LabelField
              label="Where is the experience taking place?"
              value={
                <Box mt={2}>
                  <Controller
                    name="environmentType"
                    control={control}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <Select
                          {...field}
                          data-testid="locationType"
                          fullWidth
                          disabled={experience.bookingMethods?.includes(
                            BookingMethod.DELIVERY
                          )}
                          displayEmpty={true}
                          error={!!errors.environmentType}
                          renderValue={selected => {
                            if (!selected) {
                              return 'Select location type';
                            }
                            const selectedLocationType =
                              SERVICE_TYPE_CHOICES.find(
                                choice => choice.id === selected
                              );
                            return selectedLocationType?.label || '';
                          }}
                        >
                          {SERVICE_TYPE_CHOICES.map(choice => (
                            <MenuItem key={choice.id} value={choice.id}>
                              {choice.label}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText error={!!errors.environmentType}>
                          {errors.environmentType?.message}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </Box>
              }
            />
          )}
          {canAddressBeEdited && isNotAdvert ? (
            <>
              <LabelField
                label="Edit address"
                value={
                  <>
                    <Stack direction="row" my={2}>
                      <Controller
                        control={control}
                        name="address.street"
                        render={({ field }) => (
                          <Box flex={2}>
                            <InputField
                              {...field}
                              data-testid="addressStreet"
                              error={!!errors.address?.street}
                              label="Address"
                              helperText={errors.address?.street?.message}
                            />
                          </Box>
                        )}
                      />
                      <Controller
                        control={control}
                        name="address.apartment"
                        render={({ field }) => (
                          <Box flex={1}>
                            <InputField
                              {...field}
                              data-testid="addressApt"
                              error={!!errors.address?.apartment}
                              label="Apt./Fl."
                              helperText={errors.address?.apartment?.message}
                            />
                          </Box>
                        )}
                      />
                    </Stack>
                    <Stack direction="row">
                      <Controller
                        control={control}
                        name="address.city"
                        render={({ field }) => (
                          <Box flex={2}>
                            <InputField
                              {...field}
                              data-testid="addressCity"
                              error={!!errors.address?.city}
                              label="City"
                              helperText={errors.address?.city?.message}
                            />
                          </Box>
                        )}
                      />
                      <Controller
                        control={control}
                        name="address.state"
                        render={({ field }) => (
                          <Box flex={1}>
                            <InputField
                              {...field}
                              data-testid="addressState"
                              label="State"
                              error={!!errors.address?.state}
                              helperText={errors.address?.state?.message}
                            />
                          </Box>
                        )}
                      />
                      <Controller
                        control={control}
                        name="address.zipCode"
                        render={({ field }) => (
                          <Box flex={1}>
                            <InputField
                              {...field}
                              data-testid="addressZip"
                              label="Zipcode"
                              error={!!errors.address?.zipCode}
                              helperText={errors.address?.zipCode?.message}
                            />
                          </Box>
                        )}
                      />
                    </Stack>
                  </>
                }
              />
            </>
          ) : null}
          {!experience.bookingMethods?.includes(BookingMethod.DELIVERY) &&
          isNotAdvert ? (
            <LabelField
              label="Describe the location"
              value={
                <Box mt={2}>
                  <Controller
                    control={control}
                    name="description"
                    render={({ field }) => (
                      <InputField
                        {...field}
                        data-testid="locationDescription"
                        label="Add description"
                        multiline
                        minRows={6}
                        error={!!errors.description}
                        helperText={renderInputFieldHelperText(
                          watch('description')?.length,
                          TEXT_AREA_LIMIT_LONG,
                          errors.description?.message
                        )}
                      />
                    )}
                  />
                </Box>
              }
            />
          ) : null}
        </Stack>
      </form>
    </Stack>
  );
};
