import { BusinessSignupComponentProps } from './Component';
import { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { useFormData } from '../../contexts/MerchCreationProvider';
import { HOURS_OPTIONS, MINUTES_OPTIONS } from '@/constants';
import { TEXT_AREA_LIMIT_LONG } from '@/constants';
import {
  Box,
  Button,
  InputField,
  Typography,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import { BackButton } from '../BackButton';
import { PartialMerchCreationObject } from '@/types';
import { OfferingDescriptionInput } from '@/classes/BusinessFormContainerInput';
import { renderInputFieldHelperText } from '@/utils';

const resolver = classValidatorResolver(OfferingDescriptionInput);

export function OfferingDescription(props: BusinessSignupComponentProps) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const theme = useTheme();

  const { setFormValues, merchandiseData } = useFormData();
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<OfferingDescriptionInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      description: merchandiseData?.description || '',
      durationHours:
        (typeof merchandiseData?.durationHours === 'string'
          ? merchandiseData.durationHours
          : (merchandiseData.durationHours?.value as string)) || '',
      durationMinutes:
        (typeof merchandiseData?.durationMinutes === 'string'
          ? merchandiseData.durationMinutes
          : (merchandiseData.durationMinutes?.value as string)) || '',
    },
  });

  const onSubmit = (data: OfferingDescriptionInput) => {
    setFormValues(data as PartialMerchCreationObject);
    props.updateExperience(data as PartialMerchCreationObject);
    props.nextPage();
  };

  return (
    <div className="row ms-xl-5 ms-lg-5 me-md-5 ms-sm-1 ms-xs-1 top-64 d-flex justify-content-end">
      <div className="col-8">
        <h4 className="business-form-headline mb-5">Describe your offering</h4>
        <div className="theme-form">
          <Typography variant="subtitle1" marginBottom={theme.spacers.m}>
            Provide specific plans from start to finish. Describe what makes it
            special.
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <InputField
                  {...field}
                  inputProps={{
                    'data-testid': 'offering.description',
                  }}
                  multiline
                  minRows={5}
                  label="Add description"
                  error={!!errors.description}
                  helperText={renderInputFieldHelperText(
                    watch('description')?.length,
                    TEXT_AREA_LIMIT_LONG,
                    errors.description?.message
                  )}
                />
              )}
            />

            <Typography variant="subtitle1">Length of service</Typography>
            <Box sx={{ width: '100%', display: 'flex' }}>
              <Controller
                name="durationHours"
                control={control}
                render={({ field }) => (
                  <InputField
                    {...field}
                    inputProps={{
                      'data-testid': 'offering.hours',
                    }}
                    select
                    label="Hours"
                    placeholder="Hours"
                    fullWidth
                    error={!!errors.durationHours}
                    helperText={errors.durationHours?.message}
                    dropdownItems={HOURS_OPTIONS}
                    sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                  />
                )}
              />
              <Controller
                name="durationMinutes"
                control={control}
                render={({ field }) => (
                  <InputField
                    {...field}
                    inputProps={{
                      'data-testid': 'offering.minutes',
                    }}
                    select
                    label="Minutes"
                    placeholder="Minutes"
                    fullWidth
                    error={!!errors.durationMinutes}
                    helperText={errors.durationMinutes?.message}
                    dropdownItems={MINUTES_OPTIONS}
                    sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                  />
                )}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: theme.spacers.xl,
              }}
            >
              <BackButton onClick={props.prevPage} />
              <Button type="submit">Continue</Button>
            </Box>
          </form>
        </div>
      </div>
    </div>
  );
}
