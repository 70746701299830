import { BookingMethod, Experience, RecursiveKeyOf } from '@/types';
import { Box, Grid, Typography } from '@silverstein-properties/inspirelabs-ui';
import { Card } from '@mui/material';
import { StyledCardContent } from './DetailSection.styles';
import RoundButton from '@/components/RoundButton/Component';
import { checkValuesInObject, formatDateForExperienceView } from '@/utils';
import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useFeatureFlags } from '@/hooks';
import { DetailEmptySection } from './DetailEmptySection';
import { CustomExperienceCheck } from '@/types';

export type DetailSectionPropsType = {
  children: ReactNode;
  id: string;
  title?: string;
  emptyImage?: string;
  emptyMessage?: string;
  completeInfoCheck?: {
    [Property in BookingMethod]: (
      | RecursiveKeyOf<Experience>
      | CustomExperienceCheck
    )[];
  };
  showCompleteInfoCheck?: boolean;
  experience: Experience;
  onEditClick?: (screenName: string) => void;
};

export const DetailSection = ({
  children,
  id,
  title,
  emptyImage,
  emptyMessage,
  completeInfoCheck,
  showCompleteInfoCheck,
  experience,
  onEditClick,
}: DetailSectionPropsType) => {
  let { isEditButtonInExperienceDetailsPageEnabled } = useFeatureFlags();
  isEditButtonInExperienceDetailsPageEnabled =
    id === 'location' ? true : isEditButtonInExperienceDetailsPageEnabled; // temporary hack to edit Building while FT is off

  const formattedTimeStamp = experience.updatedAt
    ? 'Updated at ' + formatDateForExperienceView(experience.updatedAt)
    : experience.createdAt
    ? 'Created at ' + formatDateForExperienceView(experience.createdAt)
    : '';

  const navigate = useNavigate();

  const isEmpty =
    showCompleteInfoCheck &&
    (experience?.bookingMethods || [BookingMethod.INSTANT]).every(
      bookingMethod =>
        completeInfoCheck &&
        checkValuesInObject(
          experience,
          completeInfoCheck[bookingMethod] as string[],
          'empty'
        )
    );

  useEffect(() => {
    const el = document.getElementById(id);
    const observer = new window.IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          navigate(`#${id}`);
          return;
        }
      },
      {
        // Root element is the viewport and 64px offset for the header
        root: null,
        rootMargin: '200px 0px 0px 0px',
        threshold: 1,
      }
    );

    if (el) {
      observer.observe(el);
    }
  }, [id, navigate]);

  if (isEmpty) {
    return (
      <DetailEmptySection
        id={id}
        title={title}
        image={emptyImage}
        emptyMessage={emptyMessage}
        onEditClick={onEditClick}
      />
    );
  }

  return (
    <Grid id={id} item xs={12}>
      <Card variant="outlined">
        <StyledCardContent sx={{}}>
          <Grid container>
            {title && (
              <Box>
                <Typography variant="h5">{title}</Typography>
                <Typography variant="body3" color="primary.medium">
                  {formattedTimeStamp}
                </Typography>
              </Box>
            )}
            {isEditButtonInExperienceDetailsPageEnabled && !!onEditClick ? (
              <Box sx={{ marginLeft: 'auto' }}>
                <RoundButton
                  text="Edit"
                  default="white-navy small"
                  hover="blue small"
                  handleClick={() => onEditClick(id)}
                />
              </Box>
            ) : null}
          </Grid>
          <Box sx={{ mt: 4 }}>{children}</Box>
        </StyledCardContent>
      </Card>
    </Grid>
  );
};
