import { Listing } from 'types';
import { useQuery } from '@tanstack/react-query';
import { ApiClient } from '../api/apiClient';

type UseListingPropsType = {
  listingId?: string;
};

export const useListing = ({ listingId }: UseListingPropsType) => {
  const apiClient = ApiClient();

  const getListing = async (): Promise<Listing | null> => {
    if (listingId) {
      return await apiClient.products.getListing({ listingId });
    } else {
      return null;
    }
  };

  return useQuery<Listing | null>(['listing', listingId], () => getListing(), {
    enabled: !!listingId,
  });
};
