import { CalculatePriceBreakdown, formatPricing } from '@/utils';
import { StyledGrid } from './QuotePriceBreakdown.styles';
import { Typography } from '@mui/material';
import { Grid } from '@silverstein-properties/inspirelabs-ui';

export const QuotePriceBreakdown = ({
  guestPrice,
  platformFee,
  taxAmount,
  netAmount,
}: CalculatePriceBreakdown) => {
  if (isNaN(guestPrice)) {
    return null;
  }

  return (
    <StyledGrid container>
      <Grid container item>
        <Grid item xs={8}>
          <Typography variant="body2">Guest price</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">
            {formatPricing(guestPrice, 2)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item xs={8}>
          <Typography variant="body2">Tax</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">
            {formatPricing(-taxAmount, 2)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item xs={8}>
          <Typography variant="body2">Platform fee</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">
            {formatPricing(-platformFee, 2)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item xs={8}>
          <Typography variant="subtitle2">Total</Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">
            {formatPricing(netAmount, 2)}
          </Typography>
        </Grid>
      </Grid>
    </StyledGrid>
  );
};
