import { Stack } from '@mui/material';
import { useFieldArray, useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { Experience, PriceType, ProductTypes, PurchaseMethods } from '@/types';
import { useMembershipPlansByProductId, useMerchantPlatformFee } from '@/hooks';
import { Box } from '@silverstein-properties/inspirelabs-ui';
import { PricingFormInput } from '@/classes';
import { PricingForm } from '../PricingForm';
import { useEffect } from 'react';
import {
  evaluateIsExperienceBookingRequest,
  evaluateIsPriceMembersExclusive,
} from '@/utils';

const resolver = classValidatorResolver(PricingFormInput);

export enum BookingRequestPrice {
  PRICE_VIA_QUOTE = 'PRICE_VIA_QUOTE',
  PRICE_PER_GUEST = 'PRICE_PER_GUEST',
}

export type PricingSectionEditPropsType = {
  experience: Experience;
  onFormSubmit: (data: PricingFormInput) => Promise<void>;
  handleCloseDrawer: () => void;
};

export const PricingSectionEdit = ({
  experience,
  onFormSubmit,
  handleCloseDrawer,
}: PricingSectionEditPropsType) => {
  const { data: platformFeeRaw } = useMerchantPlatformFee(
    ProductTypes.ONE_TIME_EVENT
  );
  const {
    data: membershipPlans,
    isFetched,
    isLoading,
    refetch,
  } = useMembershipPlansByProductId({
    productId: experience.id,
  });

  const { control, formState, handleSubmit, watch, setValue, resetField } =
    useForm<PricingFormInput>({
      resolver,
      mode: 'onTouched',
      defaultValues: {
        acceptedPurchaseMethods: experience.acceptedPurchaseMethods?.length
          ? experience.acceptedPurchaseMethods
          : [PurchaseMethods.ONE_TIME_MONEY_PURCHASE],
        prices: [
          {
            priceId: experience.basePrice.id,
            amount: experience.basePrice.totalAmount ?? '',
            taxRateId: experience.basePrice.taxRateId || 0,
            priceType: experience.basePrice.priceType || '',
            isMemberExclusive: evaluateIsPriceMembersExclusive(
              experience.acceptedPurchaseMethods
            ),
          },
        ],
      },
    });

  const { fields, append, remove } = useFieldArray({ control, name: 'prices' });

  // Append new input fields for prices to the form once membership plans are loaded
  useEffect(() => {
    if (isFetched) {
      let priceType = experience.basePrice.priceType;
      if (!priceType) {
        priceType = evaluateIsExperienceBookingRequest(experience)
          ? PriceType.FLAT_FEE
          : PriceType.PRICE_PER_PERSON;
      }

      if (!fields.length) {
        // Here the first price is the base price of the product and then the
        // rest of the price points are populated from membership plans if any
        append({
          priceId: experience.basePrice.id,
          amount: experience.basePrice.totalAmount ?? '',
          taxRateId: experience.basePrice.taxRateId || 0,
          priceType: priceType || '',
          isMemberExclusive: evaluateIsPriceMembersExclusive(
            experience.acceptedPurchaseMethods
          ),
        });
      }
      // Field should be dirty if price id does not exist for the experience. This
      // ensures that once the merchant saves the form, we attach a price to experience
      if (!experience.basePrice.id) {
        setValue('prices.0.amount', experience.basePrice.totalAmount, {
          shouldDirty: true,
        });
      }

      if (membershipPlans) {
        membershipPlans.forEach((plan, i) => {
          const membershipPlanProduct = plan.membershipProducts.find(
            x => x.productId === experience.id
          );
          if (membershipPlanProduct) {
            // Default to experience's base price when membership plan pricing is missing
            const price = membershipPlanProduct.price ?? experience.basePrice;
            append(
              {
                priceId: membershipPlanProduct.price?.id,
                amount: price.totalAmount || '',
                taxRateId: price.taxRateId || 0,
                membershipPlanProductId: membershipPlanProduct.id,
                priceType: priceType || '',
              },
              { shouldFocus: false }
            );
            // Membership plan pricing should be set to dirty when pricing is not set. This
            // ensures that once the merchant saves the form, we attach a price to membership plan
            if (!membershipPlanProduct.price) {
              setValue(`prices.${i + 1}.amount`, price.totalAmount, {
                shouldDirty: true,
              });
            }
          }
        });
      }
    }

    // Remove the field once the react component is unloaded. This is to prevent memory leaks
    return () => remove();
  }, [isFetched]);

  const handleFormSubmit = (data: PricingFormInput): void => {
    const { dirtyFields } = formState;
    // If fields got dirtied, send only those fields for submission
    if (Object.keys(dirtyFields).length) {
      const finalData: PricingFormInput = {
        ...{
          acceptedPurchaseMethods:
            dirtyFields.acceptedPurchaseMethods && data.acceptedPurchaseMethods,
        },
        prices: dirtyFields.prices?.map((_x, i) => data.prices[i]) ?? [],
      };
      finalData.prices = finalData.prices.filter(x => x);
      finalData.prices = finalData.prices.map(x => ({
        ...x,
        amount: x.amount || null,
        taxRateId: x.taxRateId || null,
        // Allow input to store null as values for when reverting back price set using quotes
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      })) as any;
      onFormSubmit(finalData).then(() => {
        refetch();
      });
    } else {
      handleCloseDrawer();
    }
  };

  return (
    <Stack spacing={4}>
      <Box
        component="form"
        id="pricingForm"
        onSubmit={handleSubmit(handleFormSubmit)}
        // NOTE: Just a quick fix b/c currently "form > gap: 24px" was there in CSS.
        // It causes weird spacings in the form
        sx={{ gap: 0 }}
      >
        <PricingForm
          experience={experience}
          isInsideDrawer={true}
          control={control}
          formState={formState}
          setValue={setValue}
          platformFeeRaw={platformFeeRaw}
          membershipPlans={membershipPlans}
          watch={watch}
          resetField={resetField}
          fields={fields}
          isLoading={isLoading}
        />
      </Box>
    </Stack>
  );
};
