import {
  Container,
  IconBlock,
  RequirementsIcon,
  Typography,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import { StatementTable } from './components';
import { useMerchantPaymentStatements } from '@/hooks/useMerchantPaymentStatements';
import StatementTableRowSkeleton from './components/StatementTable/StatementTableRowSkeleton';
import { useNavigate } from 'react-router';
import { routes } from '@/routing/routes';

export const MerchantPaymentStatementsModule = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { data: merchantPaymentStatements, isLoading } =
    useMerchantPaymentStatements();

  // Empty state
  if (!isLoading && !merchantPaymentStatements?.statements.length) {
    return (
      <Container
        sx={{
          paddingY: theme.spacers.xxl4,
          paddingX: {
            xs: '15%',
            md: '30%',
          },
        }}
      >
        <IconBlock
          icon={
            <RequirementsIcon
              width={80}
              color={theme.palette.secondary.light}
            />
          }
          title="You don't have reports yet. Reports for live experiences are generated on the last day of the month."
          titleMarginBottom={40}
          primaryButtonTitle="Back to dashboard"
          onClickPrimaryButton={() => navigate(routes.dashboard)}
        />
      </Container>
    );
  }

  return (
    <Container sx={{ paddingY: theme.spacers.xxl4 }}>
      <Typography variant="h4" marginBottom={theme.spacers.m}>
        Statements
      </Typography>

      {!isLoading ? (
        <StatementTable
          statements={merchantPaymentStatements?.statements || []}
        />
      ) : (
        <>
          <StatementTableRowSkeleton />
          <StatementTableRowSkeleton />
          <StatementTableRowSkeleton />
        </>
      )}
    </Container>
  );
};
