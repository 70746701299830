import { ApiClient } from '@/api/apiClient';
import { useAuth } from './useAuth';
import { useQuery } from '@tanstack/react-query';
import {
  MembersDataTable,
  MembershipPlanPurchaseQuery,
  MembershipPlanPurchaseQueryResponse,
} from '@/types';
import { transformPlanPurchases } from '@/utils';

export const useSearchMembershipPlanPurchases = ({
  membershipId,
  queryParams,
}: {
  membershipId: string;
  queryParams: MembershipPlanPurchaseQuery;
}) => {
  const { data: user } = useAuth();
  const apiClient = ApiClient();

  const { data: membershipPlanPurchases, isLoading } =
    useQuery<MembershipPlanPurchaseQueryResponse>({
      queryKey: ['membershipPlanPurchases', membershipId, queryParams],
      queryFn: () =>
        apiClient.membershipPlanPurchases.getMembershipPlanPurchasesBySearchParams(
          {
            membershipId: membershipId,
            queryParams: queryParams,
          }
        ),
      enabled: !!user,
    });

  let transformedPlanPurchases: MembersDataTable[] = [];
  if (membershipPlanPurchases) {
    transformedPlanPurchases = transformPlanPurchases(membershipPlanPurchases);
  }

  return {
    data: transformedPlanPurchases,
    metadata: membershipPlanPurchases?.metadata,
    isLoading,
  };
};
