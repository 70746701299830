type MainMessageIframeProps = {
  htmlContent: string;
};

export const MainMessageIframe = ({ htmlContent }: MainMessageIframeProps) => {
  const writeHTML = (frame: HTMLIFrameElement) => {
    if (!frame) {
      return;
    }
    const doc = frame.contentDocument;
    doc?.open();
    doc?.write(htmlContent);
    doc?.close();
    frame.style.width = '100%';
    frame.style.height = `${frame.contentWindow?.document.body.parentElement?.scrollHeight}px`;
  };

  return (
    <iframe
      src="about:blank"
      scrolling="no"
      frameBorder="0"
      ref={writeHTML}
      // important to disable pointer events so that we don't allow users
      // to interact with the iframe. I.E. clicking on buttons and links
      style={{ pointerEvents: 'none' }}
    />
  );
};
