import { AxiosInstance } from 'axios';
import { Todo } from 'types/Todo';

export const endpoints = {
  userTodos({ userId }: { userId: string }) {
    return `todo/${userId}`;
  },
};

export const todos = ({ axiosInstance }: { axiosInstance: AxiosInstance }) => {
  return {
    getUserTodos: async ({ userId }: { userId: string }): Promise<Todo[]> => {
      const { data } = await axiosInstance.get(endpoints.userTodos({ userId }));
      return data;
    },
  };
};
