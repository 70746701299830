// Purpose: Input class for the CreatePassword mutation.
import { IsBoolean, IsNotEmpty, MinLength } from 'class-validator';

export class CreatePasswordInput {
  @IsNotEmpty({
    message: 'An error has occurred. Please contact support',
  })
  email: string;

  @IsNotEmpty({ message: 'Please enter a password' })
  @MinLength(8, { message: 'Passwords must be at least 8 characters' })
  password: string;

  @IsNotEmpty({
    message: 'An error has occurred. Please contact support',
  })
  passwordResetToken: string;

  @IsNotEmpty({ message: 'Please accept the Terms and Conditions' })
  @IsBoolean({ message: 'Please accept the Terms and Conditions' })
  termsAccepted: boolean;

  @IsNotEmpty({
    message: 'An error has occurred. Please contact support',
  })
  merchantId: string;
}
