import { AnalyticsAdapter, User } from '@/types';
const moengage = require('@moengage/web-sdk').default;

export class MoengageAnalyticsAdapter implements AnalyticsAdapter {
  initializeSession(user: User) {
    moengage.initialize({
      app_id: process.env.REACT_APP_MOENGAGE_APP_ID,
      debug_logs: 0,
    });

    moengage.add_unique_user_id(user.uid);
    moengage.add_first_name(user.firstName);
    moengage.add_last_name(user.lastName);
    moengage.add_email(user.email);
    moengage.add_mobile(user.phone);
    moengage.add_user_name(user.email);
  }

  closeSession() {
    moengage.destroy_session();
  }
}
