import { routes } from '@/routing/routes';
import RandomLine from './RandomLine';
import {
  Box,
  Button,
  Container,
  Stack,
  Typography,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import { useNavigate } from 'react-router';

export const PageNotFound = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Container
      sx={{
        height: '80vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Stack direction="column" alignItems="center" spacing={theme.spacers.l}>
        <Typography variant="h1">404 Error</Typography>
        <Typography variant="h4">Page Not Found</Typography>
        <RandomLine />

        <Box sx={{ paddingTop: theme.spacers.xl }}>
          <Button onClick={() => navigate(routes.home)}>Back to Home</Button>
        </Box>
      </Stack>
    </Container>
  );
};
