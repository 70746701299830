import { Address } from './Location';
import { UserRole } from './User';

export enum MerchantPrivilege {
  // Basic privileges, needs to wait for a property manager to approve for
  // experiences to be visible to the consumers
  STANDARD = 'standard',
  // Trusted merchant, all created experiences get published without approval
  TRUSTED = 'trusted',
  // TBD: not currently implemented!
  // Has same privileges as trusted but can also approve/disapprove experiences
  PROPERTY_MANAGER = 'property_manager',
}

export enum MerchantType {
  // Default merchant type
  STANDARD = 'standard',
  // Gym, tennis court, bbq, etc.
  AMENITIES = 'amenities',
  // Goes to your house to clean it (faireez, etc)
  HOME_SERVICES = 'home_services',
}

export type MerchantProfile = {
  id: number;
  name: string;
  mainAddressId: number;
  email: string;
  websiteUrl: string;
  linkedinUrl: string;
  facebookUrl: string;
  otherUrl1: string;
  otherUrl2: string;
  yearsOperating: string;
  description: string;
  bankAccountId?: string; // stripe bank account id
  photo: string;
  mainAddress: Address;
  privilege: MerchantPrivilege;
  type: MerchantType;

  taxRateId?: string;
  affordableHousingDiscountId?: string;
  defaultServiceAgreementNb?: string;

  createdUserId?: string;
  createdAt?: Date;
  updatedUserId?: string;
  updatedAt?: Date;
  deletedUserId?: string;
  deletedAt?: Date;
};

export type CreateNewTeamMemberDto = {
  firstName: string;
  lastName: string;
  email: string;
  primaryMerchantId: number;
  senderUserId: number;
  primaryMerchantUserRole: UserRole;
};

export type SendTeamMemberInviteDto = {
  userId: number;
  primaryMerchantId: number;
  senderUserId: number;
};

export type RemoveTeamMemberDto = {
  merchantId: number;
  memberUserId: number;
  senderUserId: number;
};
