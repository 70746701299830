import { Box, Grid, styled } from '@silverstein-properties/inspirelabs-ui';

export const CenteredBox = styled(Box)({
  textAlign: 'center',
});

export const StyledGrid = styled(Grid)(({ theme }) => ({
  textAlign: 'center',
  paddingTop: theme.spacing(5),
  justifyContent: 'space-evenly',
  '& > .MuiGrid-item': {
    maxWidth: 240, // Requirement of the design to have min width of 240px
  },
}));
