import { TEXT_AREA_LIMIT_LONG, TEXT_AREA_LIMIT_SHORT } from '@/constants';
import { Transform, Type } from 'class-transformer';
import { Allow, IsNotEmpty, IsPhoneNumber, MaxLength } from 'class-validator';
import { FileWithoutStream } from './FileWithoutStream';

export class AboutHostSectionEditInput {
  @Allow()
  @Type(() => FileWithoutStream)
  @Transform(({ obj, key }) => obj[key])
  hostPhoto: string | FileWithoutStream;

  @IsNotEmpty({ message: 'Please enter a phone number.' })
  @IsPhoneNumber('US', { message: 'Please enter a valid phone number.' })
  phoneNumber: string;

  @IsNotEmpty({ message: 'Please enter a bio' })
  @MaxLength(TEXT_AREA_LIMIT_LONG, { message: 'Please enter a shorter bio' })
  bio: string;

  @IsNotEmpty({ message: 'Please enter a value' })
  operatingYears: string;

  @IsNotEmpty({ message: 'Please enter a value' })
  @MaxLength(TEXT_AREA_LIMIT_SHORT, {
    message: 'Please enter a shorter fist name',
  })
  firstName: string;

  @IsNotEmpty({ message: 'Please enter a value' })
  @MaxLength(TEXT_AREA_LIMIT_SHORT, {
    message: 'Please enter a shorter last name',
  })
  lastName: string;
}
