import {
  CreateRecurringEvent,
  ResponseRecurringEventDto,
  UpdateRecurringEvent,
} from '@/types/RecurringEvent';
import { AxiosInstance } from 'axios';

export const endpoints = {
  recurringEvents() {
    return '/recurring-events';
  },
  recurringEvent(id: string) {
    return `/recurring-events/${id}`;
  },
  cancelRecurringEvent({
    recurringEventId,
    listingId,
  }: {
    recurringEventId: string;
    listingId: string;
  }) {
    return `/recurring-events/${recurringEventId}/listings/${listingId}`;
  },
};

export const recurringEvents = ({
  axiosInstance,
}: {
  axiosInstance: AxiosInstance;
}) => {
  return {
    createRecurringEvents: async (
      recurringEventDto: CreateRecurringEvent
    ): Promise<ResponseRecurringEventDto> => {
      const { data } = await axiosInstance.post(
        endpoints.recurringEvents(),
        recurringEventDto
      );
      return data;
    },
    getRecurringEvents: async ({
      recurringEventId,
      includeListings,
    }: {
      recurringEventId: string;
      includeListings?: boolean;
    }): Promise<ResponseRecurringEventDto> => {
      const { data } = await axiosInstance.get(
        endpoints.recurringEvent(recurringEventId),
        { params: { includeListings } }
      );
      return data;
    },
    editRecurringEvents: async ({
      recurringEventId,
      recurringEventDto,
    }: {
      recurringEventId: string;
      recurringEventDto: UpdateRecurringEvent;
    }): Promise<ResponseRecurringEventDto> => {
      const { data } = await axiosInstance.put(
        endpoints.recurringEvent(recurringEventId),
        recurringEventDto
      );
      return data;
    },
    cancelRecurringEvents: async ({
      recurringEventId,
      listingId,
    }: {
      recurringEventId: string;
      listingId: string;
    }): Promise<ResponseRecurringEventDto> => {
      const { data } = await axiosInstance.delete(
        endpoints.cancelRecurringEvent({ recurringEventId, listingId })
      );
      return data;
    },
  };
};
