import Vector from './images/vector.svg';
import Lock from './images/lock.svg';
import GreenCheck from './images/checkmark.svg';
import { SubItem } from './SubItem';
import {
  MembershipCategory,
  MenuProgressStatus,
} from '@/hooks/useMembershipCreationHooks';

interface MainItemProps {
  id?: number;
  category: MembershipCategory;
  changePage: (
    sectionId: number,
    subItem: number,
    finished: MenuProgressStatus
  ) => void;
  pageIsOpen: boolean;
  currentSection: number;
  currentSubItem: number;
}

export const MainItem = ({
  id,
  category,
  changePage,
  pageIsOpen,
  currentSection,
  currentSubItem,
}: MainItemProps): JSX.Element => {
  const finished =
    category.sectionId > currentSection
      ? MenuProgressStatus.Locked
      : category.sectionId < currentSection
      ? MenuProgressStatus.Finished
      : MenuProgressStatus.InProgress;

  // should add a check to verify if finished based off data instead of position

  const image =
    finished === MenuProgressStatus.Locked
      ? Lock
      : finished === MenuProgressStatus.Finished
      ? GreenCheck
      : undefined;

  return (
    <>
      <div className={!pageIsOpen ? 'main-item' : 'open-main-item'}>
        <div
          className="main-item-inner-frame"
          onClick={() => changePage(category.sectionId, 0, finished)}
        >
          <img
            src={Vector}
            id={`${id} ${finished}`}
            height="6px"
            width="12px"
            className={
              pageIsOpen ? 'main-category-open' : 'main-category-closed'
            }
            alt="vector"
          />
          <span className="main-item-label">{category.title}</span>
        </div>
        {image && (
          <img
            src={image}
            height="24px"
            width="24px"
            alt="icon"
            className="main-item-image"
          />
        )}
      </div>
      {pageIsOpen &&
        category.subItems &&
        category.subItems.map(categorySubItem => (
          <SubItem
            subItemIndex={categorySubItem.subItemId}
            category={category}
            categorySubItem={categorySubItem}
            changePage={changePage}
            currentSection={currentSection}
            currentSubItem={currentSubItem}
            key={categorySubItem.name}
          />
        ))}
    </>
  );
};
