import { useState } from 'react';
import { MonthlyStatementExperience } from '@/types';
import {
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  getExpandedRowModel,
  useReactTable,
  ExpandedState,
} from '@tanstack/react-table';
import { DataTable } from '@/components';
import { TableData, getTableColumns } from './ExperiencesTable.columns';

interface ExperiencesTableProps {
  experiences: MonthlyStatementExperience[];
  onDetailsClick: (id: string) => void;
}

export const ExperiencesTable = (props: ExperiencesTableProps) => {
  const { experiences, onDetailsClick } = props;

  // Transform the data into the expected format from the table.
  const transformedExperiences: TableData[] = experiences.map(experience => {
    return {
      id: experience.productId,
      name: experience.productName,
      guests: experience.guests || 0,
      netEarnings: experience.netEarnings,
      platformFee: experience.inspireFee,
      refunds: experience.refunds,
      events: experience.events.map(event => ({
        id: event.listingId,
        name: event.startDate.toString(),
        guests: event.guests,
        netEarnings: event.netEarnings,
        platformFee: event.inspireFee,
        refunds: event.refunds,
      })),
    };
  });

  const [data] = useState(() => [...transformedExperiences]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const rightAlignedColumns = [
    'guests',
    'netEarnings',
    'platformFee',
    'refunds',
  ];

  const table = useReactTable({
    data,
    columns: getTableColumns(onDetailsClick),
    state: {
      sorting,
      expanded,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onExpandedChange: setExpanded,
    getSubRows: row => row.events,
    getExpandedRowModel: getExpandedRowModel(),
  });

  return <DataTable table={table} rightAlignedColumns={rightAlignedColumns} />;
};
