import FilterButton from 'components/RoundButton/Filter';
import { BusinessSignupComponentProps } from './Component';
import { useEffect } from 'react';
import { useFormData } from '../../contexts/MerchCreationProvider';
import { FieldValues, useForm } from 'react-hook-form';
import { CANCELLATION_OPTIONS } from '@/constants';
import {
  Box,
  Button,
  MessageBanner,
  Svg,
} from '@silverstein-properties/inspirelabs-ui';

interface SubmitProps {
  submit: () => void;
  warning?: string;
}

type CancellationProps = BusinessSignupComponentProps & SubmitProps;

export function Cancellation(props: CancellationProps) {
  const { cancelPolicy } = props.values;
  const { setFormValues } = useFormData();
  const { register, handleSubmit, setValue } = useForm<FieldValues>({
    mode: 'onTouched',
    defaultValues: {
      cancelPolicy,
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderButtons = () => {
    return CANCELLATION_OPTIONS.map(option => (
      <FilterButton
        key={option.label}
        text={option.label}
        selected={cancelPolicy === option.value}
        value={option.value?.toString()}
        default="filter-button"
        hover="filter-button-hover"
        handleClick={props.handleFilterChange}
        name="cancelPolicy"
        register={register}
        setValue={setValue}
      />
    ));
  };

  // Disabled due to current implementation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (data: any) => {
    setFormValues(data);
    props.updateExperience(data);
    props.submit();
  };

  const assistiveText = (
    <div className="cancellation-assistive-text">
      Cancellation before the selected timeframe is fully refundable.
      Cancellation after the selected timeframe is non-refundable.
    </div>
  );

  return (
    <div className="row ms-xl-5 ms-lg-5 me-md-5 ms-sm-1 ms-xs-1 top-64 d-flex justify-content-end">
      <div className="col-8">
        <div className="business-form-headline mb-5">Cancellation</div>

        <div className="theme-form">
          {props.warning && (
            <MessageBanner type="error">{props.warning}</MessageBanner>
          )}
          <div className="business-form-subtitle mb-3">
            Participants may cancel prior to service:
          </div>
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <span style={{ display: 'flex', gap: '5px' }}>
              {renderButtons()}
            </span>
            {assistiveText}
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Button
                variant="text"
                onClick={props.prevPage}
                startIcon={
                  <Svg src="/images/chevron-left.svg" height={24} width={24} />
                }
              >
                Back
              </Button>
              <Button type="submit">Continue</Button>
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
}
