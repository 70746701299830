import { MAIN_CATEGORY_LIMIT, SUB_CATEGORY_LIMIT } from '@/constants';
import { Type } from 'class-transformer';
import {
  ArrayMaxSize,
  IsArray,
  IsString,
  ValidateNested,
} from 'class-validator';

export class CategoryDto {
  @ArrayMaxSize(SUB_CATEGORY_LIMIT, {
    message: `Please enter a maximum of ${SUB_CATEGORY_LIMIT} subcategories`,
  })
  @IsArray()
  @IsString({ each: true })
  info: string[];

  @ArrayMaxSize(MAIN_CATEGORY_LIMIT, {
    message: `Please enter a maximum of ${MAIN_CATEGORY_LIMIT} categories`,
  })
  @IsArray()
  @IsString({ each: true })
  type: string[];
}

export class BasicInfoSectionEditInput {
  @ValidateNested()
  @Type(() => CategoryDto)
  category: CategoryDto;
}
