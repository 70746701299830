export const MobileHamburger = () => {
  return (
    <div className="d-xl-none d-lg-none d-md-none d-sm-none d-block d-inline">
      <div id="menuToggle">
        <input type="checkbox" />

        <span></span>
        <span></span>
        <span></span>

        <ul id="menu">
          <a href="#">
            <li>Home</li>
          </a>
          <a href="#">
            <li>How it Works</li>
          </a>
          <a href="#">
            <li>Contact us</li>
          </a>
        </ul>
      </div>
    </div>
  );
};
