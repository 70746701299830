import { Experience } from '@/types';
import { billingCycleMap, contractLengthMap } from '@/utils/membershipUtils';
import React, { useState, createContext, useContext } from 'react';

//TODO - add defined FE type for membership creation object & for memberships retrieved from BE

type SubscriptionCreationPlan = {
  membershipPlanId?: string;
  membershipPlanTitle: string;
  membershipPlanPrice: string;
  memberQuantity: string; // Max number of family members eligible for the plan
  isAutoRenew: string;
  billingCycle: keyof typeof billingCycleMap;
  contractLength: keyof typeof contractLengthMap;
  membershipProducts: Experience[] | undefined;
  taxRateId?: number;
};

type SubscriptionCreation = {
  membershipTitle: string;
  membershipDescription: string;
  membershipPlans: SubscriptionCreationPlan[];
};

export const defaultSubscriptionCreationObject: SubscriptionCreation = {
  membershipTitle: '',
  membershipDescription: '',
  membershipPlans: [
    {
      membershipPlanId: '',
      membershipPlanTitle: '',
      membershipPlanPrice: '',
      memberQuantity: '',
      isAutoRenew: '',
      billingCycle: 'Every 1 month', // Specified in Acceptance Criteria
      contractLength: '1 year', // Specified in Acceptance Criteria
      membershipProducts: [],
    },
  ],
};

export const FormContext = createContext({
  subscriptionData: defaultSubscriptionCreationObject,
  // Disabling rule due to current implementation
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  setFormValues: (_data: any) => {},
});

export default function SubscriptionFormProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [subscriptionData, setSubscriptionData] = useState(
    defaultSubscriptionCreationObject
  );

  // Disabling rule due to current implementation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setFormValues = (newValues: any) => {
    // Disabling rule due to current implementation
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setSubscriptionData((previousValues: any) => ({
      ...previousValues,
      ...newValues,
    }));
  };

  return (
    <FormContext.Provider value={{ subscriptionData, setFormValues }}>
      {children}
    </FormContext.Provider>
  );
}

export const useFormData = () => useContext(FormContext);
