import {
  Grid,
  Typography,
  Button,
  useTheme,
  Skeleton,
} from '@silverstein-properties/inspirelabs-ui';
import { Link } from 'react-router-dom';
import { NumericCircle } from '@/components/NumericCircle';
import { Container, ListItem, ListItemText } from '@mui/material';
import {
  StatementTableGrid,
  StyledTopHeaderGrid,
  StyledGridTitleCard,
} from './MerchantMembershipsModule.styles';
import AlertIcon from '@/components/AlertDialog/AlertIcon.svg';
import {
  MerchantMembershipEmptyState,
  MembershipHeaderRow,
  MembershipRow,
  MembershipPlanRow,
} from './components';
import { useMerchantMemberships } from '@/hooks/useMerchantMemberships';
import AlertDialog from '@/components/AlertDialog/AlertDialog';

export const MerchantMembershipsModule = () => {
  const {
    memberships,
    isLoading,
    onClickMenuItem,
    showTerminateAlertDialog,
    handleTerminatedAlertDialogClose,
    showDeleteAlertDialog,
    handleDeleteAlertDialogClose,
  } = useMerchantMemberships();
  const nbOfSkeletonGrid = 5;
  const theme = useTheme();

  if (!isLoading && !memberships?.length) {
    return <MerchantMembershipEmptyState />;
  }

  return (
    <Container sx={{ paddingY: theme.spacers.xxl4 }}>
      {showTerminateAlertDialog && (
        <AlertDialog
          open={showTerminateAlertDialog}
          onClose={handleTerminatedAlertDialogClose}
          image={AlertIcon}
          title="Are you sure you want to terminate this membership?"
          message={
            <>
              <Typography sx={{ paddingBottom: theme.spacing(5) }}>
                Selecting the button "Confirm" below will stop plans renewal on
                the next billing date for all members.
              </Typography>
              <Typography>
                Current members will retain access to their plan entitlements
                until their billing cycle ends. All plans will be immediately
                unpublished, preventing new sign-ups.
              </Typography>
            </>
          }
          agree="Confirm"
          disagree="Cancel"
        />
      )}
      {showDeleteAlertDialog && (
        <AlertDialog
          open={showDeleteAlertDialog}
          onClose={handleDeleteAlertDialogClose}
          image={AlertIcon}
          title="Are you sure you want to delete this membership?"
          message="Selecting the button 'Confirm' will permanently delete this membership and all plans within."
          agree="Confirm"
          disagree="Cancel"
        />
      )}
      <StyledTopHeaderGrid container>
        <StyledGridTitleCard gap={1}>
          <Typography variant="h4">Memberships</Typography>
          <NumericCircle size="sm" value={memberships?.length} />
        </StyledGridTitleCard>
        <Link to="/memberships/create">
          <Button>Create membership</Button>
        </Link>
      </StyledTopHeaderGrid>

      <Grid container item spacing={2} sx={{ pt: 2, pb: 2 }}>
        <Grid container item xs={12}>
          <MembershipHeaderRow />
          {isLoading
            ? [...Array(nbOfSkeletonGrid)].map((_item, i) => (
                <ListItem key={`skeleton_${i}`}>
                  <ListItemText>
                    <Skeleton />
                  </ListItemText>
                </ListItem>
              ))
            : (memberships || []).map(membership => (
                <StatementTableGrid container item key={membership.id}>
                  <MembershipRow
                    membership={membership}
                    onClickMenuItem={onClickMenuItem}
                  />
                  {membership.membershipPlans.map(plan => (
                    <MembershipPlanRow {...plan} key={plan.id} />
                  ))}
                </StatementTableGrid>
              ))}
        </Grid>
      </Grid>
    </Container>
  );
};
