import { BusinessSignupComponentProps } from './Component';
import Selector from 'components/Selector/Component';
import { HookFormTextArea } from 'components/FormTextInput/HookFormTextArea';
import { TEXT_AREA_LIMIT_LONG } from '@/constants';
import { useEffect } from 'react';
import {
  Box,
  Button,
  InputField,
  Svg,
} from '@silverstein-properties/inspirelabs-ui';

import {
  styles,
  errorStyles,
  customValueContainer,
} from 'components/FormTextInput/HookFormSelect';
import Select from 'react-select';

import { useForm, Controller } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { IsNotEmpty, IsOptional } from 'class-validator';
import { useFormData } from '../../contexts/MerchCreationProvider';
import { ACTIVITY_CHOICES, AUDIENCE_CHOICES } from '@/constants';

class ExperienceRequirementsInput {
  @IsNotEmpty({ message: 'Please enter a value' })
  targetAudience: string | object;

  @IsNotEmpty({ message: 'Please enter a value' })
  activityLevel: string | object;

  // @IsNotEmpty(undefined, {message:"Please enter a requirement"})
  @IsOptional()
  requiredByHost?: string | undefined | null;

  // @IsNotEmpty(undefined, {message:"Please enter a requirement"})
  @IsOptional()
  requiredByGuest?: string | undefined | null;
}

const resolver = classValidatorResolver(ExperienceRequirementsInput);

export function ExperienceRequirements(props: BusinessSignupComponentProps) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Disabled due to current implementation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (data: any) => {
    setFormValues(data);
    props.updateExperience(data);
    props.nextPage();
  };

  const { setFormValues, merchandiseData } = useFormData();
  const {
    register,
    control,
    formState: { dirtyFields, errors },
    handleSubmit,
    watch,
  } = useForm<ExperienceRequirementsInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      targetAudience: merchandiseData.targetAudience || '',
      activityLevel: merchandiseData.activityLevel || '',
      requiredByHost: merchandiseData.requiredByHost || '',
      requiredByGuest: merchandiseData.requiredByGuest || '',
    },
  });

  const renderDescriptionHelperText = (): string => {
    if (errors.requiredByHost?.message) {
      return errors.requiredByHost?.message;
    }
    const descriptionLength = watch('requiredByHost')?.length ?? 0;
    return `${descriptionLength}/${TEXT_AREA_LIMIT_LONG}`;
  };

  return (
    <div className="row ms-xl-5 ms-lg-5 me-md-5 ms-sm-1 ms-xs-1 top-64 d-flex justify-content-end">
      <div className="col-8">
        <h4 className="business-form-headline mb-5">Experience requirements</h4>
        <div className="theme-form">
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="business-form-subtitle">Target audience</div>
            <Controller
              name="targetAudience"
              control={control}
              rules={{
                required: { value: true, message: 'Please enter a value' },
              }}
              render={({ field }) => (
                <div data-testid="requirements.targetAudience">
                  <Select
                    {...field}
                    inputId="target-audience-dropdown"
                    data-testid="targetAudienceTest"
                    isSearchable={false}
                    options={AUDIENCE_CHOICES}
                    styles={errors.targetAudience ? errorStyles : styles}
                    components={{ ValueContainer: customValueContainer }}
                    placeholder="Select"
                  />
                </div>
              )}
            />
            <div className="business-form-subtitle">Activity level</div>
            <Controller
              name="activityLevel"
              control={control}
              rules={{
                required: { value: true, message: 'Please enter a value' },
              }}
              render={({ field }) => (
                <div data-testid="requirements.activityLevel">
                  <Select
                    {...field}
                    inputId="activity-level-dropdown"
                    data-testid="requirements.activityLevel"
                    isSearchable={false}
                    options={ACTIVITY_CHOICES}
                    styles={errors.activityLevel ? errorStyles : styles}
                    components={{ ValueContainer: customValueContainer }}
                    placeholder="Select"
                  />
                </div>
              )}
            />
            <div className="business-form-subtitle">
              Will you provide the guests with anything?
            </div>
            <span style={{ gap: '0px' }}>
              <div data-testid="requirements.hostProvide">
                <Selector
                  type="radio"
                  data-testid="requirements.hostProvide"
                  selected={!!props.values.hostProvide}
                  value="true"
                  text="Yes"
                  name="hostProvide"
                  onClick={props.handleClickChange}
                />
              </div>
              <Selector
                type="radio"
                selected={!props.values.hostProvide}
                value="false"
                text="No, I'm not providing anything"
                name="hostProvide"
                onClick={props.handleClickChange}
              />
            </span>
            {props.values.hostProvide && (
              <Controller
                name="requiredByHost"
                control={control}
                render={({ field }) => (
                  <InputField
                    {...field}
                    value={field.value || ''}
                    inputProps={{
                      'data-testid': 'requirements.descriptionHostOffer',
                    }}
                    multiline
                    minRows={5}
                    label="Add description"
                    error={!!errors.requiredByHost}
                    helperText={renderDescriptionHelperText()}
                  />
                )}
              />
            )}
            <div className="business-form-subtitle">
              Will guests need to bring anything to the experience?
            </div>
            <span style={{ gap: '0px' }}>
              <Selector
                type="radio"
                selected={!!props.values.guestProvide}
                value="true"
                text="Yes"
                name="guestProvide"
                onClick={props.handleClickChange}
              />
              <div data-testid="requirements.guestProvide">
                <Selector
                  type="radio"
                  data-testid="requirements.guestProvide"
                  selected={!props.values.guestProvide}
                  value="false"
                  text="No, guests just need to show up"
                  name="guestProvide"
                  onClick={props.handleClickChange}
                />
              </div>
            </span>
            {props.values.guestProvide && (
              <HookFormTextArea
                name="requiredByGuest"
                isFilled={
                  Object.keys(dirtyFields).includes('requiredByGuest') ||
                  !!merchandiseData.requiredByGuest
                }
                limit={TEXT_AREA_LIMIT_LONG}
                size="form-xl"
                label="What should guests bring?"
                register={register}
                error={errors.requiredByGuest}
              />
            )}
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Button
                variant="text"
                onClick={props.prevPage}
                startIcon={
                  <Svg src="/images/chevron-left.svg" height={24} width={24} />
                }
              >
                Back
              </Button>
              <Button type="submit">Continue</Button>
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
}
