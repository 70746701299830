import { LabelField } from '@/components';
import { formatDate, formatPricing, formatTime } from '@/utils';
import { Typography, Stack } from '@silverstein-properties/inspirelabs-ui';
import { HostList, GuestList } from '@/modules/SchedulingModule/components';
import { useListing, useExperience, useListingBookingSummary } from '@/hooks';

export type ListingDetailsDrawerProps = {
  listingId?: string;
};

export const ListingDetailsDrawer = ({
  listingId,
}: ListingDetailsDrawerProps) => {
  const { data: listing, isLoading: isListingLoading } = useListing({
    listingId,
  });

  const { data: experience, isLoading: isExperienceLoading } = useExperience({
    experienceId: listing?.productId || '',
  });

  const { data: bookingSummary, isLoading: isBookingsSummaryLoading } =
    useListingBookingSummary({ listingId: listing?.id });

  if (
    isListingLoading ||
    !listing ||
    isExperienceLoading ||
    !experience ||
    !bookingSummary ||
    isBookingsSummaryLoading
  ) {
    return null;
  }

  const { realizedBookings, cancellations } = bookingSummary;

  return (
    <Stack spacing={2}>
      <Typography variant="h5">{experience.title}</Typography>
      <LabelField label="Date" value={formatDate(listing.startsAt)} />
      <LabelField label="Time" value={formatTime(listing.startsAt)} />
      <Stack direction="row">
        <LabelField
          label="Host"
          value={<HostList hosts={listing.host ? [listing.host] : []} />}
        />
      </Stack>
      <LabelField
        label="Price range"
        value={`${formatPricing(
          listing.basePrice?.totalAmount || listing.totalAmount
        )}/person`}
      />
      <LabelField
        label="Group Size"
        value={`${realizedBookings.length} ${
          realizedBookings.length === 1 ? 'person' : 'people'
        }`}
      />
      <GuestList
        bookings={realizedBookings}
        isLoading={isBookingsSummaryLoading}
        title="Signups"
        handleClick={() => {}}
        enableMessaging={false}
      />
      <GuestList
        bookings={cancellations}
        isLoading={isBookingsSummaryLoading}
        title="Cancellations"
        handleClick={() => {}}
        enableMessaging={false}
      />
    </Stack>
  );
};
