import { MembershipProduct } from '@/types/MerchantMembership';
import { AxiosInstance } from 'axios';

export const endpoints = {
  membershipProducts: '/membership-products',
  getMerchantMemberships({ membershipPlanId }: { membershipPlanId: string }) {
    return `/membership-products/${membershipPlanId}`;
  },
};

export const membershipProducts = ({
  axiosInstance,
}: {
  axiosInstance: AxiosInstance;
}) => {
  return {
    createMembershipProduct: async ({
      membershipPlanId,
      productId,
      maxNumberOfUse,
    }: {
      membershipPlanId: string;
      productId: string;
      maxNumberOfUse: number;
    }): Promise<MembershipProduct> => {
      const { data } = await axiosInstance.post(endpoints.membershipProducts, {
        membershipPlanId: membershipPlanId,
        productId: productId,
        maxNumberOfUse: maxNumberOfUse,
      });
      return data;
    },
    getMembershipProductsForPlan: async ({
      membershipPlanId,
    }: {
      membershipPlanId: string;
    }): Promise<MembershipProduct[]> => {
      const { data } = await axiosInstance.get(
        endpoints.getMerchantMemberships({ membershipPlanId })
      );
      return data;
    },
  };
};
