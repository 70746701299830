import { ApiClient } from '../api/apiClient';
import { useState } from 'react';
import { useAuth } from './useAuth';
import { MEMBERSHIP_DETAIL_MENUS } from '@/constants';
import { useMembership } from './useMembership';
import { useParams } from 'react-router';
import { deepObjDiff, deepOmitNil, getPhotosObject } from '@/utils';
import { Experience, MenuItem } from '@/types';
import { billingCycleMap, contractLengthMap } from '@/utils/membershipUtils';
import {
  PlanEditInput,
  TitleAndDescriptionSectionEditInput,
} from '@/classes/MembershipEditInput';
import { useMutation } from '@tanstack/react-query';
import { MembershipPhotoSectionEditInput } from '@/classes';
import { set } from 'lodash';

export enum ScreensViews {
  TITLE_DESCRIPTION = 'titleAndDescription',
  EDIT_TITLE_DESCRIPTION = 'titleAndDescription',
  PLANS = 'plans',
  EDIT_PLANS = 'plansEdit',
  ADD_PLANS = 'plansAdd',
  MEMBERS = 'members',
  PHOTOS = 'photos',
  EDIT_PHOTOS = 'photos',
}

export enum ActionsType {
  EDIT_PLAN = 'editPlan',
  ADD_PLAN = 'addPlan',
  PUBLISH_PLAN = 'publishPlan',
  UNPUBLISH_PLAN = 'unpublishPlan',
  TERMINATED_PLAN = 'terminatePlan',
  DELETE_PLAN = 'deletePlan',
}
export function useMembershipEdit() {
  const { data: user } = useAuth();
  const merchantId = parseInt(user?.primaryMerchantId || '');
  // if/when user can be multiple merchants this will need changing
  const apiClient = ApiClient();

  const { membershipId = '' } = useParams();
  const { data: membership, refetch } = useMembership({
    membershipId,
    withProducts: true,
  });

  const [drawerScreen, setDrawerScreen] = useState('');
  const [height, setHeight] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [planId, setPlanId] = useState('');
  const [menuSelected, setMenuSelected] = useState({
    ...MEMBERSHIP_DETAIL_MENUS[0].children[0],
  });
  const [showTerminateAlertDialog, setTerminateAlertDialog] = useState(false);
  const [
    showTerminateOneMemberAlertDialog,
    setShowTerminateOneMemberAlertDialog,
  ] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [showDeletePlanAlertDialog, setDeletePlanAlertDialog] = useState(false);
  const [banner, setBanner] = useState<{
    message: string;
    type: 'success' | 'error';
  }>({ message: '', type: 'success' });

  const onWindowClose = () => {
    setDrawerOpen(false);
  };

  const onEditClick = (screenName: string) => {
    setDrawerScreen(screenName);
    setDrawerOpen(true);
  };

  const onMenuClick = (menuItem: MenuItem) => {
    setBanner({ message: '', type: 'success' });
    setMenuSelected(menuItem);
  };

  /**
   * action on form submit of Title & Description
   * @param data input data from form
   */
  const onFormSubmitTitleAndDescription = async (
    data: TitleAndDescriptionSectionEditInput
  ) => {
    const { newCompleteChanges } = deepObjDiff(membership, data);

    try {
      if (Object.keys(newCompleteChanges).length) {
        await apiClient.memberships.updateMembership({
          membershipId,
          merchantId,
          name: data.name,
          description: data.description,
        });
        refetch();
        setDrawerScreen('');
        setDrawerOpen(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * action on form submit of Title & Description
   * @param data input data from form
   */
  const onFormSubmitPhotosMutation = useMutation({
    mutationFn: async (data: MembershipPhotoSectionEditInput) => {
      const { newCompleteChanges } = deepObjDiff(membership, deepOmitNil(data));
      const newPhotos = getPhotosObject(data);
      try {
        if (Object.keys(newPhotos).length) {
          for (const key in newPhotos) {
            if (!newPhotos[key].file) {
              continue;
            }
            const submittedPhoto = await apiClient.images.uploadImage({
              fileName: key,
              photo: newPhotos[key].file,
              productId: membershipId,
            });
            set(newCompleteChanges, newPhotos[key].objectKey, submittedPhoto);
          }
        }
        if (Object.keys(newCompleteChanges).length) {
          await apiClient.memberships.updateMembership({
            membershipId,
            merchantId,
            coverPhoto: newCompleteChanges.coverPhoto as string,
            photos: {
              photo1: newCompleteChanges.photos?.photo1 as string,
              photo2: newCompleteChanges.photos?.photo2 as string,
              photo3: newCompleteChanges.photos?.photo3 as string,
              photo4: newCompleteChanges.photos?.photo4 as string,
            },
          });
          refetch();
          setDrawerScreen('');
          setDrawerOpen(false);
        }
      } catch (error) {
        console.error(error);
        setBanner({
          message: 'There was an error uploading the photos, please try again',
          type: 'error',
        });
      }
    },
  });

  /**
   * action on form submit of Single Plan Edit
   * @param data input data from form
   */
  const onFormSubmitEditPlan = async (data: PlanEditInput) => {
    const { newCompleteChanges } = deepObjDiff(membership, data);

    try {
      if (Object.keys(newCompleteChanges).length) {
        const billingCycle =
          billingCycleMap[data.billingCycle as keyof typeof billingCycleMap];

        const contractCycle =
          contractLengthMap[
            data.contractLength as keyof typeof contractLengthMap
          ];

        await apiClient.membershipPlans.updatePlan({
          subscriptionId: membership?.id,
          membershipPlanId: planId,
          name: data.membershipPlanTitle,
          maxNbOfConsumers: data.memberQuantity,
          totalAmount: Number(data.membershipPlanPrice),
          taxRateId: data.taxRateId,
          billingCycleType: billingCycle && billingCycle.type,
          billingCycleLength: billingCycle && billingCycle.length,
          contractType: contractCycle && contractCycle.type,
          contractLength: contractCycle && contractCycle.length,
          autoRenew: data.autoRenew === 'auto-renew',
          membershipProducts: data.membershipProducts.map((x: Experience) => ({
            maxNumberOfUse: -1,
            productId: x.id,
          })),
        });
        refetch();
        setDrawerScreen('');
        setDrawerOpen(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const terminateMembershipPlanPurchaseMutation = useMutation({
    mutationFn: async (data: {
      membershipPlanPurchaseId: string;
      isGracefulCancellation: boolean;
    }) => {
      const { membershipPlanPurchaseId, isGracefulCancellation } = data;
      await apiClient.membershipPlanPurchases.terminateOneMembershipPlanPurchase(
        {
          membershipPlanPurchaseId: membershipPlanPurchaseId,
          isGracefulCancellation: isGracefulCancellation,
        }
      );
    },
  });

  const handleTerminatePlansAlertDialogClose = async (value: boolean) => {
    setTerminateAlertDialog(false);
    // if the user clicks Accept in the confirmation dialog
    if (value) {
      try {
        await apiClient.membershipPlanPurchases.terminateAllMembershipPlanPurchases(
          {
            planId: planId,
          }
        );
        setBanner({
          message: 'Plan has been gracefully terminated and unpublished',
          type: 'success',
        });
        refetch();
      } catch (err) {
        setBanner({
          message: 'Error occured',
          type: 'error',
        });
        console.error(err);
        //TODO: more robust success/error handling => https://spinspire.monday.com/boards/3397008425/pulses/5353047850
      }
    }
  };

  const handleDeletePlansAlertDialogClose = async (value: boolean) => {
    setDeletePlanAlertDialog(false);
    if (value) {
      try {
        await apiClient.membershipPlans.deletePlan({
          membershipPlanId: planId,
        });

        setBanner({
          message: 'Plan has been deleted',
          type: 'success',
        });
        refetch();
      } catch (err) {
        setBanner({
          message: 'Error occured',
          type: 'error',
        });
        console.error(err);
        //TODO: more robust success/error handling => https://spinspire.monday.com/boards/3397008425/pulses/5353047850
      }
    }
  };

  /**
   * action on form submit of Single Plan Edit
   * @param data input data from form
   */
  const onFormSubmitAddPlan = async (data: PlanEditInput) => {
    try {
      const billingCycle =
        billingCycleMap[data.billingCycle as keyof typeof billingCycleMap];

      const contractCycle =
        contractLengthMap[
          data.contractLength as keyof typeof contractLengthMap
        ];

      await apiClient.membershipPlans.createPlan({
        subscriptionId: membershipId,
        name: data.membershipPlanTitle,
        maxNbOfConsumers: Number(data.memberQuantity),
        totalAmount: Number(data.membershipPlanPrice), //is in cents?
        billingCycleType: billingCycle.type,
        billingCycleLength: billingCycle.length,
        contractType: contractCycle.type,
        contractLength: contractCycle.length,
        autoRenew: data.autoRenew === 'auto-renew',
        membershipProducts:
          data?.membershipProducts.map((x: Experience) => ({
            maxNumberOfUse: -1,
            productId: x.id,
          })) || [],
        taxRateId: data.taxRateId,
      });
      refetch();
      setDrawerScreen('');
      setDrawerOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * On section for Plans, this corresponds to the action taken from the Actions dropdown button
   * @param action ActionsType
   * @param planId string
   * @returns
   */
  const planOnActionsClick = async (action: ActionsType, planId: string) => {
    switch (action) {
      case ActionsType.EDIT_PLAN:
        setDrawerOpen(true);
        setDrawerScreen(ScreensViews.EDIT_PLANS);
        setPlanId(planId);
        return null;
      case ActionsType.ADD_PLAN:
        setDrawerOpen(true);
        setDrawerScreen(ScreensViews.ADD_PLANS);
        return null;
      case ActionsType.TERMINATED_PLAN:
        // TODO: BE code for plan termination within terminateAllMembershipPlanPurchases endpoint
        //       is needed beofre below code can be uncommented
        setPlanId(planId);
        setTerminateAlertDialog(true);
        return;
      case ActionsType.UNPUBLISH_PLAN:
        // will likely need a dialog pop up confirmation before taking action
        await apiClient.membershipPlans.unpublishPlan({
          membershipPlanId: planId,
        });
        refetch();
        return null;
      case ActionsType.PUBLISH_PLAN:
        await apiClient.membershipPlans.publishPlan({
          membershipPlanId: planId,
        });
        refetch();
        return null;
      case ActionsType.DELETE_PLAN:
        setPlanId(planId);
        setDeletePlanAlertDialog(true);
        return null;
      default:
        console.error(`no action given for ${action}`);
        return null;
    }
  };

  return {
    onFormSubmitTitleAndDescription,
    onFormSubmitEditPlan,
    onFormSubmitAddPlan,
    onFormSubmitPhotosMutation,
    setDrawerOpen,
    drawerScreen,
    setDrawerScreen,
    height,
    setHeight,
    onMenuClick,
    onEditClick,
    onWindowClose,
    menuSelected,
    drawerOpen,
    membership,
    planOnActionsClick,
    planId,
    showTerminateAlertDialog,
    showDeletePlanAlertDialog,
    showTerminateOneMemberAlertDialog,
    snackbarMessage,
    setSnackbarMessage,
    setShowTerminateOneMemberAlertDialog,
    banner,
    setBanner,
    refetch,
    handleTerminatePlansAlertDialogClose,
    handleDeletePlansAlertDialogClose,
    terminateMembershipPlanPurchaseMutation,
  };
}
