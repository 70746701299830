import {
  IndicatorIcon,
  ReadIndicatorIcon,
} from './MessageReadIndicator.styles';

export type MessageReadIndicatorProps = {
  isRead: boolean;
};

export const MessageReadIndicator = ({ isRead }: MessageReadIndicatorProps) => {
  return isRead ? <IndicatorIcon /> : <ReadIndicatorIcon />;
};
