import { RepeatDaysModalInput } from '@/classes';
import { WeekdayPicker } from '@/components/WeekdayPicker';
import {
  DaysOfTheWeek,
  RepeatDays,
  RepeatDaysType,
} from '@/types/RecurringEvent';
import { Modal } from '@mui/material';
import {
  Box,
  Button,
  DatePicker,
  InputField,
  Stack,
  Typography,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import { addMonths } from 'date-fns';
import { useState } from 'react';

interface SetRepeatDaysModalProps {
  modalOpen: boolean;
  setModalOpen: (vale: boolean) => void;
  handleRepeatDaysOptionChange: (value: RepeatDaysModalInput) => void;
  currentDate: Date;
  defaultRepeatDays?: RepeatDays;
}

// TODO: Fix annoying React.forwardRef warning in future PR.
export const SetRepeatDaysModal = (props: SetRepeatDaysModalProps) => {
  const {
    modalOpen,
    setModalOpen,
    handleRepeatDaysOptionChange,
    currentDate,
    defaultRepeatDays,
  } = props;
  const theme = useTheme();

  const [selectedDays, setSelectedDays] = useState<DaysOfTheWeek[]>(
    defaultRepeatDays?.daysOfWeek || []
  );
  const [repeatDaysType, setRepeatDaysType] = useState<RepeatDaysType>(
    defaultRepeatDays?.type || RepeatDaysType.WEEKLY
  );
  const [repeatDaysInterval, setRepeatDaysInterval] = useState<number>(
    defaultRepeatDays?.interval || 1
  );

  const [until, setUntil] = useState<Date>(
    defaultRepeatDays?.until || addMonths(currentDate, 3) || new Date()
  );

  const frequencyCountText = () => {
    switch (repeatDaysType) {
      case RepeatDaysType.DAILY:
        return 'Every day(s)';
      case RepeatDaysType.WEEKLY:
        return 'Every week(s)';
      case RepeatDaysType.MONTHLY:
        return 'Every month(s)';
      default:
        return 'Every week(s)';
    }
  };

  return (
    <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
      <Box
        sx={{
          position: 'absolute',
          top: '35%',
          right: theme.spacers.xl,
          width: '400px',
          bgcolor: theme.palette.common.white,
          boxShadow: 24,
          padding: theme.spacers.xxl,
          borderRadius: `${theme.constants.BORDER_RADIUS.large}px`,
        }}
      >
        {/* INPUT FOR REOCCURRENCE */}
        <Stack spacing={theme.spacers.m} marginBottom={theme.spacers.l}>
          <Typography variant="subtitle1">Set reoccurrence</Typography>
          <InputField
            select
            label="Frequency"
            data-testid="frequencyRecurringEvent"
            value={repeatDaysType}
            dropdownItems={[
              ...Object.values(RepeatDaysType).map(type => ({
                label: `${type.charAt(0).toUpperCase()}${type.slice(1)}`, // Capitalize first letter
                value: type, // Use enum value
              })),
            ]}
            onChange={e => {
              setRepeatDaysType(e.target.value);
              setSelectedDays([]);
            }}
          />
          {/*  BUG - error returned when frequencyCount is not one https://spinspire.monday.com/boards/3396817916/pulses/6447467848  */}
          <InputField
            label={frequencyCountText()}
            data-testid="frequencyCount"
            value={repeatDaysInterval}
            onChange={e => {
              setRepeatDaysInterval(e.target.value);
            }}
          />
          {repeatDaysType === RepeatDaysType.WEEKLY && (
            <WeekdayPicker
              selectedDays={selectedDays}
              setSelectedDays={setSelectedDays}
            />
          )}
          <DatePicker
            label="End date"
            data-testid="endDateRecurringEvent"
            value={until}
            onChange={newDate => setUntil(newDate as Date)}
          />
        </Stack>

        {/* ACTION BUTTONS */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Button
            variant="text"
            size="small"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={() => {
              handleRepeatDaysOptionChange({
                repeatDaysType,
                repeatDaysInterval,
                selectedDays,
                until,
              });
              setModalOpen(false);
            }}
          >
            Save
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};
