import {
  Box,
  Stack,
  Typography,
  Button,
  InputField,
  Container,
  useTheme,
  MessageBanner,
} from '@silverstein-properties/inspirelabs-ui';
import { ApiClient } from '@/api/apiClient';
import { LabelField } from '@/components';
import { Controller, useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { useEffect, useState } from 'react';
import { useAuth } from 'hooks';
import MiniFileInput from '@/components/FormTextInput/MiniFileInput';
import { omit } from 'lodash';
import { UserProfileInput } from '@/classes/UserProfileInput';
import { TEXT_AREA_LIMIT_LONG } from '@/constants';
import { useMerchantProfile } from 'hooks';
import { Error_sm } from '@/assets';

const resolver = classValidatorResolver(UserProfileInput);

export const UserProfileModule = () => {
  const theme = useTheme();
  const apiClient = ApiClient();
  const { data: user } = useAuth();
  const [success, setSuccess] = useState('');
  const [failure, setFailure] = useState('');
  const {
    reset,
    control,
    getValues,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm<UserProfileInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      photoUrl: user?.photoUrl || '',
      phone: user?.phone || '',
      bio: user?.bio || '',
    },
  });
  const { isSuccess, isError, status, mutate } = useMerchantProfile(user);

  // autopopulate form values when user is loaded
  useEffect(() => {
    if (user) {
      reset(user);
    }
  }, [user]);

  // update success/failure banner when mutation status changes
  useEffect(() => {
    if (isSuccess) {
      setFailure('');
      setSuccess('Your profile has been updated');
    } else if (isError) {
      setSuccess('');
      setFailure("We couldn't update your profile. Please try again");
    }
  }, [status]);

  // update user and display error or success banner, depending on result
  const onSubmit = (data: UserProfileInput) => {
    mutate(data);
  };

  const onPhotoChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      // error in target file
      return;
    }

    const photoFile = e.target.files[0];
    const submittedPhoto = await apiClient.images.uploadImage({
      fileName: `user_profile${+new Date()}`,
      photo: photoFile,
      productId: '',
    });

    setValue('photoUrl', submittedPhoto);
  };

  return (
    <Container
      sx={{
        paddingY: theme.spacers.xxl4,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box maxWidth="384px">
        <Typography variant="h4" marginBottom={theme.spacers.xxl}>
          My Profile
        </Typography>

        <Box marginBottom={theme.spacers.m}>
          {success && <MessageBanner type="success">{success}</MessageBanner>}
          {failure && <MessageBanner type="error">{failure}</MessageBanner>}
          {!!Object.keys(errors).length && (
            <MessageBanner type="error">
              Please fix the errors below.
            </MessageBanner>
          )}
        </Box>

        <form id="userProfileForm" onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={theme.spacers.l}>
            <LabelField
              spacing={1}
              label="Email"
              value={<Typography>{user?.email}</Typography>}
            />

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Controller
                name="photoUrl"
                control={control}
                render={({ field }) => (
                  <MiniFileInput
                    {...omit(field, 'ref')}
                    keyNumber="1"
                    handleChange={e => {
                      onPhotoChange(e);
                    }}
                  />
                )}
              />
              <Typography
                color={theme.palette.primary.main}
                variant="body2"
                marginLeft={theme.spacers.m}
              >
                Please provide a clear photo of your face
              </Typography>
            </Box>

            <Controller
              control={control}
              name="firstName"
              render={({ field }) => (
                <InputField
                  {...field}
                  label="First Name"
                  fullWidth
                  helperText={errors.firstName?.message}
                  helperIcon={Error_sm}
                  error={!!errors.firstName}
                />
              )}
            />
            <Controller
              control={control}
              name="lastName"
              render={({ field }) => (
                <InputField
                  {...field}
                  label="Last Name"
                  fullWidth
                  helperText={errors.lastName?.message}
                  helperIcon={Error_sm}
                  error={!!errors.lastName}
                />
              )}
            />
            <Controller
              control={control}
              name="phone"
              render={({ field }) => (
                <InputField
                  {...field}
                  label="Phone"
                  fullWidth
                  helperText={errors.phone?.message}
                  helperIcon={Error_sm}
                  error={!!errors.phone}
                />
              )}
            />
            <Controller
              control={control}
              name="bio"
              render={({ field }) => (
                <InputField
                  {...field}
                  label="Biography"
                  fullWidth
                  multiline
                  rows={6}
                  helperText={
                    errors.bio?.message
                      ? errors.bio?.message
                      : `${getValues('bio')?.length} / ${TEXT_AREA_LIMIT_LONG}`
                  }
                  helperIcon={!!errors.bio ? Error_sm : undefined}
                  error={!!errors.bio}
                />
              )}
            />
          </Stack>

          <Box sx={{ alignSelf: 'flex-end' }}>
            <Button type="submit">Save Changes</Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
};
