import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { auth } from '../config/config';
import { User, signOut } from 'firebase/auth';
import * as endpoints from './endpoints';
import { useAuth } from '@/hooks';
import { transformDates } from '@/utils';

export type ApiClientType = {
  [endpointName in keyof typeof endpoints]: ReturnType<
    (typeof endpoints)[endpointName]
  >;
};

const UNAUTHORIZED_STATUS_CODE = 401;

const onResponse = (originalResponse: AxiosResponse): AxiosResponse => {
  // Handle dates in the response
  transformDates(originalResponse.data);
  return originalResponse;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  if (error.response?.status === UNAUTHORIZED_STATUS_CODE) {
    signOut(auth);
  }

  return Promise.reject(error);
};

const onRequest = async (
  requestConfig: InternalAxiosRequestConfig,
  user?: User
) => {
  if (user && !requestConfig.headers.Authorization) {
    // Add the firebase user's id token to the request headers
    requestConfig.headers.Authorization = `Bearer ${await user.getIdToken()}`;
  }
  return requestConfig;
};

export const ApiClient = () => {
  const { firebaseUser } = useAuth();

  // Create a common axios instance to be shared across the package. This allows for a single
  // place for shared request properties to be configured
  const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_HUB_SERVICE_BASE_URL}`,
  });

  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  axiosInstance.interceptors.request.use(
    (requestConfig: InternalAxiosRequestConfig) => {
      // Add the firebase user's id token to the request headers
      return onRequest(requestConfig, firebaseUser);
    }
  );

  // Add the individual api end points to the api client as properties using their
  // initializer functions
  return Object.entries(endpoints).reduce((result, keyValuePair) => {
    const [endpointName, endpointInitializer] = keyValuePair;

    return {
      ...result,
      [endpointName]: endpointInitializer({ axiosInstance }),
    };
  }, {}) as ApiClientType;
};
