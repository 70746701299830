import { FormHelperText } from '@mui/material';
import { Box, styled } from '@silverstein-properties/inspirelabs-ui';

export const StyledBoxFormControlContainer = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  flexDirection: 'row',
  width: '100%',
  marginTop: theme.spacers.m,
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacers.m,
}));

export const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  marginTop: 0,
  marginLeft: theme.spacers.xs,
  width: '100%',
  'text-align': 'center',
}));
