import { useAuth } from './useAuth';
import { useQuery } from '@tanstack/react-query';
import { ApiClient } from '../api/apiClient';
import { MembershipPlanPurchase } from '@/types/MembershipPlanPurchase';

export type UseMemberShipPlanByMembershipPropsType = {
  membershipId: string;
};

export const useMembershipPlanByMembership = ({
  membershipId,
}: UseMemberShipPlanByMembershipPropsType) => {
  const { data: user } = useAuth();
  const apiClient = ApiClient();

  const getMembershipPlanPurchaseByMembership = async (): Promise<
    MembershipPlanPurchase[]
  > => {
    return await apiClient.membershipPlanPurchases.getMembershipPlanPurchasesByMerchantMembership(
      {
        membershipId,
      }
    );
  };

  return useQuery<MembershipPlanPurchase[]>(
    ['membershipPlanPurchases', membershipId],
    () => getMembershipPlanPurchaseByMembership(),
    { enabled: !!user }
  );
};
