import { styled, List } from '@silverstein-properties/inspirelabs-ui';

export const StyledList = styled(List)(({ theme }) => ({
  borderEndStartRadius: theme.shape.borderRadius,
  margin: 0,
  padding: 0,
  borderTop: 0,
  borderLeft: `1px solid ${theme.palette.grey[300]}`,
  borderRight: `1px solid ${theme.palette.grey[300]}`,
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  '& .MuiListItem-root': {
    padding: theme.spacing(2.5, 2),
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
  },
}));
