/**
 * Formats the camelCase validation property name to more readable name for FE
 * @example formatPropertyName('totalAmount')
 * // returns 'Total amount'
 * @param property property name
 * @returns formatted property name
 */
export const formatPropertyName = (property: String) => {
  const spacedProperty = property.replace(/([A-Z])/g, ' $1').toLowerCase();
  return spacedProperty.charAt(0).toUpperCase() + spacedProperty.slice(1);
};
