import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AlertIcon from './AlertIcon.svg';
import {
  DialogBox,
  StyledDialogActions,
  StyledImage,
} from './AlertDialog.styles';
import {
  Button,
  MessageBanner,
  Stack,
  Typography,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import { Box } from '@mui/material';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';

export interface SimpleDialogProps {
  open: boolean;
  onClose: (value: boolean) => Promise<void> | void;
  title: string;
  image?: string | JSX.Element;
  message: string | JSX.Element;
  errorMessage?: string;
  agree: string;
  disagree: string;
}

export default function AlertDialog({
  open,
  onClose,
  title,
  image,
  message,
  errorMessage,
  agree,
  disagree,
}: SimpleDialogProps) {
  const [isLoadingButton, setLoadingButton] = useState(false);
  const theme = useTheme();

  const handleUserConfirmation = async () => {
    setLoadingButton(true);
    try {
      await onClose(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingButton(false);
    }
  };

  return (
    <>
      <DialogBox
        open={open}
        onClose={() => onClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            width: '400px',
            maxHeight: '80%',
            height: 'auto',
          },
        }}
      >
        {typeof image === 'string' ? (
          <StyledImage
            height="80px"
            width="80px"
            src={image || AlertIcon}
            alt="Alert Icon"
          />
        ) : (
          <Stack
            justifyContent="center"
            alignItems="center"
            alignSelf="center"
            sx={{
              width: '80px',
              height: '80px',
            }}
          >
            {image}
          </Stack>
        )}
        <DialogTitle component={Stack} id="alert-dialog-title">
          <Typography variant="h4">{title}</Typography>
          <Box sx={{ width: '100%' }}>
            <MessageBanner hidden={!errorMessage} type="error">
              {errorMessage}
            </MessageBanner>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {typeof message === 'string' ? (
              <Typography color="primary" variant="subtitle1">
                {message}
              </Typography>
            ) : (
              message
            )}
          </DialogContentText>
        </DialogContent>
        <StyledDialogActions>
          <LoadingButton
            loading={isLoadingButton}
            variant="contained"
            size="large"
            onClick={() => handleUserConfirmation()}
          >
            {agree}
          </LoadingButton>

          <Button
            variant="text"
            size="large"
            sx={{ marginTop: theme.spacers.l }}
            onClick={() => onClose(false)}
          >
            {disagree}
          </Button>
        </StyledDialogActions>
      </DialogBox>
    </>
  );
}
