import LeftMenu from './LeftMenu';
import './leftColumnContainer.css';
import { Link, useNavigate } from 'react-router-dom';
import { PartialMerchCreationObject } from '../../types';

export interface LeftColumnContainerProps {
  changePage: (page: number, finished: string) => void;
  currentPage: number;
  furthestPage: number;
  partiallyCreateExperience: (data: PartialMerchCreationObject) => void;
  updateExperience: (data?: PartialMerchCreationObject) => void;
  updateExperienceLocation: (data: PartialMerchCreationObject) => void;
}

export default function LeftColumnContainer({
  changePage,
  currentPage,
  furthestPage,
  partiallyCreateExperience,
  updateExperience,
  updateExperienceLocation,
}: LeftColumnContainerProps) {
  const navigate = useNavigate();

  // Disabled rule due to current implementation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onExitForm = (e: any) => {
    e.stopPropagation();

    // Refactor this code as it assumes there will always be a form in the container
    const form = document.querySelector('form');
    if (form) {
      const formData = new FormData(form);
      const data: Record<string, FormDataEntryValue> = {};
      formData.forEach((value, key) => {
        data[key] = value;
      });
      if (data && currentPage !== 1) {
        switch (currentPage) {
          case 2:
            partiallyCreateExperience(data);
            break;
          case 7:
            updateExperienceLocation(data);
            break;
          default:
            updateExperience(data);
            break;
        }
      }
    }

    // Navigates back to previous page (i.e. welcome for users with no
    // experiences or experiences page if user already has experiences)
    navigate(-1);
  };

  return (
    <div className="bg-light">
      <div className="left-column-container bg-light d-md-block d-none">
        <Link to="#" onClick={onExitForm}>
          <span className="left-column-exit-button">‹ Exit</span>
        </Link>
        <div className="listing">Create an experience</div>
        <LeftMenu
          changePage={changePage}
          currentPage={currentPage}
          furthestPage={furthestPage}
        />
      </div>
    </div>
  );
}
