import {
  Box,
  Container,
  InputField,
  Stack,
  Typography,
  Button,
  Link,
  useTheme,
  MessageBanner,
} from '@silverstein-properties/inspirelabs-ui';
import { usePassword } from '@/hooks/usePassword';
import { Controller, useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { CreatePasswordInput } from '@/classes';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useEffect } from 'react';

const resolver = classValidatorResolver(CreatePasswordInput);

const TermsConditions = (
  <span style={{ fontWeight: '300', fontSize: '14px' }}>
    <Typography variant="labelMedium">I accept the </Typography>
    <Link
      underline="always"
      href="termsandconditions"
      target="_blank"
      rel="noopener norefferer"
    >
      <Typography variant="labelMedium">Terms & Conditions</Typography>
    </Link>
  </span>
);

export const CreatePasswordModule = () => {
  const theme = useTheme();
  const {
    setPassword: { mutate: setPassword, isLoading },
    searchParams,
    passwordResetSuccess,
    passwordResetWarning,
  } = usePassword();

  const {
    control,
    formState: { errors, isValid },
    trigger,
    handleSubmit,
    watch,
  } = useForm<CreatePasswordInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      email: searchParams.get('email') || '',
      password: '',
      passwordResetToken: searchParams.get('token') || '',
      merchantId: searchParams.get('merchantId') || '',
      termsAccepted: false,
    },
  });
  const [email, passwordResetToken, merchantId] = watch([
    'email',
    'passwordResetToken',
    'merchantId',
  ]);
  useEffect(() => {
    trigger(['email', 'passwordResetToken', 'merchantId']);
  }, [email, passwordResetToken, merchantId]);

  const onSubmit = async (data: CreatePasswordInput) => {
    setPassword(data);
  };

  const errorMessages =
    errors.email?.message ||
    errors.passwordResetToken?.message ||
    errors.merchantId?.message ||
    passwordResetWarning;

  return (
    <Container
      sx={{
        paddingY: theme.spacers.xxl4,
      }}
      maxWidth="sm"
    >
      <Stack spacing={4} ml="auto">
        <Typography variant="h4">Welcome</Typography>
        <Typography variant="subtitle1">Please create a password</Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box width="100%">
            {errorMessages && (
              <MessageBanner type="error">{errorMessages}</MessageBanner>
            )}
            {passwordResetSuccess && (
              <MessageBanner type="success">
                {passwordResetSuccess}
              </MessageBanner>
            )}
          </Box>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <InputField
                {...field}
                type="password"
                ref={null}
                label="Password"
                fullWidth
                helperText={
                  errors.password?.message ?? '8 characters or longer'
                }
                error={!!errors.password}
              />
            )}
          />
          <Controller
            name="termsAccepted"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                label={TermsConditions}
                control={<Checkbox {...field} />}
              />
            )}
          />
          <Box sx={{ alignSelf: 'flex-end' }}>
            <Button type="submit" disabled={isLoading || !isValid}>
              Next
            </Button>
          </Box>
        </form>
      </Stack>
    </Container>
  );
};
