import { ExperienceHost } from '@/types';
import { Grid } from '@silverstein-properties/inspirelabs-ui';
import { StyledAvatar, StyledTypography } from './HostList.styles';

export type HostListProps = {
  hosts: ExperienceHost[];
  // TODO: use selectedHostId to display selected host's avatar
  selectedHostId?: string;
};

export const HostList = ({ hosts }: HostListProps) => {
  // FIXME: currently we're not storing host names into the listing/product
  // we should store host's name instead of displaying current user's name
  return (
    <Grid container columnSpacing={3}>
      {hosts.map((host, i) => (
        <Grid
          container
          item
          rowGap={0.5}
          key={`${host.phoneNumber}_${i}`}
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
            width: 'auto',
          }}
        >
          <StyledAvatar
            alt={host?.firstName || 'host'}
            isSelected
            src={host.hostPhoto}
          />
          <StyledTypography>{host?.firstName || 'No host'}</StyledTypography>
        </Grid>
      ))}
    </Grid>
  );
};
