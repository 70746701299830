import {
  Grid,
  Typography,
  Container,
  Link,
  useTheme,
  Button,
  Stack,
  DocumentIcon,
  IconBlock,
  BalanceIcon,
  CreditsIcon,
  CancelationIcon,
  HelpIcon,
  Box,
} from '@silverstein-properties/inspirelabs-ui';
import { BackButton, DashboardCard, DetailsDrawer } from '@/components';
import { formatPricing } from '@/utils/productUtils';

import { ExperiencesTable, SubscriptionsTable } from './components';
import { useNavigate, useParams } from 'react-router';
import { formatDate } from '@/utils';
import { NumericCircle } from '@/components/NumericCircle';
import { useFeatureFlags } from '@/hooks';
import { usePaymentStatement } from '@/hooks/usePaymentStatement';
import { ListingDetailsDrawer } from './components/PaymentStatementDetailsDrawer';
import { useState } from 'react';

export const PaymentStatementDetailsModule = () => {
  const theme = useTheme();
  // statement id to be used when pulling statement details from hub-service
  const { statementId = '' } = useParams();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedListingId, setSelectedListingId] = useState<
    undefined | string
  >();

  const { data: paymentStatement, isLoading } = usePaymentStatement({
    paymentStatementId: +statementId,
  });
  const navigate = useNavigate();
  const { isStatementExportToPdfEnabled } = useFeatureFlags();

  const handleBackNavigation = () => {
    navigate(-1);
  };

  // TODO empty state
  // https://spinspire.monday.com/boards/3396817916/pulses/4575720625
  if (!paymentStatement || isLoading) {
    return null;
  }

  const closeDrawer = () => {
    setDrawerOpen(false);
    setSelectedListingId(undefined);
  };

  const onListingDetailsClick = (id: string) => {
    setSelectedListingId(id);
    setDrawerOpen(true);
  };

  // const onMembershipDetailsClick = (id: string) => {
  // TODO create membershipPlan details Click
  // https://spinspire.monday.com/boards/3396817916/pulses/6094064318
  // };

  return (
    <Container sx={{ paddingY: theme.spacers.xxl4 }}>
      <DetailsDrawer closeWindow={closeDrawer} drawerOpen={drawerOpen}>
        <ListingDetailsDrawer listingId={selectedListingId} />
      </DetailsDrawer>
      <BackButton
        onClick={handleBackNavigation}
        sx={{ marginBottom: theme.spacers.m }}
      />
      {/* BASIC STAT TEXTS */}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={theme.spacers.xs}
      >
        <Typography variant="h4">
          {`Statement — ${paymentStatement.billingCycle}`}
        </Typography>
        {isStatementExportToPdfEnabled ? (
          <Button
            variant="text"
            startIcon={<DocumentIcon width={theme.constants.ICON_SIZE.small} />}
          >
            Export PDF
          </Button>
        ) : null}
      </Stack>

      <Typography
        variant="body1"
        color="success.main"
        marginBottom={theme.spacers.xxs}
      >
        {paymentStatement?.paymentDate &&
          `Payment day: ${formatDate(paymentStatement?.paymentDate, true)}`}
      </Typography>
      <Typography variant="body3" color="primary.medium">
        Account ID: {paymentStatement?.accountId}
      </Typography>

      {/* STAT CARDS */}
      <Grid
        container
        marginTop={theme.spacers.xl}
        columnSpacing={theme.spacers.xl}
        data-testid="payment-statement-dashboard-cards"
        marginBottom={theme.spacers.xxl4}
      >
        <Grid item xs={4}>
          <DashboardCard
            icon={<BalanceIcon width={64} />}
            value={formatPricing(paymentStatement.netEarnings || 0)}
            valueColor="success.main"
            label="Net earnings"
            data-testid="payment-statement-dashboard-cards"
          />
        </Grid>
        <Grid item xs={4}>
          <DashboardCard
            icon={<CreditsIcon width={64} />}
            value={formatPricing(paymentStatement.inspireFee || 0)}
            label="Platform fee"
            data-testid="payment-statement-dashboard-cards"
          />
        </Grid>
        <Grid item xs={4}>
          <DashboardCard
            icon={<CancelationIcon width={64} />}
            value={`${formatPricing(paymentStatement?.refunds || 0)}`}
            valueColor="error.main"
            label="Refunds"
            data-testid="payment-statement-dashboard-cards"
          />
        </Grid>
      </Grid>

      {/* EXPERIENCES TABLE */}
      <Box marginBottom={theme.spacers.xxl4}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={theme.spacers.s}
          marginBottom={theme.spacers.l}
        >
          <Typography variant="h5">Experiences</Typography>
          <NumericCircle
            isLoading={isLoading}
            value={paymentStatement.experiences?.length || 0}
          />
        </Stack>

        <ExperiencesTable
          experiences={paymentStatement.experiences || []}
          onDetailsClick={onListingDetailsClick}
        />
      </Box>

      {/* SUBSCRIPTIONS TABLE */}
      <Box marginBottom={theme.spacers.xxl4}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={theme.spacers.s}
          marginBottom={theme.spacers.l}
        >
          <Typography variant="h5">Subscriptions</Typography>
          <NumericCircle
            isLoading={isLoading}
            value={paymentStatement.subscriptions?.length || 0}
          />
        </Stack>

        <SubscriptionsTable
          subscriptions={paymentStatement.subscriptions || []}
        />
      </Box>

      {/* NEED HELP */}
      <IconBlock
        icon={
          <HelpIcon
            width={theme.constants.ICON_SIZE.large}
            style={{ marginBottom: theme.spacers.m }}
          />
        }
        title="Need help?"
        body={
          <Typography variant="body1">
            Contact{' '}
            <Link href="mailto:payments@inspire.com" target="_blank">
              payments@inspire.com
            </Link>
          </Typography>
        }
      />
    </Container>
  );
};
