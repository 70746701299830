import { styled } from '@silverstein-properties/inspirelabs-ui';

export const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  // FIXME: these styles are overriding fixed width/height from the HookFormTextArea and
  // HookFormTextInput components. Once they're "responsive", we can remove these overrides
  '& .input-container, .text-area, textarea, .css-trjecr-Control': {
    width: '100%',
  },
  '& .css-b62m3t-container': {
    width: '50%',
  },
}));

export const StyledButtonsDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  borderTop: `1px solid ${theme.palette.grey[400]}`,
  width: `calc(100% + ${theme.spacing(10)})`,
  margin: theme.spacing(0, -5, -5, -5),
  padding: theme.spacing(2, 5),
}));
