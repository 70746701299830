import { useMutation, useQuery } from '@tanstack/react-query';
import { ApiClient } from '../api/apiClient';
import { useAuth } from './useAuth';
import { MerchantProfile } from '@/types/Merchant';
import { AxiosError } from 'axios';
import { BuildingSelect } from '@/types';

export const useMerchant = () => {
  const { data: user } = useAuth();
  const apiClient = ApiClient();

  const getMerchantProfile = async (
    primaryMerchantId: string
  ): Promise<MerchantProfile> => {
    return await apiClient.merchants.getMerchantProfile({
      merchantId: parseInt(primaryMerchantId),
    });
  };

  return useQuery<MerchantProfile>(
    ['merchantProfile'],
    () => getMerchantProfile(user?.primaryMerchantId || ''),
    { enabled: !!user }
  );
};

export const useMerchantServiceableBuildings = (merchantId: string) => {
  const apiClient = ApiClient();

  const getMerchantServiceableBuildings = async (
    primaryMerchantId: string
  ): Promise<BuildingSelect[]> => {
    return await apiClient.merchants.getMerchantServiceableBuildings({
      merchantId: parseInt(primaryMerchantId),
    });
  };

  return useQuery<BuildingSelect[]>({
    queryKey: ['merchantServiceableBuildings', merchantId],
    queryFn: async () => await getMerchantServiceableBuildings(merchantId),
  });
};

export const useUpdateMerchantBusinessProfile = () => {
  const apiClient = ApiClient();

  const updateBusinessProfile = async ({
    merchantId,
    updateMerchantProfile,
  }: {
    merchantId: number;
    updateMerchantProfile: Partial<MerchantProfile>;
  }): Promise<MerchantProfile> => {
    return await apiClient.merchants.updateMerchantProfile({
      merchantId,
      updateMerchantProfile,
    });
  };

  return useMutation(
    async (data: {
      merchantId: number;
      updateMerchantProfile: Partial<MerchantProfile>;
    }) => await updateBusinessProfile(data),
    {
      onSuccess: () => {},
      onError: (error: AxiosError) => {
        console.error(error);
      },
    }
  );
};
