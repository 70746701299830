import {
  Box,
  FirstPageIcon,
  IconButton,
  LastPageIcon,
  NextPageIcon,
  PreviousPageIcon,
  Stack,
  Typography,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import { Table } from '@tanstack/react-table';

interface PaginationProps<T> {
  show: boolean;
  table: Table<T>;
  totalItems: number;
}

export function Pagination<T>(props: PaginationProps<T>) {
  const { show, table, totalItems } = props;
  const theme = useTheme();

  const pageIndex = table.getState().pagination.pageIndex;
  const pageSize = table.getState().pagination.pageSize;

  const fromIndex = pageIndex * pageSize + 1; // 0-based index
  const toIndex = Math.min((pageIndex + 1) * pageSize, totalItems);

  return show ? (
    <Stack
      direction="row"
      spacing={theme.spacers.xxl2}
      justifyContent="center"
      alignItems="center"
      marginTop={theme.spacers.l}
      marginBottom={theme.spacers.xxl}
    >
      <Box>
        <IconButton
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
          color="primary"
        >
          <FirstPageIcon width={theme.constants.ICON_SIZE.small} />
        </IconButton>
        <IconButton
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
          color="primary"
        >
          <PreviousPageIcon width={theme.constants.ICON_SIZE.small} />
        </IconButton>
      </Box>

      {/* =================================== */}

      <Typography variant="body3" color="primary.medium">
        {fromIndex}-{toIndex} of {totalItems}
      </Typography>

      {/* =================================== */}

      <Box>
        <IconButton
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
          color="primary"
        >
          <NextPageIcon width={theme.constants.ICON_SIZE.small} />
        </IconButton>
        <IconButton
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
          color="primary"
        >
          <LastPageIcon width={theme.constants.ICON_SIZE.small} />
        </IconButton>
      </Box>
    </Stack>
  ) : null;
}
