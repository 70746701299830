export enum GymBuildings {
  SILVER_TOWERS = 'ST',
  RIVER_PLACE = 'RP',
}
// export enum GymBuildings {
//   SILVER_TOWERS = 'Silver Towers',
//   RIVER_PLACE = 'River Place',
// }

export enum PhysicalAccessErrorCodes {
  PHYSICAL_ACCESS_USER_NOT_FOUND = 'Record not found for this user', // Could not find user's email in lenel
  PHYSICAL_ACCESS_USER_CONFLICT = 'Found multiple records for this user', // Found more than one user in lenel with the email
  PHYSICAL_ACCESS_SOMETHING_WENT_WRONG = "Something went wrong with this user's physical access", // Default error
}

export class HttpHubError {
  errorCode: PhysicalAccessErrorCodes;
  message: string;
}

export type GymBuildingsType = {
  -readonly [key in keyof typeof GymBuildings]?: {
    hasAccess: boolean;
    error?: HttpHubError;
  };
};

export type GetGymPhysicalAccessType = {
  // When at least one of the building physical access is granted
  hasPhysicalAccess: boolean;
} & GymBuildingsType;
