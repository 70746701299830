import { ToggleButton } from '@mui/material';
import { Box } from '@silverstein-properties/inspirelabs-ui';
import { StyledToggleButtonGroup } from './WeekdayPicker.styles';
import { DaysOfTheWeek } from '@/types/RecurringEvent';
import { WEEKDAY_BUTTON_SIZE } from '@/constants';

interface WeekDayOption {
  label: string;
  value: DaysOfTheWeek;
}

interface WeekdayPickerProps {
  selectedDays: DaysOfTheWeek[];
  setSelectedDays: (value: DaysOfTheWeek[]) => void;
}

export const WeekdayPicker = (props: WeekdayPickerProps) => {
  const { selectedDays, setSelectedDays } = props;

  const handleDayChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    newDays: DaysOfTheWeek[]
  ) => {
    setSelectedDays(newDays);
  };

  const weekdays: WeekDayOption[] = [
    { label: 'S', value: DaysOfTheWeek.SUNDAY },
    { label: 'M', value: DaysOfTheWeek.MONDAY },
    { label: 'T', value: DaysOfTheWeek.TUESDAY },
    { label: 'W', value: DaysOfTheWeek.WEDNESDAY },
    { label: 'T', value: DaysOfTheWeek.THURSDAY },
    { label: 'F', value: DaysOfTheWeek.FRIDAY },
    { label: 'S', value: DaysOfTheWeek.SATURDAY },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <StyledToggleButtonGroup value={selectedDays} onChange={handleDayChange}>
        {weekdays.map(day => (
          <ToggleButton
            key={day.value}
            value={day.value}
            disableRipple
            sx={{
              width: WEEKDAY_BUTTON_SIZE,
              height: WEEKDAY_BUTTON_SIZE,
            }}
          >
            {day.label}
          </ToggleButton>
        ))}
      </StyledToggleButtonGroup>
    </Box>
  );
};
