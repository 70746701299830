import {
  IsNotEmpty,
  MaxLength,
  MinLength,
  IsPhoneNumber,
  IsString,
  IsOptional,
} from 'class-validator';
import { TEXT_AREA_LIMIT_LONG } from '@/constants';

export class UserProfileInput {
  @IsOptional()
  id?: number;

  @IsString()
  @IsOptional()
  photoUrl: string | File;

  @MinLength(1, { message: 'Please enter a first name' })
  @MaxLength(50, { message: 'Please enter a shorter version of your name' })
  firstName: string;

  @MinLength(1, { message: 'Please enter a last name' })
  @MaxLength(50, { message: 'Please enter a shorter version of your name' })
  @IsNotEmpty({ message: 'Please enter a last name' })
  lastName: string;

  @IsPhoneNumber('US', { message: 'Please enter a valid phone number' })
  @IsNotEmpty({ message: 'Please enter a phone number' })
  phone: string;

  @MinLength(1, { message: 'Please enter a biography' })
  @MaxLength(TEXT_AREA_LIMIT_LONG, {
    message: 'Please enter a shorter version of your biography',
  })
  @IsNotEmpty({ message: 'Please enter a biography' })
  bio: string;
}
