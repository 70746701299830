import { Drawer } from '@mui/material';
import { styled } from '@silverstein-properties/inspirelabs-ui';

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: theme.spacing(58),
    borderRadius: 0,
  },
}));

export const StyledButtonsDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  borderTop: `1px solid ${theme.palette.grey[400]}`,
  padding: `${theme.spacers.m} ${theme.spacers.xxl}`, // "16px 40px"
}));
