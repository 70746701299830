import CheckMark from '../SignupFormContainer/checkmark.svg';
import { useEffect } from 'react';

export function FormSuccess() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="row bump-left mt-5 d-flex justify-content-center">
      <div className="col-8 text-center mb-2">
        <img src={CheckMark} alt="green checkmark" height="80px" />
      </div>

      <h4 className="col-8 text-center">Your form was submitted!</h4>
      <div className="success-subtitle col-8 text-center">
        Please allow us some time to review your inquiry. Our team will respond
        to you soon.
      </div>
      <div className="col-8 d-flex justify-content-center mt-3"></div>
    </div>
  );
}
