import { MerchantUser, User, UserRole } from 'types';
import { useAuth } from './useAuth';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ApiClient } from '../api/apiClient';
import { TeamInviteInput } from '@/classes';
import { AxiosError } from 'axios';

const useMerchantTeamMembers = () => {
  const { data: user } = useAuth();
  const merchantId = parseInt(user?.primaryMerchantId || '');
  const apiClient = ApiClient();

  const getMerchantTeam = async (): Promise<MerchantUser[]> => {
    return await apiClient.merchants.getMerchantTeamMembers({ merchantId });
  };

  return useQuery<MerchantUser[]>(
    ['merchantTeams', merchantId],
    () => getMerchantTeam(),
    { enabled: !!user }
  );
};

const useNewTeamMember = (
  successCallback: () => void,
  errorCallback: () => void
) => {
  const { data: user } = useAuth();
  const merchantId = parseInt(user?.primaryMerchantId || '');
  const apiClient = ApiClient();

  const newUserToTheTeam = async (data: TeamInviteInput): Promise<User> => {
    return await apiClient.merchants.createMerchantTeamMember({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      primaryMerchantId: merchantId,
      // TODO: if users can have multiple merchants, this might not always be accurate
      // would need a new field to fetch current merchant
      senderUserId: user?.id || 0,
      primaryMerchantUserRole: data.role as UserRole,
    });
  };

  return useMutation(
    async (data: TeamInviteInput) => await newUserToTheTeam(data),
    {
      onSuccess: () => {
        successCallback();
      },
      onError: (error: AxiosError) => {
        console.error(error);
        errorCallback();
      },
    }
  );
};

const resendInviteToTeamMember = () => {
  const { data: user } = useAuth();
  const merchantId =
    user?.merchantUser?.merchantId || parseInt(user?.primaryMerchantId || '');
  const apiClient = ApiClient();

  const onResendInviteClick = async (newMemberId: number): Promise<boolean> => {
    return await apiClient.merchants.sendTeamMemberInvite({
      userId: newMemberId,
      primaryMerchantId: merchantId,
      senderUserId: user?.id || 0,
    });
  };

  return useMutation(
    async (newMemberId: number) => await onResendInviteClick(newMemberId),
    {
      onSuccess: () => {},
      onError: (error: AxiosError) => {
        console.error(error);
      },
    }
  );
};

const removeTeamMember = (
  successCallback: () => void,
  errorCallback: () => void
) => {
  const { data: user } = useAuth();
  const merchantId =
    user?.merchantUser?.merchantId || parseInt(user?.primaryMerchantId || '');
  const apiClient = ApiClient();

  const onRemoveTeamMember = async (newMemberId: number): Promise<boolean> => {
    return await apiClient.merchants.removeTeamMember({
      memberUserId: newMemberId,
      merchantId: merchantId,
      senderUserId: user?.id || 0,
    });
  };

  return useMutation(
    async (newMemberId: number) => await onRemoveTeamMember(newMemberId),
    {
      onSuccess: () => {
        successCallback();
      },
      onError: (error: AxiosError) => {
        console.error(error);
        errorCallback();
      },
    }
  );
};

export {
  useMerchantTeamMembers,
  useNewTeamMember,
  resendInviteToTeamMember,
  removeTeamMember,
};
