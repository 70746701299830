export enum SquareEnvironment {
  Production = 'production',
  Sandbox = 'sandbox',
  Custom = 'custom',
}

export interface SquareConfiguration {
  clientEnvironment: SquareEnvironment;
  locationId: string;
  accessToken: string;
}

export interface SquareLocation {
  id?: string;
  name?: string | null;
  timezone?: string | null;
  capabilities?: string[];
  status?: string;
  createdAt?: string;
  merchantId?: string;
  country?: string;
  languageCode?: string | null;
  currency?: string;
  phoneNumber?: string | null;
  businessName?: string | null;
  type?: string;
  websiteUrl?: string | null;
  businessEmail?: string | null;
  description?: string | null;
  twitterUsername?: string | null;
  instagramUsername?: string | null;
  facebookUrl?: string | null;
  logoUrl?: string;
  posBackgroundUrl?: string;
  mcc?: string | null;
  fullFormatLogoUrl?: string;
}

export interface CatalogModifierOverride {
  modifierId: string;
  onByDefault?: boolean | null;
}

export interface CatalogItemModifierListInfo {
  modifierListId: string;
  modifierOverrides?: CatalogModifierOverride[] | null;
  minSelectedModifiers?: number | null;
  maxSelectedModifiers?: number | null;
  enabled?: boolean | null;
  ordinal?: number | null;
}

export enum CatalogObjectType {
  CATEGORY = 'CATEGORY',
  MODIFIER_LIST = 'MODIFIER_LIST',
  ITEM = 'ITEM',
  TAX = 'TAX',
  IMAGE = 'IMAGE',
  ITEM_VARIATION = 'ITEM_VARIATION',
  MODIFIER = 'MODIFIER',
}

export type SquareConfigType = {
  clientEnvironment: SquareEnvironment;
  accessToken?: string;
};

export type SquareCategory = {
  id: string;
  name: string;
};

export type SquareModifier = {
  id: string;
  name: string;
  ordinal: number;
  price: number | null;
  onByDefault: boolean;
};

export type SquareModifierList = {
  id: string;
  name: string;
  selectionType: string;
  values: SquareModifier[];
};

export type SquareItemModifierList = {
  min: number;
  max: number;
} & SquareModifierList;

export type SquareItem = {
  id: string;
  name: string;
  imageId: string;
  categories: string[];
  description: string;
  taxIds: string[];
  variations: SquareVariation[];
  modifiers: SquareModifierList[];
  modifierListInfo: CatalogItemModifierListInfo[];
};

export type SquareVariation = {
  id: string;
  name: string;
  price: number;
};

export type SquareTax = {
  id: string;
  name: string;
  inclusionType: string;
  percentage: string;
};

export type SquareMenu = {
  categories: SquareCategory[];
  modifiersList: Record<string, SquareModifierList>;
  items: SquareItem[];
  taxes: SquareTax[];
  images: Record<string, string>;
  location?: SquareLocation;
};
