import {
  Chip,
  Collapse,
  ListItem,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import { useState } from 'react';
import { StyledList } from './MembershipProductsList.styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export type SkeletonListItemsPropsType = {
  show: boolean;
  title: string;
  numberOfSkeletons?: number;
};

export type ProductInfo = {
  id: string;
  title: string;
};

export type ListItemsPropsType = {
  show: boolean;
  productsInfo: ProductInfo[];
};

export type ProductsListPropsType = {
  title: string;
  productsInfo: ProductInfo[];
  isLoading?: boolean;
  defaultOpen?: boolean;
};

export const MembershipProductsList = ({
  title,
  productsInfo = [],
  isLoading = false,
  defaultOpen = false,
}: ProductsListPropsType) => {
  const theme = useTheme();
  const [open, setOpen] = useState(defaultOpen);

  return (
    <>
      <Stack
        border={`1px solid ${theme.palette.grey[400]}`}
        padding={theme.spacers.m}
        marginTop={theme.spacers.m}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        borderRadius={open ? '4px 4px 0 0' : '4px'}
        onClick={() => setOpen(!open)}
      >
        <Stack direction="row" spacing={theme.spacers.s} alignItems="center">
          <Typography variant="subtitle1">{title}</Typography>
          <Chip
            label={
              isLoading ? (
                <Skeleton />
              ) : (
                <Typography variant="button">{productsInfo.length}</Typography>
              )
            }
            size="medium"
            color="default"
          />
        </Stack>
        <ExpandMoreIcon
          onClick={() => setOpen(!open)}
          sx={{
            transform: open ? 'rotate(180deg)' : 'none',
            cursor: 'pointer',
          }}
        />
      </Stack>
      <Collapse
        in={open}
        sx={{
          marginTop: '0px !important',
        }}
      >
        <StyledList>
          <SkeletonListItems show={isLoading} title={title} />
          <ListItems show={!isLoading} productsInfo={productsInfo} />
        </StyledList>
      </Collapse>
    </>
  );
};

const SkeletonListItems = ({
  show = false,
  title,
  numberOfSkeletons = 3,
}: SkeletonListItemsPropsType) => {
  if (!show) {
    return null;
  }
  return (
    <>
      {[...Array(numberOfSkeletons)].map((_item, i) => (
        <ListItem key={`${title}_${i}`}>
          <ListItemText>
            <Skeleton />
          </ListItemText>
        </ListItem>
      ))}
    </>
  );
};

const ListItems = ({ show = false, productsInfo }: ListItemsPropsType) => {
  if (!show) {
    return null;
  }
  return (
    <>
      {productsInfo.map(product => {
        return (
          <ListItem key={product.id}>
            <Typography>{product.title}</Typography>
          </ListItem>
        );
      })}
    </>
  );
};
