import { DaysOfTheWeek, TimeInterval } from '@/types/RecurringEvent';
import { formatDate, formatTime } from './dateUtils';
import { capitalizeEachWord } from './commonUtils';
import { capitalize } from 'lodash';
import { addMinutes, differenceInMinutes } from 'date-fns';
import { TimeIntervalInput } from '@/classes';

export const formatRecurrenceModalString = (
  selectedDays: DaysOfTheWeek[] = [],
  untilDate: Date
) => {
  const daysOfTheWeek = (selectedDays || []).map(x =>
    capitalizeEachWord(DaysOfTheWeek[x].slice(0, 3), true)
  );

  return daysOfTheWeek.length === 0
    ? `${formatDate(untilDate)}`
    : `${daysOfTheWeek.join(', ')}, ${formatDate(untilDate)}`;

  // Mon, Tue, Wed, until April 15,2023
};

export const formatTimeIntervalString = (
  timeInterval: TimeInterval,
  startTime: Date,
  endTime: Date
) => {
  return `${timeInterval.intervalSegment}-min interval between ${formatTime(
    startTime
  )} - ${formatTime(endTime)}${
    timeInterval.timeBetweenInterval > 0
      ? ` with ${timeInterval.timeBetweenInterval}-min in-between intervals`
      : ''
  }`;
};

export const formatRecurringDaysString = (
  repeatDaysType: string,
  until: Date
) => {
  return `${capitalize(repeatDaysType)} until ${formatDate(until)}`;
};

export const determineTimeFrameMessage = (
  startDateTime: Date,
  endDateTime: Date,
  timeInterval: TimeIntervalInput | undefined
): {
  message: string;
  error: boolean;
} => {
  if (!timeInterval || timeInterval.intervalSegment === 0) {
    return {
      message: '',
      error: false,
    };
  }

  const duration = differenceInMinutes(endDateTime, startDateTime);
  const numberOfFits =
    duration /
    (timeInterval.intervalSegment + (timeInterval.timeBetweenInterval || 0));
  const numberOfEvents = Math.floor(numberOfFits);
  const lengthOfEvents =
    numberOfEvents *
    (timeInterval.intervalSegment + (timeInterval.timeBetweenInterval || 0));
  const newEndDateTime = addMinutes(startDateTime, lengthOfEvents);

  return {
    message: `You are about to create ${numberOfEvents} time slots which wil end at ${formatTime(
      newEndDateTime
    )}`,
    error: endDateTime.getTime() !== newEndDateTime.getTime(),
  };
};
