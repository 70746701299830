import { useNavigate } from 'react-router-dom';
import { usePassword } from '@/hooks/usePassword';
import { useEffect } from 'react';
import {
  Grid,
  IconBlock,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';

export const EmailVerificationModule = (): JSX.Element => {
  const { searchParams, applyActionCodeRequest } = usePassword();
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get('mode') && searchParams.get('oobCode')) {
      applyActionCodeRequest(searchParams.get('oobCode') as string);
    }
  }, [searchParams]);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      mt={theme.spacers.xxl4}
    >
      <Grid item xs={3}>
        <IconBlock
          icon="/images/success.svg"
          iconColor="success.main"
          iconSize={80}
          title="Your email was successfully verified"
          titleSize="h4"
          titleMarginBottom={theme.spacers.xxl_num}
          primaryButtonTitle="Login"
          onClickPrimaryButton={() => navigate('/login')}
        />
      </Grid>
    </Grid>
  );
};
