import { memo, useMemo, useState } from 'react';
import { StyledTableRow } from './Members.styles';
import { MembershipPlanPurchase } from '@/types';
import { TableCell } from '@mui/material';
import {
  Button,
  IconButton,
  Typography,
} from '@silverstein-properties/inspirelabs-ui';
import {
  convertPriceAndBillingToString,
  determineMembersDisplayStatus,
} from '@/utils/membershipUtils';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MemberDetailsDrawer from './MemberDetailsDrawer';

export type RowMemberPropsType = {
  membershipPlanPurchase: MembershipPlanPurchase;
};

// TODO: I disabled one of the eslint rule (which prefers arrow function) because `memo()` doesn't take arrow functions
// eslint-disable-next-line prefer-arrow-callback
export const MemberRow = memo(function MemberRow(props: RowMemberPropsType) {
  const { membershipPlanPurchase } = props;
  const [open, setOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const planConsumers = useMemo(
    () =>
      membershipPlanPurchase.planConsumers.filter(
        consumer =>
          consumer.consumerId !== membershipPlanPurchase.primaryConsumerId
      ),
    [membershipPlanPurchase]
  );

  const membersDisplayStatus = useMemo(
    () => determineMembersDisplayStatus(membershipPlanPurchase),
    [membershipPlanPurchase]
  );

  return (
    <>
      <StyledTableRow key={membershipPlanPurchase.id}>
        <TableCell>
          {membershipPlanPurchase.planConsumers.length > 1 && (
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2">
            {membershipPlanPurchase.firstName} {membershipPlanPurchase.lastName}
          </Typography>
          <Typography variant="body3">Primary member</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body2">
            {membershipPlanPurchase.membershipPlanSnapshot.name}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body2">
            {membershipPlanPurchase.planConsumers.length}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2" align="right">
            {convertPriceAndBillingToString(
              membershipPlanPurchase.membershipPlanSnapshot.totalAmount || 0,
              membershipPlanPurchase.membershipPlanSnapshot.billingCycleType
            )}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant="body2"
            align="right"
            color={membersDisplayStatus.color || 'primary.medium'}
          >
            {membersDisplayStatus.status}
          </Typography>
          <Typography variant="body2" align="right" color="primary.medium">
            {!!membersDisplayStatus.expiration
              ? membersDisplayStatus.expiration
              : ''}
          </Typography>
        </TableCell>
        <TableCell align="right" size="small">
          <Button
            sx={{ alignSelf: 'flex-end' }}
            size="small"
            variant="outlined"
            onClick={() => setDrawerOpen(true)}
          >
            Details
          </Button>
        </TableCell>
      </StyledTableRow>

      {planConsumers.map(planConsumer => (
        <StyledTableRow
          key={planConsumer.id}
          sx={{ visibility: open ? 'visible' : 'collapse' }}
        >
          <TableCell />
          <TableCell>
            <Typography variant="subtitle2">
              {planConsumer.consumer?.firstName}{' '}
              {planConsumer.consumer?.lastName}
            </Typography>
          </TableCell>
          <TableCell colSpan={5} />
        </StyledTableRow>
      ))}

      <MemberDetailsDrawer
        title="Member details"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onClickAway={() => setDrawerOpen(true)}
        membershipPlanPurchaseId={membershipPlanPurchase.id}
      />
    </>
  );
});
