import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Verify2FACodeInput } from '@/classes';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import {
  Grid,
  Typography,
  Button,
  InputField,
  Stack,
  Box,
  MessageBanner,
} from '@silverstein-properties/inspirelabs-ui';
import { useEffect, useRef, useState } from 'react';
import { WAIT_TIME_RESEND_CODE } from '@/constants/LoginModuleConstants';

const resolver = classValidatorResolver(Verify2FACodeInput);

class VerifyCodeProps {
  onAuthorize: (code: string) => void;
  mobileNumber: string | undefined;
  warning?: string;
  showEditNumber?: boolean;
  goBack: () => void;
  onResendCode: () => void;
}

export const VerifyCode = ({
  onAuthorize,
  mobileNumber,
  showEditNumber = true,
  warning,
  onResendCode,
  goBack,
}: VerifyCodeProps) => {
  const inputRef = useRef({} as { [key: number]: HTMLInputElement | null });
  const {
    control,
    watch,
    formState: { errors },
    setValue,
  } = useForm<Verify2FACodeInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      data: Array<{ code: string }>(6).fill({ code: '' }),
    },
  });
  const { fields } = useFieldArray({
    control,
    name: 'data',
  });

  const handleBack = (index: number) => {
    inputRef.current[index - 1]?.focus();
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    if (event.key === 'Backspace') {
      handleBack(index);
    }
  };

  const handlePaste = (pastedValue: string) => {
    // get the first 6 characters or all characters if less than 6
    const values =
      pastedValue.length >= 6
        ? pastedValue.split('').slice(0, 6)
        : pastedValue.split('');
    // set the values in the form
    for (let index = 0; index < values.length; index++) {
      setValue(`data.${index}.code`, values[index]);
    }
  };

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (value.data && !!name) {
        const index = Number(name.split('.')[1]);
        const code = value.data.reduce(
          (acc, curr) => acc + (curr?.code || ''),
          ''
        );
        if (code.length === 6 && index === 5) {
          onAuthorize(code);
        } else if (
          Number.isInteger(index) &&
          value.data[index]?.code?.length !== 0
        ) {
          inputRef.current[index + 1]?.focus();
          inputRef.current[index + 1]?.select();
        }
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <Grid item container flexDirection="column" mt={8} gap={4} xs={12}>
      <Typography variant="h4">Verify mobile number</Typography>
      {warning ? <MessageBanner type="error">{warning}</MessageBanner> : null}
      <Grid item container flexDirection="column" gap={4}>
        <Typography variant="subtitle1">
          We sent a 6 digit code to {mobileNumber}
        </Typography>
        <Stack flexDirection="row" gap={1}>
          {fields.map((data, index) => (
            <Controller
              key={data.id}
              control={control}
              name={`data.${index}.code`}
              render={({ field: { ref: fieldRef, onChange, ...rest } }) => {
                return (
                  <Box width={50}>
                    <InputField
                      {...rest}
                      inputRef={ref => {
                        fieldRef(ref);
                        inputRef.current[index] = ref;
                      }}
                      onChange={e => {
                        if (
                          /[0-9]/.test(e.target.value) ||
                          e.target.value === ''
                        ) {
                          onChange(e);
                        }
                      }}
                      fullWidth
                      label=""
                      onKeyUp={e => handleKeyDown(e, index)}
                      onPaste={e =>
                        handlePaste(e.clipboardData.getData('text'))
                      }
                      inputProps={{
                        inputMode: 'numeric',
                        style: { textAlign: 'center' },
                        maxLength: 1,
                      }}
                      error={!!errors.data?.[index]?.code}
                    />
                  </Box>
                );
              }}
            />
          ))}
        </Stack>
        <Stack flexDirection="column" spacing={3}>
          <Box>
            <ResendCodeButton onResendCode={onResendCode} />
          </Box>
          {showEditNumber && (
            <Box>
              <Button variant="text" onClick={goBack}>
                Edit phone number
              </Button>
            </Box>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};

const ResendCodeButtonProps = {
  onResendCode: () => {},
};

const ResendCodeButton = ({ onResendCode }: typeof ResendCodeButtonProps) => {
  const [counter, setCounter] = useState(WAIT_TIME_RESEND_CODE);

  useEffect(() => {
    if (counter <= 0) {
      return;
    }
    const timer = setInterval(() => setCounter(counter - 1), 1000);
    return () => timer && clearInterval(timer);
  }, [counter]);

  return (
    <Button
      variant="text"
      disabled={counter > 0}
      onClick={() => {
        setCounter(60);
        onResendCode();
      }}
    >
      Resend Code {counter ? `(${counter} seconds)` : null}
    </Button>
  );
};
