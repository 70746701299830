import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import { ActionsMenuType } from '@/types';
import { ApiClient } from '@/api/apiClient';
import { AxiosError } from 'axios';
import { useMemberships } from './useMemberships';
import { useState } from 'react';

export const useMerchantMemberships = () => {
  const navigate = useNavigate();
  const [showTerminateAlertDialog, setShowTerminateAlertDialog] =
    useState(false);
  const [showDeleteAlertDialog, setShowDeleteAlertDialog] = useState(false);
  const [membershipId, setMembershipId] = useState('');
  const { data: memberships, refetch, isLoading } = useMemberships();
  const apiClient = ApiClient();

  const terminateMembershipMutation = useMutation(
    async (membershipId: string) => {
      return await apiClient.membershipPlanPurchases.terminateAllMembershipPlansPurchases(
        { membershipId }
      );
    },
    {
      onSuccess: () => {
        refetch();
      },
      onError: (error: AxiosError) => {
        console.error(error);
        //TODO: more robust success/error handling => https://spinspire.monday.com/boards/3397008425/pulses/5353047850
      },
    }
  );

  const deleteMembershipMutation = useMutation(
    async (membershipId: string) => {
      return await apiClient.memberships.deleteMembership({ membershipId });
    },
    {
      onSuccess: () => {
        refetch();
      },
      onError: (error: AxiosError) => {
        console.error(error);
        //TODO: more robust success/error handling => https://spinspire.monday.com/boards/3397008425/pulses/5353047850
      },
    }
  );

  const handleTerminatedAlertDialogClose = async (value: boolean) => {
    // if the user clicks Accept in the confirmation dialog
    if (value) {
      terminateMembershipMutation.mutate(membershipId);
    }
    setMembershipId('');
    setShowTerminateAlertDialog(false);
  };

  const handleDeleteAlertDialogClose = async (value: boolean) => {
    // if the user clicks Accept in the confirmation dialog
    if (value) {
      deleteMembershipMutation.mutate(membershipId);
    }
    setMembershipId('');
    setShowDeleteAlertDialog(false);
  };

  const onClickMenuItem = (type: ActionsMenuType, membershipId: string) => {
    switch (type) {
      case ActionsMenuType.EDIT_MEMBERSHIP:
        navigate(`${membershipId}`);
        break;
      case ActionsMenuType.DUPLICATE_MEMBERSHIP:
        console.log('Not implemented yet');
        break;
      case ActionsMenuType.DELETE_MEMBERSHIP:
        setMembershipId(membershipId);
        setShowDeleteAlertDialog(true);
        break;
      case ActionsMenuType.TERMINATE_MEMBERSHIP:
        setMembershipId(membershipId);
        setShowTerminateAlertDialog(true);
        break;
      default:
        throw new Error(`Unknown action type: ${type}`);
    }
  };

  return {
    memberships,
    isLoading,
    refetch,
    onClickMenuItem,
    terminateMembershipMutation,
    showTerminateAlertDialog,
    setShowTerminateAlertDialog,
    showDeleteAlertDialog,
    handleDeleteAlertDialogClose,
    handleTerminatedAlertDialogClose,
  };
};
