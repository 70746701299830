import { useState } from 'react';
import { HookFormTextInput } from 'components/FormTextInput/HookFormTextInput';
import HookFormSubmitButton from 'components/RoundButton/HookFormSubmitButton';
import { HookFormTextArea } from 'components/FormTextInput/HookFormTextArea';
import { TEXT_AREA_LIMIT_LONG } from '@/constants';
import { useAuth } from 'hooks';
import { useForm } from 'react-hook-form';
import { MessageBanner } from '@silverstein-properties/inspirelabs-ui';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { ContactUsFormInput } from '@/classes/ContactUsFormInput';
import { CONTACT_FORM_RECIPIENT } from '@/constants';

export const ContactUsForm = () => {
  const [success, setSuccess] = useState<string>('');
  const { data: user } = useAuth();
  const resolver = classValidatorResolver(ContactUsFormInput);
  const {
    register,
    formState: { dirtyFields, errors, isValid },
  } = useForm<ContactUsFormInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
      phone: user?.phone || '',
      company: '',
      question: '',
    },
  });

  const onSubmit = () => {
    setSuccess('Form submitted successfully!');
  };

  return (
    <div id="contact-us" className="container align-sec">
      <div className="row">
        <div className="top-64">
          <h4 className="text-center bottom-55">Contact us</h4>
        </div>

        <form
          action={`https://formsubmit.co/${CONTACT_FORM_RECIPIENT}`}
          method="POST"
          onSubmit={onSubmit}
          target="_blank"
          autoComplete="off"
          className="align-sec d-flex justify-content-center"
        >
          {success && (
            <MessageBanner type="success" sx={{ width: '384px' }}>
              {success}
            </MessageBanner>
          )}

          <div className="pt-0">
            <HookFormTextInput
              error={errors.firstName}
              isFilled={
                Object.keys(dirtyFields).includes('firstName') ||
                !!user?.firstName
              }
              name="firstName"
              label="First Name"
              width="form-xl"
              register={register}
            />
          </div>
          <div className="pt-0">
            <HookFormTextInput
              error={errors.lastName}
              isFilled={
                Object.keys(dirtyFields).includes('lastName') ||
                !!user?.lastName
              }
              name="lastName"
              label="Last Name"
              width="form-xl"
              register={register}
            />
          </div>
          <div className="pt-0">
            <HookFormTextInput
              error={errors.email}
              isFilled={
                Object.keys(dirtyFields).includes('email') || !!user?.email
              }
              name="email"
              label="Email"
              width="form-xl"
              register={register}
            />
          </div>

          <div className="pt-0">
            <HookFormTextInput
              error={errors.phone}
              isFilled={
                Object.keys(dirtyFields).includes('phone') || !!user?.phone
              }
              name="phone"
              label="Phone"
              width="form-xl"
              register={register}
            />
          </div>
          <div className="pt-0">
            <HookFormTextInput
              error={errors.company}
              isFilled={Object.keys(dirtyFields).includes('company')}
              name="company"
              label="Business Name (optional)"
              width="form-xl"
              register={register}
            />
          </div>
          <div className="pt-0">
            <HookFormTextArea
              error={errors.question}
              isFilled={Object.keys(dirtyFields).includes('question')}
              size="form-xl"
              name="question"
              label="What's your question?"
              limit={TEXT_AREA_LIMIT_LONG}
              register={register}
            />
          </div>
          <input
            type="hidden"
            name="_next"
            value={`${process.env.REACT_APP_BASE_URL}/formsubmit`}
          />
          <input type="hidden" name="_captcha" value="false" />
          <input
            type="hidden"
            name="loggedIn"
            value={!!user ? 'true' : 'false'}
          />
          <div className="pt-0 submit-btn-contact">
            <HookFormSubmitButton
              default="blue-baby-blue"
              hover="white-navy"
              text="Submit"
              disabled={!isValid}
            />
          </div>
        </form>
        <div className="top-96"></div>
      </div>
    </div>
  );
};
