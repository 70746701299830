import { routes } from '@/routing/routes';
import {
  Box,
  BoxProps,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import { Link } from 'react-router-dom';

interface HubLogoProps extends BoxProps {
  width?: number;
  height?: number;
  link?: string;
}

const HubLogo = (props: HubLogoProps) => {
  const { width = 94, height = 40, link = routes.home, ...restOfProps } = props;

  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;

  const svgContent = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 94 40"
      fill="none"
    >
      <path
        d="M18.7556 39.5829V8.70361H24.1804V22.4741H37.4889V8.70361H42.9136V39.5829H37.4889V27.5635H24.1804V39.5829H18.7556Z"
        fill={primaryColor}
      />
      <path
        d="M54.6872 40.0002C53.1 40.0002 51.7214 39.6425 50.559 38.9346C49.3891 38.2267 48.5024 37.2133 47.8913 35.8869C47.2803 34.568 46.9748 32.9883 46.9748 31.1477V17.5859H52.1089V30.0598C52.1089 31.2297 52.2878 32.2208 52.6529 33.0404C53.0106 33.8601 53.5396 34.4935 54.2401 34.9406C54.933 35.3877 55.7676 35.6112 56.7438 35.6112C57.4666 35.6112 58.1298 35.492 58.7259 35.2535C59.322 35.0151 59.8436 34.6872 60.2907 34.255C60.7378 33.8229 61.0806 33.3087 61.3339 32.7126C61.5873 32.1164 61.7065 31.4682 61.7065 30.7752V17.5859H66.8407V39.5755H61.8332L61.7752 36.3277C61.5421 36.6696 61.276 36.9946 60.9763 37.3027C60.1864 38.1224 59.2475 38.7781 58.1596 39.2625C57.0717 39.7468 55.9167 39.9928 54.6946 39.9928L54.6872 40.0002Z"
        fill={primaryColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.2012 39.5606C81.1327 39.8511 82.0865 40.0002 83.0626 40.0002C84.9553 40.0002 86.6543 39.5084 88.1744 38.5173C89.6945 37.5337 90.8793 36.1775 91.7437 34.4488C92.6081 32.7275 93.0403 30.723 93.0403 28.5248C93.0403 26.3266 92.6081 24.3594 91.7288 22.6381C90.8495 20.9167 89.6498 19.568 88.1371 18.5918C86.617 17.6157 84.8734 17.1313 82.8987 17.1313C82.0045 17.1313 81.1327 17.2804 80.2683 17.571C79.4039 17.8616 78.614 18.2565 77.8912 18.7409C77.161 19.2252 76.5574 19.7692 76.0731 20.3653C76.0656 20.3747 76.0581 20.384 76.0507 20.3933V8.70361H70.9166V39.5754H75.8793L75.9438 36.8545C76.4173 37.4103 76.9944 37.9167 77.6752 38.3683C78.4278 38.875 79.2698 39.2699 80.2012 39.5606ZM85.1044 34.5978C84.1878 35.1939 83.1446 35.492 81.9747 35.492H81.9673C80.7676 35.492 79.7094 35.1939 78.7929 34.5978C77.8763 34.0017 77.1535 33.182 76.6245 32.1388C76.0954 31.0956 75.8346 29.8661 75.8346 28.5322C75.8346 27.1984 76.0954 26.0136 76.6245 24.9853C77.1535 23.957 77.8763 23.1448 78.7929 22.5635C79.7169 21.9748 80.7676 21.6843 81.9673 21.6843C83.167 21.6843 84.2251 21.9823 85.1416 22.5635C86.0582 23.1522 86.7735 23.957 87.2877 24.9853C87.8018 26.0136 88.0626 27.1686 88.0626 28.5322C88.0626 29.8959 87.8018 31.0956 87.2728 32.1388C86.7437 33.182 86.0209 34.0017 85.1044 34.5978Z"
        fill={primaryColor}
      />
      <path
        d="M3.42798 12.2184H12.2766V8.81494H14.6914V18.4911H12.2766V15.0063H3.42798V12.2184Z"
        fill={primaryColor}
      />
      <path
        d="M3.42798 19.6353H14.6914V22.4173H10.2671V26.7384H14.6914V29.5205H3.42798V26.7384H7.81805V22.4173H3.42798V19.6353Z"
        fill={primaryColor}
      />
      <path
        d="M14.6914 31.5707H3.42798V39.667H5.76288V34.2714H8.0464V37.674H10.2671V34.2714H12.3565V39.667H14.6914V31.5707Z"
        fill={primaryColor}
      />
      <path
        opacity="0.4"
        d="M51.9099 8.81494H47.0127V13.7121H51.9099V8.81494Z"
        fill={primaryColor}
      />
      <path
        opacity="0.4"
        d="M66.6013 8.81494H61.7041V13.7121H66.6013V8.81494Z"
        fill={primaryColor}
      />
      <path
        opacity="0.4"
        d="M23.996 0H18.6091V4.89716H23.996V0Z"
        fill={primaryColor}
      />
      <path
        opacity="0.4"
        d="M42.6054 0H37.2185V4.89716H42.6054V0Z"
        fill={primaryColor}
      />
      <path
        opacity="0.4"
        d="M76.0506 0H70.9165V4.84352H76.0506V0Z"
        fill={primaryColor}
      />
    </svg>
  );

  return (
    <Link to={link}>
      {/* Added negative marginTop due to current implementation of NavBar */}
      <Box sx={{ marginTop: `-${theme.spacers.xs}` }} {...restOfProps}>
        {svgContent}
      </Box>
    </Link>
  );
};

export default HubLogo;
