import { BusinessSignupComponentProps } from './Component';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useFormData } from '../../contexts/MerchCreationProvider';
import { HookFormTextInput } from '../FormTextInput/HookFormTextInput';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { Box, Button, Svg } from '@silverstein-properties/inspirelabs-ui';
import { GroupSizeInput } from '@/classes/GroupSizeInput';

const resolver = classValidatorResolver(GroupSizeInput);

export const GroupSize = (props: BusinessSignupComponentProps) => {
  const { setFormValues, merchandiseData } = useFormData();
  const {
    register,
    formState: { dirtyFields, errors },
    handleSubmit,
  } = useForm<GroupSizeInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      capacityMin: '0', // hardcoded to zero while we decide if this is needed
      capacityMax: merchandiseData.capacityMax || '',
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Disabled due to current implementation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (data: any) => {
    // removes leading 000
    data['capacityMax'] = parseInt(data['capacityMax']);
    setFormValues(data);
    props.updateExperience(data);
    props.nextPage();
  };

  return (
    <div className="row ms-xl-5 ms-lg-5 me-md-5 ms-sm-1 ms-xs-1 top-64 d-flex justify-content-end">
      <div className="col-8">
        <div className="business-form-headline mb-5">Group size</div>
        <div className="business-form-subtitle mb-3">
          What's the capacity for this service?
        </div>
        <div className="theme-form">
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <HookFormTextInput
              isFilled={
                Object.keys(dirtyFields).includes('capacityMax') ||
                !!merchandiseData.capacityMax
              }
              register={register}
              name="capacityMax"
              testid="groupSize.capacityMax"
              label="Maximum Size"
              type="text"
              width="form-xl"
              error={errors.capacityMax}
            />
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                // marginTop: theme.spacers.xl,
              }}
            >
              <Button
                variant="text"
                onClick={props.prevPage}
                startIcon={
                  <Svg src="/images/chevron-left.svg" height={24} width={24} />
                }
              >
                Back
              </Button>
              <Button type="submit">Continue</Button>
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
};
