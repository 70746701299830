import inspiretext from 'components/PhotoGuidelines/inspiretext.svg';
import xBtn from 'components/PhotoGuidelines/icon-close-circle.svg';
import checkMark from 'components/PhotoGuidelines/checkmark.svg';
import errorMark from 'components/PhotoGuidelines/errorMark.svg';

import './PhotoGuidelines.css';

export const PhotoGuidelines = () => {
  return (
    <div
      className="container-fluid bg-secondary headings-line-height "
      style={{ border: '1px solid transparent' }} // A super weird fix for (CSS + Bootstrap) white space
    >
      <div className="row pt-5 px-5 mx-5 d-flex justify-content-center photo-font">
        <div></div>
        <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
          <h3 className="image-requirements-title">Image requirements</h3>
          <div className="top-80">
            <h4 className="image-requirements-subtitle">Prepare your image</h4>
            <div className="mb-2">
              Your images are an important part of how your experience is shown
              to potential customers, and each image will be shown in different
              contexts, from small thumbnails on a mobile app, to full screen
              hero images on a desktop.
            </div>
            <div className="mt-5">
              <p className="fw-bold lh-lg image-requirements-sub-subtitle">
                Aspect ratio
              </p>
              <div className="navy-box">
                <div className="text-white text-center">
                  3 x 2 <br />
                  aspect ratio
                </div>
              </div>
              <div className="mt-5">
                <p className="fw-bold mt-5 lh-lg image-requirements-sub-subtitle">
                  Recommeded dimensions
                </p>
                Ideally, your images are high resolution, around 1200 x 800
                pixels. This way, it will look sharp on retina display phone
                screens.
              </div>

              <div className="mt-5">
                <p className="fw-bold mt-5 lh-lg image-requirements-sub-subtitle">
                  Compression/File Types
                </p>
                For best results, upload your photos as JPEGs, compressed at a
                quality of 50-70%.
                <br />
                <br />
                If you're uploading brand marks or graphic art instead of
                photographs, you'll get best results upload them as PNG files.
              </div>

              <div className="mt-5">
                <p className="fw-bold mt-5 lh-lg image-requirements-sub-subtitle">
                  Choosing photography
                </p>
                It's important to pick images that set the right tone and look
                professional and respectful.
                <br />
                <br />
                The images should capture key characteristics in terms of mood,
                perspective, depth and space. Background should be on the dark
                side to support a nice contrast between the hero image and the
                page content.
              </div>

              <div className="mt-5 mb-3">
                <p className="fw-bold mt-5 lh-lg image-requirements-sub-subtitle">
                  Using brandmarks
                </p>
                We think photographs are better than brandmarks in describing
                your service, but if you want to upload one, please use it over
                nonwhite background, and place the graphic is within the middle
                of the image.
              </div>
              <div className="row">
                <div className=" col-11 text-center">1200px</div>

                <div className="navy-box-brand">
                  <div className="row text-white">
                    <div className="col-2 sideways-text text-white px-0"></div>
                    <div className="col-10 text-white mt-3">600px</div>
                    <div className="col-2 sideways-text text-white px-0"></div>

                    <div className="col-8 border-dashed border-green-300 border-3 px-0">
                      <div className="text-white text-center py-5 px-0">
                        Brandmark
                        <br />
                        safe area
                      </div>
                    </div>
                    <div className="col-1 sideways-text text-white px-0">
                      600px
                    </div>
                  </div>
                </div>
                <div className="col-1">
                  <p className="text-center sideways-text ">800px</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-1"></div>
        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
          <div className="text-end mt-2">
            <img src={inspiretext} alt="Inspire Labs" className="mb-3" />
          </div>
          <h4 className="top-80 image-requirements-subtitle">
            Keep it flexible!{' '}
          </h4>
          <div className="mb-4">
            Your images will be seen in different contexts, and rendered as
            either squares or rectangles, depending on what device your users
            are using.
            <br />
            <br />
            To have your images look their best, they should have a
            3:2(rectangle) aspect ratio, with the essential part of the image in
            the center.
            <br />
          </div>
          <div className="row imageHolder">
            <div className="col-1 overlay-left d-flex justify-content-start"></div>
            <div className="col d-flex justify-content-center">
              <div className="photo-text">
                Photography
                <br />
                safe area
              </div>
            </div>
            <div className="col-1 overlay-right d-flex justify-content-end"></div>
          </div>
          <div className="row">
            <div className="col-1 imageHolder-small mt-5"></div>
            <div className="col-3 mt-5 py-3 fw-bold">
              Aesop
              <p className="fw-light subtitle mt-2">10% off first purchase</p>
            </div>
            <div className="col-xl-4 col-lg-3 d-lg-block d-md-none d-sm-none mt-5 py-3">
              <hr className="grey-line"></hr>
            </div>
            <div className="col-2 mt-5 py-3">
              <p className="fw-light">Mini-Card thumbnail</p>
            </div>
          </div>

          <div className="row">
            <div className="col-4 imageHolder-semi-top mt-5">
              <div className="label-btn">Beauty</div>
            </div>
            <div className="col-xl-2 col-lg-1 d-lg-block d-md-none d-sm-none mt-5 py-3">
              <hr className="grey-line"></hr>
            </div>
            <div className="col-2 mt-5 py-3">
              <p className="fw-light">Carousel-Card thumbnail</p>
            </div>
          </div>
          <div className="fw-bold mt-2">
            <p className="fw-light subtitle mt-2">10% off first purchase</p>
            Aesop
          </div>

          <div className="row">
            <div className="col-2 imageHolder-md mt-5">
              <div className="label-btn">Beauty</div>
            </div>
            <div className="col-xl-1 d-xl-block d-lg-none d-md-none d-sm-none mt-5 py-3">
              <hr className="grey-line"></hr>
            </div>
            <div className="col-1 mt-5 py-3">
              <p className="fw-light">Full-Card thumbnail</p>
            </div>
          </div>
          <div className="fw-bold mt-2">
            <p className="fw-light subtitle mt-2">The Oculus</p>
            <h5 className="mt-2">Aesop</h5>
            <p className="fw-light">10% off first purchase</p>
          </div>

          <div className="row">
            <div className="col-4 imageHolder-full mt-5">
              <div className="X-btn">
                <img src={xBtn} alt="Close" className="mb-5" />
              </div>
              <div className="label-btn-low">Beauty</div>
            </div>
            <div className="col-xl-1 d-xl-block d-lg-none d-md-none d-sm-none mt-5 py-3">
              <hr className="grey-line"></hr>
            </div>
            <div className="col-2 mt-5 py-3">
              <p className="fw-light">Full-Screen Hero product page</p>
            </div>
          </div>
          <div className="details-main bg-white row">
            <div className="fw-bold mt-2 col-4">
              <p className="fw-light subtitle mt-2">The Oculus</p>
              <h3 className="mt-2">Aesop</h3>
              <p className="fw-light">10% off first purchase</p>
            </div>

            <div className="mt-2 py-2">
              <hr className="grey-line"></hr>
            </div>

            <h4 className="my-3">About</h4>
            <div className="mt-2 mx-1">
              Original image example 1200 x 800px
              <br />
              Safe area: 800 x 800px in the center of the image should capture
              the main characteristics of the photo.
            </div>

            <div className="mt-2 py-2">
              <hr className="grey-line"></hr>
            </div>

            <h4 className="my-3">Promotion info</h4>
            <div className="my-2 mx-1">
              We're proud to offer employees throughout the World Trade Center
              campus a discount to select retailers at Westfield World Trade
              Center and Fulton Center — just show your Silverstein Properties
              badge when you check out.
            </div>
            <div className="mt-5 py-3"></div>
          </div>
        </div>
      </div>
      <div className="row pt-5 px-5 mx-5 photo-font">
        <h1 className="ps-0">Please Do!</h1>

        <div className="col-2 imageHolder-small mt-5"></div>
        <div className="col-xl-2 col-lg-1 d-lg-block d-md-none d-sm-none mt-5"></div>

        <div className="col-4 imageHolder-semi mt-5">
          <div className="label-btn">Beauty</div>
          <div className="fw-bold mt-2">
            <p className="fw-light subtitle-photo top-203">The Oculus</p>
            <h5 className="mt-2">Aesop</h5>
            <p className="fw-light">10% off first purchase</p>
          </div>
        </div>

        <div className="col-xl-2 col-lg-1 d-lg-block d-md-none d-sm-none mt-5"></div>

        <div className="col-5 imageHolder-full mt-5 px-0">
          <div className="X-btn">
            <img src={xBtn} alt="Close" className="mb-5" />
          </div>
          <div className="label-btn-low">Beauty</div>
          <div className="bg-white details-grey">
            <p className="fw-light subtitle-photo mt-2">The Oculus</p>
            <h3 className="mt-2">Aesop</h3>
            <p className="fw-light mb-5">10% off first purchase</p>
          </div>
        </div>
      </div>
      <div className="row row pt-5 px-5 m-5 photo-font">
        <div className="col-1">
          <div className="checkMark d-inline-block">
            <img src={checkMark} alt="Checkmark" className="mb-5" />
          </div>
        </div>
        <div className="col-xl-4 col-lg-2 ms-1 d-inline-block">
          Use images that can be will look still look good if cropped into a
          square shape.
        </div>

        <div className="col-xl-2 col-lg-3 mt-5"></div>
      </div>

      <div className="row pt-5 px-5 mx-5 photo-font">
        <div className="col-2 imageTea-small mt-5"></div>
        <div className="col-xl-2 col-lg-1 d-lg-block d-md-none d-sm-none mt-5"></div>

        <div className="col-4 imageTea-semi mt-5">
          <div className="label-food-btn">Food & Beverage</div>
          <div className="fw-bold mt-2">
            <p className="fw-light subtitle-photo top-203">The Oculus</p>
            <h5 className="mt-2">Art of Tea</h5>
            <p className="fw-light">10% off first purchase</p>
          </div>
        </div>

        <div className="col-xl-2 col-lg-1 d-lg-block d-md-none d-sm-none mt-5"></div>

        <div className="col-5 imageTea-full mt-5 px-0">
          <div className="X-btn">
            <img src={xBtn} alt="Close" className="mb-5" />
          </div>
          <div className="label-food-btn-low">Food & Beverage</div>

          <div className="bg-white details-grey">
            <p className="fw-light subtitle mt-2">The Oculus</p>
            <h3 className="mt-2">Art of Tea</h3>
            <p className="fw-light mb-5">10% off first purchase</p>
          </div>
        </div>
      </div>

      <div className="row row pt-5 px-5 m-5 photo-font">
        <div className="col-1">
          <div className="checkMark d-inline-block">
            <img src={checkMark} alt="Checkmark" className="mb-5" />
          </div>
        </div>
        <div className="col-xl-4 col-lg-2 ms-1 d-inline-block">
          Use brandmarks on dark or colored backgrounds where the brand doesn't
          take up too much of the image.
        </div>

        <div className="col-2 mt-5"></div>
      </div>

      <div className="row pt-5 px-5 mx-5 photo-font">
        <h1 className="ps-0">Please Don't!</h1>
        <div className="col-2 imageYoga-small mt-5"></div>
        <div className="col-xl-2 col-lg-1 d-lg-block d-md-none d-sm-none mt-5"></div>

        <div className="col-4 imageYoga-semi mt-5">
          <div className="label-mind-btn">Mindfullness</div>
          <div className="fw-bold mt-2">
            <p className="fw-light subtitle-photo top-203">
              The Studio · 10th Fl. of WTC 7, NYC
            </p>
            <h5 className="mt-2">Vinyasa Flow</h5>
            <p className="fw-light">Frank Orlando · 45 Min</p>
          </div>
        </div>

        <div className="col-xl-2 col-lg-1 d-lg-block d-md-none d-sm-none mt-5"></div>

        <div className="col-5 imageYoga-full mt-5 px-0">
          <div className="X-btn">
            <img src={xBtn} alt="Close" className="mb-5" />
          </div>
          <div className="label-mind-btn-low">Mindfulness</div>

          <div className="bg-white details-white">
            <p className="fw-light subtitle mt-2">
              The Studio · 10th Fl. of WTC 7, NYC
            </p>
            <h3 className="mt-2">Vinyasa Flow</h3>
            <p className="fw-light mb-5">Frank Orlando · 45 Min</p>
          </div>
        </div>
      </div>

      <div className="row row pt-5 px-5 m-5 photo-font">
        <div className="col-1">
          <div className="checkMark d-inline-block">
            <img src={errorMark} alt="Errormark" className="mb-5" />
          </div>
        </div>
        <div className="col-xl-4 col-lg-2 ms-1 d-inline-block">
          Do not use images where the subject of the image is not centered,
          otherwise an important part of your image may be cropped out when
          displayed on certain screens.
        </div>

        <div className="col-2 mt-5"></div>
      </div>

      <div className="row pt-5 px-5 mx-5 photo-font">
        <div className="col-2 imageStudio-small mt-5"></div>
        <div className="col-xl-2 col-lg-1 d-lg-block d-md-none d-sm-none mt-5"></div>

        <div className="col-4 imageStudio-semi mt-5">
          <div className="label-mind-btn">Mindfullness</div>
          <div className="fw-bold mt-2">
            <p className="fw-light subtitle-photo top-203">
              The Studio · 10th Fl. of WTC 7, NYC
            </p>
            <h5 className="mt-2">Vinyasa Flow</h5>
            <p className="fw-light">Frank Orlando · 45 Min</p>
          </div>
        </div>

        <div className="col-xl-2 col-lg-1 d-lg-block d-md-none d-sm-none mt-5"></div>

        <div className="col-5 imageStudio-full mt-5 px-0">
          <div className="X-btn">
            <img src={xBtn} alt="Close" className="mb-5" />
          </div>
          <div className="label-mind-btn-low">Mindfullness</div>

          <div className="bg-white details-white">
            <p className="fw-light subtitle mt-2">
              The Studio · 10th Fl. of WTC 7, NYC
            </p>
            <h3 className="mt-2">Vinyasa Flow</h3>
            <p className="fw-light mb-5">Frank Orlando · 45 Min</p>
          </div>
        </div>
      </div>

      <div className="row row pt-5 px-5 m-5 photo-font">
        <div className="col-1">
          <div className="checkMark d-inline-block">
            <img src={errorMark} alt="Errormark" className="mb-5" />
          </div>
        </div>
        <div className="col-xl-4 col-lg-2 ms-1 d-inline-block">
          Do not use images that have cluttered backgrounds or low contrast.
        </div>

        <div className="col-2 mt-5"></div>
      </div>

      <div className="row pt-5 px-5 mx-5 photo-font">
        <div className="col-2 imageGreen-small mt-5"></div>
        <div className="col-xl-2 col-lg-1 d-lg-block d-md-none d-sm-none mt-5"></div>

        <div className="col-4 imageGreen-semi mt-5">
          <div className="label-mind-btn">Mindfullness</div>
          <div className="fw-bold mt-2">
            <p className="fw-light subtitle-photo top-203">
              The Studio · 10th Fl. of WTC 7, NYC
            </p>
            <h5 className="mt-2">Vinyasa Flow</h5>
            <p className="fw-light">Frank Orlando · 45 Min</p>
          </div>
        </div>

        <div className="col-xl-2 col-lg-1 d-lg-block d-md-none d-sm-none mt-5"></div>

        <div className="col-5 imageGreen-full mt-5 px-0">
          <div className="X-btn">
            <img src={xBtn} alt="Close" className="mb-5" />
          </div>
          <div className="label-mind-btn-low">Mindfullness</div>

          <div className="bg-white details-white">
            <p className="fw-light subtitle mt-2">
              The Studio · 10th Fl. of WTC 7, NYC
            </p>
            <h3 className="mt-2">Vinyasa Flow</h3>
            <p className="fw-light mb-5">Frank Orlando · 45 Min</p>
          </div>
        </div>
      </div>

      <div className="row row pt-5 px-5 m-5 photo-font">
        <div className="col-1">
          <div className="checkMark d-inline-block">
            <img src={errorMark} alt="Errormark" className="mb-5" />
          </div>
        </div>
        <div className="col-xl-4 col-lg-2 ms-1 d-inline-block">
          Do not use images that are masked on a white background. It might look
          good on its own, but will look awkward when viewed on the app.
        </div>

        <div className="col-2 mt-5"></div>
      </div>

      <div className="row pt-5 px-5 mx-5 photo-font">
        <div className="col-2 imageCaudalie-small mt-5"></div>
        <div className="col-xl-2 col-lg-1 d-lg-block d-md-none d-sm-none mt-5"></div>

        <div className="col-4 imageCaudalie-semi mt-5">
          <div className="label-btn">Beauty</div>
          <div className="fw-bold mt-2">
            <p className="fw-light subtitle-photo top-203">The Oculus, NYC</p>
            <h5 className="mt-2">Caudalie</h5>
            <p className="fw-light">10% off first purchase</p>
          </div>
        </div>

        <div className="col-xl-2 col-lg-1 d-lg-block d-md-none d-sm-none mt-5"></div>

        <div className="col-5 imageCaudalie-full mt-5 px-0">
          <div className="X-btn">
            <img src={xBtn} alt="Close" className="mb-5" />
          </div>
          <div className="label-btn-low">Beauty</div>
          <div className="bg-white details-white">
            <p className="fw-light subtitle mt-2">
              The Studio · 10th Fl. of WTC 7, NYC
            </p>
            <h3 className="mt-2">Caudalie</h3>
            <p className="fw-light mb-5">Frank Orlando · 45 Min</p>
          </div>
        </div>
      </div>

      <div className="row row pt-5 px-5 m-5 photo-font">
        <div className="col-1">
          <div className="checkMark d-inline-block">
            <img src={errorMark} alt="Errormark" className="mb-5" />
          </div>
        </div>
        <div className="col-xl-4 col-lg-2 ms-1 d-inline-block">
          Do not use brandmarks on a white background - it might look good on
          its own, but will look awkward when viewed on the app.
          <br />
          Also, please make sure to have the brandmark within the safe area, so
          it will still look good if cropped into a square shape.
        </div>

        <div className="col-2 mt-5"></div>
      </div>

      <div className="row pt-5 px-5 mx-5 photo-font">
        <div className="col-2 imageCovid-small mt-5"></div>
        <div className="col-xl-2 col-lg-1 d-lg-block d-md-none d-sm-none mt-5"></div>

        <div className="col-4 imageCovid-semi mt-5">
          <div className="label-btn">Health</div>
          <div className="fw-bold mt-2">
            <p className="fw-light subtitle-photo top-203">The Oculus, NYC</p>
            <h5 className="mt-2">Covid Testing Center</h5>
            <p className="fw-light">Open 9am-5pm</p>
          </div>
        </div>

        <div className="col-xl-2 col-lg-1 d-lg-block d-md-none d-sm-none mt-5"></div>

        <div className="col-5 imageCovid-full mt-5 px-0">
          <div className="X-btn">
            <img src={xBtn} alt="Close" className="mb-5" />
          </div>
          <div className="label-btn-low">Health</div>

          <div className="bg-white details-white">
            <p className="fw-light subtitle mt-2">The Oculus, NYC</p>
            <h3 className="mt-2">Covid Testing Center</h3>
            <p className="fw-light mb-5">Open 9am-5pm</p>
          </div>
        </div>
      </div>

      <div className="row row pt-5 px-5 m-5 photo-font">
        <div className="col-1">
          <div className="checkMark d-inline-block">
            <img src={errorMark} alt="Errormark" className="mb-5" />
          </div>
        </div>
        <div className="col-xl-4 col-lg-2 ms-1 d-inline-block">
          Please do not use any unprofessional photography, and refrain from
          using copy on the images.
        </div>

        <div className="col-2 mt-5"></div>
      </div>
    </div>
  );
};
