import { BusinessSignupComponentProps } from './Component';
import { useEffect } from 'react';
import {
  styles,
  errorStyles,
  customValueContainer,
} from 'components/FormTextInput/HookFormSelect';
import Select, { components, InputProps } from 'react-select';

import { useForm, Controller } from 'react-hook-form';
import { useFormData } from '../../contexts/MerchCreationProvider';
import { EXPERIENCE_YEARS_CHOICES } from '@/constants';
import { Box, Button, Svg } from '@silverstein-properties/inspirelabs-ui';

export function ExperienceYears(props: BusinessSignupComponentProps) {
  const { setFormValues, merchandiseData } = useFormData();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      hostOperatingYears: merchandiseData.hostOperatingYears || '',
    },
  });

  const yearsExp: React.FC<InputProps> = props => {
    return <components.Input {...props} data-testid="yearsExp" />;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Disabled due to current implementation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (data: any) => {
    setFormValues(data);
    props.updateExperience(data);
    props.nextPage();
  };

  return (
    <div className="row ms-xl-5 ms-lg-5 me-md-5 ms-sm-1 ms-xs-1 top-64 d-flex justify-content-end">
      <div className="col-8">
        <h4 className="business-form-headline mb-5">
          Tell us about your experience
        </h4>
        <div className="theme-form">
          <div className="business-form-subtitle mb-4">
            When did you begin providing this service?
          </div>
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="hostOperatingYears"
              control={control}
              rules={{
                required: { value: true, message: 'Please enter a value' },
              }}
              render={({ field }) => (
                <div data-testid="experienceYears.years">
                  <Select
                    {...field}
                    inputId="operating-years-dropdown"
                    data-testid="experienceYears.years"
                    className="yearsTest"
                    isSearchable={false}
                    options={EXPERIENCE_YEARS_CHOICES}
                    styles={!!errors.hostOperatingYears ? errorStyles : styles}
                    components={{
                      ValueContainer: customValueContainer,
                      Input: yearsExp,
                    }}
                    placeholder="Year started"
                  />
                </div>
              )}
            />
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                // marginTop: theme.spacers.xl,
              }}
            >
              <Button
                variant="text"
                onClick={props.prevPage}
                startIcon={
                  <Svg src="/images/chevron-left.svg" height={24} width={24} />
                }
              >
                Back
              </Button>
              <Button type="submit">Continue</Button>
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
}
