import { InputField, styled } from '@silverstein-properties/inspirelabs-ui';

export const LeftStyledInputField = styled(InputField)(() => ({
  '& fieldset': {
    borderRightColor: 'transparent',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
}));

export const RightStyledInputField = styled(InputField)(() => ({
  '& fieldset': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
}));

export const StyledTable = styled('table')(({ theme }) => ({
  '& tr > td:first-of-type': {
    paddingRight: theme.spacers.m,
  },
  '& tr > td:last-of-type': {
    textAlign: 'right',
  },
  '& tr:last-of-type': {
    borderTop: `1px solid ${theme.palette.common.white}`,
  },
}));
