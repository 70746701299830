import { Theme } from '@silverstein-properties/inspirelabs-ui';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const getStyles = (
  buildingId: string,
  buildingIds: readonly string[],
  theme: Theme
) => ({
  fontWeight:
    buildingIds.indexOf(buildingId) === -1
      ? theme.typography.fontWeightLight
      : theme.typography.fontWeightMedium,
});
