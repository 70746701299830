import { MembershipPlan } from '@/types/MerchantMembership';
import { formatPricing, pluralize } from '@/utils';
import {
  Grid,
  Typography,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';

export const MembershipPlanRow = ({
  name,
  maxNbOfConsumers,
  totalAmount: guestPrice,
  price,
  billingCycleType,
  hasMembers,
  publishStatus,
  membersCount,
}: MembershipPlan) => {
  const theme = useTheme();
  return (
    <Grid container item alignItems="center" padding={2} xs={12}>
      <Grid item xs={2}>
        {/* Plan Name */}
        <Typography variant="subtitle2">{name}</Typography>
      </Grid>
      <Grid item xs={2}>
        {/* guestPrice per billing cycle & quatity of members */}
        <Typography variant="body2">
          ${guestPrice}/{billingCycleType}
        </Typography>
        <Typography variant="body2">
          per {pluralize(maxNbOfConsumers, 'member')}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        {/* Plan statuses */}
        {/* need to colour accordingly */}
        <Typography
          variant="body2"
          color={
            hasMembers
              ? theme.palette.success.main
              : theme.palette.primary.medium
          }
        >
          {hasMembers ? 'Active' : 'Inactive'}
        </Typography>
        <Typography
          variant="body2"
          color={
            publishStatus === 'published'
              ? theme.palette.success.main
              : theme.palette.primary.medium
          }
        >
          {publishStatus === 'published' ? 'Published' : 'Unpublished'}
        </Typography>
      </Grid>
      <Grid item xs={2} textAlign="right">
        {/* total active members for a plan */}
        <Typography variant="body2">
          {membersCount?.totalActiveMembers || 0}
        </Typography>
      </Grid>
      <Grid item xs={2} textAlign="right">
        {/* total income for a plan */}
        <Typography variant="body2">
          {formatPricing((membersCount?.totalActivePlans || 0) * price)}
        </Typography>
      </Grid>
    </Grid>
  );
};
