// Lint error disabled to allow for comparing any objects in functions below
/* eslint-disable @typescript-eslint/no-explicit-any */

/*

IMPORTANT

Cryptic functions ahead.

If something changes in this file in a PR, triple check the changes, and make sure new test scenarios are added.
Chances are high changes will introduce bugs.

*/

import { PropertyObject, SelectionType } from '@/types';
import {
  isArray,
  isEqual,
  isNaN,
  isNil,
  isEmpty,
  isObject,
  mergeWith,
  omitBy,
  transform,
  cloneDeepWith,
  result,
  PropertyPath,
} from 'lodash';

/**
 * Deeply traverses through original object and compares with the
 * new object to find changes in values
 *
 * @param origObj The original object.
 * @param newObj The new object.
 * @returns Returns the new changes, new changes complete with siblings,
 * complete object with new changes.
 */
export const deepObjDiff = (origObj: any, newObj: any) => {
  const changes = (newObj: any, origObj: any) => {
    let arrayIndexCounter = 0;
    return transform(
      newObj,
      (result: any, value, key) => {
        if (!isEqual(value, origObj[key])) {
          const resultKey = isArray(origObj) ? arrayIndexCounter++ : key;
          result[resultKey] =
            isObject(value) &&
            isObject(origObj[key]) &&
            !(value instanceof File) &&
            !isArray(origObj[key])
              ? changes(value, origObj[key])
              : value;
          if (
            isObject(result[resultKey]) &&
            !isArray(origObj[key]) &&
            !(value instanceof File) &&
            (isNil(result[resultKey]) || isEmpty(result[resultKey]))
          ) {
            delete result[resultKey];
          }
        }
      },
      {}
    );
  };
  const newChanges = origObj ? changes(newObj, origObj) : newObj;
  // Prioritize latest changes over original values, but take care of not keeping only changes in case of sub-objects
  const objWithChanges = mergeWith(
    origObj,
    newChanges,
    (objValue, srcValue) => {
      // If the value is an object and an array, keep the new value
      if (isObject(objValue) && isArray(objValue)) {
        return srcValue;
      }
      // return undefined for mergeWith to handle the rest
      return undefined;
    }
  );
  const newCompleteChanges = transform(
    newChanges,
    (result: any, _value, key) => {
      result[key] = cloneDeepWith(objWithChanges[key], (value: unknown) => {
        // If the value is a file, keep it the original value because file cannot be cloned
        if (value instanceof File) {
          return value;
        }
        // return undefined for cloneDeepWith to handle the rest
        return undefined;
      });
    }
  );
  return { newChanges, newCompleteChanges, objWithChanges };
};

/**
 * Deeply transforms any object and removes any property that is either
 * undefined, null, NaN, or empty object.
 *
 * @param object The source object.
 * @returns Returns the new object.
 */
export const deepOmitNil = (obj: any) => {
  return omitBy(
    omitBy(
      transform(obj, (result: any, value, key) => {
        if (!isArray(value) && isObject(value) && !(value instanceof File)) {
          const omittedValue = omitBy(omitBy(value, isNil), isNaN);
          if (Object.keys(omittedValue).length !== 0) {
            result[key] = omittedValue;
          }
        } else {
          result[key] = value;
        }
      }),
      isNil
    ),
    isNaN
  );
};

/**
 * Parses a number from either a string or number. If number is already a number
 * then it returns the number
 * @param value numerical value in either string or number type
 * @returns the parsed value in number
 */
export const parseFloatIfString = (value: string | number): number => {
  return typeof value === 'string' ? parseFloat(value) : value;
};

/**
 * Turns an input of type string | number into a number with 2 decimal places
 * @param input the input to format
 * @returns the formatted number
 *
 * Note: the '+' operator is used to convert the string back to a number
 */
export const formatTo2DecimalPlaces = (input: number | string): number => {
  const num = typeof input === 'string' ? parseFloat(input) : input;
  return +(Math.round(num * 100) / 100).toFixed(2);
};

/**
 * Capitalizes (or un-capitalize) words in a string
 * @param str
 * @param type 'Uppercase' | 'Lowercase' | 'First word' | 'Each Word'
 * 'Uppercase' returns all letters uppercase
 * 'Lowercase' returns all letters lowercase
 * 'First word' returns first word of string to uppercase
 * 'Each word' returns each word of string to uppercase
 * @returns formatted string depending on the selected type
 */
export const capitalizeWords = (
  str: string,
  type: 'Uppercase' | 'Lowercase' | 'First word' | 'Each word'
) => {
  if (!str) {
    return '';
  }
  const lowercaseStr = str.toLowerCase();
  switch (type) {
    case 'Each word':
      return lowercaseStr.replace(/(?:^|\s|["'([{-])+\S/g, match =>
        match.toUpperCase()
      );
    case 'First word':
      return lowercaseStr.charAt(0).toUpperCase() + lowercaseStr.slice(1);
    case 'Lowercase':
      return lowercaseStr;
    case 'Uppercase':
      return str.toUpperCase();
  }
};

/**
 *  Pluralizes the noun using the count
 * @param count number to pluralize
 * @param noun noun for the pluralization
 * @param suffix suffix for when noun is plural. Defaults to 's'
 * @returns plural form of the noun
 */
export const pluralize = (count: number, noun: string, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`;

/**
 * Creates initials from a given string
 * @param name string full name
 * @returns string initials from the full name
 */
export const createInitialsFromName = (name: string | undefined) => {
  return !!name
    ? name
        .match(/\b(\w)/g)
        ?.join('')
        .toUpperCase()
    : 'NP';
};

export const getMaskedNumber = (number: string) => {
  const endDigits = number.slice(-4);
  return endDigits.padStart(number.length, '*');
};

export const isTypeSelectionType = (item: any): item is SelectionType => {
  return !!item ? Object.keys(item).includes('label') : false;
};

/**
 * Creates the helper text below an input field, with character count and error message
 * @param currentCharacterCount number Current length of the input
 * @param characterLimit number The maximum input allowed
 * @param error string The error message to display, if any
 * @returns string Something like "10/100 - Error message"
 */
export const renderInputFieldHelperText = (
  currentCharacterCount = 0,
  characterLimit: number,
  error: string | undefined
) => {
  return `${currentCharacterCount}/${characterLimit}${
    error ? ` - ${error}` : ''
  }`;
};

/**
 * Capitalizes first letters of words in string.
 * @param string str String to be modified
 * @param boolean default false  = Whether all other letters should be lowercased
 * @return string
 * @usage
 *   capitalize('fix this string');     // -> 'Fix This String'
 *   capitalize('javaSCrIPT');          // -> 'JavaSCrIPT'
 *   capitalize('javaSCrIPT', true);    // -> 'Javascript'
 */
export const capitalizeEachWord = (str: string, lower = false): string => {
  if (!str) {
    return '';
  }
  return (lower ? str.toLowerCase() : str).replace(
    /(?:^|\s|["'([{-])+\S/g,
    match => match.toUpperCase()
  );
};

/**
 * Checks if the object has values in the given paths
 * @note The function will validate if all paths satisfies the validation.
 * In other words, 'empty' will validate if all paths are empty and 'notEmpty' will validate if all paths are notEmpty
 * @param obj object to check
 * @param paths paths to check
 * @param typeCheck 'empty' | 'notEmpty'
 * @returns boolean
 **/
export const checkValuesInObject = (
  obj: any,
  paths: (PropertyPath | PropertyObject)[],
  typeCheck: 'empty' | 'notEmpty' = 'notEmpty'
): boolean => {
  // check if all paths are empty or not empty depending on the typeCheck
  return paths?.every(keyPath => {
    // check if the path is an object
    if (typeof keyPath === 'object') {
      const { key: path, value, completeInfoCheck } = keyPath as PropertyObject;
      // check if value is defined, if it is, check if the value is equal to the value in the object
      // if it is not defined, check if the path exists in the object
      const equalResult =
        value !== undefined
          ? `${isEqual(result(obj, path), value)}`
          : result<string | number | symbol>(obj, path);
      // check if the path is in the completeInfoCheck object
      const infoCheck = completeInfoCheck[equalResult];
      // if it is, check the values in the object
      if (infoCheck) {
        // recursively check the values in the object
        return checkValuesInObject(obj, infoCheck, typeCheck);
      } else {
        // if it is not, return the result of the path
        return false;
      }
    } else {
      // if the path is not an object, return the result of the path
      const value = result(obj, keyPath);
      // check if the value is an object
      if (isObject(value)) {
        // depending on the typeCheck, check if the object is empty or not empty
        return typeCheck === 'empty'
          ? isNil(value) || isEmpty(value)
          : !isNil(value) && !isEmpty(value);
      }
      // depending on the typeCheck, check if the value is empty or not empty
      return typeCheck === 'notEmpty' ? !isNil(value) : isNil(value);
    }
  });
};

/**
 * Checks if the object has values in the given paths
 * @param obj object to check
 * @param paths object that include the paths and value to check
 * @returns boolean
 **/
export const checkCustomValuesInObject = (
  obj: any,
  paths: { key: PropertyPath; value: unknown }[]
) => {
  return paths?.every(data => {
    const { key, value: expectedValue } = data;
    const value = result(obj, key);
    if (isArray(expectedValue)) {
      if (isObject(value)) {
        const find = expectedValue.find((item: any) => isEqual(item, value));
        return !!find;
      } else {
        return expectedValue.includes(value);
      }
    }
    return isEqual(value, expectedValue);
  });
};

/**
 * Replaces a portion of a string with asterisks.
 * @param str - The input string.
 * @param start - The starting index of the portion to be replaced.
 * @param end - The ending index of the portion to be replaced.
 * @returns The modified string with the specified portion replaced by asterisks.
 * @throws {Error} If the end index is less than or equal to the start index.
 * @throws {Error} If the end index is greater than the length of the string.
 */
export const obscuredString = (str: string, start: number, end: number) => {
  if (end <= start) {
    console.error('End index must be greater than start index');
    return '***';
  }
  if (end > str.length) {
    console.error('End index must be less than string length');
    return '***';
  }
  return str.substring(0, start) + '*'.repeat(end - start) + str.substring(end);
};

/**
 * Rounds up a number to the nearest integer.
 * @param num - The number to round up.
 * @returns The rounded up integer value.
 */
export const roundUp = (num: number) => {
  return Math.ceil(num);
};
