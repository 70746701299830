import {
  styled,
  Dialog,
  DialogActions,
} from '@silverstein-properties/inspirelabs-ui';

export const DialogBox = styled(Dialog)({
  textAlign: 'center',
  alignItems: 'center',
});

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(5),
}));
