import { merchantVisibilityConfig } from '@/config/merchantVisibilityConfig';
import {
  EXPERIENCE_SECTIONS_MENUS,
  ExperienceDetailsMenuType,
  ExperienceDetailsSectionMenuType,
} from '@/constants';
import { BookingMethod, ExperienceLocation, Location } from '@/types';
import { MerchantType } from '@/types/Merchant';

export type ExperienceDetailsMenuVisibility = ExperienceDetailsMenuType & {
  visible: boolean;
};

export type ExperienceDetailsSectionMenuVisibility = Omit<
  ExperienceDetailsSectionMenuType,
  'menu'
> & {
  menu: ExperienceDetailsMenuVisibility[];
  visible: boolean;
};

/**
 * Get the experience detail menus AND the menu items' visibilities based on merchant type.
 * The ".experienceDetails" denotes the parent object (module/group) in "merchantVisibilityConfig.ts".
 * @param merchantType
 */
export function getExperienceDetailMenus(
  merchantType: MerchantType,
  bookingMethods: BookingMethod[] = [BookingMethod.INSTANT]
) {
  const experienceSections = getExperienceDetailSectionMenus(
    merchantType,
    bookingMethods
  );

  // Map over the "EXPERIENCE_DETAIL_MENUS" and add the "visible" prop to each menu item (look up using "menuItem.id").
  return experienceSections.reduce((acc, section) => {
    const hasMenu = section.menu.some(menuItem => menuItem.visible);
    if (hasMenu) {
      acc[section.id] = section;
    }
    return acc;
  }, {} as Record<string, ExperienceDetailsSectionMenuVisibility>);
}

/**
 * Get the experience detail menus AND the menu items' visibilities based on merchant type.
 * The ".experienceDetails" denotes the parent object (module/group) in "merchantVisibilityConfig.ts".
 * @param merchantType
 */
export function getExperienceDetailSectionMenus(
  merchantType: MerchantType,
  bookingMethods: BookingMethod[] = [BookingMethod.INSTANT]
) {
  const visibilityConfig =
    merchantVisibilityConfig[merchantType].experiencesDetails;

  // Map over the "EXPERIENCE_DETAIL_MENUS" and add the "visible" prop to each menu item (look up using "menuItem.id").
  return EXPERIENCE_SECTIONS_MENUS.map(section => {
    const menu = section.menu.map(menuItem => ({
      ...menuItem,
      visible:
        visibilityConfig[menuItem.id] &&
        menuItem.visibilityCheck.some(method =>
          bookingMethods.includes(method)
        ),
    }));
    const hasMenu = menu.some(menuItem => menuItem.visible);
    return {
      ...section,
      visible:
        section.visibilityCheck.some(method =>
          bookingMethods.includes(method)
        ) && hasMenu,
      menu: menu,
    };
  });
}

/**
 * Format a location object to an experience location object.
 * @param location
 * @returns ExperienceLocation
 **/
export function formatLocationToExperienceLocation(
  location: Location
): ExperienceLocation {
  return {
    id: location.id,
    name: location.name || '',
    info: location.description,
    type: location.type,
    environmentType: location.environmentType,
    address: location.address,
    url: location.url || '',
  };
}
