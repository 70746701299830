import { HookFormTextInput } from '@/components/FormTextInput/HookFormTextInput';
import { HookFormTextArea } from '@/components/FormTextInput/HookFormTextArea';
import {
  useForm,
  Controller,
  UseFormSetValue,
  UseFormWatch,
  Control,
} from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { TEXT_AREA_LIMIT_LONG } from '@/constants';
import { CreateListing, ProductTypes } from '@/types';
import {
  Box,
  MessageBanner,
  Typography,
  DesktopTimePicker,
  FormControl,
  InputField,
  DatePicker,
  useTheme,
  DropdownItem,
} from '@silverstein-properties/inspirelabs-ui';
import { AddListingInput, PricingInput, RepeatDaysModalInput } from '@/classes';
import { useFeatureFlags, useMerchantPlatformFee } from '@/hooks';
import {
  convertToLocalDate,
  formatDateShortForm,
  parseStringDateToTime,
} from '@/utils';
import { useState } from 'react';
import { FormHelperText } from '@mui/material';
import { PricingComponent } from '@/components';
import {
  determineTimeFrameMessage,
  formatRecurrenceModalString,
} from '@/utils/recurrenceUtils';
import { SetRepeatDaysModal } from '../SetRepeatDaysModal';
import { addMinutes } from 'date-fns';
import {
  TIME_INTERVALS,
  TIME_INTERVALS_BETWEEN,
} from '@/constants/recurringEvent';
import {
  StyledBox,
  StyledBoxFormControlContainer,
  StyledFormHelperText,
} from './AddListing.styles';

export enum RepeatOption {
  NONE = 'none',
  REPEAT = 'repeat',
}

export interface TimeIntervalDropDown extends DropdownItem {
  disable: boolean;
}

export type AddListingProps = {
  listingError: string | null;
  handleSubmit: (data: AddListingInput) => void;
  experienceType?: ProductTypes;
  listing: CreateListing;
  experienceLength?: number;
};

export const AddListing = ({
  listingError,
  handleSubmit,
  listing,
  experienceType,
  experienceLength = 0,
}: AddListingProps) => {
  const resolver = classValidatorResolver(AddListingInput);
  const theme = useTheme();
  const { isRecurringEventsEnabled } = useFeatureFlags();
  const { data: platformFeeRaw } = useMerchantPlatformFee(experienceType);
  const {
    register,
    control,
    formState,
    handleSubmit: useFormHandleSubmit,
    setValue,
    getValues,
    watch,
    setError,
  } = useForm<AddListingInput>({
    resolver,
    mode: 'onTouched',
    values: {
      date: listing.date || '',
      // TODO: cleanup the price and total amount and use basePrice instead
      price: listing.price || 0,
      totalAmount: listing.totalAmount || 0,
      ...(experienceType === ProductTypes.ONE_TIME_EVENT && {
        description: listing.description || '',
        address: listing.meetingPoint?.address?.street || '',
        apt: listing.meetingPoint?.address?.apartment || '',
        city: listing.meetingPoint?.address?.city || '',
        state: listing.meetingPoint?.address?.state || '',
        zip: listing.meetingPoint?.address?.zipCode || '',
      }),
      startTime:
        listing.startsAt || parseStringDateToTime(listing.date || '', 9, 0),
      endTime:
        listing.endsAt ||
        addMinutes(
          parseStringDateToTime(listing.date || '', 9, 0),
          experienceLength
        ),
      repeatDays: undefined,
      timeInterval: undefined,
    },
  });
  const { dirtyFields, errors } = formState;

  const [repeat, setRepeat] = useState(RepeatOption.NONE);
  const [timeInterval, setTimeInterval] = useState(0);
  const [timeBetweenInterval, setTimeBetweenInterval] = useState(0);
  const [recurrenceDisplayString, setRecurrenceDisplayString] =
    useState<string>('Set reoccurrence');
  const [modalOpen, setModalOpen] = useState(false);
  const [timeFrameMessage, setTimeFrameMessage] = useState<{
    message: string;
    error: boolean;
  }>({ message: '', error: false });

  const handleTimeFrameMessage = () => {
    const startTime = getValues('startTime');
    const endTime = getValues('endTime');
    const timeInterval = getValues('timeInterval');

    setTimeFrameMessage(
      determineTimeFrameMessage(startTime, endTime, timeInterval)
    );
  };

  const setNewEndTime = (newlySelectedDateTime: Date) => {
    const newEndTime = new Date(
      newlySelectedDateTime.getTime() + experienceLength * 60000
    );
    if (newEndTime.getDay() === newlySelectedDateTime.getDay()) {
      setValue('endTime', newEndTime);
    }
  };

  const onChangeStartTime = (newStartTime: Date) => {
    setNewEndTime(newStartTime);
    handleTimeFrameMessage();
  };
  const onChangeEndTime = () => {
    handleTimeFrameMessage();
  };

  const handleRepeatDaysOptionChange = (data: RepeatDaysModalInput) => {
    const displayRecurrence = formatRecurrenceModalString(
      data.selectedDays,
      data.until
    );
    setValue('repeatDays', data);
    setRecurrenceDisplayString(displayRecurrence);
  };

  const handleTimeInterval = (intervalSegment: number) => {
    const timeBetweenInterval = getValues('timeInterval.timeBetweenInterval');
    setValue('timeInterval', {
      intervalSegment,
      timeBetweenInterval: timeBetweenInterval || 0,
    });
    setTimeInterval(intervalSegment);
    handleTimeFrameMessage();
  };

  const handleTimeBetweenInterval = (timeBetweenInterval: number) => {
    const intervalSegment = getValues('timeInterval.intervalSegment');
    setValue('timeInterval', {
      intervalSegment: intervalSegment || 0,
      timeBetweenInterval,
    });
    setTimeBetweenInterval(timeBetweenInterval);
    handleTimeFrameMessage();
  };

  return (
    <>
      {/* INLINE ERROR BANNER */}
      {!!listingError && (
        <Box sx={{ mb: 2 }}>
          <MessageBanner type="error">{listingError}</MessageBanner>
        </Box>
      )}

      {/* SET RECURRENCE MODAL */}
      {modalOpen && (
        <SetRepeatDaysModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          handleRepeatDaysOptionChange={handleRepeatDaysOptionChange}
          currentDate={listing.startsAt || new Date()}
        />
      )}

      <Box
        component="form"
        onSubmit={useFormHandleSubmit(handleSubmit)}
        id="addListingForm"
        sx={{ gap: 0 }} // NOTE: Just a quick fix b/c currently "form > gap: 24px" was there in CSS. It causes weird spacings in the form
      >
        <Typography variant="h5" marginBottom={theme.spacers.l}>
          {listing.title}
        </Typography>
        <Typography variant="subtitle1" marginBottom={theme.spacers.m}>
          Date
        </Typography>
        {/*
         * A date string like "2024-03-22" to the DatePicker is interpreted as
         * being in UTC by default. The Montreal/NewYork timezone is behind UTC,
         * when this UTC date is converted to local timezone, it might show the previous day.
         * ---> Need to convert date string to local Date object
         */}
        <FormControl
          data-testid="dateTest"
          fullWidth
          sx={{ marginBottom: theme.spacers.m }}
        >
          <Controller
            name="date"
            data-testid="dateTest"
            control={control}
            render={({ field }) => (
              <DatePicker
                label="Date"
                data-testid="dateTest"
                value={convertToLocalDate(field.value)} // Convert the date string to a local date object. DatePicker will display the date in the local time zone
                onChange={newValue => {
                  field.onChange(formatDateShortForm(newValue as Date)); // Convert the local date object back to a date string (e.g. 2024-03-30) and store it in the form state
                }}
                onError={(error, value) =>
                  // Set the error message for the 'date' field
                  setError('date', {
                    message: `${error}: ${value}`,
                  })
                }
              />
            )}
          />
          <FormHelperText error={!!errors.date}>
            {!!errors.date ? errors.date.message : ''}
          </FormHelperText>
        </FormControl>
        {isRecurringEventsEnabled && (
          <InputField
            select
            label="Repeat"
            data-testid="repeatOccurrence"
            value={repeat}
            dropdownItems={[
              { label: 'Does not repeat', value: RepeatOption.NONE },
              {
                label: recurrenceDisplayString,
                value: RepeatOption.REPEAT,
              },
            ]}
            onChange={e => {
              setRepeat(e.target.value);
              if (e.target.value === RepeatOption.REPEAT) {
                setModalOpen(true);
              }
            }}
            sx={{ marginBottom: theme.spacers.l }}
          />
        )}
        <Typography variant="subtitle1" marginBottom={theme.spacers.m}>
          Time
        </Typography>
        <StyledBox>
          <StyledBoxFormControlContainer>
            <FormControl>
              <Controller
                name="startTime"
                data-testid="startTime"
                control={control}
                render={({ field: { onChange, ...field } }) => (
                  <DesktopTimePicker
                    label="From"
                    {...field}
                    onChange={val => {
                      onChange(val);
                      onChangeStartTime(val);
                    }}
                  />
                )}
              />
              <FormHelperText error={!!errors.startTime}>
                {!!errors.startTime ? errors.startTime.message : ''}
              </FormHelperText>
            </FormControl>
            <FormControl>
              <Controller
                name="endTime"
                data-testid="endTime"
                control={control}
                rules={{
                  required: { value: true, message: 'Please enter a time' },
                }}
                render={({ field: { onChange, ...field } }) => (
                  <DesktopTimePicker
                    label="To"
                    {...field}
                    onChange={val => {
                      onChange(val);
                      onChangeEndTime();
                    }}
                  />
                )}
              />
              <FormHelperText error={!!errors.endTime}>
                {!!errors.endTime ? errors.endTime.message : ''}
              </FormHelperText>
            </FormControl>
          </StyledBoxFormControlContainer>
          {timeFrameMessage.message && (
            <StyledFormHelperText error={timeFrameMessage.error}>
              {timeFrameMessage.message}
            </StyledFormHelperText>
          )}
        </StyledBox>
        {isRecurringEventsEnabled && (
          <StyledBox>
            <StyledBoxFormControlContainer>
              <FormControl fullWidth>
                <InputField
                  select
                  label="Intervals"
                  value={timeInterval}
                  dropdownItems={TIME_INTERVALS}
                  onChange={e => {
                    handleTimeInterval(e.target.value);
                  }}
                  sx={{ marginBottom: theme.spacers.s }}
                />
              </FormControl>

              <FormControl fullWidth>
                <InputField
                  select
                  label="Time Between Intervals"
                  value={timeBetweenInterval}
                  dropdownItems={TIME_INTERVALS_BETWEEN}
                  onChange={e => {
                    handleTimeBetweenInterval(e.target.value);
                  }}
                  sx={{ marginBottom: theme.spacers.s }}
                />
              </FormControl>
            </StyledBoxFormControlContainer>
            <StyledFormHelperText error={false}>
              This event repeats at this interval within the time frame
            </StyledFormHelperText>
          </StyledBox>
        )}
        {experienceType !== ProductTypes.FOOD_SERVICE && (
          <PricingComponent
            hideTitle={true}
            hidePaymentMethods={true}
            platformFeeRaw={platformFeeRaw}
            data-testid="totalAmount"
            formState={formState}
            control={control as unknown as Control<PricingInput>}
            setValue={setValue as unknown as UseFormSetValue<PricingInput>}
            watch={watch as unknown as UseFormWatch<PricingInput>}
          />
        )}
        {experienceType === ProductTypes.ONE_TIME_EVENT && (
          <>
            <Typography variant="subtitle1" marginBottom={theme.spacers.m}>
              Where is the experience?
            </Typography>
            <span className="inline-group" style={{ overflow: 'hidden' }}>
              <HookFormTextInput
                type="text"
                label="Address"
                testid="addressTest"
                width="form-l"
                isFilled={
                  !!listing.meetingPoint?.address?.street ||
                  !!Object.keys(dirtyFields).includes('address')
                }
                name="address"
                register={register}
                error={errors.address}
              />
              <HookFormTextInput
                isFilled={
                  !!listing.meetingPoint?.address?.apartment ||
                  !!Object.keys(dirtyFields).includes('apt')
                }
                register={register}
                name="apt"
                label="Apt./Fl."
                testid="aptTest"
                type="text"
                width="form-s"
                rounded="rounded-right"
              />
            </span>
            <span className="inline-group" style={{ overflow: 'hidden' }}>
              <HookFormTextInput
                isFilled={
                  !!listing.meetingPoint?.address?.city ||
                  !!Object.keys(dirtyFields).includes('city')
                }
                register={register}
                name="city"
                label="City"
                testid="cityTest"
                type="text"
                width="form-m"
                rounded="rounded-left"
                error={errors.city}
              />
              <HookFormTextInput
                isFilled={
                  !!listing.meetingPoint?.address?.state ||
                  !!Object.keys(dirtyFields).includes('state')
                }
                register={register}
                name="state"
                label="State"
                testid="stateTest"
                type="text"
                width="form-s"
                rounded="square-input"
                error={errors.state}
              />

              <HookFormTextInput
                isFilled={
                  !!listing.meetingPoint?.address?.zipCode ||
                  !!Object.keys(dirtyFields).includes('zip')
                }
                register={register}
                name="zip"
                label="ZipCode"
                testid="zipTest"
                type="text"
                width="form-s"
                rounded="rounded-right"
                error={errors.zip}
              />
            </span>
            <Typography variant="subtitle1">
              Describe where you'll meet
            </Typography>
            <HookFormTextArea
              size=""
              label="Meeting point description"
              testid="descriptionTest"
              limit={TEXT_AREA_LIMIT_LONG}
              name="description"
              isFilled={
                !!listing.description ||
                !!Object.keys(dirtyFields).includes('description')
              }
              register={register}
              error={errors.description}
            />
          </>
        )}
      </Box>
    </>
  );
};
