import { LabelField } from '@/components';
import { Experience } from '@/types';
import { Stack } from '@silverstein-properties/inspirelabs-ui';

export type CapacitySectionPropsType = {
  experience: Experience;
};

export const CapacitySection = ({ experience }: CapacitySectionPropsType) => {
  return (
    <Stack spacing={4}>
      <LabelField
        label="Max no. of guests"
        value={
          experience?.capacity?.max?.toString() ||
          'This experience has not set a capacity'
        }
      />
      <LabelField
        label="Guests"
        value={
          !!experience.groupBookingsAllowed
            ? 'This product allows group bookings'
            : 'This product does not allow group bookings'
        }
      />
    </Stack>
  );
};
