import MiniFileInput from 'components/FormTextInput/MiniFileInput';
import { BusinessSignupComponentProps } from './Component';
import { HookFormTextInput } from 'components/FormTextInput/HookFormTextInput';
import { HookFormTextArea } from 'components/FormTextInput/HookFormTextArea';
import { useState, useEffect } from 'react';
import { TEXT_AREA_LIMIT_LONG } from '@/constants';
import { useForm } from 'react-hook-form';

import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { useFormData } from '../../contexts/MerchCreationProvider';
import {
  Box,
  Button,
  MessageBanner,
  Svg,
  Typography,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import { AboutYouInput } from '@/classes/BusinessFormContainerInput';

const resolver = classValidatorResolver(AboutYouInput);

export function AboutYou(props: BusinessSignupComponentProps) {
  const theme = useTheme();
  const [warning, setWarning] = useState('');
  const { setFormValues, merchandiseData } = useFormData();
  const {
    register,
    formState: { dirtyFields, errors },
    handleSubmit,
  } = useForm<AboutYouInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      hostPhone: merchandiseData.hostPhone || '',
      hostBio: merchandiseData.hostBio || '',
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Disabled due to current implementation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (data: any) => {
    setFormValues(data);
    if (!props.values.hostPhoto) {
      setWarning('Please select a host photo');
    } else {
      delete data.hostPhoto;
      props.updateExperience(data, dirtyFields.hostPhoto);
      props.nextPage();
    }
  };

  const displayPrivacyLink = (
    <span className="privacy-policy">
      <span>
        Your phone will be shared with your confirmed guests 24 hours before the
        event. See our{' '}
        <a href="/privacy" target="_blank" rel="noopener norefferer">
          Privacy Policy.
        </a>
      </span>
    </span>
  );

  return (
    <div className="row ms-xl-5 ms-lg-5 me-md-5 ms-sm-1 ms-xs-1 top-64 d-flex justify-content-end">
      <div className="col-8">
        <Typography
          variant="h4"
          marginBottom={theme.spacers.xxl2}
          className="business-form-headline"
        >
          Tell the guests about you
        </Typography>
        <div className="theme-form">
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            {warning && (
              <Box sx={{ width: '100%' }}>
                <MessageBanner type="error">{warning}</MessageBanner>
              </Box>
            )}

            <Typography variant="subtitle1">
              {`${props.user?.firstName} ${props.user?.lastName}`}
            </Typography>
            <span className="merchandise-photo-wrapper">
              <MiniFileInput
                name="hostPhoto"
                testId="aboutTheHost.photo"
                handleChange={props.handleChange}
                value={props.values.hostPhoto}
                keyNumber="1"
                register={register}
                reset={props.reset}
              />
              <span className="merchandise-photo-explainer">
                Please provide a clear photo of your face
              </span>
            </span>
            <HookFormTextInput
              isFilled={
                Object.keys(dirtyFields).includes('hostPhone') ||
                !!merchandiseData.hostPhone
              }
              register={register}
              name="hostPhone"
              testid="aboutTheHost.phone"
              label="Phone"
              type="tel"
              width="form-xl"
              error={errors.hostPhone}
            />
            {displayPrivacyLink}
            <div className="business-form-subtitle mb-1">
              Add a short bio. Share your passion as a host and experience
              provider
            </div>
            <HookFormTextArea
              limit={TEXT_AREA_LIMIT_LONG}
              isFilled={
                Object.keys(dirtyFields).includes('hostBio') ||
                !!merchandiseData.hostBio
              }
              name="hostBio"
              testid="aboutTheHost.bio"
              label="Your bio"
              size="form-xl"
              register={register}
              error={errors.hostBio}
            />
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Button
                variant="text"
                onClick={props.prevPage}
                startIcon={
                  <Svg src="/images/chevron-left.svg" height={24} width={24} />
                }
              >
                Back
              </Button>
              <Button type="submit">Continue</Button>
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
}
