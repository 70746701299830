import { TEXT_AREA_LIMIT_MEDIUM_PLUS } from '@/constants';
import { Type } from 'class-transformer';
import {
  IsObject,
  IsString,
  MaxLength,
  MinLength,
  ValidateNested,
} from 'class-validator';

export class BookingRequestInfo {
  @MinLength(1, { message: 'Please enter a details about the booking request' })
  @MaxLength(TEXT_AREA_LIMIT_MEDIUM_PLUS, { message: 'Message is too long' })
  @IsString()
  info: string;
}

export class RequestCommunicationSectionEditInput {
  @IsObject()
  @ValidateNested()
  @Type(() => BookingRequestInfo)
  bookingRequestInfo: BookingRequestInfo;
}
