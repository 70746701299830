import './privacy.css';

export const Privacy = () => {
  return (
    <div className="container-fluid bg-secondary headings-line-height ">
      <div className="row pt-5 px-5 mx-5 d-flex justify-content-center">
        <div></div>
        <div className="col-8">
          <h2 className="my-5">Privacy Policy</h2>
          <div className="policy-text">
            The HUB exists to help build connections between people and make the
            world more open and inclusive. In short—to build a world where
            anyone can belong anywhere. We are a community built on trust. A
            fundamental part of earning that trust means being clear about how
            we use your information and protecting your human right to privacy.
          </div>
          <div className="policy-text">
            This Privacy Policy describes how The HUB, Inc. and its affiliates (
            <strong>“we,” “us,”</strong> or <strong>“The HUB”</strong>), process
            personal information in relation to your use of The HUB Platform.
            Depending on where you live and what you are doing on The HUB
            Platform, the supplemental privacy pages listed below may apply to
            you. Please follow the links and review the supplemental information
            describing how we process personal information for those regions and
            services.
          </div>

          <div className="col-10">
            <h5 className="my-2">1. DEFINITIONS</h5>
            Undefined terms in this Privacy Policy have the same definition as
            in our{' '}
            <a href="/" target="_blank;">
              Terms of Service
            </a>{' '}
            (<strong>“Terms”</strong>).
            <h5 className="my-2">2. PERSONAL INFORMATION WE COLLECT</h5>
            <strong>2.1 Information needed to use The HUB Platform.</strong>
            <br /> We collect personal information about you when you use the
            The HUB Platform. Without it, we may not be able to provide all
            services requested. This information includes:
            <ul>
              <li className="policy-list">
                <strong>
                  Contact Information, Account, Profile Information
                </strong>
                . Such as your first name, last name, phone number, home
                address, postal address, email address, date of birth, and
                profile photo, some of which will depend on the features you
                use.
              </li>
              <li className="policy-list">
                <strong>Identity Verification and Payment Information</strong>.
                Such as images of your government issued ID (as permitted by
                applicable laws), your ID number or other{' '}
                <a href="/" target="_blank;">
                  verification
                </a>{' '}
                information, bank account or payment account information. If you
                are not The HUB user, we may receive payment information
                relating to you, such as when an The HUB user provides your
                payment card to complete a booking. If a copy of your ID is
                provided to us, we may scan, use, and store information
                contained in your ID to verify your identity.
              </li>
            </ul>
            <p className="policy-text">
              <strong>2.2 Information you choose to give us.</strong>
              You can choose to provide us with additional personal information.
              This information may include:
            </p>
            <ul>
              <li className="policy-list">
                <strong>Additional Profile Information</strong>. Such as gender,
                preferred language(s), city, and personal description. Some of
                this information as indicated in your account settings is part
                of your public profile page and will be publicly visible.
              </li>
              <li className="policy-list">
                <strong>Information About Others</strong>. Such as a payment
                instrument belonging to another person or information about a
                co-traveler. By providing us with personal information about
                others, you certify that you have permission to provide that
                information to The HUB for the purposes described in this
                Privacy Policy, have shared The HUB Privacy Policy with them,
                and they have read and understood that it applies to them.{' '}
              </li>
              <li className="policy-list">
                <strong>Address Book Contact Information</strong>. Address book
                contacts you import or enter manually.
              </li>
              <li className="policy-list">
                <strong>Other Information</strong>. Such as when you fill in a
                form, add information to your account, respond to surveys, post
                to community forums, participate in promotions, communicate with
                our customer care team and other Members, or share your
                experience with us. This may include health information if you
                choose to share it with us.
              </li>
            </ul>
            <p className="policy-text">
              <strong>
                2.3 Information Automatically Collected by Using The HUB
                Platform and our Payment Services.
              </strong>
              When you use The HUB Platform and Payment Services, we
              automatically collect personal information. This information may
              include:
            </p>
            <ul>
              <li className="policy-list">
                <strong>Geo-location Information</strong>. Such as precise or
                approximate location determined from your IP address or mobile
                device's GPS depending on your device settings. We may also
                collect this information when you're not using the app if you
                enable this through your settings or device permissions.
              </li>
              <li className="policy-list">
                <strong>Usage Information</strong>. Such as the pages or content
                you view, searches for Listings, bookings you have made, and
                other actions on the HUB Platform.
              </li>
              <li className="policy-list">
                <strong>Log Data and Device Information</strong>. Such as
                details about how you've used the HUB Platform (including if you
                clicked on links to third party applications), IP address,
                access dates and times, hardware and software information,
                device information, device event information, unique
                identifiers, crash data, cookie data, and the pages you've
                viewed or engaged with before or after using the HUB Platform.
                We may collect this information even if you haven't created an
                The HUB account or logged in.
              </li>
              <li className="policy-list">
                <strong>Payment Transaction Information</strong>. Such as
                payment instrument used, date and time, payment amount, payment
                instrument expiration date and billing postcode, PayPal email
                address, IBAN information, your address, and other related
                transaction details.
              </li>
            </ul>
            <p className="policy-text">
              <strong>
                2.4 Personal Information We Collect from Third Parties.
              </strong>
              We collect personal information from other sources, such as:
            </p>
            <ul>
              <li className="policy-list">
                <strong>Third-Party Services</strong>. If you link, connect, or
                login to The HUB Platform with a third party service (e.g.
                Google, Facebook, WeChat), you direct the service to send us
                information such as your registration, friends list, and profile
                information as controlled by that service or as authorized by
                you via your privacy settings at that service.
              </li>
              <li className="policy-list">
                <strong>Background Information</strong>. For Members in the
                United States, to the extent permitted by applicable laws, we
                may obtain, for example, reports of criminal records, sex
                offender registrations, and other information about you and/or
                your background. For Members outside of the United States, to
                the extent permitted by applicable laws and with your consent
                where required, we may obtain the local version of police,
                background, or registered sex offender checks. We may use your
                information, including your full name and date of birth, to
                obtain such reports.
              </li>
              <li className="policy-list">
                <strong>
                  Enterprise Product Invitations and Account Management
                </strong>
                . Organizations that use our Enterprise products may submit
                personal information to facilitate account management and
                invitations to use enterprise products.
              </li>
              <li className="policy-list">
                <strong>Referrals and co-travelers</strong>. If you are invited
                to The HUB Platform, such as a co-traveler on a trip, the person
                who invited you can submit personal information about you such
                as your email address or other contact information.
              </li>
              <li className="policy-list">
                <strong>Other Sources</strong>. To the extent permitted by
                applicable law, we may receive additional information about you,
                such as{' '}
                <a href="/" target="_black;">
                  references
                </a>
                , demographic data, or information to help detect fraud and
                safety issues from third party service providers and/or
                partners, and combine it with information we have about you. For
                example, we may receive background check results or fraud
                warnings from identity verification service providers for use in
                our fraud prevention and risk assessment efforts. We may receive
                information about you and your activities on and off The HUB
                Platform, or about your experiences and interactions from our
                partners. We may receive health information, including but not
                limited to, health information related to contagious diseases.
              </li>
            </ul>
            <h5 className="my-2">3. HOW WE USE INFORMATION WE COLLECT</h5>
            <p className="policy-text">
              <strong>
                3.1 Provide, Improve, and Develop The HUB Platform.
              </strong>
              <br />
              <br />
              Such as to:
            </p>
            <ul>
              <li className="policy-list">
                enable you to receive Products and Services provided on The HUB,
              </li>
              <li className="policy-list">
                enable you to access The HUB Platform and make and receive
                payments,
              </li>
              <li className="policy-list">
                enable you to communicate with other Members,
              </li>
              <li className="policy-list">process your request,</li>
              <li className="policy-list">
                perform analytics, debug and conduct research,
              </li>
              <li className="policy-list">
                provide customer service, training,
              </li>
              <li className="policy-list">
                send you messages, updates, security alerts, and account
                notifications,
              </li>
              <li className="policy-list">
                if you provide us with your contacts' information, such as your
                friends or co-travelers, we may process this information: (i) to
                facilitate your referral invitations, (ii) to share your trip
                details and facilitate trip planning, (iii) for fraud detection
                and prevention, and (iv) to facilitate your requests or for any
                other purpose you authorize,
              </li>
              <li className="policy-list">
                personalize and customize your experience based on your
                interactions with The HUB Platform, your search and booking
                history, your profile information and preferences, and other
                content you submit, and
              </li>
              <li className="policy-list">
                enable your use of our enterprise products.
              </li>
            </ul>
            <p className="policy-text">
              <strong>
                3.2 Create and Maintain a Trusted and Safer Environment
              </strong>{' '}
              Including to:
            </p>
            <ul>
              <li className="policy-list">
                detect and prevent fraud, spam, abuse, security and safety
                incidents, and other harmful activity,
              </li>
              <li className="policy-list">
                study and combat discrimination consistent with our{' '}
                <a href="/" target="_blank;">
                  Nondiscrimination Policy,
                </a>
              </li>
              <li className="policy-list">
                conduct security investigations and risk assessments,
              </li>
              <li className="policy-list">
                verify or authenticate information provided by you,
              </li>
              <li className="policy-list">
                conduct checks against databases and other information sources,
                including background or police checks,
              </li>
              <li className="policy-list">
                comply with our legal obligations, protect the health and
                well-being of our Guests, Hosts, Hosts’ employees, and members
                of the public,
              </li>
              <li className="policy-list">
                resolve disputes with our Members, including sharing information
                with your co-Host or additional Guests about disputes related to
                your role as a co-Host or additional Guest,
              </li>
              <li className="policy-list">
                enforce our agreements with third parties,
              </li>
              <li className="policy-list">
                comply with law, respond to legal requests, prevent harm, and
                protect our rights (see section 4.5),
              </li>
              <li className="policy-list">
                enforce our{' '}
                <a href="/" target="_blank;">
                  Terms
                </a>{' '}
                and other policies (e.g.{' '}
                <a href="/" target="_blank;">
                  Nondiscrimination Policy
                </a>
                ), and
              </li>
              <li className="policy-list">
                in connection with the activities above, we may conduct
                profiling based on your interactions with The HUB Platform, your
                profile information and other content you submit to The HUB, and
                information obtained from third parties. In limited cases,
                automated processes, which analyze your account and activities
                on The HUB platform as well as information in relation to
                activities on and off The HUB platform that can be associated
                with you, could restrict or suspend access to The HUB Platform
                if such processes detect activity that may pose a safety or
                other risk to The HUB, our community, or third parties. If you
                would like to challenge decisions based on automated processes,
                please contact us via the Contact Information section below.
              </li>
            </ul>
            <p className="policy-text">
              <strong>
                3.3 Provide, Personalize, Measure, and Improve our Advertising
                and Marketing
              </strong>
              . For example to:
            </p>
            <ul>
              <li className="policy-list">
                send you promotional messages, marketing, advertising, and other
                information based on your preferences and social media
                advertising through social media platforms,
              </li>
              <li className="policy-list">
                personalize, measure, and improve our advertising,
              </li>
              <li className="policy-list">
                administer referral programs, rewards, surveys, sweepstakes,
                contests, or other promotional activities or events sponsored or
                managed by The HUB or its third-party partners,
              </li>
              <li className="policy-list">
                analyze characteristics and preferences to send you promotional
                messages, marketing, advertising, and other information that we
                think might be of interest to you, and
              </li>
              <li className="policy-list">
                invite you to events and relevant opportunities.
              </li>
            </ul>
            <p className="policy-text">
              <strong>3.4 Provide Payment services</strong>. Personal
              information is used to enable, or authorize third parties to use,
              Payment Services such as to:
            </p>
            <ul>
              <li className="policy-list">
                detect and prevent money laundering, fraud, abuse, and security
                incidents,
              </li>
              <li className="policy-list">
                conduct security investigations and risk assessments,
              </li>
              <li className="policy-list">
                comply with legal obligations (such as anti-money laundering
                regulations),
              </li>
              <li className="policy-list">
                enforce the{' '}
                <a href="/" target="_black;">
                  Payment Terms
                </a>{' '}
                and other payment policies,
              </li>
              <li className="policy-list">
                with your consent, send you promotional messages, marketing,
                advertising, and other information that may be of interest to
                you based on your preferences, and,
              </li>
              <li className="policy-list">
                provide and improve Payment Services.
              </li>
            </ul>
            <h5 className="my-2">4. SHARING & DISCLOSURE</h5>
            <p className="policy-text">
              If you reside outside of the United States, learn about safeguards
              we rely on for transferring personal information to recipients
              outside of the EEA{' '}
              <a href="/" target="_blank;">
                here
              </a>
              .
            </p>
            <p className="policy-text">
              <strong>
                4.1 Sharing With Your Consent or at Your Direction.
              </strong>
            </p>
            <p className="policy-text">
              Where you provide consent, we share your information as described
              at the time of consent, such as when authorizing a third-party
              application or website to access your The HUB account or
              participating in promotional activities by The HUB partners or
              third parties.
            </p>
            <p className="policy-text">
              Where permissible under applicable law, we may use certain
              information about you, such as your email address, de-identify it,
              and share it with social media platforms, to generate leads, drive
              traffic to The HUB, or otherwise promote our products and
              services.
            </p>
            <p className="policy-text">
              <strong>4.2 Sharing Between Members.</strong>
            </p>
            <p className="policy-text">
              To help facilitate bookings or other interactions between Members,
              we may need to share certain information such as:
            </p>
            <ul>
              <li className="policy-list">
                When a booking request is made, when there is a co-host, or a
                dispute is submitted, certain information may be shared between
                Guest(s) and Host(s), including profile, name, names of any
                additional Guests, cancellation history, review information, age
                of guest (unless prohibited by applicable law), dispute outcome
                (when applicable), address of where the Product or Service needs
                to be delivered, and other information you choose to share and
                submit. When a booking is confirmed, additional information is
                shared to assist with coordinating the trip, like profile photo
                and phone number. When you as a Host have a confirmed booking,
                certain information is shared with the Guest (and any additional
                Guests they invite, if applicable) to coordinate the booking,
                such as your profile, full name, phone number, and Listing
                address.
              </li>
              <li className="policy-list">
                When you as a Host invite another Member to host with you, you
                authorize that person to access and update your information and
                Member Content, including, but not limited to, certain
                information like your full name, phone number, Accommodation
                address, calendar, Listing information, Listing photos, and
                email address.
              </li>
              <li className="policy-list">
                When you as a Guest invite additional Guests to a booking, your
                name, travel dates, Host name, Listing details, Accommodation
                address, and other related information will be shared with each
                additional Guest.
              </li>
            </ul>
            <p className="policy-text">
              <strong>
                4.3 Information You Publish in Profiles, Listings, and other
                Public Information.
              </strong>
            </p>
            <p className="policy-text">
              You can make certain information publicly visible to others, such
              as:
            </p>
            <ul>
              <li className="policy-list">
                Your public profile page, which includes your profile photo,
                first name (or initials where applicable), description, and
                city.
              </li>
              <li className="policy-list">
                Listing pages that include information such as the Accommodation
                or Experience’s approximate or precise location description,
                calendar availability, profile photo, aggregated demand
                information (like page views over a period of time), and
                additional information you choose to share.
              </li>
              <li className="policy-list">
                Reviews, ratings, and other public feedback.
              </li>
              <li className="policy-list">
                Content in a community or discussion forum, blog, or social
                media post.
              </li>
            </ul>
            <p className="policy-text">
              We may display parts of your public profile and other Content you
              make available to the public like Listing details on third-party
              sites, platforms, and apps.
            </p>
            <p className="policy-text">
              We may display parts of your public profile and other Content you
              make available to the public like Listing details on third-party
              sites, platforms, and apps.
            </p>
            <p className="policy-text">
              <strong>4.4 Host Service Providers.</strong>
            </p>
            <p className="policy-text">
              Hosts may use third-party services to help manage or deliver their
              services, such as cleaning services or lock providers. Hosts may
              use features on the HUB Platform to share information about the
              Guest (like check-in and check-out dates, Guest name, Guest phone
              number) with such third-party service providers.
            </p>
            <strong>
              4.5 Complying with Law, Responding to Legal Requests, Preventing
              Harm and Protecting our Rights.
            </strong>
            <p className="policy-text">
              We may disclose your information to courts, law enforcement,
              governmental or public authorities, tax authorities, or authorized
              third parties, if and to the extent we are required or permitted
              to do so by law or where disclosure is reasonably necessary to:
              (i) comply with our legal obligations, (ii) comply with a valid
              legal request (such as a subpoena or court order) or to respond to
              claims asserted against The HUB, (iii) respond to a valid legal
              request relating to a criminal investigation to address alleged or
              suspected illegal activity, or to respond to or address any other
              activity that may expose us, you, or any other of our users to
              legal or regulatory liability (more information on The HUB's Law
              Enforcement Guidelines{' '}
              <a href="/" target="_blank;">
                here
              </a>
              ), (iv) enforce and administer our{' '}
              <a href="/" target="_blank;">
                agreements
              </a>{' '}
              with Members, including our{' '}
              <a href="/" target="_blank;">
                Terms
              </a>
              ,{' '}
              <a href="/" target="_blank;">
                Additional Legal Terms, and Policies
              </a>
              , or (v) protect the rights, property or personal safety of The
              HUB, its employees, its Members, or members of the public. For
              example, if permitted due to the foregoing circumstances, Host tax
              information may be shared with tax authorities or other
              governmental agencies.
            </p>
            <p className="policy-text">
              Where appropriate, we may notify Members about legal requests
              unless: (i) providing notice is prohibited by the legal process
              itself, by court order we receive, or by applicable law, or (ii)
              we believe that providing notice would be futile, ineffective,
              create a risk of injury or bodily harm to an individual or group,
              or create or increase a risk of fraud upon or harm to The HUB, our
              Members, or expose The HUB to a claim of obstruction of justice.
            </p>
            <p className="policy-text">
              Where legally required or permissible according to applicable law,
              we may disclose Hosts' and/or Guests' information to tax
              authorities for the purpose of the tax authorities' determination
              of proper compliance with relevant tax obligations. Relevant tax
              obligations include The HUB's tax obligations on its service fees,
              its facilitation of taxes on accommodations and withholding taxes,
              and Hosts' individual tax obligations. Information that may be
              disclosed includes, but is not limited to, Host and Guest names,
              listing addresses, Host addresses, tax/business identification
              number(s), date of birth, and/or contact information, property
              parcel identification numbers, payout information, transaction
              dates and amounts, number of nights and Guests booked, gross and
              net booking value and payout amounts, taxes collected by The HUB
              on behalf of Guests and Hosts, to the extent any of this
              information is known by The HUB.
            </p>
            <p className="policy-text">
              In jurisdictions where The HUB facilitates or requires a
              registration, notification, permit, or license application or
              number of a Host with a governmental authority, we may share
              information of participating Hosts with the relevant authority,
              both during the application process, when the Listing is
              published, and periodically thereafter, such as the Host's full
              name and contact details, Accommodation address, tax
              identification number, registration, permit, or license number,
              Listing details, reservation information, and number of nights
              booked subject to applicable laws.
            </p>
            <p className="policy-text">
              <strong>4.6 Programs with Managers and Owners.</strong>
            </p>
            <p className="policy-text">
              We may share personal information of Hosts and Guests with
              landlords, management companies, and/or property owners (“
              <strong>Building Management</strong>”), such as booking
              information and information related to compliance with applicable
              laws, in order to facilitate programs with Building Management.
              For example, guest booking and personal information, including
              guest contact information, may be shared with the Building
              Management of the building, complex, or community where a host
              lives and/or the listing is located to facilitate hosting
              services, compliance with applicable laws, security, billing, and
              other services.
            </p>
            <p className="policy-text">
              <strong>
                4.7 Host Information Provided to The HUB for Work Customers.
              </strong>
            </p>
            <p className="policy-text">
              If a booking is designated as being for a business or work purpose
              and (1) is made by a Guest affiliated with an Enterprise and (2)
              the Enterprise is enrolled in The HUB for Work, we may disclose
              information related to the booking to the Enterprise (e.g., name
              of the Host, Accommodation address, booking dates, Listing
              details, etc.) to the extent necessary for the adequate
              performance of The HUB's contract with the Enterprise and to
              provide the services. At the request of the Enterprise or the
              Guest, we may also share this information with third parties
              engaged by the Enterprise to provide support services.
            </p>
            <p className="policy-text">
              <strong>4.8 Service Providers.</strong>
            </p>
            <p className="policy-text">
              We share personal information with affiliated and unaffiliated
              service providers to help us run our business and for their
              compliance purposes, including service providers that help us: (i)
              verify your identity or authenticate your identification
              documents, (ii) check information against public databases, (iii)
              conduct background or police checks, fraud prevention and risk
              assessment, (iv) perform product development, maintenance and
              debugging, (v) allow the provision of The HUB Services through
              third-party platforms and software tools (e.g. through the
              integration with our APIs), (vi) provide customer service,
              advertising, or payments services, or (vii) process, handle or
              assess insurance claims or similar claims. These providers are
              contractually bound to protect your personal information and have
              access to your personal information to perform these tasks.
            </p>
            <p className="policy-text">
              <strong>4.9 Business Transfers.</strong>
            </p>
            <p className="policy-text">
              If The HUB undertakes or is involved in any merger, acquisition,
              reorganization, sale of assets, bankruptcy, or insolvency event,
              then we may sell, transfer, or share some or all of our assets,
              including your information in connection with such transaction or
              in contemplation of such transaction (e.g., due diligence). In
              this event, we will notify you before your personal information is
              transferred and becomes subject to a different privacy policy.
            </p>
            <p className="policy-text">
              <strong>4.10 Corporate Affiliates.</strong>
            </p>
            <p className="policy-text">
              To support us in providing, integrating, promoting and improving
              The HUB Platform, Payment Services, and our affiliates' services,
              we may share personal information within our corporate family of
              companies that are related by common ownership or control. Some
              examples are:
            </p>
            <ul>
              <li className="policy-list">
                <strong>Sharing with The HUB Payments</strong>. In order to
                facilitate payments on or through The HUB Platform, certain
                information as described in the “
                <a href="/" target="_blank;">
                  Outside of the United States
                </a>
                ” section, will be shared with the relevant The HUB Payments
                entity.
              </li>
              <ul>
                <li className="policy-list">
                  <strong>Host Services</strong>. If you inquire about or book a
                  Host Service located in China, information shared includes:
                  (i) your name, phone number, and email address (ii) booking
                  and check-in/out information, including city, dates, times,
                  payment amount, listing address and publicly available data
                  about your listing, (iii) messages between the Host and you or
                  other Guests on the same booking, and (iv) the names,
                  nationalities, gender, date of birth, and passport/national ID
                  details (including type of ID, ID number and expiry date) of
                  any Guests.{' '}
                  <a href="/" target="_blank;">
                    Learn more
                  </a>
                </li>
                <li className="policy-list">
                  <strong>Sending Messages</strong>. If you send a message to a
                  Host in relation to that Host's Listing in China, information
                  shared includes your name, profile picture, and message
                  content.{' '}
                  <a href="/" target="_blank;">
                    Learn more
                  </a>
                </li>
              </ul>
            </ul>
            <p className="policy-text">
              The data sharing described above is necessary for the performance
              of the contract between you and us to enable you to list or book
              Host Services in China and connect with Members in China, and vice
              versa. Chinese government agencies require The HUB China to
              disclose host and listing information relating to China listings,
              and guest booking and check-in/out information relating to
              reservations in China. Similar to other hospitality or short term
              rental companies that do business in China, The HUB China will
              disclose your information above to Chinese government agencies
              without further notice to you. Your information may be further
              shared with service providers (including in China) to help run our
              business. Our service providers may also disclose your information
              where required by law.{' '}
            </p>
            <h5 className="my-2">5. OTHER IMPORTANT INFORMATION</h5>
            <p className="policy-text">
              <strong>5.1 Analyzing your Communications.</strong>
            </p>
            <p className="policy-text">
              We may review, scan, or analyze your communications on The HUB
              Platform for reasons outlined in the “How We Use Information We
              Collect” section of this policy, including fraud prevention, risk
              assessment, regulatory compliance, investigation, product
              development, research, analytics, enforcing our{' '}
              <a href="/" target="_blank;">
                Terms of Service
              </a>
              , and customer support purposes. For example, as part of our fraud
              prevention efforts, we scan and analyze messages to mask contact
              information and references to other sites. In some cases, we may
              also scan, review, or analyze messages to debug, improve, and
              expand product offerings. We use automated methods where
              reasonably possible. Occasionally we may need to manually review
              communications, such as for fraud investigations and customer
              support, or to assess and improve the functionality of these
              automated tools. We will not review, scan, or analyze your
              messaging communications to send third-party marketing messages to
              you and we will not sell reviews or analyses of these
              communications.
            </p>
            <p className="policy-text">
              <strong>5.2 Linking Third-Party Accounts.</strong>
            </p>
            <p className="policy-text">
              You can link your The HUB account with certain third-party
              services like social networks. Your contacts on these third-party
              services are referred to as “Friends.” When you direct the data
              sharing by creating this link:
            </p>
            <ul>
              <li className="policy-list">
                some of the information provided to us from linking accounts may
                be published on your public profile,
              </li>
              <li className="policy-list">
                your activities on the HUB Platform may be displayed to your
                Friends on the HUB Platform and/or that third-party service,
              </li>
              <li className="policy-list">
                a link to your public profile on that third-party service may be
                included in your The HUB public profile,
              </li>
              <li className="policy-list">
                other The HUB users may be able to see any Friends that you may
                have in common with them, or that you are a Friend of their
                Friend if applicable,
              </li>
              <li className="policy-list">
                other The HUB users may be able to see any schools, hometowns,
                or other groups you have in common with them as listed on your
                linked social networking service,
              </li>
              <li className="policy-list">
                information you provide to us from the linking of your accounts
                may be stored, processed, and transmitted for fraud prevention
                and risk assessment purposes, and
              </li>
              <li className="policy-list">
                publication and display of information that you provide to the
                The HUB Platform through this linkage is subject to your
                settings and authorizations on The HUB Platform and the
                third-party service.
              </li>
            </ul>
            <p className="policy-text">
              <strong>5.3 Third-Party Partners & Integrations.</strong>
            </p>
            <p className="policy-text">
              Parts of The HUB may link to third-party services, not owned or
              controlled by The HUB, such as Google Maps/Earth. Use of these
              services is subject to the privacy policies of those providers,
              such as{' '}
              <a href="/" target="_blank;">
                Google Maps/Earth Additional Terms of Use
              </a>
              ,{' '}
              <a href="/" target="_blank;">
                Google Privacy Policy
              </a>{' '}
              (see here for more information on how Google uses information),
              and{' '}
              <a href="/" target="_blank;">
                Citi Privacy Policy
              </a>
              . The HUB does not own or control these third parties and when you
              interact with them you are providing your information to them.
            </p>
            <h5 className="my-2">6. YOUR RIGHTS</h5>
            <p className="policy-text">
              You can exercise any of the rights described in this section
              consistent with applicable law. See{' '}
              <a href="/" target="_blank;">
                here
              </a>{' '}
              for information on data subject rights requests and how to submit
              a request. We may ask you to verify your identity and request
              before taking further action on your request.
            </p>
            <p className="policy-text">
              <strong>6.1 Managing Your Information.</strong>
            </p>
            <p className="policy-text">
              You can access and update some of your personal information
              through your Account settings. If you connected your The HUB
              Account to a third-party service, like Facebook or Google, you can
              change your settings and unlink from that service in your Account
              settings. You are responsible for keeping your personal
              information up to date.
            </p>
            <p className="policy-text">
              <strong>6.2 Data Access and Portability.</strong>
            </p>
            <p className="policy-text">
              In some jurisdictions, applicable law may entitle you to request
              certain copies of your personal information or information about
              how we handle your personal information, request copies of
              personal information that you have provided to us in a structured,
              commonly used, and machine-readable format, and/or request that we
              transmit this information to another service provider (where
              technically feasible).
            </p>
            <p className="policy-text">
              <strong>6.3 Data Erasure.</strong>
            </p>
            <p className="policy-text">
              In some jurisdictions, you can request that your personal
              information be deleted. Please note that if you request deletion
              of your personal information, or if your account is suspended,
              terminated, or voluntarily closed:
            </p>
            <ul>
              <li className="policy-list">
                We may retain your personal information as necessary for our
                legitimate business interests, such as prevention of money
                laundering, fraud detection and prevention, and enhancing
                safety. For example, if we suspend an The HUB Account for fraud
                or safety reasons, we may retain information from that The HUB
                Account to prevent that Member from opening a new The HUB
                Account in the future.
              </li>
              <li className="policy-list">
                We may retain and use your personal information to the extent
                necessary to comply with our legal obligations. For example, The
                HUB and The HUB Payments may keep information for tax, legal
                reporting, and auditing obligations.
              </li>
              <li className="policy-list">
                Information you have shared with others (e.g., Reviews, forum
                postings) will continue to be publicly visible on The HUB, even
                after your The HUB Account is canceled. However, attribution of
                such information to you will be removed. Some copies of your
                information (e.g., log records) will remain in our database, but
                are disassociated from personal identifiers.
              </li>
              <li className="policy-list">
                Because we take measures to protect data from accidental or
                malicious loss and destruction, residual copies of your personal
                information may not be removed from our backup systems for a
                limited period of time.
              </li>
            </ul>
            <h5 className="my-2">7. SECURITY</h5>
            <p className="policy-text">
              While no organization can guarantee perfect security, we are
              continuously implementing and updating administrative, technical,
              and physical security measures to help protect your information
              against unauthorized access, loss, destruction, or alteration.
            </p>
            <h5 className="my-2">8. CHANGES TO THIS PRIVACY POLICY</h5>
            <p className="policy-text">
              We reserve the right to modify this Privacy Policy at any time in
              accordance with applicable law. If we do so, we will post the
              revised Privacy Policy and update the “Last Updated” date at the
              top. In case of material changes, we will also provide you with
              notice of the modification by email at least thirty (30) days
              before the effective date. If you disagree with the revised
              Privacy Policy, you can cancel your Account. If you do not cancel
              your Account before the date the revised Privacy Policy becomes
              effective, your continued access to or use of The HUB Platform
              will be subject to the revised Privacy Policy.
            </p>
            <h5 className="my-2">
              9. CONTACT INFORMATION AND RESPONSIBLE THE HUB ENTITIES
            </h5>
            <p className="policy-text">
              For questions or complaints about this Privacy Policy or The HUB’s
              handling of personal information (i) If you reside in the United
              States contact The HUB, Inc., Legal Privacy, 888 Brannan Street,
              San Francisco, CA 94103 USA; (ii) for payments related matter
              please use the contact information provided in the{' '}
              <a href="/" target="_blank;">
                Payments Terms of Service
              </a>{' '}
              page, and (iii) if you reside outside the United States, please
              use the contact information for your controller provided in the{' '}
              <a href="/" target="_blank;">
                Outside of the United States
              </a>{' '}
              page.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
