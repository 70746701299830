import { Paper } from '@mui/material';
import { styled } from '@silverstein-properties/inspirelabs-ui';

export type StyledPaperProps = {
  isDisabled: boolean;
  isSelected: boolean;
  spacing: number;
};

export const StyledPaper = styled(Paper, {
  shouldForwardProp: prop =>
    prop !== 'isDisabled' && prop !== 'isSelected' && prop !== 'spacing',
})<StyledPaperProps>(({ theme, isDisabled, isSelected, spacing }) => ({
  borderRadius: theme.shape.borderRadius / 2,
  padding: theme.spacing(spacing),
  border: `1px solid ${
    isSelected ? theme.palette.primary.main : theme.palette.grey[400]
  }`,
  outline: `1px solid ${
    isSelected ? theme.palette.primary.main : 'transparent'
  }`,
  cursor: isDisabled ? 'initial' : 'pointer',
  width: '100%',
  opacity: isDisabled ? 0.5 : 1,
  '&:hover': !isDisabled
    ? {
        border: `1px solid ${theme.palette.primary.main}`,
        outline: `1px solid ${theme.palette.primary.main}`,
      }
    : {},
}));
